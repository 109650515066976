import { useEffect, useRef, useState } from 'react';
import { useLead } from '../lead.hook';
import { AddLeadComponent } from './add-lead.component';
import { LeadGridViewComponent } from './gridView/lead-grid-view.component';
import PageHeader from '../../common/page-header.component';
import { MainLeadTableComponent } from './table.component';
import { pushEvent } from '../../../event/analytics.helper';
import { EventName } from '../../../event/event-name.enum';
import { SecurityManager } from '../../../security/security-manager';
import { useLocation } from 'react-router-dom';
import { useMapLocation } from '../../../location/location.hook';

export const MainLeadComponent = () => {

    const { state } = useLocation();
    const { location } = useMapLocation()

    const [gridView, setGridView] = useState(true)

    const searchInterval = useRef<any>()

    const { leadSearchReq, fetchLeadList, updateLoading } = useLead()
    // const [addNewLead, setOpenNewLead] = useState(false)


    // const updateOpenNewLead = () => {
    //     if (addNewLead) {
    //         fetchLeadList({ ...leadSearchReq, pageNumber: 1 }, () => { updateLoading(false) }, () => { updateLoading(false) })
    //     }
    //     setOpenNewLead(prevVal => !prevVal);
    // }

    useEffect(() => {
        if (state && state.status && state.status.length > 0) {
            const _leadSearchReq = { ...leadSearchReq }
            delete _leadSearchReq.startDate
            delete _leadSearchReq.endDate
            fetchLeadList({
                ..._leadSearchReq,
                statusList: [state.status],
                assigneeIdList: state.filterData?.assigneeIdList ? [...state.filterData?.assigneeIdList] : [],
                sourceList: state.filterData?.sourceList ? [...state.filterData?.sourceList] : [],
                ...(state.filterData?.startDate && { startDate: state.filterData?.startDate }),
                ...(state.filterData?.endDate && { endDate: state.filterData?.endDate }),
            }, () => { updateLoading(false) }, () => { updateLoading(false) })
        } else {
            fetchLeadList({ ...leadSearchReq, pageNumber: 1 }, () => { updateLoading(false) }, () => { updateLoading(false) })
        }
    }, [state])

    const searchFunction = (text: string) => {
        updateLoading(true)
        if (searchInterval.current) {
            clearTimeout(searchInterval.current)
        }
        searchInterval.current = setTimeout(() => {
            fetchLeadList({ ...leadSearchReq, searchText: text, pageNumber: 1 }, () => { updateLoading(false) }, () => { updateLoading(false) })
        }, 500)
    }

    useEffect(() => {
        pushEvent(EventName.LEAD_PAGE_VIEW, {
            employeeId: SecurityManager.getEid(),
            location: location,
            createdOn: new Date()
        })
    }, [])

    return <>

        {/* <PageHeader heading={"Lead Manager"} search={leadSearchReq} addNewMethod={updateOpenNewLead} setGridView={setGridView}
            searchFunction={searchFunction} /> */}

        <PageHeader heading={"Lead Manager"} search={leadSearchReq} setGridView={setGridView}
            searchFunction={searchFunction} />

        {gridView ? <LeadGridViewComponent /> : <MainLeadTableComponent />}

        {/* {addNewLead &&
            <AddLeadComponent open={addNewLead} onCancel={updateOpenNewLead} />
        } */}
    </>
}