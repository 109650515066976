import { Row, Col, Typography, Card, theme } from "antd"
import Meta from "antd/es/card/Meta"
import { EnquiryNoteRes } from "../../../../../response/enquiry-note.request"
import dayjs from "dayjs"
import ReactAudioPlayer from "react-audio-player"
import { CallRes } from "../../../../../response/call.response"


export interface EnquiryFollowUpCardViewComponentProp {
    enquiryNote: EnquiryNoteRes
}

export const EnquiryFollowUpCardViewComponent = (props: EnquiryFollowUpCardViewComponentProp) => {

    const { enquiryNote } = props

    const { useToken } = theme;
    const { token } = useToken();

    return <Card
        style={{
            padding: '0 20px',
            marginBottom: '8px'
        }}
        headStyle={{
            padding: 0
        }}
        bodyStyle={{
            padding: '15px 0'
        }}>
        <Row>
            <Col md={12} xs={24} style={{
                paddingRight: '50px'
            }}>
                <Row style={{
                    marginBottom: '20px'
                }}>
                    <Col md={12} xs={24}>
                        <Meta
                            title={"Type of Follow up"}
                            description={enquiryNote.type}
                        />
                    </Col>
                </Row>
                <Row style={{
                    marginBottom: '20px'
                }}>
                    <Col md={8} xs={24}>
                        <Meta
                            title={"Status"}
                            description={enquiryNote.status}
                        />
                    </Col>
                    <Col md={8} xs={24}>
                        <Meta
                            title={"Next Follow up"}
                            description={dayjs(enquiryNote.dueDate).format("DD-MM-YYYY")}
                        />
                    </Col>
                    <Col md={8} xs={24}>
                            <Meta
                                title={"Recordings"}
                                description={enquiryNote.callList?.map((callRes: CallRes) => {
                                    return <ReactAudioPlayer controls={true} src={callRes.recording?.url?.servetel} />
                                })}
                            />
                        </Col>
                </Row>

            </Col>
            <Col md={12} xs={24} style={{
                paddingLeft: '50px'
            }}>

                <Typography.Title level={5} style={{
                    fontSize: '14px'
                }}>Response</Typography.Title>
                <Typography.Paragraph>{enquiryNote.description}</Typography.Paragraph>

            </Col>
        </Row>
    </Card>
}