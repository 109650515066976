export enum EnquiryAction {
    UPDATE_LOADING = "UPDATE_LOADING",
    ADD_ENQUIRY_LIST = "ADD_ENQUIRY_LIST",
    UPDATE_ENQUIRY_LIST = "UPDATE_ENQUIRY_LIST",
    UPDATE_ENQUIRY_SEARCH_REQ = "UPDATE_ENQUIRY_SEARCH_REQ",
    SET_UPDATE_ENQUIRY = "SET_UPDATE_ENQUIRY",
    UPDATE_EMPLOYEE_LIST = "UPDATE_EMPLOYEE_LIST",
    UPDATE_PROJECT_LIST = "UPDATE_PROJECT_LIST",
    UPDATE_ENQUIRY = "UPDATE_ENQUIRY",
    UPDATE_CLIENT = "UPDATE_CLIENT"
}