import { Button, Checkbox, Col, Form, Input, Row, Space } from "antd";
import { useLogIn } from "../login.hook";
import { validatePhone } from "../../../validator/user-info.validator";

export const MobileComponent = () => {
  const [form] = Form.useForm();
  const { sendOtp, updateMobile } = useLogIn();

  const _sentOtp = () => {
    form.validateFields().then((values) => {
      sendOtp(values["mobile"]);
    });
  };

  return (
    <div className="form-content">
      <Form
        form={form}
        initialValues={{ remember: true }}
        layout="vertical"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <Form.Item
          label={<label style={{ color: "#FFFFFF" }}>Enter Mobile Number</label>}
          name="mobile"
          style={{color: "#"}}
          rules={[{ required: true, validator: validatePhone }]}
        >
          <Space.Compact>
            <Row gutter={8}>
              <Col span={5}>
                <Input defaultValue="+91" />
              </Col>
              <Col>
                <Input name="mobile" placeholder="99876#####" />
              </Col>
            </Row>
          </Space.Compact>
        </Form.Item>

        <Form.Item wrapperCol={{ span: 16 }}>
          <Button type="primary" htmlType="submit" onClick={_sentOtp}>
            Proceed
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};