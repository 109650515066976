import { LeadStateProvider } from './lead.context';
import { Outlet } from 'react-router-dom';

const LeadPageBase = () => {

  return <>
    <Outlet />
  </>
}

export const LeadPage = () => {
  return <LeadStateProvider>
    <LeadPageBase />
  </LeadStateProvider>
}
