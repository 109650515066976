import { TargetStateProvider } from './target.context';
import { Outlet } from 'react-router-dom';

const TargetPageBase = () => {

    return <>
        <Outlet />
    </>
}


export const TargetPage = () => {
    return <TargetStateProvider>
        <TargetPageBase />
    </TargetStateProvider>
}

