import { BulkLeadUpdateReq, LeadReportReq, LeadRequest, LeadSearchReq } from "../request/lead.request";
import { LeadReportRes, LeadRes } from "../response/lead.response";
import httpClient from "../util/http-client"
const FileDownload = require('js-file-download');

export const LeadService = {
    saveLead: async (leadRequest: LeadRequest): Promise<(LeadRes | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/lead", leadRequest)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    getLeadById: async (id: string): Promise<(LeadRes | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.get(`/lead/${id}`)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    getLeadList: async (leadSearchReq: LeadSearchReq): Promise<(LeadRes[] | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/lead-search", leadSearchReq)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    updateLead: async (id: string, leadRequest: LeadRequest): Promise<(LeadRes | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.put(`/lead/${id}`, leadRequest)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    updateBulkLead: async (bulkLeadUpdateReq: BulkLeadUpdateReq): Promise<(any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post(`/lead-bulk-update`, bulkLeadUpdateReq)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    getReport: async (leadReportReq: LeadReportReq): Promise<(LeadReportRes[])[]> => {
        let data
        let err
        try {
            const res = await httpClient.post(`/lead-report`, leadReportReq)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    downLoadList: async (leadSearchReq: LeadSearchReq) => {
        httpClient.post("/lead-download", leadSearchReq, { responseType: 'blob' }).then((response: any) => {
            FileDownload(response.data, 'lead.csv');
        })
    },
}
