import { Row, Col, Typography, Button, Card, theme, DatePicker, Form, Select } from "antd"
import Meta from "antd/es/card/Meta"
import { useState } from "react"
import { EnquiryNoteRes } from "../../../../../response/enquiry-note.request"
import dayjs from "dayjs"
import ImgIcon from "../../../../../view/img-icon"
import TextArea from "antd/es/input/TextArea"
import { useEnquiry } from "../../../enquiry.hook"
import { EnquiryNoteRequest } from "../../../../../request/enquiry-note.request"
import { EnquiryNoteService } from "../../../../../service/enquiry-note.service"
import { handleFilter } from "../../../../../view/input.helper"
import { EnquiryRes } from "../../../../../response/enquiry.response"
import { CallRes } from "../../../../../response/call.response"
import ReactAudioPlayer from "react-audio-player"
import { isMobile } from "../../../../../util/device-helper"


export interface FollowUpCardViewComponentProp {
    enquiry: EnquiryRes
    enquiryNote: EnquiryNoteRes
    fetchEnquiryNote: (enquiryId: string) => void
}

export const FollowUpCardViewComponent = (props: FollowUpCardViewComponentProp) => {

    const { enquiry, enquiryNote, fetchEnquiryNote } = props

    const { useToken } = theme;
    const { token } = useToken();

    const { noteStatusList, noteTypeList } = useEnquiry()

    const [edit, setEdit] = useState(false)

    const [form] = Form.useForm()

    const type = Form.useWatch('type', form);

    const updateNote = () => {
        form.validateFields().then(async values => {
            const enquiryNoteRequest = {
                type: values["type"],
                status: values["status"],
                description: values["description"],
                dueDate: values["dueDate"]
            } as EnquiryNoteRequest

            const [data, error] = await EnquiryNoteService.updateEnquiryNote(enquiryNote.id, enquiryNoteRequest)
            if (error) {
                fetchEnquiryNote(enquiryNote.enquiryId)
                setEdit(false)
            } else {
                fetchEnquiryNote(enquiryNote.enquiryId)
                setEdit(false)
            }
        });
    }

    return <Card
        extra={(!edit && (enquiry.status.value == 'Pending' || enquiry.status.value == 'In Progress') && enquiryNote.status != "Closed") &&
            <Button style={{
                display: 'flex',
                alignItems: 'center',
                border: '0',
                background: '#E0EEF7',
                color: '#000000'
            }}
                onClick={() => setEdit(true)}
            >
                <ImgIcon iconName="edit.svg" />
                Edit
            </Button>
        }
        style={{
            padding: '0 20px',
        }}
        headStyle={{
            padding: 0
        }}
        bodyStyle={{
            padding: '15px 0'
        }}>
        {edit ? <Form form={form}>
            <Row>
                <Col md={12} xs={24} style={{
                    paddingRight: '50px'
                }}>
                    <Row style={{
                        marginBottom: '20px'
                    }}>
                        <Col md={12} xs={24}>
                            <Typography.Title level={5} style={{
                                fontSize: '14px'
                            }}>Type</Typography.Title>
                            <Form.Item
                                name="type"
                                style={{
                                    margin: 0
                                }}
                                rules={[{ required: true, message: 'Please input your type!' }]}
                                initialValue={enquiryNote.type}
                            >

                                <Select
                                    showSearch={true}
                                    className="form-custom-select"
                                    placeholder="Select Type"
                                    style={{
                                        width: '94%',
                                        border: '1px solid #92B5D7',
                                        borderRadius: token.borderRadius,
                                        boxShadow: 'none'
                                    }}
                                    options={noteTypeList}
                                    filterOption={handleFilter}
                                />
                            </Form.Item>

                        </Col>
                        <Col md={12} xs={24}>
                            <Typography.Title level={5} style={{
                                fontSize: '14px'
                            }}>Expected Due Date</Typography.Title>
                            <Form.Item
                                name="dueDate"
                                style={{
                                    margin: 0
                                }}
                                rules={[{ required: true, message: 'Please input your username!' }]}
                                initialValue={dayjs(enquiryNote.dueDate)}
                            >
                                <DatePicker
                                    inputReadOnly={isMobile()}
                                    showTime={true}
                                    showSecond={false}
                                    style={{
                                        border: '1px solid #92B5D7',
                                        width: '98%',
                                        borderRadius: token.borderRadius,
                                        boxShadow: 'none',

                                    }}
                                    placeholder="Select date" />
                            </Form.Item>

                        </Col>
                    </Row>
                    {(type && type.length > 0) && (
                        <Row style={{
                            marginBottom: '20px'
                        }}>
                            <Col span={10}>
                                <Typography.Title level={5} style={{
                                    fontSize: '14px'
                                }}>Status</Typography.Title>
                                <Form.Item
                                    name="status"
                                    style={{
                                        margin: 0
                                    }}
                                    rules={[{ required: true, message: 'Please input your username!' }]}
                                    initialValue={enquiryNote.status}
                                >
                                    <Select
                                        showSearch={true}
                                        className="form-custom-select"
                                        placeholder="Select Status"
                                        style={{
                                            width: '94%',
                                            border: '1px solid #92B5D7',
                                            borderRadius: token.borderRadius,
                                            boxShadow: 'none'
                                        }}
                                        options={noteStatusList[type]}
                                        filterOption={handleFilter}
                                    />
                                </Form.Item>

                            </Col>
                        </Row>
                    )}

                </Col>
                <Col md={12} xs={24} style={{
                    paddingLeft: '50px'
                }}>

                    <Typography.Title level={5} style={{
                        fontSize: '14px'
                    }}>Reason</Typography.Title>
                    <Form.Item
                        name="description"
                        style={{
                            margin: 0
                        }}
                        rules={[{ required: true, message: 'Please input your username!' }]}
                        initialValue={enquiryNote.description}
                    >
                        <TextArea style={{
                            width: '99%',
                            border: '1px solid #92B5D7',
                            borderRadius: token.borderRadius,
                            boxShadow: 'none',
                        }} rows={5} placeholder="Write something" />
                    </Form.Item>


                </Col>
            </Row>
            <Row style={{
                marginTop: '10px',
                marginBottom: '10px',
                justifyContent: 'end'
            }}>
                <Col style={{
                    display: 'flex'
                }}>
                    <Button type="default" style={{
                        boxShadow: 'none',
                        marginRight: '20px'
                    }}
                        onClick={() => setEdit(false)}
                    >
                        Cancel
                    </Button>
                    <Button type="primary" block style={{
                        background: token.colorInfo,
                        boxShadow: 'none'
                    }}
                        onClick={updateNote}
                    >
                        Save Changes
                    </Button>
                </Col>

            </Row>
        </Form> :
            <Row>
                <Col md={12} xs={24} style={{
                    paddingRight: '50px'
                }}>
                    <Row style={{
                        marginBottom: '20px'
                    }}>
                        <Col md={12} xs={24}>
                            <Meta
                                title={"Type of Follow up"}
                                description={enquiryNote.type}
                            />
                        </Col>
                    </Row>
                    <Row style={{
                        marginBottom: '20px'
                    }}>
                        <Col md={8} xs={24}>
                            <Meta
                                title={"Status"}
                                description={enquiryNote.status}
                            />
                        </Col>
                        <Col md={8} xs={24}>
                            <Meta
                                title={"Next Follow up"}
                                description={dayjs(enquiryNote.dueDate).format("DD-MM-YYYY")}
                            />
                        </Col>
                        <Col md={8} xs={24}>
                            <Meta
                                title={"Recordings"}
                                description={enquiryNote.callList?.map((callRes: CallRes) => {
                                    return <>
                                    <ReactAudioPlayer controls={true} src={callRes.recording?.url?.servetel} />
                                    <p>{`${dayjs(callRes.createdOn).format('DD-MM-YYYY, hh:mm A')}`}</p>
                                    </>
                                })}
                            />
                        </Col>
                    </Row>

                </Col>
                <Col md={12} xs={24} style={{
                    paddingLeft: '50px'
                }}>

                    <Typography.Title level={5} style={{
                        fontSize: '14px'
                    }}>Response</Typography.Title>
                    <Typography.Paragraph>{enquiryNote.description}</Typography.Paragraph>

                </Col>
            </Row>}
    </Card>
}