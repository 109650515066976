import { Button, Card, Col, Form, Input, Row, Select, Slider, theme, Typography } from "antd";
import Meta from "antd/es/card/Meta";
import React, { useEffect, useState } from "react";
import ImgIcon from "../../../../../view/img-icon";
import { EnquiryRes } from "../../../../../response/enquiry.response";
import { useEnquiry } from "../../../enquiry.hook";
import { BudgetRange, EnquiryRequest } from "../../../../../request/enquiry.request";
import { EnquiryService } from "../../../../../service/enquiry.service";
import { ItemProp } from "../../../../../model/item-prop.model";
import { ProjectRes } from "../../../../../response/project.response";
import { handleFilter } from "../../../../../view/input.helper";
import { EmployeeRes } from "../../../../../response/employee.response";
import { formatName } from "../../../../../util/name-helper";

export interface PropertyPrefrenceProp {
    enquiry: EnquiryRes
}

export const PropertyPrefrence = (props: PropertyPrefrenceProp) => {

    const [form] = Form.useForm();

    const { enquiry } = props
    const { useToken } = theme;
    const { token } = useToken();

    const [isPropertyEdit, setIsPropertyEdit] = useState(false);

    const handlePropertyEdit = () => {
        setIsPropertyEdit(true)
    }

    const { projectList, fetchEnquiry, fetchProjectList, fetchEmployeeList, unitTypeList, employeeList } = useEnquiry()

    useEffect(() => {
        fetchProjectList()
        fetchEmployeeList()
    }, [])

    const savePreference = () => {
        form.validateFields().then(async values => {
            const enquiryRequest = {
                bhkType: Number(values["bhkType"]),
                unitType: values["unitType"],
                projectId: values["projectId"],
                ownerId: values["ownerId"],
                budgetRange: {
                    start: values["budgetRange"][0],
                    end: values["budgetRange"][1]
                } as BudgetRange,
            } as EnquiryRequest
            const [data, err] = await EnquiryService.updateEnquiry(enquiry.id, enquiryRequest)
            if (err) {

            } else {
                fetchEnquiry(enquiry.id, () => { setIsPropertyEdit(false) }, () => { setIsPropertyEdit(false) })
            }
        });
    }

    return (
        <React.Fragment>
            <Col lg={12} xs={24}>
                <Card title="Property Preferences" bordered={false}
                    extra={(!isPropertyEdit && enquiry.status.value == 'Pending' || enquiry.status.value == 'In Progress') &&
                        <Button style={{
                            display: 'flex',
                            alignItems: 'center',
                            border: '0',
                            background: '#E0EEF7',
                            color: '#000000'
                        }}
                            onClick={handlePropertyEdit}
                        >
                            <ImgIcon iconName="edit.svg" />
                            Edit
                        </Button>
                    }
                    style={{
                        padding: '0 20px',
                    }}
                    headStyle={{
                        padding: 0
                    }}
                    bodyStyle={{
                        padding: '15px 0'
                    }}
                    className="enquiry-professional-details wrapper-class"
                >

                    {isPropertyEdit &&
                        <Form
                            form={form}>
                            <Row style={{
                                marginBottom: '20px'
                            }}>
                                <Col md={12} xs={24}>
                                    <Meta
                                        title={"id"}
                                        description={enquiry.id}
                                    />
                                </Col>
                                <Col md={12} xs={24}>
                                    <Meta
                                        title={"Client Name"}
                                        description={formatName(enquiry.client.name)}
                                        className="ml-responsive-8 mt-responsive-4"
                                    />
                                </Col>
                            </Row>
                            <Row style={{
                                marginBottom: '20px'
                            }}>
                                <Col md={12} xs={24}>
                                    <Typography.Title level={5} style={{
                                        fontSize: '14px'
                                    }}>Budget Range</Typography.Title>
                                    <Form.Item
                                        name="budgetRange"
                                        style={{
                                            margin: 0
                                        }}
                                        rules={[{ required: true, message: 'Please input your username!' }]}
                                        initialValue={[enquiry.budgetRange.start, enquiry.budgetRange.end]}
                                    >
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                            <span>0</span>
                                            <Slider range style={{
                                                width: '87%',
                                                margin: '0 10px'
                                            }} />
                                            <span>100 cr</span>
                                        </div>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            marginTop: '5px'
                                        }}>
                                            <span>Min: 20,00,000</span>
                                            <span>Max: 70,00,000</span>
                                        </div>

                                    </Form.Item>


                                </Col>
                            </Row>
                            <Row style={{
                                marginBottom: '20px'
                            }}>
                                <Col md={12} xs={24}>
                                    <Typography.Title level={5} style={{
                                        fontSize: '14px'
                                    }}>Unit Type</Typography.Title>
                                    <Form.Item
                                        name="unitType"
                                        style={{
                                            margin: 0
                                        }}
                                        rules={[{ required: true, message: 'Please input your username!' }]}
                                        initialValue={enquiry.unitType}
                                    >
                                        <Select
                                            showSearch={true}
                                            value="unitType"
                                            options={unitTypeList}
                                            className="form-custom-select"
                                            style={{
                                                width: '94%',
                                                border: '1px solid #92B5D7',
                                                borderRadius: token.borderRadius,
                                                boxShadow: 'none'
                                            }}
                                            filterOption={handleFilter} />
                                    </Form.Item>

                                </Col>
                                <Col md={12} xs={24}>
                                    <Typography.Title level={5} style={{
                                        fontSize: '14px'
                                    }}>BHK Type</Typography.Title>
                                    <Form.Item
                                        name="bhkType"
                                        style={{
                                            margin: 0
                                        }}
                                        rules={[{ required: true, message: 'Please input your username!' }]}
                                        initialValue={enquiry.bhkType}
                                    >
                                        <Input
                                            type="number"
                                            placeholder="Type here"
                                            style={{
                                                width: '94%',
                                                border: '1px solid #92B5D7',
                                                borderRadius: token.borderRadius,
                                                boxShadow: 'none'
                                            }}
                                        />
                                    </Form.Item>

                                </Col>
                            </Row>

                            <Row style={{
                                marginBottom: '20px'
                            }}>
                                <Col md={12} xs={24}>
                                    <Typography.Title level={5} style={{
                                        fontSize: '14px'
                                    }}>Project</Typography.Title>
                                    <Form.Item
                                        name="projectId"
                                        style={{
                                            margin: 0
                                        }}
                                        rules={[{ required: true, message: 'Please input your username!' }]}
                                        initialValue={enquiry.project.id}
                                    >
                                        <Select
                                            showSearch={true}
                                            className="form-custom-select"
                                            style={{
                                                width: '94%',
                                                border: '1px solid #92B5D7',
                                                borderRadius: token.borderRadius,
                                                boxShadow: 'none'
                                            }}
                                            options={projectList.map((projectRes: ProjectRes) => {
                                                return {
                                                    label: `${projectRes.name}`,
                                                    value: projectRes.id
                                                } as ItemProp
                                            })}
                                            filterOption={handleFilter}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col md={12} xs={24}>
                                    <Typography.Title level={5} style={{
                                        fontSize: '14px'
                                    }}>Owner</Typography.Title>
                                    <Form.Item
                                        name="ownerId"
                                        style={{
                                            margin: 0
                                        }}
                                        rules={[{ required: true, message: 'Please input employee name' }]}
                                        initialValue={enquiry.owner.assignedTo.id}
                                    >
                                        <Select
                                            showSearch={true}
                                            className="form-custom-select"
                                            style={{
                                                width: '94%',
                                                border: '1px solid #92B5D7',
                                                borderRadius: token.borderRadius,
                                                boxShadow: 'none'
                                            }}
                                            options={employeeList.map((employee: EmployeeRes) => {
                                                return {
                                                    label: `${formatName(employee.userInfo.name)}`,
                                                    value: employee.id
                                                } as ItemProp
                                            })}
                                            filterOption={handleFilter}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className="action-row" style={{
                                marginBottom: '20px',
                                justifyContent: 'end'
                            }}>
                                <Col style={{ marginRight: 16 }}>
                                    <Button style={{
                                        border: '0',
                                        background: '#E0EEF7',
                                        color: '#000000'
                                    }}
                                        onClick={() => setIsPropertyEdit(false)}
                                    >
                                        Cancel
                                    </Button>
                                </Col>
                                <Col>
                                    <Button type="primary" block style={{
                                        background: token.colorInfo,
                                        boxShadow: 'none'
                                    }}
                                        onClick={savePreference}
                                    >
                                        Save Changes
                                    </Button>
                                </Col>

                            </Row>

                        </Form>
                    }

                    {!isPropertyEdit &&
                        <React.Fragment>

                            <Row style={{
                                marginBottom: '20px'
                            }}>
                                <Col md={12} xs={24}>
                                    <Meta
                                        title={"id"}
                                        description={enquiry.id}
                                    />
                                </Col>
                                <Col span={12}>
                                    <Meta
                                        title={"Client Name"}
                                        description={formatName(enquiry.client.name)}
                                        className="ml-responsive-8 mt-responsive-4"
                                    />
                                </Col>
                            </Row>

                            <Row style={{
                                marginBottom: '20px'
                            }}>
                                <Col md={12} xs={24}>
                                    <Meta
                                        title={"Budget Range"}
                                        description={enquiry.budgetRange.start + " -" + enquiry.budgetRange.end}
                                    />
                                </Col>

                            </Row>

                            <Row style={{
                                marginBottom: '20px'
                            }}>
                                <Col md={12} xs={24}>
                                    <Meta
                                        title={"Unit Type"}
                                        description={enquiry.unitType}
                                    />
                                </Col>
                                <Col md={12} xs={24}>
                                    <Meta
                                        title={"Requirement"}
                                        description={enquiry.requirement}
                                        className="ml-responsive-8 mt-responsive-4"
                                    />
                                </Col>
                            </Row>


                            <Row style={{
                                marginBottom: '20px'
                            }}>
                                <Col md={12} xs={24}>
                                    <Meta
                                        title={"BHK Type"}
                                        description={enquiry.bhkType}
                                    />
                                </Col>
                                <Col md={12} xs={24}>
                                    <Meta
                                        title={"Project Name"}
                                        description={enquiry.project.name}
                                    />
                                </Col>
                            </Row>
                            <Row style={{
                                marginBottom: '20px'
                            }}>
                                {/* <Col md={12} xs={24}>
                                    <Meta
                                        title={"Project Name"}
                                        description={enquiry.project.name}
                                    />
                                </Col> */}
                                <Col md={12} xs={24}>
                                    <Meta
                                        title={"Owner"}
                                        description={formatName(enquiry.owner.assignedTo.name)}
                                        className="ml-responsive-8 mt-responsive-4 "
                                    />
                                </Col>
                            </Row>

                        </React.Fragment>
                    }
                </Card>
            </Col>
        </React.Fragment>
    )
}