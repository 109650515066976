import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { PlusCircleOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Row,
  Col,
  Space,
  Typography,
  Button,
  Input,
  theme,
  Form,
  Radio,
} from "antd";
import { EnquiryFilterComponent } from "../enquiry/component/filter.component";
import { PageTypeEnum } from "../../enum/page-type.enum";
import { TaskFilterComponent } from "../task/component/filters/filter.component";
import { UserFilterComponent } from "../user/component/filters/filter.component";
import { LeadFilterComponent } from "../lead/component/filter.component";
import CustomFileUpload from "./custom-file-upload.component";
import { useMain } from "../main.hook";

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const PageHeader = (props: any) => {

  const { userConfig } = useMain()
  const { useToken } = theme;
  const { token } = useToken();
  const { heading, search, addNewMethod, setGridView, searchFunction, showUploadEnquiry } = props;

  // const [prevScrollPos, setPrevScrollPos] = useState(0);
  // const [visible, setVisible] = useState(true);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const currentScrollPos = window.pageYOffset;
  //     const isScrollingUp = prevScrollPos > currentScrollPos;

  //     setPrevScrollPos(currentScrollPos);
  //     setVisible(isScrollingUp || currentScrollPos === 0);
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [prevScrollPos]);

  return (
    <Row
      style={{
        width: "100%",
        marginBottom: 6,
        alignItems: "center",
        padding: "20px 10px 20px 200px",
        background: "#f5f5f5",
        zIndex: 99,
      }}
      className={`main-page-row fixed-header`}
    >
      <Col lg={props.isEnquiryPage ? 6 : 10} xs={props.isEnquiryPage ? 24 : 10}>
        <Space
          className="gap-0 main-task-headeing page-heading"
          align="start"
          style={{ marginLeft: 25 }}
        >
          <img
            src="/images/calendar-full.svg"
            className="task-calender"
            alt=""
          />
          <div className="ml-2">
            <Typography.Title
              level={5}
              className="m-0"
              style={{
                color: "#000000",
              }}
            >
              {heading}
            </Typography.Title>
            <Typography.Text className="mweb-hidden-elem">{`${dayjs().format(
              "DD MMMM YYYY, dddd"
            )}`}</Typography.Text>
          </div>
        </Space>
      </Col>
      <Col
        lg={props.isEnquiryPage ? 18 : 14} md={props.isEnquiryPage ? 24 : 14}
        style={{ display: "flex", justifyContent: "end" }}
        className="ms-md-auto filterd-header"
      >
        {(!isMobile && showUploadEnquiry && (userConfig.employeeRes.employeeRole == "Manager" ||
          userConfig.employeeRes.employeeRole == "Admin" ||
          userConfig.employeeRes.employeeRole == "SuperAdmin")) && <CustomFileUpload />}
        {(setGridView && !isMobile) && <Radio.Group defaultValue={true} style={{ marginRight: 10 }} buttonStyle="solid" onChange={(e: any) => {
          setGridView(e.target.value)
        }}>
          <Radio.Button value={true}>Grid</Radio.Button>
          <Radio.Button value={false}>Table</Radio.Button>
        </Radio.Group>}
        {addNewMethod &&
          <Button type="primary" style={{ background: token.colorInfo, boxShadow: 'none', borderRadius: token.borderRadiusSM, padding: '2px 8px', marginRight: 10 }} className="add-new-btn" onClick={addNewMethod}>
            <PlusCircleOutlined />
            <span className="mweb-hidden-elem">Add New</span>
          </Button>
        }

        {searchFunction && <Form>
          <Form.Item
            name="title"
            initialValue={search.title}
            className="m-0"
            style={{ paddingRight: "16px" }}
          >
            <Input
              placeholder="Title"
              style={{
                border: "none",
                borderRadius: token.borderRadiusSM,
                padding: "5px 10px",
              }}
              onChange={(e: any) => {
                searchFunction(e.target.value)
              }}
              suffix={<SearchOutlined />}
            />
          </Form.Item>
        </Form>}

        <div className="filter-btn">
          {heading === PageTypeEnum.EnquiryManager && (
            <EnquiryFilterComponent openReCreateModel={props.openReCreateModel} />
          )}
          {heading === PageTypeEnum.LeadManager && <LeadFilterComponent />}
          {heading === PageTypeEnum.TaskManager && <TaskFilterComponent />}
          {heading === PageTypeEnum.UserDirectory && <UserFilterComponent />}
        </div>
      </Col>
    </Row>
  );
};

export default PageHeader;
