import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Select, Switch, TreeSelect, DatePicker } from 'antd';
import { useEmployee } from '../employee.hook';
import { EmployeeRequest } from '../../../request/employee.request';
import { UserInfo } from '../../../model/user-info.model';
import { UserRes } from '../../../response/user.response';
import { ItemProp } from '../../../model/item-prop.model';
import { RoleRes } from '../../../response/role.response';
import { OfficeRes } from '../../../response/office.response';
import { TeamRes } from '../../../response/team.response';
import { EmployeeRes, EmployeeType } from '../../../response/employee.response';
import { UserRequest } from '../../../request/user.request';
import { Address } from '../../../model/address.model';
import { validateAddressLineOne, validateAddressLineTwo, validateAddressStreet, validateCity, validateEmail, validateName, validatePhone, validatePinCode, validateState } from '../../../validator/user-info.validator';
import { PermissionType } from '../../../enum/permission-type.enum';
import { Permission } from '../../../model/permission.model';
import { handleFilter } from '../../../view/input.helper';
import { formatName } from '../../../util/name-helper';
import { isMobile } from '../../../util/device-helper';
import FileUploadView from '../../../view/file-upload.view';
const { SHOW_CHILD } = TreeSelect;

export const AddEmployeeComponent: React.FC<{ open: boolean, onCancel: () => void }> = ({ open, onCancel }) => {
    const [form] = Form.useForm();

    const userId = Form.useWatch('userId', form);
    const roleId = Form.useWatch('roleId', form);
    const [search, setSearch] = useState('');

    const { loading, updateLoading, saveEmployee,
        fetchUserList, fetchOfficeList, fetchTeamList, fetchRoleList, fetchPermissionList,
        teamList, officeList, permissionList, roleList, userList, employeeList,
        bloodGroupList, departmentList } = useEmployee()

    const handleOk = () => {
        form.validateFields().then(values => {
            updateLoading(true);
            const employeeRequest = {
                userRequest: {
                    userInfo: {
                        name: values["name"],
                        phone: values["phone"],
                        email: values["email"],
                        ...(values["profilePic"] && { profilePic: values["profilePic"] })
                    } as UserInfo,
                    address: {
                        lineOne: values["lineOne"],
                        lineTwo: values["lineTwo"],
                        street: values["street"],
                        city: values["city"],
                        state: values["state"],
                        pinCode: values["pinCode"]
                    } as Address
                } as UserRequest,
                userId: values["userId"],
                roleId: values["roleId"],
                type: EmployeeType.Permanent,
                officeId: values["officeId"],
                permissionList: values["permissionList"],
                teamIdList: values["teamIdList"],
                directCall: values["directCall"],
                hideFromWebsite:  values["hideFromWebsite"],
                reportingTo: values["reportingTo"],
                employeeRole: "Normal",
                altPhone: values["altPhone"],
                altEmail: values["altEmail"],
                joiningDate: values["joiningDate"],
                department: values["department"],
                birthDate: values["birthDate"],
                anniversaryDate: values["anniversaryDate"],
                emergencyContact: values["emergencyContact"],
                bloodGroup: values["bloodGroup"],
                identityCardNo: values["identityCardNo"],
                insuranceNo: values["insuranceNo"],
            } as EmployeeRequest
            saveEmployee(employeeRequest, onSuccess, onFailure)
        });
    };

    const onSuccess = () => {
        updateLoading(false);
        form.resetFields()
        onCancel();
    }

    const onFailure = () => {
        updateLoading(false);
    }

    useEffect(() => {
        fetchUserList()
        fetchOfficeList()
        fetchTeamList()
        fetchRoleList()
        fetchPermissionList()
    }, [])

    const handleSearch = (input: string) => {
        setSearch(input.trim());
        fetchUserList({
            phone: input.trim(),
            itemsPerPage: 100,
            pageNumber: 1,
        })
    }

    const handleFilterLocal = () => {
        return search.length >= 0;
    }

    return (
        <Modal
            open={open}
            title="Add New Employee"
            onCancel={onCancel}
            confirmLoading={loading}
            onOk={handleOk}
            okText={"Submit"}
            cancelText={"Cancel"}
            style={{ top: 8, maxWidth: 768 }}
            width={'90vw'}
        >
            <Form form={form} layout="vertical">

                <Form.Item
                    name="userId"
                    label="Select User"
                    rules={[{ required: false, message: 'Please select user' }]}
                >
                    <Select
                        showSearch={true}
                        value='userId'
                        options={userList.filter((user: UserRes) =>{
                            return user.userInfo.phone.indexOf(search) >= 0
                        }).map((user: UserRes) => {
                            return {
                                label: `${formatName(user.userInfo.name)}`,
                                value: user.id
                            } as ItemProp
                        })}
                        allowClear
                        onSearch={handleSearch}
                        filterOption={handleFilterLocal} />
                </Form.Item>

                {!(userId && userId.length > 0) && (
                    <>
                        <Form.Item
                            name="name"
                            label="Name"
                            rules={[{ required: true, validator: validateName }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="phone"
                            label="Phone"
                            rules={[{ required: true, validator: validatePhone }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="email"
                            label="Email"
                            rules={[{ required: true, validator: validateEmail }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name="lineOne"
                            label="Address Line One"
                            rules={[{ required: true, validator: validateAddressLineOne }]}
                        >
                            <Input.TextArea rows={2} placeholder="Enter your address" />
                        </Form.Item>
                        <Form.Item
                            name="lineTwo"
                            label="Address Line Two"
                            rules={[{ required: true, validator: validateAddressLineTwo }]}
                        >
                            <Input.TextArea rows={2} placeholder="Enter your address" />
                        </Form.Item>

                        <Form.Item
                            name="street"
                            label="Street"
                            rules={[{ required: true, validator: validateAddressStreet }]}
                        >
                            <Input.TextArea rows={2} placeholder="Enter your street" />
                        </Form.Item>
                        <Form.Item
                            name="city"
                            label="City"
                            rules={[{ required: true, validator: validateCity }]}
                        >
                            <Input placeholder="Enter your city" />
                        </Form.Item>
                        <Form.Item
                            name="state"
                            label="State"
                            rules={[{ required: true, validator: validateState }]}
                        >
                            <Input placeholder="Enter your state" />
                        </Form.Item>
                        <Form.Item
                            name="pinCode"
                            label="Pin Code"
                            rules={[{ required: true, validator: validatePinCode }]}
                        >
                            <Input type='number' placeholder="Enter your pin code" />
                        </Form.Item>
                    </>)}

                <Form.Item
                    name="roleId"
                    label="Select Role"
                    rules={[{ required: true, message: 'Please select role' }]}
                >
                    <Select
                        showSearch={true}
                        value='roleId'
                        options={roleList.map((role: RoleRes) => {
                            return {
                                label: `${role.name}`,
                                value: role.id
                            } as ItemProp
                        })}
                        filterOption={handleFilter} />
                </Form.Item>

                <Form.Item
                    name="officeId"
                    label="Select Office"
                    rules={[{ required: true, message: 'Please select office' }]}
                >
                    <Select
                        showSearch={true}
                        value='officeId'
                        options={officeList.map((office: OfficeRes) => {
                            return {
                                label: office.name,
                                value: office.id
                            } as ItemProp
                        })}
                        filterOption={handleFilter} />
                </Form.Item>

                <Form.Item
                    name="teamIdList"
                    label="Select teams"
                    rules={[{ required: false }]}
                >
                    <Select
                        mode='multiple'
                        value='teamIdList'
                        options={teamList.map((team: TeamRes) => {
                            return {
                                label: team.name,
                                value: team.id
                            } as ItemProp
                        })}
                        maxTagCount='responsive'
                        filterOption={handleFilter} />
                </Form.Item>

                {(roleId && roleId.length > 0) && (
                    <Form.Item
                        name="permissionList"
                        label="Select Permissions"
                        rules={[{ required: true }]}
                        initialValue={roleList.filter((role: RoleRes) => {
                            return role.id == roleId
                        })[0].permissionList}
                    >
                        <TreeSelect
                            treeData={permissionList.map((permission: Permission) => {
                                return {
                                    title: `${permission.label}-All`,
                                    value: `${permission.value}`,
                                    key: `${permission.value}`,
                                    children: [
                                        {
                                            title: `${permission.label}-Read`,
                                            value: `${permission.value}-${PermissionType.ReadPermission}`,
                                            key: `${permission.value}-${PermissionType.ReadPermission}`,
                                        },
                                        {
                                            title: `${permission.label}-Write`,
                                            value: `${permission.value}-${PermissionType.WritePermission}`,
                                            key: `${permission.value}-${PermissionType.WritePermission}`,
                                        },
                                        {
                                            title: `${permission.label}-Update`,
                                            value: `${permission.value}-${PermissionType.UpdatePermission}`,
                                            key: `${permission.value}-${PermissionType.UpdatePermission}`,
                                        },
                                        {
                                            title: `${permission.label}-Delete`,
                                            value: `${permission.value}-${PermissionType.DeletePermission}`,
                                            key: `${permission.value}-${PermissionType.DeletePermission}`,
                                        },
                                        {
                                            title: `${permission.label}-Import`,
                                            value: `${permission.value}-${PermissionType.ImportPermission}`,
                                            key: `${permission.value}-${PermissionType.ImportPermission}`,
                                        },
                                        {
                                            title: `${permission.label}-Export`,
                                            value: `${permission.value}-${PermissionType.ExportPermission}`,
                                            key: `${permission.value}-${PermissionType.ExportPermission}`,
                                        },
                                    ],
                                }
                            })}
                            value='permissionList'
                            treeCheckable={true}
                            showCheckedStrategy={SHOW_CHILD}
                        />
                    </Form.Item>
                )}

                <Form.Item
                    name="reportingTo"
                    label="Select reportee"
                    rules={[{ required: false }]}
                >
                    <Select
                        showSearch={true}
                        value='reportingTo'
                        options={employeeList.map((employeeRes: EmployeeRes) => {
                            return {
                                label: employeeRes.userInfo.name,
                                value: employeeRes.id
                            } as ItemProp
                        })}
                        filterOption={handleFilter} />
                </Form.Item>

                <Form.Item
                    name="directCall"
                    label="DirectCall"
                    rules={[{ required: false }]}
                >
                    <Switch defaultChecked={true} />
                </Form.Item>

                <Form.Item
                    name="hideFromWebsite"
                    label="Hide From Website"
                    rules={[{ required: false }]}
                >
                    <Switch defaultChecked={true} />
                </Form.Item>

                <Form.Item
                    name="score"
                    label="Score"
                    rules={[{ required: true, message: 'Please enter score' }]}
                >
                    <Input placeholder="Enter score" />
                </Form.Item>

                <Form.Item
                    name="altPhone"
                    label="Alt Phone"
                    rules={[{ required: false }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="altEmail"
                    label="Alt Email"
                    rules={[{ required: false }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="joiningDate"
                    label="Joining Date"
                    rules={[{ required: true, message: "Please select joining date" }]}
                >
                    <DatePicker inputReadOnly={isMobile()} style={{ width: '100%', border: '1px solid #92b5d7' }} placeholder="Joining Date" />
                </Form.Item>

                <Form.Item
                    name="birthDate"
                    label="Birth Date"
                    rules={[{ required: true, message: "Please select birth date" }]}
                >
                    <DatePicker inputReadOnly={isMobile()} style={{ width: '100%', border: '1px solid #92b5d7' }} placeholder="Birth Date" />
                </Form.Item>

                <Form.Item
                    name="anniversaryDate"
                    label="Anniversary Date"
                    rules={[{ required: false }]}
                >
                    <DatePicker inputReadOnly={isMobile()} style={{ width: '100%', border: '1px solid #92b5d7' }} placeholder="Anniversary Date" />
                </Form.Item>

                <Form.Item
                    name="emergencyContact"
                    label="Emergency Contact"
                    rules={[{ required: true, validator: validatePhone }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="bloodGroup"
                    label="Blood Group"
                    rules={[{ required: true, message: "Please select bloog group" }]}
                >
                    <Select
                        showSearch={true}
                        value='bloodGroup'
                        options={bloodGroupList}
                        filterOption={handleFilter} />
                </Form.Item>

                <Form.Item
                    name="department"
                    label="Department"
                    rules={[{ required: false, message: 'Please select department' }]}
                >
                    <Select
                        showSearch={true}
                        value='department'
                        options={departmentList}
                        filterOption={handleFilter} />
                </Form.Item>

                <Form.Item
                    name="identityCardNo"
                    label="Identity Card No"
                    rules={[{ required: false }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="insuranceNo"
                    label="Insurance No"
                    rules={[{ required: false }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="profilePic"
                    label="Profile Pic"
                    rules={[{ required: false }]}
                >
                    <FileUploadView onChange={() => { }} />
                </Form.Item>

            </Form>
        </Modal>
    );
}