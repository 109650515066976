import { Button, Form, Input, Space } from "antd";
import { useLogIn } from "../login.hook";
import {
  validateName,
  validateEmail,
  validateAddressLineOne,
  validateAddressLineTwo,
  validateAddressStreet,
  validateCity,
  validateState,
  validatePinCode,
} from "../../../validator/user-info.validator";
import { UserInfo } from "../../../model/user-info.model";
import { Address } from "../../../model/address.model";
import { UserRequest } from "../../../request/user.request";
import { ValidateOtpReq } from "../../../request/auth.request";

export const OtpComponent = () => {
  const handleKeyUp = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    const inputLength = 4;

    if ((e.target as HTMLInputElement).value.length === 1) {
      if (index < inputLength - 1) {
        const nextInputId = `otp${index + 2}`;
        const nextInput = document.getElementById(
          nextInputId
        ) as HTMLInputElement;
        if (nextInput) {
          nextInput.focus();
        }
      }
    } else if ((e.target as HTMLInputElement).value.length === 0 && index > 0) {
      const prevInputId = `otp${index}`;
      const prevInput = document.getElementById(
        prevInputId
      ) as HTMLInputElement;
      if (prevInput) {
        prevInput.focus();
      }
    }
  };

  const [form] = Form.useForm();

  const { sendOtpRes, submitOtp, updateOtp, updateLoading } = useLogIn();

  const _submitOtp = () => {
    form.validateFields().then((values) => {
      updateLoading(true);
      const userRequest = {
        userInfo: {
          name: values["name"],
          phone: values["phone"],
          email: values["email"],
        } as UserInfo,
        address: {
          lineOne: values["lineOne"] + values["linetow"],
          lineTwo: values["lineTwo"],
          street: values["street"],
          city: values["city"],
          state: values["state"],
          pinCode: values["pinCode"],
        } as Address,
        score: Number(values["score"]),
      } as UserRequest;

      const validateOtpReq = {
        phone: sendOtpRes.phone,
        otp: values["otp1"] + values["otp2"] + values["otp3"] + values["otp4"],
        userReq: userRequest,
      } as ValidateOtpReq;
      submitOtp(validateOtpReq, onSuccess, onFailure);
    });
  };

  const onSuccess = () => {
    updateLoading(false);
    form.resetFields();
  };

  const onFailure = () => {
    updateLoading(false);
  };

  return (
    <Form
      className="form-content"
      form={form}
      layout="vertical"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      style={{
        maxWidth: "23rem",
        position: "relative",
        overflow: "auto",
        maxHeight: "77rem",
      }}
      initialValues={{ remember: true }}
      autoComplete="off"
    >
      <Form.Item
        name=""
        label={<label style={{ color: "#FFFFFF" }}>Enter OTP</label>}
        rules={[{ required: true, message: "Please enter otp" }]}
      >
        <Space>
          <Input.Group compact>
            <Form.Item name="otp1" noStyle>
              <Input
                maxLength={1}
                style={{ width: "20%", marginRight: "8px" }}
                placeholder="0"
                onKeyUp={(e) => handleKeyUp(e, 0)}
                id="otp1"
              />
            </Form.Item>
            <Form.Item name="otp2" noStyle>
              <Input
                maxLength={1}
                style={{ width: "20%", marginRight: "8px" }}
                placeholder="0"
                onKeyUp={(e) => handleKeyUp(e, 1)}
                id="otp2"
              />
            </Form.Item>
            <Form.Item name="otp3" noStyle>
              <Input
                maxLength={1}
                style={{ width: "20%", marginRight: "8px" }}
                placeholder="0"
                onKeyUp={(e) => handleKeyUp(e, 2)}
                id="otp3"
              />
            </Form.Item>
            <Form.Item name="otp4" noStyle>
              <Input
                maxLength={1}
                style={{ width: "20%", marginRight: "8px" }}
                placeholder="0"
                onKeyUp={(e) => handleKeyUp(e, 3)}
                id="otp4"
              />
            </Form.Item>
          </Input.Group>
        </Space>
      </Form.Item>

      {sendOtpRes.new && (
        <>
          <Form.Item
            name="name"
            label={<label style={{ color: "#FFFFFF" }}>Name</label>}
            rules={[{ required: true, validator: validateName }]}
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            name="email"
            label={<label style={{ color: "#FFFFFF" }}>Email</label>}
            rules={[{ required: true, validator: validateEmail }]}
          >
            <Input type="email" />
          </Form.Item>
          <Space>
            <Form.Item
              name="lineOne"
              label={
                <label style={{ color: "#FFFFFF" }}>Address Line One</label>
              }
              rules={[{ required: true, validator: validateAddressLineOne }]}
              style={{ display: "inline-block", width: "calc(100% - 8px)" }}
            >
              <Input.TextArea rows={1} placeholder="Enter your address" />
            </Form.Item>
            <Form.Item
              name="lineTwo"
              label={
                <label style={{ color: "#FFFFFF" }}>Address Line Two</label>
              }
              rules={[{ required: true, validator: validateAddressLineTwo }]}
              style={{ display: "inline-block", width: "calc(100% - 8px)" }}
            >
              <Input.TextArea rows={1} placeholder="Enter your address" />
            </Form.Item>
          </Space>
          <Space>
            <Form.Item
              name="street"
              label={<label style={{ color: "#FFFFFF" }}>Street</label>}
              rules={[{ required: true, validator: validateAddressStreet }]}
              style={{ display: "inline-block", width: "calc(100% - 8px)" }}
            >
              <Input.TextArea rows={1} placeholder="Enter your street" />
            </Form.Item>
            <Form.Item
              name="city"
              label={<label style={{ color: "#FFFFFF" }}>City</label>}
              rules={[{ required: true, validator: validateCity }]}
              style={{ display: "inline-block", width: "calc(100% - 8px)" }}
            >
              <Input placeholder="Enter your city" />
            </Form.Item>
          </Space>
          <Space>
            <Form.Item
              name="state"
              label={<label style={{ color: "#FFFFFF" }}>State</label>}
              rules={[{ required: true, validator: validateState }]}
              style={{ display: "inline-block", width: "calc(100% - 8px)" }}
            >
              <Input placeholder="Enter your state" />
            </Form.Item>
            <Form.Item
              name="pinCode"
              label={<label style={{ color: "#FFFFFF" }}>Pin Code</label>}
              rules={[{ required: true, validator: validatePinCode }]}
              style={{ display: "inline-block", width: "calc(100% - 8px)" }}
            >
              <Input type="number" placeholder="Enter your pin code" />
            </Form.Item>
          </Space>
        </>
      )}

      <Form.Item wrapperCol={{ span: 16 }}>
        <Button type="primary" htmlType="submit" onClick={_submitOtp}>
          {sendOtpRes.new ? "Create" : "Proceed"}
        </Button>
      </Form.Item>
    </Form>
  );
};
