import { AttendanceReportReq, EndDayReq, StartDayReq } from "../request/attendance.request"
import { AttendanceConfigRes, AttendanceReportRes } from "../response/attendance.response"
import httpClient from "../util/http-client"
const FileDownload = require('js-file-download');

export const AttendanceService = {
    todayReading: async (): Promise<(AttendanceConfigRes | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.get("/attendance/today-reading")
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    startDay: async (startDayReq: StartDayReq): Promise<(any | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/attendance/start-day", startDayReq)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    endDay: async (endDayReq: EndDayReq): Promise<(any | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/attendance/end-day", endDayReq)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    downLoadReportList: async (attendanceReportReq: AttendanceReportReq) => {
        httpClient.post("/attendance/download-report", attendanceReportReq, { responseType: 'blob' }).then((response: any) => {
            FileDownload(response.data, 'attendance-report.csv');
        })
    },
    getReport: async (): Promise<(AttendanceReportRes | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.get("/attendance/report")
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
}