import { CountryRequest, CountrySearchReq } from "../request/country.request"
import { CountryRes } from "../response/country.response"
import httpClient from "../util/http-client"

export const CountryService = {
    saveCountry: async (countryRequest: CountryRequest): Promise<(CountryRes|any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/country", countryRequest)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    getCountryList: async (countrySearchReq: CountrySearchReq): Promise<(CountryRes[]|any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/country-search", countrySearchReq)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    updateCountry: async (id: string, countryRequest: CountryRequest): Promise<(CountryRes|any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.put(`/country/${id}`, countryRequest)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
}