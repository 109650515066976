import { Action } from "../../model/action.model";
import { LogInAction } from "./login.action";
import { LogInState } from "./login.state";

export const LogInReducer = (state: LogInState, action: Action): LogInState => {
    const { type, payload } = action

    switch (type) {
        case LogInAction.UPDATE_ACTIVE_VIEW:
            return {
                ...state,
                activeView: payload.activeView,
            };

        case LogInAction.UPDATE_NUMBER:
            return {
                ...state,
                mobile: { ...state.mobile, ...payload.mobile },
            };

        case LogInAction.VALIDATE_NUMBER:
            return {
                ...state,
                mobile: { ...state.mobile, ...payload.mobile },
            };

        case LogInAction.UPDATE_OTP:
            return {
                ...state,
                otp: { ...state.otp, ...payload.otp },
            };

        case LogInAction.VALIDATE_OTP:
            return {
                ...state,
                otp: { ...state.otp, ...payload.otp },
            };

        case LogInAction.UPDATE_LOADING:
            return {
                ...state,
                loading: payload.loading
            };

        case LogInAction.UPDATE_SEND_OTP_RES:
            return {
                ...state,
                sendOtpRes: payload.sendOtpRes
            }
        default:
            throw new Error(`No case for type ${type} found in LogInReducer.`);
    }
}