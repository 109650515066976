import {
    Avatar,
    Button,
    Layout,
    Menu,
    MenuProps,
    Space,
    Spin,
    Typography,
} from "antd";
import { Outlet, useNavigate } from "react-router-dom";
import { MainStateProvider } from "./main.context";
import { useMain } from "./main.hook";
import { useEffect, useRef, useState } from "react";
import { getColor } from "../enum/static-data.enum";
import { SecurityManager } from "../security/security-manager";
import { Content } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import React from "react";
import ImgIcon from "../view/img-icon";
import { addUserProperties } from "../event/analytics.helper";
import { formatName } from "../util/name-helper";
import { ExpandOutlined, WeiboOutlined } from "@ant-design/icons";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: "group"
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as MenuItem;
}

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

export const MainPageBase = () => {
    const navigate = useNavigate();

    const { loading, userConfig, fetchUserConfig, updateLoading, logOutUser } = useMain()
    const [mainMenu, setMainMenu] = useState<MenuItem[]>([])
    const [settingMenu, setSettingMenu] = useState<MenuItem[]>([])
    const [isSiderCollapsed, setIsSiderCollapsed] = useState<boolean>(false)

    const logOut = () => {
        updateLoading(true);
        logOutUser(
            () => {
                SecurityManager.logout();
            },
            () => {
                updateLoading(false);
            }
        );
    };

    useEffect(() => {
        fetchUserConfig();
    }, []);

    const settingOptionList: MenuProps["items"] = [
        {
            key: "",
            onClick: logOut,
            label: "Logout",
        },
    ];

    const updateLayoutOnToggleCollapsed = (isCollapsed: boolean) => {
        let contentBody = document.getElementsByClassName('main-content-body')
        if (window.innerWidth < 992) {
            setIsSiderCollapsed(isCollapsed)
            //@ts-ignore
            contentBody[0].style.paddingLeft = "0px"
        } else {
            //@ts-ignore
            contentBody[0].style.paddingLeft = "200px"
        }
    }

    useEffect(() => {
        if (userConfig.employee == false) {
            navigate("/not-valid-employee", { replace: true })
        } else if (userConfig.employee && userConfig.employeeRes.permissionList) {

            addUserProperties(userConfig)

            const _newMenu: MenuItem[] = []
            _newMenu.push(getItem('Dashboard', '/', <ImgIcon iconName="dashboard.svg" />))

            if (userConfig.employeeRes.permissionList.includes("task-read")) {
                _newMenu.push(
                    getItem("Task", "/task", <ImgIcon iconName="task.svg" />)
                );
            }

            if (userConfig.employeeRes.permissionList.includes("lead-read")) {
                _newMenu.push(
                    getItem("Enquiry", "/enquiry", <ImgIcon iconName="enquire.svg" />)
                );
            }

            if (userConfig.employeeRes.permissionList.includes("lead-read")) {
                _newMenu.push(getItem('Lead', '/lead', <ImgIcon iconName="lead.svg" />))
            }

            const _callSubMenuList = []

            if (!isMobile && userConfig.employeeRes.permissionList.includes("report-read")) {
                _newMenu.push(getItem('Report', '/report', <ImgIcon iconName="users.svg" />))
                _callSubMenuList.push(getItem('History', '/history'))
            }

            if (userConfig.employeeRes.permissionList.includes("lead-read")) {
                _callSubMenuList.push(getItem('OnGoing', '/inprogress'))
            }

            if (_callSubMenuList.length > 0) {
                _newMenu.push(
                    getItem(
                        "Call",
                        "/call",
                        <ImgIcon iconName="phone-white.svg" />,
                        _callSubMenuList
                    )
                );
            }

            // if (userConfig.employeeRes.permissionList.includes("report-read")) {
            //     _newMenu.push(getItem('Report', '/employee-report', <ImgIcon iconName="report.svg" />))
            // }

            const _userSubMenuList = []

            if (!isMobile && userConfig.employeeRes.permissionList.includes("employee-read")) {
                _userSubMenuList.push(getItem('Employee', '/employee'))
            }
            // if (userConfig.employeeRes.permissionList.includes("report-read")) {
            _userSubMenuList.push(getItem('My Team', '/employee/my-team'))
            if (!isMobile) {
                _userSubMenuList.push(getItem('Tree', '/employee/tree'))
            }
            // }
            // if (userConfig.employeeRes.permissionList.includes("employee-read")) {
            //     _userSubMenuList.push(getItem('Employee Tree', '/employee/tree'))
            // }
            // if (userConfig.employeeRes.permissionList.includes("target-read")) {
            //     _userSubMenuList.push(getItem('Target', '/employee/target'))
            // }

            if (_userSubMenuList.length > 0) {
                _newMenu.push(
                    getItem(
                        "Employee",
                        "",
                        <ImgIcon iconName="employe.svg" />,
                        _userSubMenuList
                    )
                );
            }

            const _configSubMenuList = [];

            if (userConfig.employeeRes.permissionList.includes("office-read")) {
                _configSubMenuList.push(getItem("Office", "/office"));
            }
            if (userConfig.employeeRes.permissionList.includes("role-read")) {
                _configSubMenuList.push(getItem("Role", "/role"));
            }
            if (userConfig.employeeRes.permissionList.includes("team-read")) {
                _configSubMenuList.push(getItem("Team", "/team"));
            }

            if (_configSubMenuList.length > 0 && !isMobile) {
                _newMenu.push(
                    getItem(
                        "Config",
                        "/config",
                        <ImgIcon iconName="config.svg" />,
                        _configSubMenuList
                    )
                );
            }

            if (userConfig.employeeRes.permissionList.includes("user-read") && !isMobile) {
                _newMenu.push(
                    getItem("User", "/user", <ImgIcon iconName="users.svg" />)
                );
            }

            if (!isIOS && userConfig.employeeRes.permissionList.includes("property-read")) {
                _newMenu.push(
                    getItem(
                        "Project",
                        "/project",
                        <ImgIcon iconName="property.svg" />,
                        [
                            getItem("Builder", "/builder"),
                            getItem("Amenities", "/amenities"),
                            getItem("Project", "/"),
                            // getItem("Property", "/"),
                        ]
                    )
                );
            }

            // const _marketingSubMenuList = []

            // if (userConfig.employeeRes.employeeRole == "Admin" ||
            //     userConfig.employeeRes.employeeRole == "SuperAdmin" ||
            //     userConfig.employeeRes.employeeRole == "Manager") {
            //     _marketingSubMenuList.push(getItem('Template', '/template'))
            //     _marketingSubMenuList.push(getItem('Campaign', '/campaign'))
            // }

            // if (!isMobile && _marketingSubMenuList.length > 0) {
            //     _newMenu.push(
            //         getItem(
            //             "Marketing",
            //             "marketing",
            //             <ExpandOutlined />,
            //             _marketingSubMenuList
            //         )
            //     );
            // }

            const _targetSubMenuList = []
            _targetSubMenuList.push(getItem('Target', '/'))

            if (userConfig.employeeRes.employeeRole == "Admin" ||
                userConfig.employeeRes.employeeRole == "SuperAdmin" ||
                userConfig.employeeRes.employeeRole == "Manager" ||
                userConfig.employeeRes.employeeRole == "Leader") {
                _targetSubMenuList.push(getItem('Add', '/add'))
            }


            if (!isMobile && _targetSubMenuList.length > 0) {
                _newMenu.push(
                    getItem(
                        "Target",
                        "target",
                        <ExpandOutlined />,
                        _targetSubMenuList
                    )
                );
            }

            const _websiteSubMenuList = []

            if (userConfig.employeeRes.employeeRole == "Admin" ||
                userConfig.employeeRes.employeeRole == "SuperAdmin" ||
                userConfig.employeeRes.employeeRole == "Manager") {
                _websiteSubMenuList.push(getItem('Menu', '/menu'))
                _websiteSubMenuList.push(getItem('Country', '/country'))
                _websiteSubMenuList.push(getItem('City', '/city'))
                _websiteSubMenuList.push(getItem('Locality', '/locality'))
                _websiteSubMenuList.push(getItem('Article', '/article'))
                _websiteSubMenuList.push(getItem('Service', '/service'))
            }


            if (!isMobile && _websiteSubMenuList.length > 0) {
                _newMenu.push(
                    getItem(
                        "Website",
                        "website",
                        <WeiboOutlined />,
                        _websiteSubMenuList
                    )
                );
            }

            setMainMenu(_newMenu);

            const _settingMenuList = [];

            _settingMenuList.push(
                getItem(
                    "Settings",
                    "",
                    <ImgIcon iconName="settings.svg" />,
                    settingOptionList
                )
            );

            setSettingMenu(_settingMenuList);
        }
    }, [userConfig]);



    const typeMenuRef = useRef<any>(null)


    const closeOpenMenus = (e: any) => {
        if (typeMenuRef.current && !isSiderCollapsed && !typeMenuRef.current.contains(e.target) && (window.innerWidth < 992)) {
            setIsSiderCollapsed(true)
        }
    }

    document.addEventListener('mousedown', closeOpenMenus)

    return <>
        {loading ? <Spin style={{ margin: 'auto', display: 'block', paddingTop: '20vh' }} /> :

            <React.Fragment>
                <Layout className='h-100'>
                    <Sider
                        className={`side-menu-slider ${isSiderCollapsed ? 'close-side-menu' : 'open-side-menu'} `}
                        breakpoint="lg"
                        collapsedWidth={isSiderCollapsed ? '0' : ''}
                        onBreakpoint={(broken) => {
                            updateLayoutOnToggleCollapsed(broken)
                        }}
                        onCollapse={(collapsed, type) => {
                            updateLayoutOnToggleCollapsed(collapsed)
                        }}
                        style={{
                            position: 'fixed',
                            top: 0,
                            bottom: 0,
                            zIndex: 999
                        }}
                        collapsed={isSiderCollapsed}
                    >

                        <div style={{ height: 'calc(100vh - 108px)' }} ref={typeMenuRef}>
                            <Typography.Title level={5} className='text-white logo-text'>RealtyBulls</Typography.Title>
                            <Menu
                                defaultSelectedKeys={['1']}
                                defaultOpenKeys={['sub1']}
                                mode="inline"
                                theme="dark"
                                inlineCollapsed={isSiderCollapsed}
                                items={mainMenu}
                                onSelect={(e: any) => {
                                    const path = e.keyPath.reverse().join("")
                                    navigate(path)
                                    updateLayoutOnToggleCollapsed(true)
                                }}
                                style={{
                                    height: '90%',
                                    overflowY: 'auto',
                                    marginBottom: 16
                                }}
                            />
                        </div>
                        <Space align="baseline" style={{ background: '#01142a', position: 'relative', height: 108 }} className="bottom-items mt-auto text-white flex-column">

                            {!isMobile && <Menu
                                defaultSelectedKeys={['1']}
                                defaultOpenKeys={['sub1']}
                                mode="vertical"
                                theme="dark"
                                inlineCollapsed={isSiderCollapsed}
                                className="setting-menu"
                                items={settingMenu}
                                onSelect={(e: any) => {
                                    const path = e.keyPath.reverse().join("")
                                    navigate(path)
                                }}
                            />}
                            {isMobile && <Button className='ml-2 mb-0 p-4' onClick={logOut}>Log out</Button>}
                            {(!isSiderCollapsed && userConfig.userRes?.userInfo?.name) && <div className='d-flex align-center px-3'>
                                {userConfig.employeeRes.userInfo.profilePic?.url?.main ?  <Avatar src={userConfig.employeeRes.userInfo.profilePic?.url?.main} style={{ backgroundColor: getColor(userConfig.userRes.userInfo.name) }}/>: 
                                <Avatar style={{ backgroundColor: getColor(userConfig.userRes.userInfo.name) }}>{userConfig.userRes.userInfo.name[0]?.toUpperCase()}</Avatar>}
                                <p className='ml-2 mb-0'>{formatName(userConfig.userRes.userInfo.name)}</p>
                            </div>
                            }

                        </Space>

                    </Sider>
                    <Layout className={`main-content-body`} style={{ position: 'absolute', width: '100%', paddingTop: 52, height: '100vh' }}>

                        <Content style={{ padding: '24px 8px 0' }}>
                            <Outlet />
                        </Content>
                    </Layout>
                </Layout>


            </React.Fragment>
        }
    </>;
};

export const MainPage = () => {
    return (
        <MainStateProvider>
            <MainPageBase />
        </MainStateProvider>
    );
};
