import {
  createBrowserRouter, Outlet,
} from "react-router-dom";
import { DashboardPage } from "../page/dashboard/dashboard.page";
import { EmployeePage } from "../page/employee/employee.page";
import { LeadPage } from "../page/lead/lead.page";
import { LogInPage } from "../page/login/login.page";
import { OfficePage } from "../page/office/office.page";
import { RolePage } from "../page/role/role.page";
import { TaskPage } from "../page/task/task.page";
import { TeamPage } from "../page/team/team.page";
import { UserPage } from "../page/user/user.page";
import { SecureRoute } from "../security/secure.route";
import { PropertyPage } from "../page/property/property.page";
import { NotFoundPage } from "../page/common/not-found.page";
import { AccessDeniedPage } from "../page/common/access-denied.page";
import { NotValidEmployeePage } from "../page/common/not-valid-employee.page";
import { MainPage } from "../page/main.page";
import { EnquiryPage } from "../page/enquiry/enquiry.page";
import { ReportPage } from "../page/report/report.page";
import { MainTaskTableComponent } from "../page/task/component/table.component";
import { TaskDetailComponent } from "../page/task/component/task-details/task-detail.component";
import { ProjectPage } from "../page/project/project.page";
import { MainEmployeeTableComponent } from "../page/employee/component/table.component";
import { EmployeeTreeComponent } from "../page/employee/component/employee-tree.component";
import { LeadDetailComponent } from "../page/lead/component/detail/lead-detail.component";
import { EnquiryDetailComponent } from "../page/enquiry/component/detail/enquiry-detail.component";
import { BuilderPage } from "../page/builder/builder.page";
import { MyTeamComponent } from "../page/employee/component/my-tem/my-team.component";
import { MainEnquiryComponent } from "../page/enquiry/component/main-enquiry.component";
import EmployeeDetailsComponent from "../page/employee/component/empDetails/employee-details.component";
import { MainLeadComponent } from "../page/lead/component/main-lead.component";
import { CallOngoingPage } from "../page/call/conponent/call-detail.component";
import { CallHistoryComponent } from "../page/call/conponent/call-history.component";
import { CallPage } from "../page/call/call.page";
import { TemplatePage } from "../page/marketing/template/template.page";
import { CampaignPage } from "../page/marketing/campaign/campaign.page";
import { TemplateListComponent } from "../page/marketing/template/component/template-list.component";
import { UpdateTemplateComponent } from "../page/marketing/template/component/update-template.component";
import { AddTemplateComponent } from "../page/marketing/template/component/add-template.component";
import { TargetTableComponent } from "../page/target/conponent/target-table.component";
import { AddTargetComponent } from "../page/target/conponent/add-target.component";
import { TargetPage } from "../page/target/target.page";
import { CountryPage } from "../page/country/country.page";
import { CityPage } from "../page/city/city.page";
import { LocalityPage } from "../page/locality/locality.page";
import { ArticlePage } from "../page/article/article.page";
import { ServicePage } from "../page/service/service.page";
import { MenuPage } from "../page/menu/menu.page";
import { AmenitiesPage } from "../page/amenities/amenities.page";
import { EnquiryDetailV2Component } from "../page/enquiry/component/detail/enquiry-detail-v2.component";


export const router = createBrowserRouter([
  {
    path: "/log-in",
    element: <LogInPage />,
  },
  {
    path: "/not-valid-employee",
    element: <NotValidEmployeePage />,
  },
  {
    path: "/access-denied",
    element: <AccessDeniedPage />,
  },
  {
    element: <SecureRoute element={<MainPage />} />,
    children: [
      {
        path: "/",
        element: <SecureRoute element={<DashboardPage />} />
      },
      {
        path: "",
        element: <EnquiryPage />,
        children: [
          {
            path: "/enquiry",
            element: <SecureRoute element={<MainEnquiryComponent />} />,
          },
          {
            path: "/enquiry/:id",
            element: <SecureRoute element={<EnquiryDetailV2Component />} />,
          },
        ]
      },
      {
        path: "",
        element: <LeadPage />,
        children: [
          {
            path: "/lead",
            element: <SecureRoute element={<MainLeadComponent />} />,
          },
          {
            path: "/lead/:id",
            element: <SecureRoute element={<LeadDetailComponent />} />,
          },
        ]
      },
      {
        path: "",
        element: <TaskPage />,
        children: [
          {
            path: "/task",
            element: <SecureRoute element={<MainTaskTableComponent />} />,
          },
          {
            path: "/task/:id",
            element: <SecureRoute element={<TaskDetailComponent />} />,
          },
        ]
      },
      {
        path: "employee",
        element: <EmployeePage />,
        children: [
          {
            path: "",
            element: <SecureRoute element={<MainEmployeeTableComponent />} />,
          },
          {
            path: "my-team",
            element: <SecureRoute element={<MyTeamComponent />} />,
          },
          {
            path: "tree",
            element: <SecureRoute element={<EmployeeTreeComponent />} />,
          },
          {
            path: "target",
            element: <h1 >Target Page</h1>
          },

          {
            path: ":id/report",
            element: <SecureRoute element={<EmployeeDetailsComponent />} />
          },

        ]
      },
      {
        path: "/report",
        element: <SecureRoute element={<ReportPage />} />
      },
      {
        path: "",
        element: <Outlet />,
        children: [
          {
            path: "/user",
            element: <UserPage />
          },
          {
            path: "/config/office",
            element: <OfficePage />
          },
          {
            path: "/config/team",
            element: <TeamPage />
          },
          {
            path: "/config/role",
            element: <RolePage />
          },
        ]
      },
      {
        path: "",
        element: <Outlet />,
        children: [
          {
            path: "/project/amenities",
            element: <AmenitiesPage />
          },
          {
            path: "/project",
            element: <ProjectPage />
          },
          {
            path: "/project/builder",
            element: <BuilderPage />
          },
        ]
      },
      {
        path: "",
        element: <TargetPage />,
        children: [
          {
            path: "/target",
            element: <TargetTableComponent />
          },
          {
            path: "/target/add",
            element: <AddTargetComponent />
          }
        ]
      },
      {
        path: "",
        element: <CallPage />,
        children: [
          {
            path: "/call/inprogress",
            element: <CallOngoingPage />
          },
          {
            path: "/call/history",
            element: <CallHistoryComponent />
          }
        ]
      },
      {
        path: "marketing/template",
        element: <TemplatePage />,
        children: [
          {
            path: "",
            element: <TemplateListComponent />
          },
          {
            path: ":id/edit",
            element: <UpdateTemplateComponent />
          },
          {
            path: "add",
            element: <AddTemplateComponent />
          }
        ]
      },
      {
        path: "marketing/campaign",
        element: <CampaignPage />
      },
      {
        path: "website",
        element: <Outlet />,
        children: [
          {
            path: "country",
            element: <CountryPage />
          },
          {
            path: "city",
            element: <CityPage />
          },
          {
            path: "locality",
            element: <LocalityPage />
          },
          {
            path: "article",
            element: <ArticlePage />
          },
          {
            path: "service",
            element: <ServicePage />
          },
          {
            path: "menu",
            element: <MenuPage />
          }
        ]
      },
    ]
  },
  {
    path: "/*",
    element: <NotFoundPage />,
  },
]);
