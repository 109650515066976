
const ImgIcon = (props:any) => {
    return (
        <div className={`${props.iconClass ? props.iconClass : 'mr-2 d-flex'} `}>
            <img style={{width: (props.iconWidth ? props.iconWidth : '14px') }} src={`/images/${props.iconName}`} alt="" />
        </div>
            
   
    )
}

export default ImgIcon