import { LocalityStateProvider } from './locality.context';
import { MainLocalityTableComponent } from './component/table.component';

const LocalityPageBase = () => {

  return <>
    <MainLocalityTableComponent />
  </>
}

export const LocalityPage = () => {
  return <LocalityStateProvider>
    <LocalityPageBase />
  </LocalityStateProvider>
}

