import { TemplateStateProvider } from './template.context';
import { Outlet } from 'react-router-dom';

const TemplatePageBase = () => {

  return <>
    <Outlet />
  </>
}

export const TemplatePage = () => {
  return <TemplateStateProvider>
    <TemplatePageBase />
  </TemplateStateProvider>
}

