import { CityRequest, CitySearchReq } from "../request/city.request"
import { CityRes } from "../response/city.response"
import httpClient from "../util/http-client"

export const CityService = {
    saveCity: async (cityRequest: CityRequest): Promise<(CityRes|any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/city", cityRequest)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    getCityList: async (citySearchReq: CitySearchReq): Promise<(CityRes[]|any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/city-search", citySearchReq)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    updateCity: async (id: string, cityRequest: CityRequest): Promise<(CityRes|any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.put(`/city/${id}`, cityRequest)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
}