import { createContext, useReducer } from "react";
import { LeadState } from "./lead.state";
import { LeadReducer } from "./lead.reducer";
import { LeadAction } from "./lead.action";
import { LeadService } from "../../service/lead.service";
import { LeadRes } from "../../response/lead.response";
import { LeadRequest, LeadSearchReq } from "../../request/lead.request";
import { EmployeeSearchReq } from "../../request/employee.request";
import { EmployeeService } from "../../service/employee.service";
import { UserService } from "../../service/user.service";
import { UserSearchReq } from "../../request/user.request";
import { ProjectSearchReq } from "../../request/project.request";
import { ProjectService } from "../../service/project.service";
import { EnquiryAction } from "../enquiry/enquiry.action";
import { UserRes } from "../../response/user.response";
import { ItemProp } from "../../model/item-prop.model";
import { SecurityManager } from "../../security/security-manager";
import dayjs from "dayjs";
import { getDataInLocalStorage, setDataInLocalStorage } from "../../util/local-storage-helper";

const _statusList = [
    { label: "Pending", value: "Pending" },
    { label: "In Progress", value: "In Progress" },
    { label: "Not Interested", value: "Not Interested" },
    { label: "Postponed", value: "Postponed" },
    { label: "Pre Closure", value: "Pre Closure" },
    { label: "Closed", value: "Closed" },
]

const _modeOfCommunicationList = [
    { label: 'Phone', value: 'Phone' },
    { label: 'SMS', value: 'SMS' },
    { label: 'Email', value: 'Email' },
]

const _noteStatusList = {
    "Follow Up": [
        { label: 'Scheduled', value: 'Scheduled' } as ItemProp,
        { label: 'In Progress', value: 'In Progress' } as ItemProp,
        { label: 'ReScheduled', value: 'ReScheduled' } as ItemProp,
        { label: 'Closed', value: 'Closed' } as ItemProp
    ],
    "Site Visit": [
        { label: 'Scheduled', value: 'Scheduled' } as ItemProp,
        { label: 'Cancelled', value: 'Cancelled' } as ItemProp,
        { label: 'ReScheduled', value: 'ReScheduled' } as ItemProp,
        { label: 'Closed', value: 'Closed' } as ItemProp
    ],
    "F2F Meeting": [
        { label: 'Scheduled', value: 'Scheduled' } as ItemProp,
        { label: 'Cancelled', value: 'Cancelled' } as ItemProp,
        { label: 'ReScheduled', value: 'ReScheduled' } as ItemProp,
        { label: 'Closed', value: 'Closed' } as ItemProp
    ]
}

const _noteTypeList = [
    { label: 'Follow Up', value: 'Follow Up' },
    { label: 'Site Visit', value: 'Site Visit' },
    { label: 'F2F Meeting', value: 'F2F Meeting' },
]

const _genderList = [
    { label: 'Male', value: 'Male' },
    { label: 'Female', value: 'Female' },
]

const _sourceList = [
    { label: 'Portal', value: 'Portal' },
    { label: 'Housing', value: 'Housing' },
    { label: '99Acre', value: 'Acre' },
    { label: 'Facebook', value: 'Facebook' },
    { label: 'Google', value: 'Google' },
    { label: 'SMS', value: 'SMS' },
    { label: 'EMail', value: 'EMail' },
    { label: 'IVR', value: 'IVR' },
    { label: 'Website', value: 'Website' },
    { label: 'Other', value: 'Other' },
]

const _unitTypeList = [
    { label: "Apartment", value: "Apartment" },
    { label: "Villa", value: "Villa" },
    { label: "Builder Floor", value: "Builder Floor" },
    { label: "Plot", value: "Plot" },
    { label: "Shop", value: "Shop" },
    { label: "Office Space", value: "Office Space" },
    { label: "Food Court", value: "Food Court" },
]

const _rejectResonList = {
    "Not Interested": [
        { label: "Out of budget", value: "Out of budget" },
        { label: "Site is Not Proper", value: "Site is Not Proper" },
        { label: "Vastu Is Not Correct", value: "Vastu Is Not Correct" },
        { label: "Booked with Other Partner", value: "Booked with Other Partner" },
        { label: "Required Unit Not Available", value: "Required Unit Not Available" },
        { label: "Location Problem", value: "Location Problem" },
        { label: "Not Interested", value: "Not Interested" },
    ],
    "Pre Closure": [
        { label: "Meeting for Price Negotiation", value: "Meeting for Price Negotiation" },
        { label: "Payment Cheque Awaited", value: "Payment Cheque Awaited" },
        { label: "Waiting for Preferred Unit", value: "Waiting for Preferred Unit" },
        { label: "Final discussion", value: "Final discussion" },
    ],
    "Postponed": [
        { label: "Due to Budget Issue", value: "Due to Budget Issue" },
        { label: "Due to Location Issue", value: "Due to Location Issue" },
        { label: "Won’t be able to Relocate", value: "Won’t be able to Relocate" },
        { label: "Personal Reason", value: "Personal Reason" },
        { label: "Moving Out Of Town", value: "Moving Out Of Town" },
    ]
}

const today = new Date()
const lastDay = new Date()
lastDay.setDate(today.getDate() - 50)

const defaultReq = {
    searchText: "",
    statusList: ["Pending", "In Progress"],
    assigneeIdList: [SecurityManager.getEid()],
    // assignedStartDate: lastDay,
    // assignedEndDate: today,
    pageNumber: 1,
    itemsPerPage: 1000
} as LeadSearchReq


const initialState = {
    loading: false,
    leadSearchReq: getDataInLocalStorage<LeadSearchReq>("LEAD_PAGE_FILTER", defaultReq),
    leadList: [],
    leadGroupList: [],
    updateLeadData: {} as LeadRes,
    employeeList: [],
    employeeSearchReq: {
        pageNumber: 1,
        itemsPerPage: 200
    } as EmployeeSearchReq,
    userList: [],
    userSearchReq: {
        pageNumber: 1,
        itemsPerPage: 200
    } as UserSearchReq,
    statusList: _statusList,
    projectList: [],
    projectSearchReq: {
        pageNumber: 1,
        itemsPerPage: 500
    } as ProjectSearchReq,
    lead: {} as LeadRes,
    client: {} as UserRes,
    modeOfCommunicationList: _modeOfCommunicationList,
    noteTypeList: _noteTypeList,
    noteStatusList: _noteStatusList,
    genderList: _genderList,
    sourceList: _sourceList,
    unitTypeList: _unitTypeList,
    rejectResonList: _rejectResonList
} as LeadState

export const LeadContext = createContext<any>(initialState);

export const LeadStateProvider = ({ children }: any) => {

    const [state, dispatch] = useReducer(LeadReducer, initialState)

    const updateLoading = (loading: Boolean) => {
        dispatch({
            type: LeadAction.UPDATE_LOADING,
            payload: {
                loading: loading
            }
        });
    };

    const fetchLead = async (id: string, onSuccess: () => void, onFailure: () => void) => {
        const [lead, error] = await LeadService.getLeadById(id)

        if (error) {
            onFailure()
        } else {
            dispatch({
                type: LeadAction.UPDATE_LEAD,
                payload: {
                    lead: {
                        ...lead
                    }
                }
            })
            onSuccess()
        }
    };

    const fetchLeadList = async (leadSearchReq: LeadSearchReq, onSuccess: () => void, onFailure: () => void) => {
        updateLoading(true)
        const [leadList, error] = await LeadService.getLeadList(leadSearchReq)

        if (error) {
            onFailure()
        } else {
            dispatch({
                type: leadSearchReq.pageNumber == 1 ? LeadAction.UPDATE_LEAD_LIST : LeadAction.ADD_LEAD_LIST,
                payload: {
                    leadList: leadList
                }
            })
            if (leadSearchReq.pageNumber == 1) {
                setDataInLocalStorage("LEAD_PAGE_FILTER", leadSearchReq)
            }
            if (leadList.length > 0) {
                dispatch({
                    type: LeadAction.UPDATE_LEAD_SEARCH_REQ,
                    payload: {
                        leadSearchReq: {
                            ...leadSearchReq,
                            pageNumber: leadSearchReq.pageNumber + 1
                        }
                    }
                })
            }
            onSuccess()
        }
    };

    const saveLead = async (leadRequest: LeadRequest, onSuccess: () => void, onFailure: () => void) => {
        const [data, error] = await LeadService.saveLead(leadRequest)

        if (error) {
            onFailure()
        } else {
            onSuccess()
        }
    }

    const updateLead = async (id: string, leadRequest: LeadRequest, onSuccess: () => void, onFailure: () => void) => {
        const [data, error] = await LeadService.updateLead(id, leadRequest)

        if (error) {
            onFailure()
        } else {
            onSuccess()
        }
    }

    const fetchEmployeeList = async () => {
        const [employeeList, error] = await EmployeeService.getReporteeList()
        if (error) {

        } else {
            dispatch({
                type: LeadAction.UPDATE_EMPLOYEE_LIST,
                payload: {
                    employeeList: employeeList
                }
            });
        }
    };

    const fetchUserList = async () => {
        const [userList, error] = await UserService.getUserList(state.userSearchReq)
        if (error) {

        } else {
            dispatch({
                type: LeadAction.UPDATE_USER_LIST,
                payload: {
                    userList: userList
                }
            });
        }
    };

    const fetchProjectList = async () => {
        const [data, error] = await ProjectService.getProjectList(state.projectSearchReq)
        if (error) {

        } else {
            dispatch({
                type: EnquiryAction.UPDATE_PROJECT_LIST,
                payload: {
                    projectList: data
                }
            });
        }
    };

    const fetchClient = async (id: string, onSuccess: () => void, onFailure: () => void) => {
        const [client, error] = await UserService.getUserById(id)

        if (error) {
            onFailure()
        } else {
            dispatch({
                type: LeadAction.UPDATE_CLIENT,
                payload: {
                    client: {
                        ...client
                    }
                }
            })
            onSuccess()
        }
    };

    const value = {
        leadList: state.leadList,
        leadGroupList: state.leadGroupList,
        loading: state.loading,
        leadSearchReq: state.leadSearchReq,
        employeeList: state.employeeList,
        userList: state.userList,
        statusList: state.statusList,
        projectList: state.projectList,
        lead: state.lead,
        client: state.client,
        modeOfCommunicationList: state.modeOfCommunicationList,
        noteStatusList: state.noteStatusList,
        noteTypeList: state.noteTypeList,
        genderList: state.genderList,
        sourceList: state.sourceList,
        unitTypeList: state.unitTypeList,
        rejectResonList: state.rejectResonList,
        updateLoading,
        fetchLeadList,
        fetchEmployeeList,
        fetchUserList,
        saveLead,
        fetchProjectList,
        fetchLead,
        fetchClient,
        updateLead
    };

    return <LeadContext.Provider value={value}>{children}</LeadContext.Provider>
};