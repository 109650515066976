import { createContext, useReducer } from "react";
import { MainState } from "./main.state";
import { MainReducer } from "./main.reducer";
import { MainAction } from "./main.action";
import { UserService } from "../service/user.service";
import { UserConfig } from "../response/user.response";
import { AuthService } from "../service/auth.service";

const initialState = {
    loading: true,
    userConfig: {} as UserConfig,
    collapsed: false
} as MainState

export const MainContext = createContext<any>(initialState);

export const MainStateProvider = ({ children }: any) => {

    const [state, dispatch] = useReducer(MainReducer, initialState)


    const updateLoading = (loading: Boolean) => {
        dispatch({
            type: MainAction.UPDATE_LOADING,
            payload: {
                loading: loading
            }
        });
    };

    const updateCollapsed = (collapsed: Boolean) => {
        dispatch({
            type: MainAction.UPDATE_COLLAPSED,
            payload: {
                collapsed: collapsed
            }
        });
    };

    const fetchUserConfig = async () => {
        updateLoading(true)
        const [data, error] = await UserService.getUserConfig()

        if (error) {
            updateLoading(false)
        } else {
            dispatch({
                type: MainAction.UPDATE_USER_CONFIG,
                payload: {
                    userConfig: data
                }
            });
            updateLoading(false)
        }
    };

    const logOutUser = async (success: () => void, failure: () => void) => {
        updateLoading(true)
        const [data, error] = await AuthService.logOut()
        if (error) {
            failure()
        } else {
            success()
        }
    };

    const value = {
        userConfig: state.userConfig,
        loading: state.loading,
        collapsed: state.collapsed,
        updateLoading,
        updateCollapsed,
        fetchUserConfig,
        logOutUser
    };

    return <MainContext.Provider value={value}>{children}</MainContext.Provider>
};