import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsSankey from 'highcharts/modules/sankey';
import HighchartsOrganization from 'highcharts/modules/organization';
import { useEmployee } from "../employee.hook";
import { useEffect } from "react";
import { EmployeeRes } from "../../../response/employee.response";
import { getColor } from "../../../enum/static-data.enum";
import './employee-tree.css';
import { groupData, groupList } from "../../../util/group-convertor";
import { formatName } from "../../../util/name-helper";
HighchartsSankey(Highcharts);
HighchartsOrganization(Highcharts);


export const EmployeeTreeComponent = () => {

    const { allEmployeeList, fetchAllEmployeeList } = useEmployee()

    useEffect(() => {
        fetchAllEmployeeList()
    }, [])

    const roleMaplingList: any[] = []

    const mapDataList = groupList([...allEmployeeList], (employee) => employee.reportingTo)

    console.log(groupData([...allEmployeeList], (employee) => employee.reportingTo))
    const dataList: any[] = [];

    mapDataList.forEach((employeeList: EmployeeRes[], index: number) => {
        employeeList.forEach((employee: EmployeeRes) => {
            roleMaplingList.push([employee.reportingTo, employee.id])
            dataList.push({
                id: employee.id,
                title: null,
                name: formatName(employee.userInfo.name),
                // column: index,
                // layout: "hanging",
                color: getColor(`${index}`)
            })
        })
    })

    const options = {
        chart: {
            height: 600,
            inverted: true
        },

        title: {
            text: 'Employee Tree'
        },

        accessibility: {
            point: {
                descriptionFormatter: function (point: any) {
                    var nodeName = point.toNode.name,
                        nodeId = point.toNode.id,
                        nodeDesc = nodeName === nodeId ? nodeName : nodeName + ', ' + nodeId,
                        parentDesc = point.fromNode.id;
                    return point.index + '. ' + nodeDesc + ', reports to ' + parentDesc + '.';
                }
            }
        },

        series: [{
            type: 'organization',
            name: 'Name',
            keys: ['from', 'to'],
            data: roleMaplingList,
            levels: [
                {
                    level: 0,
                    color: "silver",
                    dataLabels: {
                        color: "black"
                    },
                    height: 25
                },
                {
                    level: 1,
                    color: "silver",
                    dataLabels: {
                        color: "black"
                    },
                    height: 25
                },
                {
                    level: 2,
                    dataLabels: {
                        color: "black"
                    },
                    height: 25
                },
                {
                    level: 4,
                    dataLabels: {
                        color: "black"
                    },
                    height: 25
                }
            ],
            nodes: dataList,
            colorByPoint: false,
            color: "#007ad0",
            dataLabels: {
                color: "white"
            },
            borderColor: "white",
            nodeWidth: 45,
            nodePadding: 8
        }],
        tooltip: {
            outside: true
        },
        exporting: {
            allowHTML: true,
            sourceWidth: 800,
            sourceHeight: 600
        }

    }

    return <HighchartsReact highcharts={Highcharts} options={options} />
}