import React, { useEffect } from 'react';
import { Form, Input, Card, DatePicker, theme, Select, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTarget } from '../target.hook';
import { TargetRequest } from '../../../request/target.request';
import { handleFilter } from '../../../view/input.helper';
import { ItemProp } from '../../../model/item-prop.model';
import { EmployeeRes } from '../../../response/employee.response';
import { formatName } from '../../../util/name-helper';
import { isMobile } from '../../../util/device-helper';

export const AddTargetComponent: React.FC<{}> = ({ }) => {
    const navigate = useNavigate();

    const { useToken } = theme;
    const { token } = useToken();


    const [form] = Form.useForm();
    const { employeeList, updateLoading, saveTarget, typeList, fetchEmployeeList } = useTarget()

    const handleOk = () => {
        form.validateFields().then(values => {
            updateLoading(true);
            const templateRequest = {
                employeeId: values["employeeId"],
                type: values["type"],
                value: Number(values["value"]),
                startAt: values["startAt"],
                endAt: values["endAt"]
            } as TargetRequest

            saveTarget(templateRequest, onSuccess, onFailure)
        });
    };

    const onSuccess = () => {
        updateLoading(false);
        form.resetFields()
        navigate("/target");
    }

    const onFailure = () => {
        updateLoading(false);
    }

    useEffect(() => {
        fetchEmployeeList()
    }, [])

    return (
        <Card
            title="Add New Target"
            style={{ top: 8, width: '90%', margin: 'auto' }}
        >
            <Form form={form}
                layout="vertical"
                onFinish={handleOk}
                onFinishFailed={onFailure}>
                <Form.Item
                    name="employeeId"
                    label="Employee Id"
                    rules={[{ required: true, message: 'Please select client' }]}
                >
                    <Select
                        showSearch={true}
                        value='employeeId'
                        options={employeeList.map((user: EmployeeRes) => {
                            return {
                                label: `${formatName(user.userInfo.name)}`,
                                value: user.id
                            } as ItemProp
                        })}
                        filterOption={handleFilter} />
                </Form.Item>


                <Form.Item
                    name="type"
                    label="Type"
                    rules={[{ required: true, message: 'Please select type' }]}
                >
                    <Select
                        showSearch={true}
                        value='type'
                        options={typeList}
                        filterOption={handleFilter} />
                </Form.Item>

                <Form.Item
                    name="value"
                    label="Value"
                    rules={[{ required: true }]}
                >
                    <Input type='number' />
                </Form.Item>

                <Form.Item
                    name="startAt"
                    label="Start At"
                    style={{
                        margin: 0
                    }}
                    rules={[{ required: true }]}
                >
                    <DatePicker
                        inputReadOnly={isMobile()}
                        style={{
                            border: '1px solid #92B5D7',
                            width: '70%',
                            borderRadius: token.borderRadius,
                            boxShadow: 'none',
                            height: '40px'
                        }}
                        placeholder="Select start at" />

                </Form.Item>

                <Form.Item
                    name="endAt"
                    label="End At"
                    style={{
                        margin: 0
                    }}
                    rules={[{ required: true }]}
                >
                    <DatePicker style={{
                        border: '1px solid #92B5D7',
                        width: '70%',
                        borderRadius: token.borderRadius,
                        boxShadow: 'none',
                        height: '40px'
                    }}
                        placeholder="Select end at" />

                </Form.Item>

                <Form.Item style={{
                    marginTop: 24
                }}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
}