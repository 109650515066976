import { UserStateProvider } from './user.context';
import { MainUserTableComponent } from './component/table.component';

const UserPageBase = () => {
  return <>
    <MainUserTableComponent />
  </>
}

export const UserPage = () => {
  return <UserStateProvider>
    <UserPageBase />
  </UserStateProvider>
}

