import { Action } from "../model/action.model";
import { LocationAction } from "./location.action";
import { LocationState } from "./location.state";

export const LocationReducer = (state: LocationState, action: Action): LocationState => {
    const { type, payload } = action

    switch (type) {
        case LocationAction.UPDATE_LOCATION:
            return {
                ...state,
                location: { ...payload.location }
            }
        case LocationAction.UPDATE_LOADING:
            return {
                ...state,
                loading: payload.loading
            }
        case LocationAction.UPDATE_PERMISSION_DENIED:
            return {
                ...state,
                permissionDenied: payload.permissionDenied,
                locationError: { ...payload.locationError }
            }
        default:
            throw new Error(`No case for type ${type} found in LocationReducer.`);
    }
}