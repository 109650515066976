import { Card } from "antd"
import LeadChartComponent from "./lead-chart.component"
import { LeadReportRes } from "../../../../../response/lead.response"

export interface LeadCardComponentProp {
    leadReportResList: LeadReportRes[]
    filterData: any
}

const LeadCardComponent = (props: LeadCardComponentProp) => {
    const { leadReportResList, filterData } = props

    return (

        <Card className="p-mweb-card" style={{
            height: '100%',
            padding: '0 20px'
        }}
            headStyle={{
                padding: '10px 0 0',
                minHeight: 44
            }}
            bodyStyle={{
                padding: '10px 0',
                color: '#000'
            }}
            title="Lead Chart"
            bordered={false}
            extra={<div>Total: {leadReportResList.reduce((accumulator: number, leadReportRes: LeadReportRes) => accumulator + leadReportRes.count, 0)} leads</div>}
        >
            <div>
                <LeadChartComponent leadReportResList={leadReportResList} filterData={filterData}/>
            </div>


        </Card>

    )
}

export default LeadCardComponent