import { Row, Spin } from "antd"
import { UserRes } from "../../../../response/user.response"
import { UserPersonalDetail } from "./user-detail/user-personal-detail.component"
import { UserProfressionalDetail } from "./user-detail/user-profressional-details.component"

export interface UserDetailComponentProps {
    client: UserRes
}

export const UserDetailComponent = (props: UserDetailComponentProps) => {
    const { client } = props

    return <>
        {client && client.id ?
            <Row className="lead-details-row card-custom-row">
                <UserPersonalDetail client={client} />
                <UserProfressionalDetail client={client}/>
            </Row>
            :
            <Spin />
        }
    </>
}