import { useEffect, useRef, useState } from "react";
import { theme } from "antd";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HCWordCloud from 'highcharts/modules/wordcloud';
// Initialize the WordCloud module
HCWordCloud(Highcharts);




const WordColudChart = ({ data }: any) => {
    const { useToken } = theme;
    const { token } = useToken();
    const [chartHeight, setChartHeight] = useState('280px')
    const chartRef = useRef<Highcharts.Chart | null>(null);


    const options = {
        chart: {
            height: chartHeight
        },
        title: {
            text: '',
        },
        credits: {
            enabled: false
        },
        tooltip: {
            headerFormat: '<span style="font-size: 16px"><b>{point.key}</b></span><br>'
        },
        series: [
            {
                type: 'wordcloud',
                data: data,
            },
        ],

    }



    useEffect(() => {
        if (chartRef.current) {
            // Update the chart with new data
            chartRef.current.series[0].setData(data);
        }
    }, [data]);

    useEffect(() => {
        const isMobile = window.innerWidth <= 768; // Adjust the breakpoint according to your needs
        const ch = isMobile ? '200px' : '280px';
        setChartHeight(ch)
    }, [])



    return (
        <HighchartsReact highcharts={Highcharts} options={options} />
    )
}

export default WordColudChart