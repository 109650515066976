import { Action } from "../../model/action.model";
import { RoleAction } from "./role.action";
import { RoleState } from "./role.state";


export const RoleReducer = (state: RoleState, action: Action): RoleState => {
    const { type, payload } = action

    switch (type) {
        case RoleAction.UPDATE_LOADING:
            return {
                ...state,
                loading: payload.loading
            };
        case RoleAction.ADD_ROLE_LIST:
            return {
                ...state,
                roleList: [...state.roleList, ...payload.roleList]
            };
        case RoleAction.UPDATE_ROLE_LIST:
            return {
                ...state,
                roleList: [...payload.roleList]
            };
        case RoleAction.UPDATE_ROLE_SEARCH_REQ:
            return {
                ...state,
                roleSearchReq: { ...payload.roleSearchReq }
            };
        case RoleAction.UPDATE_PERMISSION_LIST:
            return {
                ...state,
                permissionList: [ ...payload.permissionList ]
            };
        default:
            throw new Error(`No case for type ${type} found in RoleReducer.`);
    }
}