import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SecurityManager } from "../../security/security-manager";
import { LoadingView } from "../../view/loading.view";
import { MobileComponent } from "./component/mobile.component";
import { OtpComponent } from "./component/otp.component";
import { LogInStateProvider } from "./login.context";
import { useLogIn } from "./login.hook";
import { PageView } from "./login.state";
import { Card, Row, Col, Space, Typography, Form } from "antd";
import "./login.css";

const LogInPageBase = () => {
  const { activeView, loading } = useLogIn();

  const getView = () => {
    switch (activeView) {
      case PageView.MOBILE_VIEW:
        return <MobileComponent />;
      case PageView.OTP_VIEW:
        return <OtpComponent />;
    }
  };

  return (
    <>
      <div className="background-container">
        <Row className="gradient">
          <Col
            className="primary-heading-column flex-align-item-center"
            xs={24}
            lg={12}
            span={12}
          >
            <Typography.Title className="main-heading font-color-white">
              RealtyBulls
            </Typography.Title>
          </Col>
          <Col
            className="login-card-column flex-align-item-center "
            xs={24}
            lg={12}
            span={12}
          >
            <Space
              direction="vertical"
              style={{
                margin: 20,
              }}
            >
              <Typography.Title level={4} className="font-color-white">
                Login / Sign-up
              </Typography.Title>
              <Card className="reg-card" hoverable bordered={false}>
                {!loading && getView()}
                {loading && <LoadingView />}
              </Card>
            </Space>
          </Col>
        </Row>
      </div>
    </>
  );
};

export const LogInPage = () => {
  const navigate = useNavigate();
  const loggedIn = SecurityManager.loggedIn();

  useEffect(() => {
    if (loggedIn) {
      navigate("/", { replace: true });
    }
  }, []);

  return (
    <LogInStateProvider>
      <LogInPageBase />
    </LogInStateProvider>
  );
};
