import { createContext, useReducer } from "react";
import { CityState } from "./city.state";
import { CityReducer } from "./city.reducer";
import { CityAction } from "./city.action";
import { CityService } from "../../service/city.service";
import { CityRequest, CitySearchReq } from "../../request/city.request";
import { UserService } from "../../service/user.service";
import { CountryService } from "../../service/country.service";
import { CountrySearchReq } from "../../request/country.request";

const initialState = {
    loading: false,
    citySearchReq: {
        pageNumber: 1,
        itemsPerPage: 100
    } as CitySearchReq,
    cityList: [],
    countryList: []
} as CityState

export const CityContext = createContext<any>(initialState);

export const CityStateProvider = ({ children }: any) => {

    const [state, dispatch] = useReducer(CityReducer, initialState)

    const updateLoading = (loading: Boolean) => {
        dispatch({
            type: CityAction.UPDATE_LOADING,
            payload: {
                loading: loading
            }
        });
    };

    const fetchCityList = async (citySearchReq: CitySearchReq, onSuccess: () => void, onFailure: () => void) => {
        updateLoading(true)
        const [cityList, error] = await CityService.getCityList(citySearchReq)

        if (error) {
            onFailure()
        } else {
            dispatch({
                type: citySearchReq.pageNumber == 1 ? CityAction.UPDATE_CITY_LIST : CityAction.ADD_CITY_LIST,
                payload: {
                    cityList: cityList
                }
            })
            if (cityList.length > 0) {
                dispatch({
                    type: CityAction.UPDATE_CITY_SEARCH_REQ,
                    payload: {
                        citySearchReq: {
                            ...citySearchReq,
                            pageNumber: citySearchReq.pageNumber + 1
                        }
                    }
                })
            }
            onSuccess()
        }
    };

    const saveCity = async (cityRequest: CityRequest, onSuccess: () => void, onFailure: () => void) => {
        const [data, error] = await CityService.saveCity(cityRequest)

        if (error) {
            onFailure()
        } else {
            onSuccess()
        }
    }

    const updateCity = async (id: string, cityRequest: CityRequest, onSuccess: () => void, onFailure: () => void) => {
        const [data, error] = await CityService.updateCity(id, cityRequest)

        if (error) {
            onFailure()
        } else {
            onSuccess()
        }
    }

    const fetchCountryList = async () => {
        const [countryList, error] = await CountryService.getCountryList({
            pageNumber: 1,
            itemsPerPage: 100
        } as CountrySearchReq)

        if (error) {

        } else {
            dispatch({
                type: CityAction.UPDATE_COUNTRY_LIST,
                payload: {
                    countryList: countryList
                }
            });
        }
    };

    const value = {
        cityList: state.cityList,
        loading: state.loading,
        citySearchReq: state.citySearchReq,
        countryList: state.countryList,
        updateLoading,
        fetchCityList,
        saveCity,
        updateCity,
        fetchCountryList
    };

    return <CityContext.Provider value={value}>{children}</CityContext.Provider>
};