import { Button, Space, Spin, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { BuilderRes } from '../../../response/builder.response';
import { useBuilder } from '../builder.hook';
import { AddBuilderComponent } from './add-builder.component';
import { UpdateBuilderComponent } from './update-builder.component';
import { BuilderFilterComponent } from './filter.component';

export const MainBuilderTableComponent = () => {
    const { loading, builderList, fetchBuilderList, builderSearchReq, updateLoading } = useBuilder()
    const [addNewBuilder, setOpenNewBuilder] = useState(false)
    const [updateBuilder, setUpdateBuilder] = useState<BuilderRes | undefined>(undefined)

    const cancelBuilderEdit = () => {
        setUpdateBuilder(prevVal => undefined);
    }
    const updateOpenNewBuilder = () => {
        if (addNewBuilder) {
            fetchBuilderList({ ...builderSearchReq, pageNumber: 1 }, () => { updateLoading(false) }, () => { updateLoading(false) })
        }
        setOpenNewBuilder(prevVal => !prevVal);
    }

    const columnList: ColumnsType<BuilderRes> = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            onCell: (builder, rowIndex) => {
                return {
                    onClick: (ev) => {
                        setUpdateBuilder(builder)
                    },
                };
            },
            render: (id) => <a>Edit</a>,
        },
    ];

    useEffect(() => {
        fetchBuilderList({ ...builderSearchReq, pageNumber: 1 }, () => { updateLoading(false) }, () => { updateLoading(false) })
    }, [])

    return <div style={{marginTop: -42 }}>
        <Space direction="horizontal" style={{ width: '100%', justifyContent: 'end', marginBottom: 8 }}>
            <BuilderFilterComponent />
            <Button type="primary" onClick={updateOpenNewBuilder}>Add New</Button>
        </Space>
        <Table
            className='task-main-table task-table'
            columns={columnList} dataSource={builderList}
            pagination={{ defaultPageSize: 30, showSizeChanger: true, pageSizeOptions: ['20', '50', '100', '200', '300', '400', '500'] }}
            loading={{ indicator: <div><Spin /></div>, spinning: loading }} />
        {addNewBuilder &&
            <AddBuilderComponent open={addNewBuilder} onCancel={updateOpenNewBuilder} />
        }

        {updateBuilder &&
            <UpdateBuilderComponent builder={updateBuilder} open={true} onCancel={cancelBuilderEdit} />
        }
    </div>
}