import { Row, Col, Space, Typography } from "antd"
import dayjs from "dayjs"
import { AttendanceComponent } from "../dashboard/component/attendance.component"
import GlobalSearchComponent from "./search.component"

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const DetailPageHeader = (props: any) => {
    const { headerTitle, dateObj, isCreatedOn, isDashboard } = props

    return (
        <Row className="details-header fixed-header" style={{ width: '100%', marginBottom: 8, display: 'flex', alignItems: 'center', padding: "20px 10px 0px 200px", zIndex: 99, background: '#f5f5f5' }}>
            <Col lg={9} md={7} xs={24}>
                <Space className='gap-0 main-task-headeing page-heading' align='start' style={{ marginLeft: 17 }}>
                    {(!isDashboard && !isMobile) &&
                        <img src="/images/calendar-full.svg" className='task-calender' alt='' />
                    }
                    <div className='ml-2'>
                        <Typography.Title level={5} className='m-0' style={{
                            color: '#000000'
                        }}>{headerTitle}</Typography.Title>
                        {isCreatedOn &&
                            <Typography.Text className="mweb-hidden-elem">Created On{`${dayjs(dateObj.createdOn).format('DD MMMM YYYY, dddd hh:mm A')}`}</Typography.Text>
                        }
                        {(!isCreatedOn && !isMobile) &&
                            <Typography.Text className="mweb-hidden-elem">{`${dayjs().format('DD MMMM YYYY, dddd')}`}</Typography.Text>
                        }
                    </div>
                </Space>
            </Col>
            {(isDashboard && !isMobile) &&
                <Col lg={15} md={17} xs={24} style={{ display: 'grid', justifyContent: 'end' }} className='ms-auto'>
                    <Row style={{ width: '100%', marginBottom: 8, display: 'flex', alignItems: 'center', height: '100%' }}>
                        <GlobalSearchComponent />
                        <AttendanceComponent />
                    </Row>
                </Col>
            }
        </Row>
    )
}

export default DetailPageHeader