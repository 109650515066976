import { createContext, useReducer } from "react";
import { ReportState } from "./report.state";
import { ReportReducer } from "./report.reducer";
import { ReportAction } from "./report.action";
import { TeamSearchReq } from "../../request/team.request";
import { EmployeeSearchReq } from "../../request/employee.request";
import { EmployeeService } from "../../service/employee.service";
import { TeamService } from "../../service/team.service";
import { OfficeSearchReq } from "../../request/office.request";
import { OfficeService } from "../../service/office.service";

const initialState = {
    loading: false,
    employeeList: [],
    employeeSearchReq: {
        pageNumber: 1,
        itemsPerPage: 200
    } as EmployeeSearchReq,
    teamList: [],
    teamSearchReq: {
        pageNumber: 1,
        itemsPerPage: 100
    } as TeamSearchReq,
    officeList: [],
    officeSearchReq: {
        pageNumber: 1,
        itemsPerPage: 100
    } as OfficeSearchReq,
} as ReportState

export const ReportContext = createContext<any>(initialState);

export const ReportStateProvider = ({ children }: any) => {

    const [state, dispatch] = useReducer(ReportReducer, initialState)

    const updateLoading = (loading: Boolean) => {
        dispatch({
            type: ReportAction.UPDATE_LOADING,
            payload: {
                loading: loading
            }
        });
    };

    const fetchEmployeeList = async (employeeSearchReq: EmployeeSearchReq, onSuccess: () => void, onFailure: () => void) => {
        const [employeeList, error] = await EmployeeService.getReporteeList(false);
        if (error) {
            onFailure()
        } else {
            dispatch({
                type: employeeSearchReq.pageNumber == 1 ? ReportAction.UPDATE_EMPLOYEE_LIST : ReportAction.ADD_EMPLOYEE_LIST,
                payload: {
                    employeeList: employeeList
                }
            })
            if (employeeList.length > 0) {
                dispatch({
                    type: ReportAction.UPDATE_EMPLOYEE_SEARCH_REQ,
                    payload: {
                        employeeSearchReq: {
                            ...employeeSearchReq,
                            pageNumber: employeeSearchReq.pageNumber + 1
                        }
                    }
                })
            }
            onSuccess()
        }
    };

    const fetchTeamList = async () => {
        const [teamList, error] = await TeamService.getTeamList(state.teamSearchReq)

        if (error) {

        } else {
            dispatch({
                type: ReportAction.UPDATE_TEAM_LIST,
                payload: {
                    teamList: teamList
                }
            });
        }
    };

    const fetchOfficeList = async () => {
        const [officeList, error] = await OfficeService.getOfficeList(state.officeSearchReq)

        if (error) {

        } else {
            dispatch({
                type: ReportAction.UPDATE_OFFICE_LIST,
                payload: {
                    officeList: officeList
                }
            });
        }
    };

    const value = {
        loading: state.loading,
        employeeList: state.employeeList,
        teamList: state.teamList,
        employeeSearchReq: state.employeeSearchReq,
        teamSearchReq: state.teamSearchReq,
        officeList: state.officeList,
        officeSearchReq: state.officeSearchReq,
        fetchEmployeeList,
        fetchTeamList,
        fetchOfficeList,
    };

    return <ReportContext.Provider value={value}>{children}</ReportContext.Provider>
};