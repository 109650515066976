import { useContext } from "react"
import { BuilderContext } from "./builder.context";


export const useBuilder = () => {
    const  context = useContext(BuilderContext)
    if (context === undefined){
        throw new Error(`Component is not inside BuilderContext scope`);
    }
    return context
}