import React, { useState } from 'react';
import { Modal, Form, Input } from 'antd';
import { CallService } from '../../../service/call.service';
import { CallReq } from '../../../request/call.request';
import { validatePhone } from '../../../validator/user-info.validator';
import { useNotification } from '../../../util/notification.hook';

export const CallClientComponent: React.FC<{ open: boolean, onCancel: () => void, onSuccess: () => void }> = ({ open, onCancel, onSuccess }) => {
    const [form] = Form.useForm();
    const { openNotification } = useNotification()

    const [loading, setLoading] = useState(false)

    const handleOk = () => {
        form.validateFields().then(async values => {
            setLoading(true);
            const callRequest = {
                recipientNumber: values["recipientNumber"]
            } as CallReq
            const [data, err] = await CallService.makeCall(callRequest)
            if (err) {
                openNotification('error', err.response.data.message, "")
            } else {
                openNotification('success', "Please pick call in your phone", "")
            }
            onSuccess()
        });
    }

    return (
        <Modal
            open={open}
            title="Dail Number"
            onCancel={onCancel}
            confirmLoading={loading}
            onOk={handleOk}
            okText={"Submit"}
            cancelText={"Cancel"}
        >
            <Form form={form} layout="vertical">
                <Form.Item
                    name="recipientNumber"
                    label="Enter Recipient Number"
                    rules={[{ required: true, validator: validatePhone }]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
}