import { useEffect, useRef, useState } from 'react';
import { useEnquiry } from '../enquiry.hook';
import { AddEnquiryComponent } from './add-enquiry.component';
import { EnquiryGridViewComponent } from './gridView/enquiry-grid-view.component';
import PageHeader from '../../common/page-header.component';
import { MainEnquiryTableComponent } from './table.component';
import MobileEnquiryComponent from './enquiry.mobile';
import { pushEvent } from '../../../event/analytics.helper';
import { EventName } from '../../../event/event-name.enum';
import { SecurityManager } from '../../../security/security-manager';
import { useLocation } from 'react-router-dom';
import { useMapLocation } from '../../../location/location.hook';
import { EnquirySearchReq } from '../../../request/enquiry.request';
import { ReCreateEnquiryComponent } from './re-create-enquiry.component';
import { EnquiryService } from '../../../service/enquiry.service';

export const MainEnquiryComponent = () => {

    const { state } = useLocation();

    const { location } = useMapLocation()

    const [gridView, setGridView] = useState(true)

    const searchInterval = useRef<any>()

    const { enquirySearchReq, fetchEnquiryList, updateLoading } = useEnquiry()
    const [addNewEnquiry, setOpenNewEnquiry] = useState(false)
    const [reCreateEnquiry, setReCreateEnquiry] = useState<any>()


    const updateOpenNewEnquiry = () => {
        if (addNewEnquiry) {
            fetchEnquiryList({ ...enquirySearchReq, pageNumber: 1 }, () => { updateLoading(false) }, () => { updateLoading(false) })
        }
        setOpenNewEnquiry(prevVal => !prevVal);
    }

    useEffect(() => {
        if (state && state.status && state.status.length > 0) {
            console.log(state.filterData)
            console.log(state.filterData?.startDate)
            const _enquirySearchReq = { ...enquirySearchReq }
            delete _enquirySearchReq.startDate
            delete _enquirySearchReq.endDate
            fetchEnquiryList({
                ..._enquirySearchReq,
                statusList: [state.status],
                assigneeIdList: state.filterData?.assigneeIdList ? [...state.filterData?.assigneeIdList] : [],
                sourceList: state.filterData?.sourceList ? [...state.filterData?.sourceList] : [],
                ...(state.filterData?.startDate && { startDate: state.filterData?.startDate }),
                ...(state.filterData?.endDate && { endDate: state.filterData?.endDate }),
                pageNumber: 1
            }, () => { updateLoading(false) }, () => { updateLoading(false) })
        } else {
            fetchEnquiryList({ ...enquirySearchReq, pageNumber: 1 }, () => { updateLoading(false) }, () => { updateLoading(false) })
        }
    }, [state])

    const searchFunction = (text: string) => {
        updateLoading(true)
        if (searchInterval.current) {
            clearTimeout(searchInterval.current)
        }
        searchInterval.current = setTimeout(() => {
            fetchEnquiryList({ ...enquirySearchReq, searchText: text, pageNumber: 1 }, () => { updateLoading(false) }, () => { updateLoading(false) })
        }, 500)
    }

    useEffect(() => {
        pushEvent(EventName.ENQUIRY_PAGE_VIEW, {
            employeeId: SecurityManager.getEid(),
            location: location,
            createdOn: new Date()
        })
    }, [])

    const openReCreateModel = (enquirySearchReq: EnquirySearchReq) => {
        setReCreateEnquiry(enquirySearchReq)
    }

    const cancelReCreateModel = () => {
        setReCreateEnquiry(null)
    }

    const recreateEnquiry = async (employeeIdList: string[], reCreateType: string) => {
        await EnquiryService.reCreateEnquiry({ ...reCreateEnquiry, itemsPerPage: 5000, pageNumber: 1, newOwnerIdList: employeeIdList }, reCreateType)
        setReCreateEnquiry(null)
    }

    return <>

        <PageHeader showUploadEnquiry={true} isEnquiryPage={true} heading={"Enquiry Manager"} search={enquirySearchReq} addNewMethod={updateOpenNewEnquiry} setGridView={setGridView}
            searchFunction={searchFunction} openReCreateModel={openReCreateModel} />

        {/* {isMobile ? <MobileEnquiryComponent /> : <> */}
        {gridView ? <EnquiryGridViewComponent /> : <MainEnquiryTableComponent />}

        {addNewEnquiry &&
            <AddEnquiryComponent open={addNewEnquiry} onCancel={updateOpenNewEnquiry} />
        }
        {reCreateEnquiry &&
            <ReCreateEnquiryComponent open={true} onCancel={cancelReCreateModel} onSuccess={recreateEnquiry} />
        }
    </>
}