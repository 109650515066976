import React, { useEffect, useState } from 'react';
import { Form, Select, Button, DatePicker, Drawer, theme, Input } from 'antd';
import { EnquirySearchReq } from '../../../request/enquiry.request';
import { useEnquiry } from '../enquiry.hook';
import { EmployeeRes } from '../../../response/employee.response';
import { ItemProp } from '../../../model/item-prop.model';
import './enquiry.css';
import { ProjectRes } from '../../../response/project.response';
import dayjs, { Dayjs } from 'dayjs';
import { handleFilter } from '../../../view/input.helper';
import ImgIcon from '../../../view/img-icon';
import { EnquiryService } from '../../../service/enquiry.service';
import { formatName } from '../../../util/name-helper';
import { isMobile } from '../../../util/device-helper';
import { useMain } from '../../main.hook';
const { RangePicker } = DatePicker;

export const EnquiryFilterComponent = (props: any) => {
    const [form] = Form.useForm();
    const { userConfig } = useMain()

    const { useToken } = theme;
    const { token } = useToken();

    const [openFilterDrawer, setOpenFilterDrawer] = useState(false);


    const { fetchEnquiryList, updateLoading, employeeList, projectList, statusList, fetchEmployeeList, enquirySearchReq, sourceList,
        fetchProjectList, rejectResonList } = useEnquiry()

    const handleOk = () => {
        form.validateFields().then(values => {
            updateLoading(true);
            const _enquirySearchReq = {
                ...enquirySearchReq,
                clientName: values["name"],
                statusList: values["statusList"],
                noteList: values["noteList"],
                projectIdList: values["projectIdList"],
                assigneeIdList: values["assigneeIdList"],
                sourceList: values["sourceList"],
                pageNumber: 1,
                itemsPerPage: 1000
            } as EnquirySearchReq
            if (values["dateRange"]) {
                _enquirySearchReq.startDate = (() => {
                    const date = new Date(values["dateRange"][0])
                    date.setHours(0, 0, 0)
                    return date
                })()
                _enquirySearchReq.endDate = (() => {
                    const date = new Date(values["dateRange"][1])
                    date.setHours(23, 59, 59)
                    return date
                })()
            }
            if (values["assignedDateRange"]) {
                _enquirySearchReq.assignedStartDate = (() => {
                    const date = new Date(values["assignedDateRange"][0])
                    date.setHours(0, 0, 0)
                    return date
                })()
                _enquirySearchReq.assignedEndDate = (() => {
                    const date = new Date(values["assignedDateRange"][1])
                    date.setHours(23, 59, 59)
                    return date
                })()
            }
            fetchEnquiryList(_enquirySearchReq, onSuccess, onFailure)
            onFilterDrawerClose()
        });
    };

    useEffect(() => {
        fetchEmployeeList()
        fetchProjectList()
    }, [])

    const onSuccess = () => {
        updateLoading(false);
    }

    const onFailure = () => {
        updateLoading(false);
    }

    const showFilterDrawer = () => {
        setOpenFilterDrawer(true);
    };

    const onFilterDrawerClose = () => {
        setOpenFilterDrawer(false);
    };

    const rangePresets: {
        label: string;
        value: [Dayjs, Dayjs];
    }[] = [
            { label: 'Last 7 Days', value: [dayjs().add(-7, 'd'), dayjs()] },
            { label: 'Last 14 Days', value: [dayjs().add(-14, 'd'), dayjs()] },
            { label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()] },
            { label: 'Last 90 Days', value: [dayjs().add(-90, 'd'), dayjs()] },
        ];

    const downLoadData = () => {
        form.validateFields().then(async values => {
            updateLoading(true);
            const _enquirySearchReq = {
                ...enquirySearchReq,
                clientName: values["name"],
                statusList: values["statusList"],
                projectIdList: values["projectIdList"],
                assigneeIdList: values["assigneeIdList"],
                sourceList: values["sourceList"],
                pageNumber: 1,
                itemsPerPage: 200
            } as EnquirySearchReq
            if (values["dateRange"]) {
                _enquirySearchReq.startDate = (() => {
                    const date = new Date(values["dateRange"][0])
                    date.setHours(0, 0, 0)
                    return date
                })()
                _enquirySearchReq.endDate = (() => {
                    const date = new Date(values["dateRange"][1])
                    date.setHours(24, 59, 59)
                    return date
                })()
            }
            if (values["assignedDateRange"]) {
                _enquirySearchReq.assignedStartDate = (() => {
                    const date = new Date(values["assignedDateRange"][0])
                    date.setHours(0, 0, 0)
                    return date
                })()
                _enquirySearchReq.assignedEndDate = (() => {
                    const date = new Date(values["assignedDateRange"][1])
                    date.setHours(23, 59, 59)
                    return date
                })()
            }
            await EnquiryService.downLoadList({ ...enquirySearchReq, itemsPerPage: 5000, pageNumber: 1 })
            updateLoading(false);
            setOpenFilterDrawer(false);
        });
    }

    const _openReCreateModel = () => {
        form.validateFields().then(async values => {
            updateLoading(true);
            const _enquirySearchReq = {
                ...enquirySearchReq,
                clientName: values["name"],
                statusList: values["statusList"],
                projectIdList: values["projectIdList"],
                assigneeIdList: values["assigneeIdList"],
                sourceList: values["sourceList"],
                pageNumber: 1,
                itemsPerPage: 200
            } as EnquirySearchReq
            if (values["dateRange"]) {
                _enquirySearchReq.startDate = (() => {
                    const date = new Date(values["dateRange"][0])
                    date.setHours(0, 0, 0)
                    return date
                })()
                _enquirySearchReq.endDate = (() => {
                    const date = new Date(values["dateRange"][1])
                    date.setHours(24, 59, 59)
                    return date
                })()
            }
            if (values["assignedDateRange"]) {
                _enquirySearchReq.assignedStartDate = (() => {
                    const date = new Date(values["assignedDateRange"][0])
                    date.setHours(0, 0, 0)
                    return date
                })()
                _enquirySearchReq.assignedEndDate = (() => {
                    const date = new Date(values["assignedDateRange"][1])
                    date.setHours(23, 59, 59)
                    return date
                })()
            }
            props.openReCreateModel(_enquirySearchReq)
            updateLoading(false);
            setOpenFilterDrawer(false);
        });
    }

    return (
        <React.Fragment>

            <Button type="primary" style={{ boxShadow: 'none', borderRadius: token.borderRadiusSM, alignItems: 'center', display: 'flex' }} className={`${openFilterDrawer ? 'open-filter-drawer' : ''} filter-btn`} onClick={showFilterDrawer}>
                <ImgIcon iconName="filters-white.svg" iconClass="mwm-0 mr-2 d-flex" iconWidth="12px" />
                <span className='mweb-hidden-elem'>Filters</span>
            </Button>
            <Drawer
                title="Filters"
                size={"default"}
                placement="right"
                rootClassName="filter-drawer-wrapper"
                className="filter-drawer"
                closable={false}
                onClose={onFilterDrawerClose}
                open={openFilterDrawer}
                bodyStyle={{ background: '#F4F4F4' }}
                extra={
                    <div className="drawer-fb-wrapper" style={{ background: '#F4F4F4' }}>
                        <Button style={{ border: '1px solid #000000', display: 'flex', alignItems: 'center' }} className="drawer-filter-btn">
                            <ImgIcon iconName="filters-black.svg" iconWidth="12px" />
                            Filters
                        </Button>
                    </div>
                }
            >
                <Form form={form} layout="vertical">

                    <Form.Item
                        name="name"
                        style={{ minWidth: 124 }}
                        initialValue={enquirySearchReq.name}
                    >
                        <Input placeholder='Name' />
                    </Form.Item>

                    <Form.Item
                        name="statusList"
                        style={{ minWidth: 124 }}
                        initialValue={enquirySearchReq.statusList}
                    >
                        <Select
                            allowClear
                            mode="multiple"
                            value='statusList'
                            placeholder="Status"
                            maxTagCount='responsive'
                            options={statusList}
                            filterOption={handleFilter} />
                    </Form.Item>

                    <Form.Item
                        name="noteList"
                        style={{ minWidth: 124 }}
                        initialValue={enquirySearchReq.noteList}
                    >
                        <Select
                            allowClear
                            mode="multiple"
                            value='noteList'
                            placeholder="Select Reason"
                            maxTagCount='responsive'
                            options={[].concat(...Object.entries(rejectResonList).map((value: any, key: any) => { return value[1] }))}
                            filterOption={handleFilter} />
                    </Form.Item>

                    <Form.Item
                        name="projectIdList"
                        style={{ minWidth: 124 }}
                        initialValue={enquirySearchReq.projectIdList}
                    >
                        <Select
                            allowClear
                            mode="multiple"
                            value='projectIdList'
                            maxTagCount='responsive'
                            placeholder="Project"
                            options={projectList.map((project: ProjectRes) => {
                                return {
                                    label: `${project.name}`,
                                    value: project.id
                                } as ItemProp
                            })}
                            filterOption={handleFilter}
                        />
                    </Form.Item>
                    <Form.Item
                        name="assigneeIdList"
                        style={{ minWidth: 124 }}
                        initialValue={enquirySearchReq.assigneeIdList}
                    >
                        <Select
                            allowClear
                            mode="multiple"
                            value='assigneeIdList'
                            options={employeeList.map((employee: EmployeeRes) => {
                                return {
                                    label: `${formatName(employee.userInfo.name)}`,
                                    value: employee.id
                                } as ItemProp
                            })}
                            maxTagCount='responsive'
                            placeholder="Assigned to"
                            filterOption={handleFilter} />
                    </Form.Item>
                    <Form.Item
                        name="sourceList"
                        style={{ minWidth: 124 }}
                        initialValue={enquirySearchReq.projectIdList}
                    >
                        <Select
                            allowClear
                            mode="multiple"
                            value='sourceList'
                            maxTagCount='responsive'
                            placeholder="Source"
                            options={sourceList}
                            filterOption={handleFilter}
                        />
                    </Form.Item>

                    <Form.Item
                        name="dateRange"
                        label="Created Date"
                        // initialValue={[dayjs(enquirySearchReq.startDate), dayjs(enquirySearchReq.endDate)]}
                    >
                        <RangePicker
                            inputReadOnly={isMobile()}
                            presets={rangePresets}
                            allowClear={true} />
                    </Form.Item>

                    <Form.Item
                        name="assignedDateRange"
                        label="Assigned Date"
                        // initialValue={[dayjs(enquirySearchReq.assignedStartDate), dayjs(enquirySearchReq.assignedEndDate)]}
                    >
                        <RangePicker
                            inputReadOnly={isMobile()}
                            presets={rangePresets}
                            allowClear={true} />
                    </Form.Item>

                    <Form.Item style={{ minWidth: 124 }}>
                        <Button type="primary" htmlType="submit"
                            onClick={handleOk}>
                            Search
                        </Button>
                    </Form.Item>

                    {(!isMobile() && (userConfig.employeeRes.employeeRole == "Admin" ||
                        userConfig.employeeRes.employeeRole == "SuperAdmin" ||
                        userConfig.employeeRes.employeeRole == "Manager"))  && <Form.Item style={{ minWidth: 124 }}>
                        <Button type="primary" htmlType="submit"
                            onClick={downLoadData}>
                            Download
                        </Button>
                    </Form.Item>
                    }

                    {(!isMobile() && (userConfig.employeeRes.employeeRole == "Admin" ||
                        userConfig.employeeRes.employeeRole == "SuperAdmin" ||
                        userConfig.employeeRes.employeeRole == "Manager")) && <Form.Item style={{ minWidth: 124 }}>
                        <Button type="primary" htmlType="submit"
                            onClick={_openReCreateModel}>
                            Re create Enquiry
                        </Button>
                    </Form.Item>
                    }
                </Form>
            </Drawer>
        </React.Fragment>
    );
}
