import { Card, Checkbox, Col, Form, Row } from "antd"
import ImgIcon from "../../../../../../view/img-icon"

const ActionComponent = () => {
    return (
        <Card style={{
            height: '100%',
            boxShadow: 'none'
        }}
            headStyle={{
                padding: '10px 0 0',
                minHeight: 44
            }}
            bodyStyle={{
                padding: '10px 0',
                color: '#000'
            }}
            title="Actions"
            bordered={false}
        >
            <Form style={{ marginTop: 10, color: '#343433' }}>
                <Form.Item name="checkbox-group">
                    <Checkbox.Group>
                        <Row>
                            <Col span={8} style={{ marginBottom: 20 }}>
                                <Checkbox value="read" style={{ marginRight: 5 }}>
                                    <div style={{ display: 'flex', marginLeft: 10 }}>
                                        Read <ImgIcon iconClass="ml-2 d-flex" iconName="read.svg" />
                                    </div>
                                </Checkbox>
                            </Col>
                            <Col span={8} style={{ marginBottom: 20 }}>
                                <Checkbox value="write" style={{ marginRight: 5 }}>
                                    <div style={{ display: 'flex', marginLeft: 10 }}>
                                        Write <ImgIcon iconClass="ml-2 d-flex" iconName="write.svg" />
                                    </div>
                                </Checkbox>
                            </Col>
                            <Col span={8} style={{ marginBottom: 20 }}>
                                <Checkbox value="update" style={{ marginRight: 5 }}>
                                    <div style={{ display: 'flex', marginLeft: 10 }}>
                                        Update <ImgIcon iconClass="ml-2 d-flex" iconName="update.svg" />
                                    </div>
                                </Checkbox>
                            </Col>
                            <Col span={8} style={{ marginBottom: 20 }}>
                                <Checkbox value="delete" style={{ marginRight: 5 }}>
                                    <div style={{ display: 'flex', marginLeft: 10 }}>
                                        Delete <ImgIcon iconClass="ml-2 d-flex" iconName="delete.svg" />
                                    </div>
                                </Checkbox>
                            </Col>
                            <Col span={8} style={{ marginBottom: 20 }}>
                                <Checkbox value="import" style={{ marginRight: 5 }}>
                                    <div style={{ display: 'flex', marginLeft: 10 }}>
                                        Import <ImgIcon iconClass="ml-2 d-flex" iconName="import.svg" />
                                    </div>
                                </Checkbox>
                            </Col>
                            <Col span={8} style={{ marginBottom: 20 }}>
                                <Checkbox value="export" style={{ marginRight: 5 }}>
                                    <div style={{ display: 'flex', marginLeft: 10 }}>
                                        Export <ImgIcon iconClass="ml-2 d-flex" iconName="export.svg" />
                                    </div>
                                </Checkbox>
                            </Col>
                        </Row>
                    </Checkbox.Group>
                </Form.Item>

            </Form>


        </Card>
    )
}

export default ActionComponent