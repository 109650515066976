import { Action } from "../../model/action.model";
import { EnquiryRes } from "../../response/enquiry.response";
import { groupList } from "../../util/group-convertor";
import { EnquiryAction } from "./enquiry.action";
import { EnquiryState } from "./enquiry.state";


export const EnquiryReducer = (state: EnquiryState, action: Action): EnquiryState => {
    const { type, payload } = action

    switch (type) {
        case EnquiryAction.ADD_ENQUIRY_LIST:
            const updatedList = [...state.enquiryList, ...payload.enquiryList]
            const updatedGroupList = groupList([...updatedList], (enquiry) => enquiry.status.value)
            return {
                ...state,
                enquiryList: updatedList,
                enquiryGroupList: updatedGroupList
            };
        case EnquiryAction.UPDATE_ENQUIRY_LIST:
            const newList = [...payload.enquiryList]
            const newGroupList = groupList([...newList], (enquiry) => enquiry.status.value)

            return {
                ...state,
                enquiryList: newList,
                enquiryGroupList: newGroupList
            };
        case EnquiryAction.UPDATE_ENQUIRY_SEARCH_REQ:
            return {
                ...state,
                enquirySearchReq: { ...payload.enquirySearchReq }
            };
        case EnquiryAction.UPDATE_LOADING:
            return {
                ...state,
                loading: payload.loading
            };
        case EnquiryAction.UPDATE_EMPLOYEE_LIST:
            return {
                ...state,
                employeeList: [...payload.employeeList]
            };
        case EnquiryAction.UPDATE_PROJECT_LIST:
            return {
                ...state,
                projectList: [...payload.projectList]
            };
        case EnquiryAction.UPDATE_ENQUIRY:
            return {
                ...state,
                enquiry: { ...payload.enquiry }
            };
        case EnquiryAction.UPDATE_CLIENT:
            return {
                ...state,
                client: { ...payload.client }
            };
        default:
            throw new Error(`No case for type ${type} found in EnquiryReducer.`);
    }
}