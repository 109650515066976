import { createContext, useReducer } from "react";
import { EmployeeState } from "./employee.state";
import { EmployeeReducer } from "./employee.reducer";
import { EmployeeAction } from "./employee.action";
import { EmployeeRes } from "../../response/employee.response";
import { EmployeeRequest, EmployeeSearchReq } from "../../request/employee.request";
import { EmployeeService } from "../../service/employee.service";
import { RoleSearchReq } from "../../request/role.request";
import { OfficeSearchReq } from "../../request/office.request";
import { TeamSearchReq } from "../../request/team.request";
import { UserSearchReq } from "../../request/user.request";
import { OfficeService } from "../../service/office.service";
import { TeamService } from "../../service/team.service";
import { UserService } from "../../service/user.service";
import { RoleService } from "../../service/role.service";

const _departmentList = [
    {
        label: "Finance",
        value: "Finance"
    },
    {
        label: "Human Resources",
        value: "Human Resources"
    },
    {
        label: "Marketing",
        value: "Marketing"
    },
    {
        label: "Sales",
        value: "Sales"
    },
    {
        label: "Operations Management",
        value: "Operations Management"
    },
    {
        label: "Engineering",
        value: "Engineering"
    },
    {
        label: "Management",
        value: "Management"
    },
    {
        label: "Quality Assurance",
        value: "Quality Assurance"
    },
    {
        label: "Product Management",
        value: "Product Management"
    },
    {
        label: "Law",
        value: "Law"
    },
    {
        label: "Business development",
        value: "Business development"
    },
    {
        label: "Distribution",
        value: "Distribution"
    },
    {
        label: "Training and development",
        value: "Training and development"
    },
]

const _bloodGroupList = [
    {
        label: "A+",
        value: "a+"
    },
    {
        label: "A-",
        value: "a-"
    },
    {
        label: "B+",
        value: "b+"
    },
    {
        label: "B-",
        value: "b-"
    },
    {
        label: "AB+",
        value: "ab+"
    },
    {
        label: "AB-",
        value: "ab-"
    },
    {
        label: "O+",
        value: "o+"
    },
    {
        label: "O-",
        value: "o-"
    }
]

const initialState = {
    loading: false,
    employeeSearchReq: {
        pageNumber: 1,
        itemsPerPage: 200
    } as EmployeeSearchReq,
    employeeList: [],
    updateEmployeeData: {} as EmployeeRes,
    teamList: [],
    employeeTeamList: [],
    teamSearchReq: {
        pageNumber: 1,
        itemsPerPage: 100
    } as TeamSearchReq,
    officeList: [],
    officeSearchReq: {
        pageNumber: 1,
        itemsPerPage: 100
    } as OfficeSearchReq,
    permissionList: [],
    roleList: [],
    roleSearchReq: {
        pageNumber: 1,
        itemsPerPage: 100
    } as RoleSearchReq,
    userList: [],
    userSearchReq: {
        pageNumber: 1,
        itemsPerPage: 800
    } as UserSearchReq,
    reporteeList: [],
    departmentList: _departmentList,
    bloodGroupList: _bloodGroupList,
    teamMemberList: [],
    allEmployeeList: []
} as EmployeeState

export const EmployeeContext = createContext<any>(initialState);

export const EmployeeStateProvider = ({ children }: any) => {

    const [state, dispatch] = useReducer(EmployeeReducer, initialState)


    const updateLoading = (loading: Boolean) => {
        dispatch({
            type: EmployeeAction.UPDATE_LOADING,
            payload: {
                loading: loading
            }
        });
    };

    const fetchEmployeeList = async (employeeSearchReq: EmployeeSearchReq, onSuccess: () => void, onFailure: () => void) => {
        const [employeeList, error] = await EmployeeService.getEmployeeList(employeeSearchReq);
        if (error) {
            onFailure()
        } else {
            dispatch({
                type: employeeSearchReq.pageNumber == 1 ? EmployeeAction.UPDATE_EMPLOYEE_LIST : EmployeeAction.ADD_EMPLOYEE_LIST,
                payload: {
                    employeeList: employeeList
                }
            })
            if (employeeList.length > 0) {
                dispatch({
                    type: EmployeeAction.UPDATE_EMPLOYEE_SEARCH_REQ,
                    payload: {
                        employeeSearchReq: {
                            ...employeeSearchReq,
                            pageNumber: employeeSearchReq.pageNumber + 1
                        }
                    }
                })
            }
            onSuccess()
        }
    };

    const saveEmployee = async (employeeRequest: EmployeeRequest, onSuccess: () => void, onFailure: () => void) => {
        const [data, error] = await EmployeeService.saveEmployee(employeeRequest)

        if (error) {
            onFailure()
        } else {
            onSuccess()
        }
    }

    const updateEmployee = async (id: string, employeeRequest: EmployeeRequest, onSuccess: () => void, onFailure: () => void) => {
        const [data, error] = await EmployeeService.updateEmployee(id, employeeRequest)

        if (error) {
            onFailure()
        } else {
            onSuccess()
        }
    }

    const fetchOfficeList = async () => {
        const [officeList, error] = await OfficeService.getOfficeList(state.officeSearchReq)

        if (error) {

        } else {
            dispatch({
                type: EmployeeAction.UPDATE_OFFICE_LIST,
                payload: {
                    officeList: officeList
                }
            });
        }
    };

    const fetchReportee = async () => {
        const [reporteeList, error] = await EmployeeService.getEmployeeList({
            pageNumber: 1,
            itemsPerPage: 200
        } as EmployeeSearchReq)

        if (error) {

        } else {
            dispatch({
                type: EmployeeAction.UPDATE_REPORTEE_LIST,
                payload: {
                    reporteeList: reporteeList
                }
            });
        }
    };


    const fetchTeamList = async () => {
        const [teamList, error] = await TeamService.getTeamList(state.teamSearchReq)

        if (error) {

        } else {
            dispatch({
                type: EmployeeAction.UPDATE_TEAM_LIST,
                payload: {
                    teamList: teamList
                }
            });
        }
    };


    const fetchEmployeeTeamList = async ( employeeId: string) => {

        const [employeeTeamList, error] = await TeamService.getTeamList({
            employeeIdList: [ employeeId],
            pageNumber: 1,
            itemsPerPage: 100
        } as TeamSearchReq)

        if (error) {

        } else {
            dispatch({
                type: EmployeeAction.UPDATE_EMPLOYEE_TEAM_LIST,
                payload: {
                    employeeTeamList: employeeTeamList
                }
            });
        }
    };

    const fetchTeamMemberList = async () => {
        updateLoading(true)
        const [teamMemberList, error] = await EmployeeService.getMyTeam()

        if (error) {

        } else {
            dispatch({
                type: EmployeeAction.UPDATE_TEAM_MEMBER_LIST,
                payload: {
                    teamMemberList: teamMemberList
                }
            });
        }
        updateLoading(false)
    };

    const fetchAllEmployeeList = async () => {
        updateLoading(true)
        const [allEmployeeList, error] = await EmployeeService.getEmployeeTree()

        if (error) {

        } else {
            dispatch({
                type: EmployeeAction.UPDATE_ALL_EMPLOYEE_LIST,
                payload: {
                    allEmployeeList: allEmployeeList
                }
            });
        }
        updateLoading(false)
    };

    const fetchUserList = async (data: any = {}) => {
        const [userList, error] = await UserService.getUserList({
            ...state.userSearchReq,
            itemsPerPage: 100,
            pageNumber: 1,
            ...(data && data)
        })

        if (error) {

        } else {
            dispatch({
                type: EmployeeAction.UPDATE_USER_LIST,
                payload: {
                    userList: userList
                }
            });
        }
    };

    const fetchRoleList = async () => {
        const [data, error] = await RoleService.getRoleList(state.roleSearchReq)

        if (error) {

        } else {
            dispatch({
                type: EmployeeAction.UPDATE_ROLE_LIST,
                payload: {
                    roleList: data
                }
            });
        }
    };


    const fetchPermissionList = async () => {
        const [data, error] = await UserService.getPermissionList()

        if (error) {

        } else {
            dispatch({
                type: EmployeeAction.UPDATE_PERMISSION_LIST,
                payload: {
                    permissionList: data
                }
            });
        }
    };

    const value = {
        employeeList: state.employeeList,
        loading: state.loading,
        teamList: state.teamList,
        employeeTeamList: state.employeeTeamList,
        officeList: state.officeList,
        permissionList: state.permissionList,
        roleList: state.roleList,
        userList: state.userList,
        employeeSearchReq: state.employeeSearchReq,
        reporteeList: state.reporteeList,
        bloodGroupList: state.bloodGroupList,
        departmentList: state.departmentList,
        teamMemberList: state.teamMemberList,
        allEmployeeList: state.allEmployeeList,
        updateLoading,
        fetchEmployeeList,
        saveEmployee,
        fetchUserList,
        fetchOfficeList,
        fetchTeamList,
        fetchRoleList,
        fetchPermissionList,
        updateEmployee,
        fetchReportee,
        fetchTeamMemberList,
        fetchAllEmployeeList,
        fetchEmployeeTeamList
    };

    return <EmployeeContext.Provider value={value}>{children}</EmployeeContext.Provider>
};