import { Space, Spin, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect } from 'react';
import { CallRes } from '../../../response/call.response';
import { useCall } from '../call.hook';
import { formatName } from '../../../util/name-helper';
import { CallFilterComponent } from './call-filter.component';
import ReactAudioPlayer from 'react-audio-player';
import dayjs from 'dayjs';


export const CallHistoryComponent = () => {
    const { loading, callList, fetchCallList, callSearchReq, updateLoading } = useCall()

    useEffect(() => {
        fetchCallList({ ...callSearchReq, pageNumber: 1 }, () => { updateLoading(false) }, () => { updateLoading(false) })
    }, [])

    const columnList: ColumnsType<CallRes> = [
        // {
        //     title: 'Call Id',
        //     dataIndex: 'id',
        //     key: 'id',
        //     render: (id) => <span>{`${String(id).padStart(3, '0')}`}</span>,
        // },
        {
            title: 'Client Name',
            dataIndex: 'client',
            key: 'client',
            render: (client) => <span>{formatName(client.userInfo.name)}</span>,
        },
        {
            title: 'Caller/Employee Name',
            dataIndex: 'employee',
            key: 'employee',
            render: (employee) => <span>{formatName(employee.userInfo.name)}</span>,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => <span>{status}</span>,
        },
        {
            title: 'Recording',
            dataIndex: 'recording',
            key: 'recording',
            render: (recording) => <ReactAudioPlayer controls={true} src={recording?.url?.servetel} />,
        },
        {
            title: 'Started At',
            dataIndex: 'createdOn',
            key: 'createdOn',
            render: (createdOn) => <span>{`${dayjs(createdOn).format('DD-MM-YYYY, hh:mm A')}`}</span>,
        },
        {
            title: 'Duration',
            dataIndex: 'meta',
            key: 'meta',
            render: (meta) => <span>{`${meta.servtelInfo.duration} Sec`}</span>,
        }
    ];

    return <div style={{ marginTop: -42 }}>
        <Space direction="horizontal" style={{ width: '100%', justifyContent: 'end', marginBottom: 8 }}>
            <CallFilterComponent />
        </Space>

        <Table

            className='task-main-table task-table'
            columns={columnList} dataSource={callList}
            pagination={{ defaultPageSize: 30, showSizeChanger: true, pageSizeOptions: ['20', '50', '100', '200', '300', '400', '500'] }}
            loading={{ indicator: <div><Spin /></div>, spinning: loading }} />

    </div>
}