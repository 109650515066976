import { Card, Row, Col } from "antd"
import DailyStatsChartComponent from "../../../employee/component/empDetails/detailsComponents/charts/dailyStats/daily-stats-chart.component"
import EmpPhoneCallTableComponent from "./emp-phone-call.component"
import { CallReportRes } from "../../../../response/call.response"

export interface PhoneStatsComponentProps {
    callReportResList: CallReportRes[]
}

const PhoneStatsComponent = (props: PhoneStatsComponentProps) => {

    const { callReportResList } = props

    return (
        <Card style={{
            height: '100%',
            padding: '0 20px',
            width: '100%',
            marginBottom: 10
        }}
            headStyle={{
                padding: '10px 0 0',
                minHeight: 44,
                fontSize: 14
            }}
            bodyStyle={{
                padding: '10px 0'
            }}
            title="Phone call Stats"
            bordered={false}
            className="profile-wrapper p-mweb-card"
        >

            <Row
                className="flex-flow-css"
            >
                <Col lg={24} xs={24}>
                    <DailyStatsChartComponent chartHeight="350px" callReportResList={callReportResList} />
                </Col>
                {/* <Col lg={8} md={24} hidden>
                    <div
                        className="emp-report-table"
                    >
                        <EmpPhoneCallTableComponent />
                    </div>

                </Col> */}
            </Row>

        </Card>
    )
}

export default PhoneStatsComponent