import { Col, DatePicker, Form, Row } from "antd"
import ImgIcon from "../../../../view/img-icon"
import { isMobile } from "../../../../util/device-helper"

const DateFilterComponent = (props: any) => {
    return (


        <Row style={{ flexDirection: 'column', width: '100%' }}>
            <Col style={{ display: 'flex' }}>
                <ImgIcon iconName="calender.svg" iconWidth="18px" />
                Due date
            </Col>
            <Col style={{ marginTop: '10px' }}>
                <Form.Item
                    name="dueDate"
                    initialValue={props.taskSearchReq.dueDate}
                    style={{width: '100%'}}
                >

                    <DatePicker
                    inputReadOnly={isMobile()} style={{width: '100%', border: '1px solid #92b5d7' }} placeholder="Due Date" />

                </Form.Item>
            </Col>
        </Row>


    )
}

export default DateFilterComponent