import { TargetRequest, TargetSearchReq } from "../request/target.request";
import { TargetRes } from "../response/target.response";
import httpClient from "../util/http-client"

export const TargetService = {
    saveTarget: async (targetRequest: TargetRequest): Promise<(TargetRes | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/candidate-target", targetRequest)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    getTargetById: async (id: string): Promise<(TargetRes | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.get(`/candidate-target/${id}`)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    getTargetList: async (targetSearchReq: TargetSearchReq): Promise<(TargetRes[] | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/candidate-target-search", targetSearchReq)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    }
}

