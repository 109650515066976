import React, {  } from "react"
import { EnquiryNoteRes } from "../../../../../response/enquiry-note.request"
import { FollowUpCardViewComponent } from "./follow-up-item.component"
import { EnquiryRes } from "../../../../../response/enquiry.response"


export interface FollowUpListComponentProp {
    enquiry: EnquiryRes
    enquiryNoteList: EnquiryNoteRes[]
    fetchEnquiryNote: (enquiryId: string) => void
}

export const FollowUpListComponent = (props: FollowUpListComponentProp) => {

    const { enquiry, enquiryNoteList, fetchEnquiryNote } = props

    return (
        <React.Fragment>
            {enquiryNoteList.map((enquiryNote: EnquiryNoteRes) => {
                return <FollowUpCardViewComponent enquiry={enquiry} enquiryNote={enquiryNote} fetchEnquiryNote={fetchEnquiryNote}/>
            })}
        </React.Fragment>
    )
}