import { Row, Col, Card, Form, Typography, Space } from "antd";
import "./common.css";
export const ImageAndCard = ({ imageSrc, cardData, cardfirst }: any) => {
  return (
    <>
      <Row className="row-common-element-ui flex-all-center" gutter={12}>
        {cardfirst ? (
          <>
            <Col
              className="card-colomn flex-all-center order-2"
              offset={1}
              xs={24}
              lg={12}
              span={16}
            >
              <Card
                className="bordered-common-ui-card font-color-white"
                bordered
              >
                <Form>
                  <Typography.Title className="card-floating-header font-color-white">
                    {cardData.title}
                  </Typography.Title>
                  <Space direction="vertical" className="para-padding">
                    <Typography.Paragraph className="para font-color-white">
                      {cardData.description1}
                    </Typography.Paragraph>
                    <Typography.Paragraph className="para font-color-white">
                      {cardData.description2}
                    </Typography.Paragraph>
                  </Space>
                </Form>
              </Card>
            </Col>
            <Col
              style={{ display: "flex", justifyContent: "center" }}
              xs={24}
              lg={9}
              span={8}
            >
              <div className="image-dev flex-all-center">
                <img className="responsive-image " src={imageSrc} alt="Alert" />
              </div>
            </Col>
          </>
        ) : (
          <>
            <Col
              style={{ display: "flex", justifyContent: "center" }}
              xs={24}
              lg={9}
              span={8}
            >
              <div className="image-dev flex-all-center">
                <img className="responsive-image " src={imageSrc} alt="Alert" />
              </div>
            </Col>
            <Col
              className="card-colomn flex-all-center order-2"
              offset={1}
              xs={24}
              lg={12}
              span={16}
            >
              <Card
                className="bordered-common-ui-card font-color-white"
                bordered
              >
                <Form>
                  <Typography.Title className="card-floating-header font-color-white">
                    {cardData.title}
                  </Typography.Title>
                  <Space direction="vertical" className="para-padding">
                    <Typography.Paragraph className="para font-color-white">
                      {cardData.description1}
                    </Typography.Paragraph>
                    <Typography.Paragraph className="para font-color-white">
                      {cardData.description2}
                    </Typography.Paragraph>
                  </Space>
                </Form>
              </Card>
            </Col>
          </>
        )}
      </Row>
    </>
  );
};
