import { Spin } from "antd"

export interface LoadingViewProp {
}

export const LoadingView = (props: LoadingViewProp) => {
    const { } = props
    return <>
        <Spin />
    </>
}