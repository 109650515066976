import { Button, Space, Spin, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { CountryRes } from '../../../response/country.response';
import { useCountry } from '../country.hook';
import { AddCountryComponent } from './add-country.component';
import { UpdateCountryComponent } from './update-country.component';
import { CountryFilterComponent } from './filter.component';

export const MainCountryTableComponent = () => {
    const { loading, countryList, fetchCountryList, countrySearchReq, updateLoading } = useCountry()
    const [addNewCountry, setOpenNewCountry] = useState(false)
    const [updateCountry, setUpdateCountry] = useState<CountryRes | undefined>(undefined)

    const cancelCountryEdit = () => {
        setUpdateCountry(prevVal => undefined);
    }
    const updateOpenNewCountry = () => {
        if (addNewCountry) {
            fetchCountryList({ ...countrySearchReq, pageNumber: 1 }, () => { updateLoading(false) }, () => { updateLoading(false) })
        }
        setOpenNewCountry(prevVal => !prevVal);
    }

    const columnList: ColumnsType<CountryRes> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            onCell: (country, rowIndex) => {
                return {
                    onClick: (ev) => {
                        setUpdateCountry(country)
                    },
                };
            },
            render: (id) => <a>Edit</a>,
        },
    ];

    useEffect(() => {
        fetchCountryList({ ...countrySearchReq, pageNumber: 1 }, () => { updateLoading(false) }, () => { updateLoading(false) })
    }, [])

    return <div style={{ marginTop: -42 }}>
        <Space direction="horizontal" style={{ width: '100%', justifyContent: 'end', marginBottom: 8 }}>
            <CountryFilterComponent />
            <Button type="primary" onClick={updateOpenNewCountry}>Add New</Button>
        </Space>
        <Table
            className='task-main-table task-table'
            columns={columnList} dataSource={countryList}
            pagination={{ defaultPageSize: 30, showSizeChanger: true, pageSizeOptions: ['20', '50', '100', '200', '300', '400', '500'] }}
            loading={{ indicator: <div><Spin /></div>, spinning: loading }} />
        {addNewCountry &&
            <AddCountryComponent open={addNewCountry} onCancel={updateOpenNewCountry} />
        }

        {updateCountry &&
            <UpdateCountryComponent country={updateCountry} open={true} onCancel={cancelCountryEdit} />
        }
    </div>
}