import React, { useEffect } from 'react';
import { Modal, Form, Input } from 'antd';
import { useCountry } from '../country.hook';
import { CountryRequest } from '../../../request/country.request';
import { validateLevelName } from '../../../validator/level.validator';
import { Media } from '../../../model/media.model';
import FileUploadView from '../../../view/file-upload.view';
import LocationPickerView from '../../../view/location-picker.view';
import { Location } from '../../../model/location.model';

export const AddCountryComponent: React.FC<{ open: boolean, onCancel: () => void }> = ({ open, onCancel }) => {
    const [form] = Form.useForm();
    const { loading, updateLoading, saveCountry } = useCountry()

    const handleOk = () => {
        form.validateFields().then(values => {
            updateLoading(true);
            const countryRequest = {
                name: values["name"],
                location: values["location"],
                thumbnail: values["thumbnail"]
            } as CountryRequest
            saveCountry(countryRequest, onSuccess, onFailure)
        });
    };

    const onSuccess = () => {
        updateLoading(false);
        form.resetFields()
        onCancel();
    }

    const onFailure = () => {
        updateLoading(false);
    }

    return (
        <Modal
            open={open}
            title="Add New Country"
            onCancel={onCancel}
            confirmLoading={loading}
            onOk={handleOk}
            okText={"Submit"}
            cancelText={"Cancel"}
            style={{ top: 8, maxWidth: 768 }}
            width={'90vw'}
        >
            <Form form={form} layout="vertical">
                <Form.Item
                    name="name"
                    label="Name"
                    rules={[{ required: true, validator: validateLevelName }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="thumbnail"
                    label="Thumbnail"
                    rules={[{ required: true, message: 'Please add thumbnail' }]}
                >
                    <FileUploadView onChange={(media: Media) => { }} />
                </Form.Item>

                <Form.Item
                    name="location"
                    label="Location"
                    rules={[{ required: true, message: 'Please add location' }]}
                >
                    <LocationPickerView onChange={(location: Location) => { }} />
                </Form.Item>

            </Form>
        </Modal>
    );
}