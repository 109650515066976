import { Action } from "../model/action.model";
import { MainAction } from "./main.action";
import { MainState } from "./main.state";


export const MainReducer = (state: MainState, action: Action): MainState => {
    const { type, payload } = action

    switch (type) {
        case MainAction.UPDATE_LOADING:
            return {
                ...state,
                loading: payload.loading
            };
        case MainAction.UPDATE_COLLAPSED:
            return {
                ...state,
                collapsed: payload.collapsed
            };
        case MainAction.UPDATE_USER_CONFIG:
            return {
                ...state,
                userConfig: { ...payload.userConfig }
            };

        default:
            throw new Error(`No case for type ${type} found in MainReducer.`);
    }
}