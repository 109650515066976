import { BuilderRequest, BuilderSearchReq } from "../request/builder.request"
import { BuilderRes } from "../response/builder.response"
import httpClient from "../util/http-client"

export const BuilderService = {
    saveBuilder: async (builderRequest: BuilderRequest): Promise<(BuilderRes|any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/builder", builderRequest)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    getBuilderList: async (builderSearchReq: BuilderSearchReq): Promise<(BuilderRes[]|any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/builder-search", builderSearchReq)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    updateBuilder: async (id: string, builderRequest: BuilderRequest): Promise<(BuilderRes|any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.put(`/builder/${id}`, builderRequest)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
}