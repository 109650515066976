import { Form, Input, Row, Col, Checkbox, Space } from "antd";
import { useState } from "react";
import ImgIcon from "../../../../view/img-icon";
const PerformanceFilterComponent = (props: any) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const handleCheckboxChange = (option: string) => {
    const isChecked = selectedOptions.includes(option);
    if (isChecked) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  return (
    <>
      {/* logging the selected option */}
      {console.log(selectedOptions)}
      <Row style={{ flexDirection: "column", width: "100%" }}>
        <Col style={{ display: "flex" }}>
          <ImgIcon iconName="high.svg" iconWidth="18px" />
          Performance
        </Col>
        <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
          <Col span={12}>
            <Checkbox onChange={() => handleCheckboxChange("Option 1")}>
              {"< 20"}
            </Checkbox>
          </Col>
          <Col span={12}>
            <Checkbox onChange={() => handleCheckboxChange("Option 2")}>
              20-40
            </Checkbox>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Checkbox onChange={() => handleCheckboxChange("Option 3")}>
              40-75
            </Checkbox>
          </Col>
          <Col span={12}>
            <Checkbox onChange={() => handleCheckboxChange("Option 4")}>
              {"75 >"}
            </Checkbox>
          </Col>
        </Row>
      </Row>
    </>
  );
};
export default PerformanceFilterComponent;
