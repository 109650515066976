export const ColorList = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae'];

export const getColor = (text: string): string => {
    const index = text?text.charCodeAt(0):0
    return ColorList[index % 4]
}

export const getColorBynumber = (index: number): string => {
    return ColorList[index % 4]
}

export const getGraphColor = (text: string): string => {
    switch (text) {
        case "Open":
            return "#544FC5"
        case "Pending":
            return "#544FC5"
        case "Closed":
            return "#00E272"
        case "Dump/Spam":
            return "#C6C6C6"
        case "Not Interested":
            return "#C6C6C6"
        case "In Progress":
            return "#2CAFFE"
        case "Rejected":
            return "#1AA30E"
        case "Duplicate":
            return "#1AA30E"
        default:
            return "#EC9007"
    }
}