import { RoleStateProvider } from './role.context';
import { MainRoleTableComponent } from './component/table.component';

const RolePageBase = () => {

  return <>
    <MainRoleTableComponent />
  </>
}

export const RolePage = () => {
  return <RoleStateProvider>
    <RolePageBase />
  </RoleStateProvider>
}

