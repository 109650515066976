import { LeadNoteCallReq, LeadNoteRequest, LeadNoteSearchReq } from "../request/lead-note.request"
import { CallRes } from "../response/call.response"
import { LeadNoteRes } from "../response/lead-note.request"
import httpClient from "../util/http-client"

export const LeadNoteService = {
    saveLeadNote: async (leadNoteRequest: LeadNoteRequest): Promise<(LeadNoteRes | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/lead-note", leadNoteRequest)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    getLeadNoteList: async (leadNoteSearchReq: LeadNoteSearchReq): Promise<(LeadNoteRes[] | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/lead-note-search", leadNoteSearchReq)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    updateLeadNote: async (id: string, leadNoteRequest: LeadNoteRequest): Promise<(LeadNoteRes | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.put(`/lead-note/${id}`, leadNoteRequest)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    connectCall: async (leadNoteCallReq: LeadNoteCallReq):Promise<(CallRes | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/lead-note/call-client", leadNoteCallReq)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
}