import { useContext } from "react"
import { RoleContext } from "./role.context";


export const useRole = () => {
    const  context = useContext(RoleContext)
    if (context === undefined){
        throw new Error(`Component is not inside RoleContext scope`);
    }
    return context
}