import { Col, Row, Select, theme } from "antd";
import { useEffect, useState } from "react";
import ImgIcon from "../../../../view/img-icon";
import { TaskService } from "../../../../service/task.service";
import { TaskRequest } from "../../../../request/task.request";
import { handleFilter } from "../../../../view/input.helper";

const PriorityComponent = (props:any) => {
    const { useToken } = theme;
    const { token } = useToken();

    const { task, priorityList } = props;
    const [selectedValue, setSelectedValue] = useState('')

    const handleChange = (value: string) => {
        setSelectedValue(value)
        updatePriority(value)
    }

    useEffect(() => {
        if(task && task.priority) {
            setSelectedValue(task.priority)
        }
    }, [])

    const updatePriority = async (priority: string) => {
        const [data, err] = await TaskService.updateTask(task.id, {
            priority: priority
        } as TaskRequest)
    }

    return (
        <Row style={{ marginBottom: '20px' }}>
            <Col style={{ display: 'flex', alignItems: "center" }}>
                <ImgIcon iconName="priority.svg" iconWidth="18px" />
                Priority
            </Col>
            <Col style={{ marginLeft: 'auto' }}>
                <div style={{border: '1px solid #92B5D7', borderRadius: token.borderRadiusLG, width: '175px', display: 'flex', padding: '2px 10px'}}>
                    <span style={{ textTransform: 'capitalize', display: 'flex' }}>
                        {selectedValue.toLowerCase() === 'highest' &&
                            <ImgIcon iconName="Highest.svg" iconClass="d-flex" />
                        }
                        {selectedValue.toLowerCase() === 'high' &&
                            <ImgIcon iconName="high.svg" iconClass="d-flex" />
                        }
                        {selectedValue.toLowerCase() === 'medium' &&
                            <ImgIcon iconName="medium.svg" iconClass="d-flex" />
                        }
                        {selectedValue.toLowerCase() === 'low' &&
                            <ImgIcon iconName="low.svg" iconClass="d-flex" />
                        }
                         {selectedValue.toLowerCase() === "lowest" &&
                            <ImgIcon iconName="Lowest.svg" />
                        }
                    </span>

                    <Select
                        showSearch={true}
                        value={selectedValue}
                        style={{ textTransform: 'capitalize', width: '100%', textOverflow: 'ellipsis' }}
                        className="custom-select  task-details-select"
                        onChange={handleChange}
                        options={priorityList}
                        filterOption={handleFilter}
                    />
                </div>



            </Col>
        </Row>
    )
}   

export default PriorityComponent