import { theme, Col, Card, Button, Row, Typography, Input, Form, Select, Modal } from "antd";
import React, { useState } from "react";
import ImgIcon from "../../../../../view/img-icon";
import { LeadRes } from "../../../../../response/lead.response";
import { useLead } from "../../../lead.hook";
import { handleFilter } from "../../../../../view/input.helper";
import { LargeStatus } from "../../../../../model/large-status.model";
import { LeadRequest } from "../../../../../request/lead.request";
import { LeadService } from "../../../../../service/lead.service";
import { CloseLeadComponent } from "../../close-lead/close-lead.component";

export interface LeadStatusProp {
    lead: LeadRes
}


export const LeadStatus = (props: LeadStatusProp) => {

    const { lead } = props

    const { useToken } = theme;
    const { token } = useToken();
    const [form] = Form.useForm()

    const [isEdit, setIsEdit] = useState(false);
    const status = Form.useWatch('status', form);
    const { statusList, rejectResonList, fetchLead, updateLoading } = useLead()
    const [closeLead, setCloseLead] = useState(false)

    const handleDetailEdit = () => {
        setIsEdit(true)
    }

    const { confirm } = Modal;

    const showConfirmationModal = () => {
        confirm({
            title: 'Are you sure, you want to save changes',
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                handleOk()
            },
            onCancel() {

            },
        });
    };

    const handleOk = () => {
        form.validateFields().then(async values => {
            const leadRequest = {
                status: {
                    value: values["status"],
                    note: values["reason"],
                    description: values["note"],
                    dateTime: new Date()
                } as LargeStatus
            } as LeadRequest

            updateLoading(true)

            const [data, error] = await LeadService.updateLead(lead.id, leadRequest)

            if (error) {
                onFailure()
            } else {
                onSuccess()
            }
        });
    };

    const onSuccess = async () => {
        form.resetFields()
        await fetchLead(lead.id, () => { updateLoading(false) }, () => { updateLoading(false) })
    }

    const onFailure = () => {
        fetchLead(lead.id, () => { }, () => { })
    }

    const onLeadSuccess = async () => {
        await fetchLead(lead.id, () => { updateLoading(false) }, () => { updateLoading(false) })
    }

    return (
        <React.Fragment>
            <Col lg={12} xs={24}>
                <Card title="Lead Status" bordered={false}
                    extra={(!isEdit && lead.status.value == 'Pending' || lead.status.value == 'In Progress') &&
                        <Button style={{
                            display: 'flex',
                            alignItems: 'center',
                            border: '0',
                            background: '#E0EEF7',
                            color: '#000000'
                        }}
                            onClick={handleDetailEdit}
                        >
                            <ImgIcon iconName="edit.svg" />
                            Edit
                        </Button>
                    }
                    style={{
                        padding: '0 20px',
                    }}
                    headStyle={{
                        padding: 0
                    }}
                    bodyStyle={{
                        padding: '15px 0'
                    }}
                    className="lead-professional-details wrapper-class common-space"
                >

                    {isEdit &&
                        <Form form={form}>

                            {(lead.status.value === 'Pending' || lead.status.value === 'In Progress') &&
                                <>
                                    <Row style={{
                                        marginBottom: '20px'
                                    }}>
                                        <Col md={12} xs={24}>
                                            <Typography.Title level={5} style={{
                                                fontSize: '14px'
                                            }}>Status</Typography.Title>
                                            <Form.Item
                                                style={{
                                                    margin: 0
                                                }}
                                                name="status"
                                                label=""
                                                rules={[{ required: true, message: 'Please select status' }]}
                                                initialValue={lead.status.value}
                                            >
                                                <Select
                                                    showSearch={true}
                                                    placeholder="Select Type"
                                                    className="form-custom-select"
                                                    style={{
                                                        width: '94%',
                                                        border: '1px solid #92B5D7',
                                                        borderRadius: token.borderRadius,
                                                        boxShadow: 'none'
                                                    }}
                                                    options={statusList}
                                                    filterOption={handleFilter}
                                                />
                                            </Form.Item>

                                        </Col>
                                        {(status && rejectResonList[status] && rejectResonList[status].length > 0) && (
                                            <Col md={12} xs={24}>
                                                <Typography.Title level={5} style={{
                                                    fontSize: '14px'
                                                }}>Reason</Typography.Title>

                                                <Form.Item
                                                    name="reason"
                                                    rules={[{ required: true, message: 'Please input your username!' }]}
                                                >
                                                    <Select
                                                        showSearch={true}
                                                        placeholder="Select Type"
                                                        className="form-custom-select"
                                                        style={{
                                                            width: '98%',
                                                            border: '1px solid #92B5D7',
                                                            borderRadius: token.borderRadius,
                                                            boxShadow: 'none'
                                                        }}
                                                        options={rejectResonList[status]}
                                                        filterOption={handleFilter}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        )}
                                    </Row>
                                    <Row style={{
                                        marginBottom: '20px'
                                    }}>
                                        <Col xs={24}>
                                            <Typography.Title level={5} style={{
                                                fontSize: '14px'
                                            }}>Note</Typography.Title>
                                            <Form.Item
                                                name="note"
                                                style={{
                                                    margin: 0
                                                }}
                                                rules={[{ required: false }]}
                                                initialValue={""}
                                            >
                                                <Input
                                                    type="text"
                                                    placeholder="Type here"
                                                    style={{
                                                        width: '100%',
                                                        border: '1px solid #92B5D7',
                                                        borderRadius: token.borderRadius,
                                                        boxShadow: 'none'
                                                    }}
                                                />
                                            </Form.Item>

                                        </Col>
                                    </Row>
                                </>

                            }

                            <Row className="action-row" style={{
                                justifyContent: 'end'
                            }}>
                                <Col style={{ marginRight: 16 }}>
                                    <Button style={{
                                        border: '0',
                                        background: '#E0EEF7',
                                        color: '#000000'
                                    }}
                                        onClick={() => setIsEdit(false)}
                                    >
                                        Cancel
                                    </Button>
                                </Col>
                                <Col>
                                    {(status !== "Closed") ?
                                        <Button type="primary" block style={{
                                            background: token.colorInfo,
                                            boxShadow: 'none'
                                        }}
                                            onClick={showConfirmationModal}
                                        >
                                            Save Changes
                                        </Button> :
                                        <Button type="primary" block style={{
                                            background: token.colorInfo,
                                            boxShadow: 'none'
                                        }}
                                            onClick={() => { setCloseLead(true) }}
                                        >
                                            Close Lead
                                        </Button>
                                    }
                                </Col>
                            </Row>
                        </Form>
                    }

                    {!isEdit &&
                        <React.Fragment>
                            <Row style={{
                                marginBottom: '20px'
                            }}>
                                <Col md={12} xs={24}>
                                    <Typography.Title level={5} style={{
                                        fontSize: '14px',
                                        margin: 0
                                    }}>Status</Typography.Title>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <ImgIcon iconName="close.svg" />
                                        {lead.status.value} | {lead.status.note} | {lead.status.description}
                                    </div>
                                </Col>
                            </Row>

                        </React.Fragment>
                    }
                </Card>

                {closeLead &&
                    <CloseLeadComponent lead={lead} open={true} onCancel={() => { setCloseLead(false) }} onLeadSuccess={onLeadSuccess} />
                }

            </Col>
        </React.Fragment>
    )
}