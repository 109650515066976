import { useContext } from "react"
import { EnquiryContext } from "./enquiry.context";


export const useEnquiry = () => {
    const  context = useContext(EnquiryContext)
    if (context === undefined){
        throw new Error(`Component is not inside EnquiryContext scope`);
    }
    return context
}