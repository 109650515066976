import { Card } from "antd"
import ActionComponent from "./action.component"
import PermissionSliderComponent from "./permission-slider.component"

const PermissionComponent = () => {
    return (
        <Card className="p-mweb-card" style={{
            height: '100%',
            padding: '0 20px'
        }}
            headStyle={{
                padding: '10px 0 0',
                minHeight: 44
            }}
            bodyStyle={{
                padding: '10px 0',
                color: '#000'
            }}
            title="Permissions"
            bordered={false}
        >
            <PermissionSliderComponent />

            <ActionComponent />
            
        </Card>
    )
}

export default PermissionComponent