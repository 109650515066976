import { Button, Popconfirm, Space, Spin, Table, Tag } from "antd";
import { useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";
import { useProject } from "../project.hook";
import { ProjectRes } from "../../../response/project.response";
import { UpdateProjectComponent } from "./update-project.component";
import { AddProjectComponent } from "./add-project.compoenent";
import { ProjectDetailComponent } from "./project-detail.component";
import { OutSideKey } from "../../../model/outside-key";
import { MiniUser } from "../../../model/mini-user";
import PageHeader from "../../common/page-header.component";
import { formatName } from "../../../util/name-helper";
import { ProjectService } from "../../../service/project.service";

export const MainProjectTableComponent = () => {

  const [deleteProjectId, setDeleteProjectId] = useState("");

  const showPopconfirm = (projectRes: ProjectRes) => {
    setDeleteProjectId(projectRes.id);
  };

  const popconfirmCancel = () => {
    setDeleteProjectId("");
  };

  const removeProject = async (deleteEmployeeId: string) => {
    updateLoading(true)
    await ProjectService.delete(deleteEmployeeId)
    setDeleteProjectId("");
    updateLoading(false)
    window.location.reload()
  }

  const {
    loading,
    projectList,
    projectSearchReq,
    fetchProjectList,
    updateLoading,
  } = useProject();

  const [addNewProject, setOpenNewProject] = useState(false);
  const [projectDetail, setProjectDetail] = useState<ProjectRes | undefined>(
    undefined
  );
  const [updateProjectDetail, setUpdateProjectDetail] = useState<
    ProjectRes | undefined
  >(undefined);

  const updateOpenNewProject = () => {
    if (addNewProject) {
      fetchProjectList(
        { ...projectSearchReq, pageNumber: 1 },
        () => {
          updateLoading(false);
        },
        () => {
          updateLoading(false);
        }
      );
    }
    setOpenNewProject((prevVal) => !prevVal);
  };

  const closeProjectDetail = () => {
    setProjectDetail((prevVal) => undefined);
  };

  const cancelProjectEdit = () => {
    setUpdateProjectDetail((prevVal) => undefined);
  };

  useEffect(() => {
    fetchProjectList(
      { ...projectSearchReq, pageNumber: 1 },
      () => {
        updateLoading(false);
      },
      () => {
        updateLoading(false);
      }
    );
  }, []);

  const columnList: ColumnsType<ProjectRes> = [
    {
      title: "Project ID",
      dataIndex: "id",
      key: "projectId",
      onCell: (project, rowIndex) => {
        return {
          onClick: (ev) => {
            setProjectDetail(project);
          },
        };
      },
      render: (projectId) => (
        <a>{`${String(projectId.substring(0, 4) + "...").padStart(3, "0")}`}</a>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Builder",
      dataIndex: "builder",
      key: "builder",
      render: (builder) => <span>{formatName(builder.name)}</span>,
    },
    {
      title: "Source",
      dataIndex: "outSideKey",
      key: "outSideKey",
      render: (outSideKey: OutSideKey) => (
        <>
          {outSideKey.housing != "" && <Tag>Housing: {outSideKey.housing}</Tag>}
          {outSideKey.housing != "" && <br />}
          {outSideKey.acre != "" && <Tag>99acre: {outSideKey.acre}</Tag>}
        </>
      ),
    },
    {
      title: "Employee List",
      dataIndex: "employeeList",
      key: "employeeList",
      render: (employeeList) => (
        <span>
          {employeeList
            ?.map((miniUser: MiniUser) => {
              return miniUser.name;
            })
            .join(", ")}
        </span>
      ),
    },
    {
      title: "Action",
      dataIndex: "projectId",
      key: "projectId",
      onCell: (project, rowIndex) => {
        return {
          onClick: (ev) => {
            setUpdateProjectDetail(project);
          },
        };
      },
      render: (project) => <a>Edit</a>,
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id) => <Popconfirm
        title="Delete"
        description="Are you sure that want to delete?"
        open={deleteProjectId == id}
        onConfirm={() => {
          removeProject(deleteProjectId)
        }}
        okButtonProps={{ loading: loading }}
        onCancel={() => {
          popconfirmCancel()
        }}
      ><a style={{ color: 'red' }} onClick={() => { setDeleteProjectId(id) }}>Remove</a></Popconfirm>,
    },
  ];

  return (
    <>
      <PageHeader
        heading={"Project List"}
        search={projectSearchReq}
        addNewMethod={updateOpenNewProject}
      />

      {/* <Space direction="horizontal" style={{ width: '100%', justifyContent: 'end', marginBottom: 8 }}>
            <Button type="primary" onClick={updateOpenNewProject}>Add New</Button>
        </Space> */}
      <Table
        className="task-main-table task-table"
        columns={columnList}
        dataSource={projectList}
        pagination={{
          defaultPageSize: 30,
          showSizeChanger: true,
          pageSizeOptions: ["20", "50", "100", "200", "300", "400", "500"],
        }}
        loading={{
          indicator: (
            <div>
              <Spin />
            </div>
          ),
          spinning: loading,
        }}
      />
      {addNewProject && (
        <AddProjectComponent
          open={addNewProject}
          onCancel={updateOpenNewProject}
        />
      )}
      {projectDetail && (
        <ProjectDetailComponent
          projectDetail={projectDetail}
          open={true}
          onCancel={closeProjectDetail}
        />
      )}
      {updateProjectDetail && (
        <UpdateProjectComponent
          projectRes={updateProjectDetail}
          open={true}
          onCancel={cancelProjectEdit}
        />
      )}
    </>
  );
};
