import React from 'react';
import { Form, Input, Card } from 'antd';
import { useTemplate } from '../template.hook';
import { validateAddressStreet, validateCity } from '../../../../validator/user-info.validator';
import { TemplateRequest } from '../../../../request/template.request';
import { validateName } from '../../../../validator/user-info.validator';
import { useNavigate } from 'react-router-dom';

export const AddTemplateComponent: React.FC<{}> = ({ }) => {
    const navigate = useNavigate();
    
    const [form] = Form.useForm();
    const { loading, updateLoading, saveTemplate } = useTemplate()

    const handleOk = () => {
        form.validateFields().then(values => {
            updateLoading(true);
            const templateRequest = {
                type: values["type"],
                title: values["title"],
                description: values["description"],
            } as TemplateRequest
            saveTemplate(templateRequest, onSuccess, onFailure)
        });
    };

    const onSuccess = () => {
        updateLoading(false);
        form.resetFields()
        navigate("/template");
    }

    const onFailure = () => {
        updateLoading(false);
    }

    return (
        <Card
            title="Add New Template"
            style={{ top: 8, width: '90%', margin: 'auto' }}
        >
            <Form form={form} layout="vertical">
                <Form.Item
                    name="type"
                    label="Type"
                    rules={[{ required: true, validator: validateName }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="title"
                    label="Title"
                    rules={[{ required: true, validator: validateAddressStreet }]}
                >
                    <Input.TextArea rows={2} placeholder="Enter title" />
                </Form.Item>
                <Form.Item
                    name="description"
                    label="Description"
                    rules={[{ required: true, validator: validateCity }]}
                >
                    <Input placeholder="Enter description" />
                </Form.Item>
            </Form>
        </Card>
    );
}