import React, { useRef, useState } from 'react';
import { Upload, Button, Modal } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import httpClient from '../../util/http-client';

const CustomFileUpload: React.FC = () => {

    const [showConfirm, setConfirm] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false);

    const selectedFile = useRef<any>();

    const handleUpload = async (file: any) => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            httpClient.post("/enquiry-upload", formData,
                { headers: { 'Content-Type': 'multipart/form-data' } })
                .then((response) => {
                    // handle the response
                    alert(`${response.data.data.length} Enquiry Uploaded`);
                    selectedFile.current = null;
                    window.location.reload();
                })
                .catch((error) => {
                    // handle errors
                    alert(`Enquiry Upload Failed`);
                    selectedFile.current = null;
                    setConfirm(false)
                });

        } catch (error) {
            console.error('Error uploading file', error);
        }
    };

    const handleChange = (info: any) => {
        if (info.file.status === 'done') {
            alert(`${info.file.name} uploaded successfully.`);
        } else if (info.file.status === 'error') {
            alert(`${info.file.name} upload failed.`);
        }
    };

    return (
        <>
            <Upload
                customRequest={({ file, onSuccess, onError }) => {
                    selectedFile.current = file
                    setConfirm(true)
                }}
                onChange={handleChange}
                multiple={true}
                showUploadList={false}
            >
                <Button  style={{ marginRight: '10px' }}>
                    <UploadOutlined /> Upload Files
                </Button>
            </Upload>

            <Modal
                title="Upload Enquiry"
                open={showConfirm}
                onOk={() => {
                    setConfirmLoading(true);
                    handleUpload(selectedFile.current);
                }}
                confirmLoading={confirmLoading}
                onCancel={() => {
                    setConfirm(false)
                }}
            >
                <p>Are you sure want to upload this data. After ok you can not stop it</p>
            </Modal>
        </>
    );
};

export default CustomFileUpload;