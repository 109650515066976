import { Card, Space, Spin, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { formatName } from '../../../util/name-helper';
import { TargetRes } from '../../../response/target.response';
import dayjs from 'dayjs';
import { TargetService } from '../../../service/target.service';
import { TargetSearchReq } from '../../../request/target.request';
import { SecurityManager } from '../../../security/security-manager';


export const TargetComponent = () => {

    const [loading, setLoading] = useState(false)
    const [targetList, setTargetList] = useState<TargetRes[]>([])

    const fetchTarget = async () => {
        const targetSearchReq = {
            pageNumber: 1,
            itemsPerPage: 10,
            employeeId: SecurityManager.getEid()
        } as TargetSearchReq
        const [data, error] = await TargetService.getTargetList(targetSearchReq)

        if (error) {

        } else {
            setTargetList([...data])
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchTarget()
    }, [])

    const columnList: ColumnsType<TargetRes> = [
        {
            title: 'Target Id',
            dataIndex: 'id',
            key: 'id',
            render: (id) => <span>{`${String(id).padStart(3, '0')}`}</span>,
        },
        {
            title: 'Targeter/Employee Name',
            dataIndex: 'employee',
            key: 'employee',
            render: (employee) => <span>{formatName(employee.userInfo.name)}</span>,
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (type) => <span>{type}</span>,
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
            render: (value) => <span>{value}</span>,
        },
        {
            title: 'Start At',
            dataIndex: 'startAt',
            key: 'startAt',
            render: (startAt) => <span>{dayjs(startAt).format('DD MMMM YYYY')}</span>,
        },
        {
            title: 'End At',
            dataIndex: 'endAt',
            key: 'endAt',
            render: (endAt) => <span>{dayjs(endAt).format('DD MMMM YYYY')}</span>,
        },
    ];

    return <>{targetList.length >= 0 ? <Card style={{ width: "100%", marginBottom: 8 }}
        title={"Your Target"}>
        <Table

            className='task-main-table task-table'
            columns={columnList} dataSource={targetList}
            pagination={false} 
            loading={{ indicator: <div><Spin /></div>, spinning: loading }} />
    </Card> : <></>}
    </>

}