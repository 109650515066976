import "./emp.css"
import { useEmployee } from '../../employee.hook';
import { EmpCardViewComponent } from './emp-card-view.component';
import { Row, Spin } from "antd";
import { useEffect } from "react";
import { EmployeeRes } from "../../../../response/employee.response";


export const MyTeamComponent = () => {
    const { loading, teamMemberList, fetchTeamMemberList } = useEmployee()

    useEffect(() => {
        fetchTeamMemberList()
    }, [])

    return <div style={{marginTop: -42 }}>
        {loading ? <Spin style={{ marginLeft: "auto", marginRight: "auto", marginTop: 48 }} /> :
            <Row>
                {teamMemberList && teamMemberList.map((employeeRes: EmployeeRes) => {
                    return <EmpCardViewComponent employeeRes={employeeRes} />
                })}
            </Row>
        }
    </div>
}