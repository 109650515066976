import React from 'react';
import { Modal, Form, Input, TreeSelect, Select } from 'antd';
import { useAmenities } from '../amenities.hook';
import { AmenitiesRequest } from '../../../request/amenities.request';
import { AmenitiesRes } from '../../../response/amenities.response';
import { TextEditorView } from '../../../view/text-editor.view';
import FileUploadView from '../../../view/file-upload.view';
import { handleFilter } from '../../../view/input.helper';

export const UpdateAmenitiesComponent: React.FC<{ amenities: AmenitiesRes, open: boolean, onCancel: () => void }> = ({ amenities, open, onCancel }) => {
    const [form] = Form.useForm();
    const { loading, updateLoading, updateAmenities } = useAmenities()

    const handleOk = () => {
        form.validateFields().then(values => {
            updateLoading(true);
            const amenitiesRequest = {
                name: values["name"],
                icon: values["icon"],
                exist: true,
                group: values["group"]
            } as AmenitiesRequest
            updateAmenities(amenities.id, amenitiesRequest, onSuccess, onFailure)
        });
    };

    const onSuccess = () => {
        updateLoading(false);
        form.resetFields()
        onCancel();
    }

    const onFailure = () => {
        updateLoading(false);
    }

    return (
        <Modal
            open={open}
            title="Add New Amenities"
            onCancel={onCancel}
            confirmLoading={loading}
            onOk={handleOk}
            okText={"Submit"}
            cancelText={"Cancel"}
            style={{ top: 8, maxWidth: 768 }}
            width={'90vw'}
        >
            <Form form={form} layout="vertical">
                <Form.Item
                    name="name"
                    label="Name"
                    rules={[{ required: true }]}
                    initialValue={amenities.name}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="icon"
                    label="Icon"
                    rules={[{ required: true, message: 'Please add icon' }]}
                    initialValue={amenities.icon}
                >
                    <FileUploadView onChange={() => { }} />
                </Form.Item>

                <Form.Item
                    name="group"
                    label="Group"
                    rules={[{ required: true }]}
                    initialValue={amenities.group}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
}