import { AndroidConnerctor } from "../android.connecter"
import { CacheKey } from "../enum/cache-key.enum"
import { Session } from "../model/session.model"

const Android = (window as any).Android

const getSession = (): Session => {
    if (Android) {
        try {
            let session: Session = JSON.parse(`${AndroidConnerctor.getSession()}`)
            const newSession = JSON.parse(String(session))
            return newSession
        } catch {
            const newSession = JSON.parse('{}') as Session
            return newSession
        }
    } else {
        const session = JSON.parse(localStorage.getItem(CacheKey.USER_SESSION) || '{}') as Session
        return session
    }
}

export const SecurityManager = {

    loggedIn: (): boolean => {
        let session = getSession()

        if (session && session.authToken && session.authToken.length > 0) {
            return true
        } else {
            return false
        }
    },

    setSession(session: Session) {
        if (Android) {
            AndroidConnerctor.setSession(session)
        } else {
            localStorage.setItem(CacheKey.USER_SESSION, JSON.stringify(session))
        }
        window.location.reload()
    },

    updateAuthToken(authToken: string) {
        const session = getSession()
        session.authToken = authToken
        if (Android) {
            AndroidConnerctor.setSession(session)
        } else {
            localStorage.setItem(CacheKey.USER_SESSION, JSON.stringify(session))
        }
    },

    getAuthToken: (): string => {
        let session = getSession()
        return session.authToken
    },

    getRefreshToken: (): string => {
        let session = getSession()
        return session.refreshToken
    },

    getUid: (): string => {
        let session = getSession()

        return session.id
    },

    getEid: (): string => {
        let session = getSession()

        return session.employeeId
    },

    getVerified: (): boolean => {
        let session = getSession()
        return session.verified
    },

    logout() {
        try {
            localStorage.removeItem(CacheKey.USER_SESSION)
            AndroidConnerctor.removeSession()
            window.location.reload()
        } catch (err) {
            console.error(err)
        }
    },
}