import { Col, Row, Tag, theme } from "antd"
import TableCardColumnComponent from "./table-card-column.component";
import { EnquiryRes } from "../../../../../response/enquiry.response";

const TableCardViewComponent = ({ getRowClassName, enquiryList }: any) => {

    const { useToken } = theme;
    const { token } = useToken();

    return (
        <>
            <Row style={{
                background: '#E0EEF7',
                padding: 10,
                borderRadius: 4,
                color: '#343433',
                fontWeight: 500,
                marginBottom: 2
            }}>
                <Col span={8}>Name</Col>
                <Col span={8}>Requirement</Col>
                <Col span={8} style={{
                    textAlign: 'center'
                }}>Next action</Col>
            </Row>

            {enquiryList.map((enquiry: EnquiryRes) => {
                return <Row style={{
                    background: '#1AA30E0D',
                    borderRadius: 4,
                    color: '#343433',
                    fontWeight: 500,
                    marginBottom: 2
                }}>
                    <TableCardColumnComponent enquiry={enquiry}/>
                </Row>
            })}
        </>
    )
}

export default TableCardViewComponent