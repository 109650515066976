import { useContext } from "react"
import { TemplateContext } from "./template.context";


export const useTemplate = () => {
    const  context = useContext(TemplateContext)
    if (context === undefined){
        throw new Error(`Component is not inside TemplateContext scope`);
    }
    return context
}