import { Card, Table, Typography } from "antd"
import React from "react";
import ImgIcon from "../../../../view/img-icon";
import { Link, useNavigate } from "react-router-dom";
import { TaskRes } from "../../../../response/task.response";
import { ColumnsType } from "antd/es/table";

export const UpcomingTaskTable = (props: any) => {

    const navigate = useNavigate()

    const { taskList } = props

    const { Text } = Typography;

    const getRowClassName = (record: any) => {
        if (record.status === 'In Progress') {
            return 'inprogress'; // CSS class for active rows
        } else if (record.status === 'Pending') {
            return 'pending'; // CSS class for inactive rows
        } else if (record.status === 'Rejected') {
            return 'rejected'; // CSS class for inactive rows
        } else if (record.status === 'Closed') {
            return 'close'; // CSS class for inactive rows
        }

        return '';
    };

    const columnList: ColumnsType<TaskRes> = [
        {
            title: (
                <div className='d-flex task-table-column'>
                    <ImgIcon iconClass="table-th-icon mr-2 d-flex" iconName="task-id.svg" />
                    <Text ellipsis={true} className='w-50'>Task ID</Text>
                </div>
            ),
            dataIndex: 'taskId',
            key: 'taskId',
            onCell: (task, rowIndex) => {
                return {
                    onClick: (ev) => {
                        navigate(`/task/${task.id}`)
                    },
                };
            },
            render: (taskId: number) => <a style={{ borderBottom: '1px solid' }}>{`Task${String(taskId).padStart(3, '0')}`}</a>,
        },
        {
            title: (
                <div className='d-flex task-table-column'>
                    <ImgIcon iconClass="table-th-icon mr-2 d-flex" iconName="title.svg" />
                    <Text ellipsis={true} className=''>Title</Text>
                </div>
            ),

            dataIndex: 'title',
            key: 'title',
            render: (title: string) => <span>{title}</span>,
        },
        {
            title: (
                <div className='d-flex task-table-column'>
                    <ImgIcon iconClass="table-th-icon mr-2 d-flex" iconName="priority.svg" />
                    <Text ellipsis={true} className='w-50'>Priority</Text>
                </div>
            ),
            dataIndex: 'priority',
            key: 'priority',
            render: (priority: string) => (
                <React.Fragment>
                    <span style={{ textTransform: 'capitalize', display: 'flex' }}>
                        {priority === "Highest" &&
                            <ImgIcon iconName="Highest.svg" />
                        }
                        {priority === "High" &&
                            <ImgIcon iconName="high.svg" />
                        }
                        {priority === "Medium" &&
                            <ImgIcon iconName="medium.svg" />
                        }
                        {priority === "Low" &&
                            <ImgIcon iconName="low.svg" />
                        }
                        {priority === "Lowest" &&
                            <ImgIcon iconName="Lowest.svg" />
                        }
                        {priority}
                    </span>
                </React.Fragment>

            )
        },
        {
            title: (
                <div className='d-flex task-table-column'>
                    <ImgIcon iconClass="table-th-icon" iconName="calender.svg" />
                    <Text ellipsis={true} className='w-50'>Due Date</Text>
                </div>
            ),
            dataIndex: 'dueDate',
            key: 'dueDate',
            render: (dueDate: Date) => <span>{new Date(dueDate).toLocaleDateString('en', { year: 'numeric', month: 'long', day: 'numeric' })}</span>,
        }
    ];


    return (
        <>
            <Card className="p-mweb-card" style={{
                height: '100%',
                padding: '0 20px 10px',
                marginBottom: 20
            }}
                headStyle={{
                    padding: '10px 0 0',
                    minHeight: 44
                }}
                bodyStyle={{
                    padding: '10px 0',
                    color: '#000'
                }}
                title="Upcoming Tasks"
                bordered={false}
                extra={<a onClick={() => {navigate("/task")}} style={{ textDecoration: 'underline' }}>View all</a>}
            >
                <Table
                    columns={columnList} dataSource={taskList}
                    className='generic-table'
                    pagination={false}
                    rowClassName={getRowClassName}
                />

            </Card>
        </>
    )
}