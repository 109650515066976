import React from 'react';
import { Modal, Form, Select, Col, Row, Typography, theme } from 'antd';
import { useLead } from '../lead.hook';
import { handleFilter } from '../../../view/input.helper';
import { BulkLeadUpdateReq } from '../../../request/lead.request';
import { LeadService } from '../../../service/lead.service';
import { ItemProp } from '../../../model/item-prop.model';
import { EmployeeRes } from '../../../response/employee.response';
import { formatName } from '../../../util/name-helper';

export const UpdateLeadOwnerComponent: React.FC<{ leadIdList: string[], open: boolean, onCancel: () => void, onOwnerSuccess: () => void }> = ({ leadIdList, open, onCancel, onOwnerSuccess }) => {
    const [form] = Form.useForm();
    const { loading, updateLoading, employeeList, fetchLeadList, leadSearchReq } = useLead()

    const { useToken } = theme;
    const { token } = useToken();

    const handleOk = () => {
        form.validateFields().then(async values => {
            const bulkLeadUpdateReq = {
                leadIdList: leadIdList,
                ownerId: values["ownerId"]
            } as BulkLeadUpdateReq

            const [data, error] = await LeadService.updateBulkLead(bulkLeadUpdateReq)

            if (error) {
                onFailure()
            } else {
                onSuccess()
            }
        });
    }

    const onSuccess = async () => {
        fetchLeadList({ ...leadSearchReq, pageNumber: 1 }, () => {
            onOwnerSuccess()
            form.resetFields()
            updateLoading(false)
            onCancel();
        }, () => {
            onOwnerSuccess()
            form.resetFields()
            updateLoading(false)
            onCancel();
        })
    }

    const onFailure = () => {
        updateLoading(false);
    }

    const { confirm } = Modal;

    const showConfirmationModal = () => {
        confirm({
            title: 'Are you sure, you want to Assign',
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                handleOk()
            },
            onCancel() {

            },
        });
    };

    return (
        <>
            <Modal
                open={open}
                title="Update Status"
                onCancel={onCancel}
                confirmLoading={loading}
                onOk={showConfirmationModal}
                okText={"Update Owner"}
                cancelText={"Cancel"}
            >
                <Form form={form}>

                    <>
                        <Row style={{
                            marginBottom: '20px'
                        }}>
                            <Col md={12} xs={24}>
                                <Typography.Title level={5} style={{
                                    fontSize: '14px'
                                }}>Owner</Typography.Title>
                                <Form.Item
                                    name="ownerId"
                                    style={{
                                        margin: 0
                                    }}
                                    rules={[{ required: true, message: 'Please input employee name' }]}
                                >
                                    <Select
                                        showSearch={true}
                                        className="form-custom-select"
                                        style={{
                                            width: '94%',
                                            border: '1px solid #92B5D7',
                                            borderRadius: token.borderRadius,
                                            boxShadow: 'none'
                                        }}
                                        options={employeeList.map((employee: EmployeeRes) => {
                                            return {
                                                label: `${formatName(employee.userInfo.name)}`,
                                                value: employee.id
                                            } as ItemProp
                                        })}
                                        filterOption={handleFilter}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                </Form>
            </Modal>
        </>
    );
}