import React, { useState } from 'react';
import { Modal, Form, Select, Button, Col, Input, Row, Typography, theme } from 'antd';
import { LargeStatus } from '../../../model/large-status.model';
import { useLead } from '../lead.hook';
import { LeadRes } from '../../../response/lead.response';
import { handleFilter } from '../../../view/input.helper';
import { LeadRequest } from '../../../request/lead.request';
import { LeadService } from '../../../service/lead.service';
import { CloseLeadComponent } from './close-lead/close-lead.component';

export const UpdateLeadStatusComponent: React.FC<{ lead: LeadRes, selectedStatus: string, open: boolean, onCancel: () => void, onStatusSuccess: () => void }> = ({ lead, selectedStatus, open, onCancel, onStatusSuccess }) => {
    const [form] = Form.useForm();
    const { loading, updateLoading, rejectResonList, fetchLeadList, leadSearchReq, statusList } = useLead()

    const { useToken } = theme;
    const { token } = useToken();

    const status = Form.useWatch('status', form);

    const handleOk = () => {
        form.validateFields().then(async values => {
            const leadRequest = {
                status: {
                    value: values["status"],
                    note: values["reason"],
                    description: values["note"],
                    dateTime: new Date()
                } as LargeStatus
            } as LeadRequest

            const [data, error] = await LeadService.updateLead(lead.id, leadRequest)

            if (error) {
                onFailure()
            } else {
                onSuccess()
            }
        });
    }

    const onSuccess = async () => {
        onStatusSuccess()
        form.resetFields()
        onCancel();
    }

    const onFailure = () => {
        updateLoading(false);
    }

    const { confirm } = Modal;

    const showConfirmationModal = () => {
        confirm({
            title: 'Are you sure, you want to save changes',
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                handleOk()
            },
            onCancel() {

            },
        });
    };

    const [createLead, setCreateLead] = useState(false)

    const onLeadSuccess = async () => {
        fetchLeadList({ ...leadSearchReq, pageNumber: 1 }, () => {
            updateLoading(false)
            onCancel()
        }, () => {
            updateLoading(false)
            onCancel()
        })
    }

    return (
        <>
            <Modal
                open={open}
                title="Update Status"
                onCancel={onCancel}
                confirmLoading={loading}
                onOk={status !== "Closed" ? showConfirmationModal : () => { setCreateLead(true) }}
                okText={status !== "Closed" ? "Save Changes" : "Create Lead"}
                cancelText={"Cancel"}
            >
                <Form form={form}>

                    {(lead.status.value === 'Pending' || lead.status.value === 'In Progress') &&
                        <>
                            <Row style={{
                                marginBottom: '20px'
                            }}>
                                <Col md={12} xs={24}>
                                    <Typography.Title level={5} style={{
                                        fontSize: '14px'
                                    }}>Status</Typography.Title>
                                    <Form.Item
                                        style={{
                                            margin: 0
                                        }}
                                        name="status"
                                        label=""
                                        rules={[{ required: true, message: 'Please select status' }]}
                                        initialValue={selectedStatus}
                                    >
                                        <Select
                                            showSearch={true}
                                            placeholder="Select Type"
                                            className="form-custom-select"
                                            style={{
                                                width: '94%',
                                                border: '1px solid #92B5D7',
                                                borderRadius: token.borderRadius,
                                                boxShadow: 'none'
                                            }}
                                            options={statusList}
                                            filterOption={handleFilter}
                                        />
                                    </Form.Item>

                                </Col>
                                {(status && rejectResonList[status] && rejectResonList[status].length > 0) && (
                                    <Col md={12} xs={24}>
                                        <Typography.Title level={5} style={{
                                            fontSize: '14px'
                                        }}>Reason</Typography.Title>

                                        <Form.Item
                                            name="reason"
                                            rules={[{ required: true, message: 'Please input your username!' }]}
                                        >
                                            <Select
                                                showSearch={true}
                                                placeholder="Select Type"
                                                className="form-custom-select"
                                                style={{
                                                    width: '98%',
                                                    border: '1px solid #92B5D7',
                                                    borderRadius: token.borderRadius,
                                                    boxShadow: 'none'
                                                }}
                                                options={rejectResonList[status]}
                                                filterOption={handleFilter}
                                            />
                                        </Form.Item>
                                    </Col>
                                )}
                            </Row>
                            <Row style={{
                                marginBottom: '20px'
                            }}>
                                <Col xs={24}>
                                    <Typography.Title level={5} style={{
                                        fontSize: '14px'
                                    }}>Note</Typography.Title>
                                    <Form.Item
                                        name="note"
                                        style={{
                                            margin: 0
                                        }}
                                        rules={[{ required: true, message: 'Please input your note!' }]}
                                        initialValue={""}
                                    >
                                        <Input
                                            type="text"
                                            placeholder="Type here"
                                            style={{
                                                width: '100%',
                                                border: '1px solid #92B5D7',
                                                borderRadius: token.borderRadius,
                                                boxShadow: 'none'
                                            }}
                                        />
                                    </Form.Item>

                                </Col>
                            </Row>
                        </>

                    }
                </Form>
            </Modal>
            <CloseLeadComponent lead={lead} open={createLead} onCancel={() => {
                setCreateLead(false)
                onCancel()
            }} onLeadSuccess={onLeadSuccess} />
        </>
    );
}