import { useContext } from "react"
import { TeamContext } from "./team.context";


export const useTeam = () => {
    const  context = useContext(TeamContext)
    if (context === undefined){
        throw new Error(`Component is not inside TeamContext scope`);
    }
    return context
}