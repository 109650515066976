import { Breadcrumb, Spin, Tabs, Button, FloatButton } from "antd"
import { useNavigate, useParams } from "react-router-dom"
import { useLead } from "../../lead.hook"
import { useEffect, useState } from "react"
import { RequirementDetailComponent } from "./requirement-detail.component"
import { FollowUpComponent } from "./follow-up.component"
import { LeadSearchReq } from "../../../../request/lead.request"
import { LeadService } from "../../../../service/lead.service"
import { UserDetailComponent } from "./user-detail.ccomponent"
import "../lead.css"
import DetailPageHeader from "../../../common/detail-page-header.component"
import ImgIcon from "../../../../view/img-icon"
import { CloseLeadComponent } from "../close-lead/close-lead.component"
import { pushEvent } from "../../../../event/analytics.helper"
import { EventName } from "../../../../event/event-name.enum"
import { SecurityManager } from "../../../../security/security-manager"
import { CustomerServiceOutlined, PhoneOutlined } from "@ant-design/icons"
import { LeadNoteService } from "../../../../service/lead-note.service"
import { LeadNoteCallReq } from "../../../../request/lead-note.request"
import { useNotification } from "../../../../util/notification.hook"
import { useMapLocation } from "../../../../location/location.hook"

export enum LeadDetailTabType {
    USER_DETAIL = "USER_DETAIL",
    REQUIREMENT_DETAIL = "REQUIREMENT_DETAIL",
    FOLLOW_UP_DETAIL = "FOLLOW_UP_DETAIL"
}

interface TabItem {
    label: string
    value: LeadDetailTabType
}

export const LeadDetailComponent = () => {
    const navigate = useNavigate()
    const { location } = useMapLocation()

    const { openNotification } = useNotification()

    let { id } = useParams()
    const [clientLoading, setClientLoading] = useState(false)
    const [oldLeadList, setOldLeadList] = useState([])
    const { loading, lead, fetchLead, updateLoading, client, fetchClient } = useLead()

    const [closeLead, setCloseLead] = useState(false)

    useEffect(() => {
        updateLoading(true);
        fetchLead(id, onSuccess, onFailure)
    }, [])

    useEffect(() => {
        if (lead.client && lead.client.id) {
            setClientLoading(true)
            fetchClient(lead.client.id, () => { setClientLoading(false) }, () => { setClientLoading(false) })
            fetchOldLead(lead.client.id)
        }
    }, [lead])

    const onSuccess = () => {
        updateLoading(false)
    }
    const onFailure = () => {
        updateLoading(false)
    }
    const fetchOldLead = async (clientId: string) => {
        const [leadList, error] = await LeadService.getLeadList({
            clientIdList: [clientId],
            pageNumber: 1,
            itemsPerPage: 50
        } as LeadSearchReq)
        if (error) {
        } else {
            setOldLeadList(leadList)
        }
    }
    const getView = (type: LeadDetailTabType) => {
        switch (type) {
            case LeadDetailTabType.USER_DETAIL:
                return <UserDetailComponent client={client} />
            case LeadDetailTabType.REQUIREMENT_DETAIL:
                return <RequirementDetailComponent oldLeadList={oldLeadList} lead={lead} />
            case LeadDetailTabType.FOLLOW_UP_DETAIL:
                return <FollowUpComponent lead={lead} />
            default:
                return <> </>
        }
    }

    const callClient = async () => {
        const [data, err] = await LeadNoteService.connectCall({
            id: lead.currentNote.id,
            leadId: lead.id
        } as LeadNoteCallReq)

        if (err) {
            openNotification('error', err.response.data.message, "")
        } else {
            openNotification('success', "Please pick call in your phone", "")
        }
    }

    const tabList = [
        {
            label: "Customer details",
            value: LeadDetailTabType.USER_DETAIL
        } as TabItem,
        {
            label: "Requirement Details",
            value: LeadDetailTabType.REQUIREMENT_DETAIL
        } as TabItem,
        {
            label: "Follow Up",
            value: LeadDetailTabType.FOLLOW_UP_DETAIL
        } as TabItem
    ]

    const onLeadSuccess = async () => {
        await fetchLead(lead.id, () => { updateLoading(false) }, () => { updateLoading(false) })
    }

    useEffect(() => {
        pushEvent(EventName.LEAD_DETAIL_PAGE_VIEW, {
            employeeId: SecurityManager.getEid(),
            leadId: id,
            location: location,
            createdOn: new Date()
        })
    }, [])

    return <>
        {loading ? <Spin style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '32vh', display: 'block' }} /> :
            <>

                <DetailPageHeader headerTitle="Lead Details" dateObj={lead} isCreatedOn={true} />

                <Breadcrumb
                    items={[
                        {
                            onClick: () => {
                                navigate(`/lead`)
                            },
                            title: (
                                <a>Leads</a>
                            ),
                        },
                        {
                            title: (
                                <span>{lead.id}</span>
                            ),
                        },
                    ]}
                    style={{
                        marginBottom: 20
                    }}
                    className="breadcrum--custom-css"
                />
                <Tabs
                    tabPosition={'top'}
                    size={'large'}
                    className="custom-tab-style lead-details-tab-item"
                    items={tabList.map((item: TabItem, id: number) => {
                        return {
                            label: item.label,
                            key: `lead_tab_${id}`,
                            children: getView(item.value),
                        };
                    })}
                    animated={false}
                    tabBarStyle={{
                        padding: 0
                    }}
                    tabBarExtraContent={
                        <Button type="default" style={{
                            padding: 0,
                            border: 0,
                            background: 'transparent',
                            fontWeight: 500,
                            boxShadow: 'none',
                            color: '#000000',
                            marginRight: 5,
                            display: 'flex',
                            alignItems: 'center'
                        }}
                            onClick={() => { setCloseLead(true) }}
                        >
                            <ImgIcon iconName="create.svg" />
                            <span>Close Lead</span>
                        </Button>
                    }
                />

                <FloatButton
                    onClick={callClient}
                    icon={<PhoneOutlined />}
                    type="primary" style={{ right: 24 }} />

                {closeLead &&
                    <CloseLeadComponent lead={lead} open={true} onCancel={() => { setCloseLead(false) }} onLeadSuccess={onLeadSuccess} />
                }
            </>
        }
    </>
}