import { Card } from "antd"
import TaskChartComponent from "./task-chart.component"
import { TaskReportRes } from "../../../../../response/task.response"

export interface TaskCardComponentProp {
    taskReportList: TaskReportRes[]
    filterData: any
}

const TaskCardComponent = (props: TaskCardComponentProp) => {
    const { taskReportList, filterData } = props

    return (
        <Card className="p-mweb-card" style={{
            height: '100%',
            padding: '0 20px'
        }}
            headStyle={{
                padding: '10px 0 0',
                minHeight: 44
            }}
            bodyStyle={{
                padding: '10px 0',
                color: '#000'
            }}
            title="Task Chart"
            bordered={false}
            extra={<div>Total: {taskReportList.reduce((accumulator: number, taskReportRes: TaskReportRes) => accumulator + taskReportRes.count, 0)} tasks</div>}
        >
            <div>
                <TaskChartComponent taskReportList={taskReportList} filterData={filterData}/>
            </div>


        </Card>
    )
}

export default TaskCardComponent