import { OfficeStateProvider } from './office.context';
import { MainOfficeTableComponent } from './component/table.component';

const OfficePageBase = () => {

  return <>
    <MainOfficeTableComponent />
  </>
}

export const OfficePage = () => {
  return <OfficeStateProvider>
    <OfficePageBase />
  </OfficeStateProvider>
}

