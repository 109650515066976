import React, { useEffect } from 'react';
import { Form, Input, Select, Button } from 'antd';
import { EmployeeSearchReq } from '../../../request/employee.request';
import { useEmployee } from '../employee.hook';
import { EmployeeRes } from '../../../response/employee.response';
import { ItemProp } from '../../../model/item-prop.model';
import { OfficeRes } from '../../../response/office.response';
import { RoleRes } from '../../../response/role.response';
import { handleFilter } from '../../../view/input.helper';
import { formatName } from '../../../util/name-helper';
import { AttendanceService } from '../../../service/attendance.service';
import dayjs from 'dayjs';

export const EmployeeFilterComponent: React.FC<any> = ({ setDownloadAttendanceReport }) => {
    const [form] = Form.useForm();
    const { fetchEmployeeList, updateLoading, employeeSearchReq,
        fetchOfficeList,
        fetchRoleList,
        fetchReportee,
        officeList, roleList, reporteeList } = useEmployee()

    const handleOk = () => {
        form.validateFields().then(values => {
            updateLoading(true);
            const _employeeSearchReq = {
                ...employeeSearchReq,
                employeeId: Number(values["employeeId"]),
                phone: values["phone"],
                officeIdList: values["officeIdList"],
                typeList: values["typeList"],
                roleIdList: values["roleIdList"],
                reportingToIdList: values["reportingToIdList"],
                pageNumber: 1,
                itemsPerPage: 1000
            } as EmployeeSearchReq
            fetchEmployeeList(_employeeSearchReq, onSuccess, onFailure)
        });
    };

    const downloadReport = async () => {
        updateLoading(true);
        await AttendanceService.downLoadReportList({
            startDate: dayjs().add(-35, 'd'),
            endDate: dayjs().add(-5, 'd')
        } as any)
        updateLoading(false);
    }

    useEffect(() => {
        fetchOfficeList()
        fetchRoleList()
        fetchReportee()
    }, [])

    const onSuccess = () => {
        updateLoading(false);
    }

    const onFailure = () => {
        updateLoading(false);
    }

    return (
        <Form form={form} layout="inline">
            <Form.Item
                name="employeeId"
                style={{ minWidth: 124 }}
                initialValue={employeeSearchReq.employeeId}
            >
                <Input placeholder='Employee Id' />
            </Form.Item>

            <Form.Item
                name="phone"
                style={{ minWidth: 124 }}
                initialValue={employeeSearchReq.phone}
            >
                <Input placeholder='Phone' />
            </Form.Item>
            <Form.Item
                name="officeIdList"
                style={{ minWidth: 124 }}
                initialValue={employeeSearchReq.officeIdList}
            >
                <Select
                    allowClear
                    mode="multiple"
                    value='officeIdList'
                    placeholder="Office"
                    maxTagCount='responsive'
                    options={officeList.map((office: OfficeRes) => {
                        return {
                            label: office.name,
                            value: office.id
                        } as ItemProp
                    })}
                    filterOption={handleFilter} />
            </Form.Item>
            <Form.Item
                name="roleIdList"
                style={{ minWidth: 124 }}
                initialValue={employeeSearchReq.roleIdList}
            >
                <Select
                    allowClear
                    mode="multiple"
                    value='roleIdList'
                    maxTagCount='responsive'
                    options={roleList.map((role: RoleRes) => {
                        return {
                            label: `${role.name}`,
                            value: role.id
                        } as ItemProp
                    })}
                    placeholder="Role"
                    filterOption={handleFilter} />
            </Form.Item>
            <Form.Item
                name="reportingToIdList"
                style={{ minWidth: 124 }}
                initialValue={employeeSearchReq.reportingToIdList}
            >
                <Select
                    allowClear
                    mode="multiple"
                    value='reportingToIdList'
                    options={reporteeList.map((employee: EmployeeRes) => {
                        return {
                            label: `${formatName(employee.userInfo.name)}`,
                            value: employee.id
                        } as ItemProp
                    })}
                    maxTagCount='responsive'
                    placeholder="Reporting To"
                    filterOption={handleFilter} />
            </Form.Item>

            <Form.Item style={{ minWidth: 124 }}>
                <Button type="primary" htmlType="submit"
                    onClick={handleOk}>
                    Search
                </Button>
            </Form.Item>

            <Button type="primary" htmlType="submit"
                onClick={() => {
                    setDownloadAttendanceReport(true)
                }}>
                DownLoad Report
            </Button>

        </Form >
    );
}
