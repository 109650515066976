import { TemplateRequest, TemplateSearchReq } from "../request/template.request"
import { TemplateRes } from "../response/template.response"
import httpClient from "../util/http-client"

export const TemplateService = {
    saveTemplate: async (templateRequest: TemplateRequest): Promise<(TemplateRes|any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/template", templateRequest)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    getTemplateList: async (templateSearchReq: TemplateSearchReq): Promise<(TemplateRes[]|any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/template-search", templateSearchReq)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    updateTemplate: async (id: string, templateRequest: TemplateRequest): Promise<(TemplateRes|any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.put(`/template/${id}`, templateRequest)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
}