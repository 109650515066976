import { useContext } from "react"
import { EmployeeContext } from "./employee.context";


export const useEmployee = () => {
    const  context = useContext(EmployeeContext)
    if (context === undefined){
        throw new Error(`Component is not inside EmployeeContext scope`);
    }
    return context
}