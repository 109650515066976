import React, { useEffect, useState } from 'react';
import { Form, Button, Drawer, theme, Tag, Divider, Select } from 'antd';
import { TaskSearchReq } from '../../../../request/task.request';
import { useTask } from '../../task.hook';
import '../add-task.css';
import PriorityFilterComponents from './priority-filter.component';
import TagListFilterComponent from './tagList-filter.component';
import StatusFilterComponents from './status-filter.component';
import DateFilterComponent from './date-filter.component';
import ImgIcon from '../../../../view/img-icon';
import { EmployeeRes } from '../../../../response/employee.response';
import { formatName } from '../../../../util/name-helper';
import { ItemProp } from '../../../../model/item-prop.model';
import { handleFilter } from '../../../../view/input.helper';


export const TaskFilterComponent: React.FC = () => {
    const [form] = Form.useForm();
    const { useToken } = theme;
    const { token } = useToken();

    const [openFilterDrawer, setOpenFilterDrawer] = useState(false);

    const { fetchTaskList, updateLoading, employeeList, priorityList, statusList, fetchEmployeeList, taskSearchReq } = useTask()


    const handleOk = () => {
        form.validateFields().then(values => {
            updateLoading(true);
            const _taskSearchReq = {
                ...taskSearchReq,
                title: values["title"],
                priorityList: values["priorityList"],
                tagList: values["tagList"],
                statusList: values["statusList"],
                assigneeIdList: values["assigneeIdList"],
                dueDate: values["dueDate"],
                pageNumber: 1,
                itemsPerPage: 10
            } as TaskSearchReq
            fetchTaskList(_taskSearchReq, onSuccess, onFailure)
            onFilterDrawerClose()
        });
    };

    useEffect(() => {
        fetchEmployeeList()
    }, [])

    const onSuccess = () => {
        updateLoading(false);
    }

    const onFailure = () => {
        updateLoading(false);
    }

    const showFilterDrawer = () => {
        setOpenFilterDrawer(true);
    };

    const onFilterDrawerClose = () => {
        setOpenFilterDrawer(false);
    };


    return (
        <React.Fragment>

            <Button type="primary" style={{ boxShadow: 'none', borderRadius: token.borderRadiusSM, alignItems: 'center', display: 'flex' }} className={`${openFilterDrawer ? 'open-filter-drawer' : ''} filter-btn`} onClick={showFilterDrawer}>
                <ImgIcon iconName="filters-white.svg" iconClass="mwm-0 mr-2 d-flex" iconWidth="12px" />
                <span className='mweb-hidden-elem'>Filters</span>
            </Button>
            <Drawer
                title="Filters"
                size={"default"}
                placement="right"
                rootClassName="filter-drawer-wrapper"
                className="filter-drawer"
                closable={false}
                onClose={onFilterDrawerClose}
                open={openFilterDrawer}
                bodyStyle={{ background: '#F4F4F4' }}
                extra={
                    <div className="drawer-fb-wrapper" style={{ background: '#F4F4F4' }}>
                        <Button style={{ border: '1px solid #000000', display: 'flex', alignItems: 'center' }} className="drawer-filter-btn">
                            <ImgIcon iconName="filters-black.svg" iconWidth="12px" />
                            Filters
                        </Button>
                    </div>
                }
            >
                <Form form={form} className='filter-form' layout="inline">
                    <div style={{ width: '100%' }} className='filter-form-items'>
                        <PriorityFilterComponents taskSearchReq={taskSearchReq} priorityList={priorityList} />
                        <Divider style={{ borderColor: '#ffffff', borderWidth: '3px' }} />

                        <TagListFilterComponent taskSearchReq={taskSearchReq} />
                        <Divider style={{ borderColor: '#ffffff', borderWidth: '3px' }} />

                        <StatusFilterComponents taskSearchReq={taskSearchReq} statusList={statusList} />
                        <Divider style={{ borderColor: '#ffffff', borderWidth: '3px' }} />

                        <Form.Item
                            name="assigneeIdList"
                            style={{ minWidth: 124, marginBottom: 16 }}
                            // initialValue={taskSearchReq.assigneeIdList}
                        >
                            <Select
                                allowClear
                                mode="multiple"
                                value='assigneeIdList'
                                options={employeeList.map((employee: EmployeeRes) => {
                                    return {
                                        label: `${formatName(employee.userInfo.name)}`,
                                        value: employee.id
                                    } as ItemProp
                                })}
                                maxTagCount='responsive'
                                placeholder="Assigned to"
                                filterOption={handleFilter} />
                        </Form.Item>


                        <DateFilterComponent taskSearchReq={taskSearchReq} />
                        <Divider style={{ borderColor: '#ffffff', borderWidth: '3px' }} />
                    </div>


                    <Form.Item className='apply-btn-styles' style={{ marginTop: '20px', marginBottom: '20px', width: '100%' }}>
                        <Button type="primary" style={{ width: '100%', background: token.colorInfo, boxShadow: 'none' }} htmlType="submit"
                            onClick={handleOk}>
                            Apply
                        </Button>
                    </Form.Item>
                </Form>
            </Drawer>
        </React.Fragment>
    );
}
