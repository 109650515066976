import { Col, Form, Input, Row, theme, Typography } from 'antd';

const PriceDetailComponent = () => {

    const { useToken } = theme;
    const { token } = useToken();

    return (
        <>
            <h3 style={{ borderBottom: "2px solid #D9D9D9", paddingBottom: '8px', marginBottom: '12px' }}>Price Detail</h3>
            <Row style={{ marginBottom: '10px' }}>
                <Col lg={6} md={12} xs={24} style={{ paddingRight: '20px', marginBottom: '20px' }}>
                    <Typography.Title level={5} style={{
                        fontSize: '14px'
                    }}>Per sqft rate</Typography.Title>
                    <Form.Item
                        style={{
                            margin: 0
                        }}
                        name="perSqRate"
                        label=""
                        rules={[{ required: true, message: 'Please Enter per sqft rate' }]}
                    >
                        <Input
                            type="number"
                            placeholder="12345"
                            style={{
                                width: '100%',
                                border: '1px solid #92B5D7',
                                borderRadius: token.borderRadius,
                                boxShadow: 'none',
                                height: '40px'
                            }}
                        />
                    </Form.Item>
                </Col>

                <Col lg={6} md={12} xs={24} style={{ paddingRight: '20px', marginBottom: '20px' }}>
                    <Typography.Title level={5} style={{
                        fontSize: '14px',
                        fontWeight: 500
                    }}>Base Selling Price</Typography.Title>
                    <Form.Item
                        style={{
                            margin: 0
                        }}
                        name="baseSelling"
                        label=""
                        rules={[{ required: true, message: 'Please Enter Base Selling Price' }]}
                    >
                        <Input
                            type="number"
                            placeholder="12345"
                            style={{
                                width: '100%',
                                border: '1px solid #92B5D7',
                                borderRadius: token.borderRadius,
                                boxShadow: 'none',
                                height: '40px'
                            }}
                        />
                    </Form.Item>
                </Col>

                <Col lg={6} md={12} xs={24} style={{ paddingRight: '20px', marginBottom: '20px' }}>
                    <Typography.Title level={5} style={{
                        fontSize: '14px',
                        fontWeight: 500
                    }}>Preferred location Cost</Typography.Title>
                    <Form.Item
                        style={{
                            margin: 0
                        }}
                        name="plc"
                        label=""
                        rules={[{ required: true, message: 'Please Enter Preferred location Cost' }]}
                    >
                        <Input
                            type="number"
                            placeholder="12345"
                            style={{
                                width: '100%',
                                border: '1px solid #92B5D7',
                                borderRadius: token.borderRadius,
                                boxShadow: 'none',
                                height: '40px'
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col lg={6} md={12} xs={24} style={{ paddingRight: '20px', marginBottom: '20px' }}>
                    <Typography.Title level={5} style={{
                        fontSize: '14px',
                        fontWeight: 500
                    }}>Floor PLC</Typography.Title>
                    <Form.Item
                        style={{
                            margin: 0
                        }}
                        name="floorPlc"
                        label=""
                        rules={[{ required: true, message: 'Please Enter Floor PLC' }]}
                    >
                        <Input
                            type="number"
                            placeholder="12345"
                            style={{
                                width: '100%',
                                border: '1px solid #92B5D7',
                                borderRadius: token.borderRadius,
                                boxShadow: 'none',
                                height: '40px'
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col lg={6} md={12} xs={24} style={{ paddingRight: '20px', marginBottom: '20px' }}>
                    <Typography.Title level={5} style={{
                        fontSize: '14px',
                        fontWeight: 500
                    }}>Internal development charges</Typography.Title>
                    <Form.Item
                        style={{
                            margin: 0
                        }}
                        name="idc"
                        label=""
                        rules={[{ required: true, message: 'Please Enter Internal development charges' }]}
                    >
                        <Input
                            type="number"
                            placeholder="12345"
                            style={{
                                width: '100%',
                                border: '1px solid #92B5D7',
                                borderRadius: token.borderRadius,
                                boxShadow: 'none',
                                height: '40px'
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col lg={6} md={12} xs={24} style={{ paddingRight: '20px', marginBottom: '20px' }}>
                    <Typography.Title level={5} style={{
                        fontSize: '14px',
                        fontWeight: 500
                    }}>External development charges</Typography.Title>
                    <Form.Item
                        style={{
                            margin: 0
                        }}
                        name="edc"
                        label=""
                        rules={[{ required: true, message: 'Please Enter External development charges' }]}
                    >
                        <Input
                            type="number"
                            placeholder="12345"
                            style={{
                                width: '100%',
                                border: '1px solid #92B5D7',
                                borderRadius: token.borderRadius,
                                boxShadow: 'none',
                                height: '40px'
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col lg={6} md={12} xs={24} style={{ paddingRight: '20px', marginBottom: '20px' }}>
                    <Typography.Title level={5} style={{
                        fontSize: '14px',
                        fontWeight: 500
                    }}>Parking Charges</Typography.Title>
                    <Form.Item
                        style={{
                            margin: 0
                        }}
                        name="cpc"
                        label=""
                        rules={[{ required: true, message: 'Please Enter Parking Charges' }]}
                    >
                        <Input
                            type="number"
                            placeholder="12345"
                            style={{
                                width: '100%',
                                border: '1px solid #92B5D7',
                                borderRadius: token.borderRadius,
                                boxShadow: 'none',
                                height: '40px'
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col lg={6} md={12} xs={24} style={{ paddingRight: '20px', marginBottom: '20px' }}>
                    <Typography.Title level={5} style={{
                        fontSize: '14px',
                        fontWeight: 500
                    }}>Club membership and registration charges</Typography.Title>
                    <Form.Item
                        style={{
                            margin: 0
                        }}
                        name="cmrc"
                        label=""
                        rules={[{ required: true, message: 'Please Enter Club membership and registration charges' }]}
                    >
                        <Input
                            type="number"
                            placeholder="12345"
                            style={{
                                width: '100%',
                                border: '1px solid #92B5D7',
                                borderRadius: token.borderRadius,
                                boxShadow: 'none',
                                height: '40px'
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col lg={6} md={12} xs={24} style={{ paddingRight: '20px', marginBottom: '20px' }}>
                    <Typography.Title level={5} style={{
                        fontSize: '14px',
                        fontWeight: 500
                    }}>other charges</Typography.Title>
                    <Form.Item
                        style={{
                            margin: 0
                        }}
                        name="oc"
                        label=""
                        rules={[{ required: true, message: 'Please Enter other charges' }]}
                    >
                        <Input
                            type="number"
                            placeholder="12345"
                            style={{
                                width: '100%',
                                border: '1px solid #92B5D7',
                                borderRadius: token.borderRadius,
                                boxShadow: 'none',
                                height: '40px'
                            }}
                        />
                    </Form.Item>
                </Col>

                <Col lg={6} md={12} xs={24} style={{ paddingRight: '20px', marginBottom: '20px' }}>
                    <Typography.Title level={5} style={{
                        fontSize: '14px',
                        fontWeight: 500
                    }}>value of property</Typography.Title>
                    <Form.Item
                        style={{
                            margin: 0
                        }}
                        name="total"
                        label=""
                        rules={[{ required: true, message: 'Please Enter value of property' }]}
                    >
                        <Input
                            type="number"
                            placeholder="12345"
                            style={{
                                width: '100%',
                                border: '1px solid #92B5D7',
                                borderRadius: token.borderRadius,
                                boxShadow: 'none',
                                height: '40px'
                            }}
                        />
                    </Form.Item>
                </Col>

                <Col lg={6} md={12} xs={24} style={{ paddingRight: '20px', marginBottom: '20px' }}>
                    <Typography.Title level={5} style={{
                        fontSize: '14px',
                        fontWeight: 500
                    }}>Booking amount</Typography.Title>
                    <Form.Item
                        style={{
                            margin: 0
                        }}
                        name="bookingAmount"
                        label=""
                        rules={[{ required: true, message: 'Please Enter Booking amount' }]}
                    >
                        <Input
                            type="number"
                            placeholder="12345"
                            style={{
                                width: '100%',
                                border: '1px solid #92B5D7',
                                borderRadius: token.borderRadius,
                                boxShadow: 'none',
                                height: '40px'
                            }}
                        />
                    </Form.Item>
                </Col>

                <Col lg={6} md={12} xs={24} style={{ paddingRight: '20px', marginBottom: '20px' }}>
                    <Typography.Title level={5} style={{
                        fontSize: '14px',
                        fontWeight: 500
                    }}>Adjusted in property value in per per sqft</Typography.Title>
                    <Form.Item
                        style={{
                            margin: 0
                        }}
                        name="apvPerSqft"
                        label=""
                        rules={[{ required: true, message: 'Please Enter Adjusted in property value in per per sqft' }]}
                    >
                        <Input
                            type="number"
                            placeholder="12345"
                            style={{
                                width: '100%',
                                border: '1px solid #92B5D7',
                                borderRadius: token.borderRadius,
                                boxShadow: 'none',
                                height: '40px'
                            }}
                        />
                    </Form.Item>
                </Col>

                <Col lg={6} md={12} xs={24} style={{ paddingRight: '20px', marginBottom: '20px' }}>
                    <Typography.Title level={5} style={{
                        fontSize: '14px',
                        fontWeight: 500
                    }}>adjusted in property value in rupee</Typography.Title>
                    <Form.Item
                        style={{
                            margin: 0
                        }}
                        name="apv"
                        label=""
                        rules={[{ required: true, message: 'Please Enter adjusted in property value in rupee' }]}
                    >
                        <Input
                            type="number"
                            placeholder="12345"
                            style={{
                                width: '100%',
                                border: '1px solid #92B5D7',
                                borderRadius: token.borderRadius,
                                boxShadow: 'none',
                                height: '40px'
                            }}
                        />
                    </Form.Item>
                </Col>

                <Col lg={6} md={12} xs={24} style={{ paddingRight: '20px', marginBottom: '20px' }}>
                    <Typography.Title level={5} style={{
                        fontSize: '14px',
                        fontWeight: 500
                    }}>net property value after adjustment</Typography.Title>
                    <Form.Item
                        style={{
                            margin: 0
                        }}
                        name="netPropertyValue"
                        label=""
                        rules={[{ required: true, message: 'Please Enter net property value after adjustment' }]}
                    >
                        <Input
                            type="number"
                            placeholder="12345"
                            style={{
                                width: '100%',
                                border: '1px solid #92B5D7',
                                borderRadius: token.borderRadius,
                                boxShadow: 'none',
                                height: '40px'
                            }}
                        />
                    </Form.Item>
                </Col>

            </Row>
        </>
    )
}

export default PriceDetailComponent