import { Action } from "../../model/action.model";
import { MenuAction } from "./menu.action";
import { MenuState } from "./menu.state";


export const MenuReducer = (state: MenuState, action: Action): MenuState => {
    const { type, payload } = action

    switch (type) {
        case MenuAction.UPDATE_LOADING:
            return {
                ...state,
                loading: payload.loading
            };
        case MenuAction.ADD_MENU_LIST:
            return {
                ...state,
                menuList: [...state.menuList, ...payload.menuList]
            };
        case MenuAction.UPDATE_MENU_LIST:
            return {
                ...state,
                menuList: [...payload.menuList]
            };
        case MenuAction.UPDATE_MENU_SEARCH_REQ:
            return {
                ...state,
                menuSearchReq: { ...payload.menuSearchReq }
            };
        default:
            throw new Error(`No case for type ${type} found in MenuReducer.`);
    }
}