import { Typography } from 'antd';
import ReportComponent from './component/report.component';
import { ReportStateProvider } from './report.context';
import "./report.css"
import { useReport } from './report.hook';
import { useEffect } from 'react';

const ReportPageBase = () => {

	const { employeeSearchReq, teamSearchReq, fetchEmployeeList, fetchTeamList, fetchOfficeList, officeSearchReq } = useReport()

	useEffect(() => {
		fetchEmployeeList(employeeSearchReq)
		fetchTeamList(teamSearchReq)
		fetchOfficeList(officeSearchReq)
	}, [])

	return <>
		<section className='report-section'>

			<div className='report-header'>
				<Typography.Title level={5} className='m-0' style={{
					color: '#000000'
				}}>
					Organization Report
				</Typography.Title>
			</div>

			<ReportComponent />
		</section>

	</>
}

export const ReportPage = () => {
	return <ReportStateProvider>
		<ReportPageBase />
	</ReportStateProvider>
}

