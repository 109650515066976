export const appTheme = {
    token: {
        "colorPrimary": "#001529",
        "colorSuccess": "#1aa30e",
        "colorWarning": "#ec9007",
        "colorError": "#ec0707",
        "colorInfo": "#1677ff",
        "colorTextBase": "#343433",
        "colorBgBase": "#ffffff",
        "fontSize": 14,
        "borderRadius": 6,
        "boxShadow": "      0 2px 16px 0 rgba(0, 0, 0, 0.08),      0 3px 6px -4px rgba(0, 0, 0, 0.12),      0 9px 28px 8px rgba(0, 0, 0, 0.05)    ",
        "boxShadowSecondary": "      0 2px 16px 0 rgba(0, 0, 0, 0.08),      0 3px 6px -4px rgba(0, 0, 0, 0.12),      0 9px 28px 8px rgba(0, 0, 0, 0.05)    ",
        "wireframe": true
    }
}