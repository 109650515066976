import React, { useEffect, useRef, useState } from 'react';
import { Upload, Button, Modal } from 'antd';
import { CloseCircleOutlined, UploadOutlined } from '@ant-design/icons';
import httpClient from '../util/http-client';
import { Media } from '../model/media.model';
import { RcFile } from 'antd/es/upload';

interface OnChangeHandler {
    (mediaList: Media[]): void;
}

export interface FileUploadViewProp {
    initmediaList?: Media[]
    onChange?: OnChangeHandler;
}

const MultiFileUploadView: React.FC<FileUploadViewProp> = ({ onChange, initmediaList }) => {
    console.log(initmediaList)

    const [mediaList, setMediaList] = useState<Media[]>(initmediaList ? initmediaList : [])

    const [showConfirm, setConfirm] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false);

    const selectedFileList = useRef<any[]>();

    const handleUpload = async (fileList: any[]) => {
        const uploadPromises = fileList.map(async (file) => {
            const formData = new FormData();
            formData.append('file', file);

            try {
                const response = await httpClient.post("/upload", formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });

                const data = response.data.data;
                const media = {
                    id: data.ETag,
                    url: {
                        "main": data.Location
                    },
                    aspectRatio: 1,
                    type: "IMAGE",
                    tagList: [],
                    meta: {}
                } as Media;

                return media;
            } catch (error) {
                console.error('Error uploading file', error);
                return null; // or handle the error in a way that suits your application
            }
        });

        try {
            const uploadedMediaList = await Promise.all(uploadPromises);
            const filteredMediaList: any[] = uploadedMediaList.filter((media) => media !== null);

            if (filteredMediaList.length > 0) {
                if (onChange) {
                    setMediaList((current) => [...current, ...filteredMediaList]);
                }
                selectedFileList.current = undefined;
                setConfirmLoading(false);
                setConfirm(false);
            } else {
                selectedFileList.current = undefined;
                setConfirm(false);
            }
        } catch (error) {
            console.error('Error during file upload', error);
            // Handle the error in a way that suits your application
        }
    };

    const handleChange = (info: any) => {
        if (info.file.status === 'done') {
            alert(`${info.file.name} uploaded successfully.`);
        } else if (info.file.status === 'error') {
            alert(`${info.file.name} upload failed.`);
        }
    };

    useEffect(() => {
        if (onChange) {
            onChange(mediaList)
        }
    }, [mediaList])

    return (
        <>
            {(mediaList && mediaList.length > 0) && <div style={{ display: "flex" }}>
                {mediaList.map((media: Media) => {
                    return <div style={{ margin: 8, width: 104, height: 104, background: "grey" }}>
                        <img
                            src={media?.url?.main}
                            style={{ width: '100%',  objectFit: 'contain', height: '100%'}}
                            alt=""
                        />
                        <CloseCircleOutlined
                            onClick={() => {
                                setMediaList((current) => current.filter((m: Media) => m.id != media.id));
                            }} style={{ width: 24, height: 24, position: 'relative', top: '-104px', left: 96 }} />
                    </div>
                })}
            </div>}

            {!showConfirm && <Upload
                customRequest={({ file, onSuccess, onError }) => {
                }}
                beforeUpload={(file: RcFile, FileList: RcFile[]) => {
                    console.log(FileList)
                    selectedFileList.current = FileList
                    setConfirm(true)
                }}
                onChange={handleChange}
                multiple={true}
                showUploadList={false}
            >
                <Button style={{ marginRight: '10px' }}>
                    <UploadOutlined /> Upload Files
                </Button>
            </Upload>}

            <Modal
                title="File Upload"
                open={showConfirm}
                onOk={() => {
                    setConfirmLoading(true);
                    if (selectedFileList.current) {
                        handleUpload(selectedFileList.current);
                    } else {
                        setConfirm(false)
                    }
                }}
                confirmLoading={confirmLoading}
                onCancel={() => {
                    setConfirm(false)
                }}
            >
                <p>Are you sure want to upload all selected file. After ok you can not stop it</p>
            </Modal>
        </>
    );
};

export default MultiFileUploadView;