import React, { useEffect } from "react";
import { Modal, Form, Input } from "antd";
import { useUser } from "../user.hook";
import { UserRequest } from "../../../request/user.request";
import { Address } from "../../../model/address.model";
import { UserInfo } from "../../../model/user-info.model";
import {
  validateAddressLineOne,
  validateAddressLineTwo,
  validateAddressStreet,
  validateCity,
  validateEmail,
  validateName,
  validatePhone,
  validatePinCode,
  validateScore,
  validateState,
} from "../../../validator/user-info.validator";
import { UserRes } from "../../../response/user.response";

export const UpdateUserComponent: React.FC<{
  user: UserRes;
  open: boolean;
  onCancel: () => void;
}> = ({ user, open, onCancel }) => {
  const [form] = Form.useForm();
  const { loading, updateLoading, updateUser } = useUser();

  const handleOk = () => {
    form.validateFields().then((values) => {
      updateLoading(true);
      const userRequest = {
        userInfo: {
          name: values["name"],
          phone: values["phone"],
          email: values["email"],
        } as UserInfo,
        address: {
          lineOne: values["lineOne"],
          lineTwo: values["lineTwo"],
          street: values["street"],
          city: values["city"],
          state: values["state"],
          pinCode: values["pinCode"],
        } as Address,
        score: Number(values["score"]),
      } as UserRequest;
      updateUser(user.id, userRequest, onSuccess, onFailure);
    });
  };
  const onSuccess = () => {
    updateLoading(false);
    form.resetFields();
    onCancel();
    window.location.reload();
  };

  const onFailure = () => {
    updateLoading(false);
  };

  return (
    <Modal
      open={open}
      title="Update User"
      onCancel={onCancel}
      confirmLoading={loading}
      onOk={handleOk}
      okText={"Submit"}
      cancelText={"Cancel"}
      style={{ top: 8, maxWidth: 768 }}
      width={"90vw"}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, validator: validateName }]}
          initialValue={user.userInfo.name}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Phone"
          rules={[{ required: true, validator: validatePhone }]}
          initialValue={user.userInfo.phone}
        >
          <Input type="tel" />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, validator: validateEmail }]}
          initialValue={user.userInfo.email}
        >
          <Input type="email" />
        </Form.Item>
        <Form.Item
          name="lineOne"
          label="Address Line One"
          rules={[{ required: true, validator: validateAddressLineOne }]}
          initialValue={user.address.lineOne}
        >
          <Input.TextArea rows={2} placeholder="Enter your address" />
        </Form.Item>
        <Form.Item
          name="lineTwo"
          label="Address Line Two"
          rules={[{ required: true, validator: validateAddressLineTwo }]}
          initialValue={user.address.lineTwo}
        >
          <Input.TextArea rows={2} placeholder="Enter your address" />
        </Form.Item>

        <Form.Item
          name="street"
          label="Street"
          rules={[{ required: true, validator: validateAddressStreet }]}
          initialValue={user.address.street}
        >
          <Input.TextArea rows={2} placeholder="Enter your street" />
        </Form.Item>
        <Form.Item
          name="city"
          label="City"
          rules={[{ required: true, validator: validateCity }]}
          initialValue={user.address.city}
        >
          <Input placeholder="Enter your city" />
        </Form.Item>
        <Form.Item
          name="state"
          label="State"
          rules={[{ required: true, validator: validateState }]}
          initialValue={user.address.state}
        >
          <Input placeholder="Enter your state" />
        </Form.Item>
        <Form.Item
          name="pinCode"
          label="Pin Code"
          rules={[{ required: true, validator: validatePinCode }]}
          initialValue={user.address.pinCode}
        >
          <Input type="number" placeholder="Enter your pin code" />
        </Form.Item>

        <Form.Item
          name="score"
          label="Score"
          rules={[{ required: true, validator: validateScore }]}
          initialValue={user.score}
        >
          <Input type="number" placeholder="Enter score" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
