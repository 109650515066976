import { Address } from "../model/address.model"
import { Location } from "../model/location.model"
import { UserInfo } from "../model/user-info.model"
import { RoleRes } from "./role.response"
import { TeamRes } from "./team.response"

export enum EmployeeType {
    Permanent = 0,
    Contract = 1,
    Agent = 2
}

export interface EmployeeRes {
    id: string
    userId: string
    userInfo: UserInfo
    roleId: string
    employeeRole: string
    role: RoleRes
    officeId: string
    designation: Map<string, string>
    permissionList: string[]
    currentLocation: Location
    type: EmployeeType
    teamIdList: string[]
    teamList: TeamRes[]
    directCall: boolean
    hideFromWebsite: boolean
    reportingTo: string
    manager: EmployeeRes
    gender: string
    score: number
    altPhone: string
    altEmail: string
    joiningDate: Date
    department: string
    birthDate: string
    anniversaryDate: string
    emergencyContact: string
    bloodGroup: string
    identityCardNo: string
    insuranceNo: string
    address: Address
    employeeId: string
    online: number
    paused: boolean
    createdOn: Date
    modifiedOn: Date
    active: boolean
}