import { createContext, useReducer } from "react";
import { ProjectRequest, ProjectSearchReq } from "../../request/project.request";
import { ProjectState } from "./project.state";
import { ProjectReducer } from "./project.reducer";
import { ProjectAction } from "./project.action";
import { ProjectService } from "../../service/project.service";
import { EmployeeService } from "../../service/employee.service";
import { EmployeeSearchReq } from "../../request/employee.request";
import { BuilderSearchReq } from "../../request/builder.request";
import { BuilderService } from "../../service/builder.service";
import { ItemProp } from "../../model/item-prop.model";
import { LocalityService } from "../../service/locality.service";
import { LocalitySearchReq } from "../../request/locality.request";
import { AmenitiesService } from "../../service/amenities.service";
import { AmenitiesSearchReq } from "../../request/amenities.request";

const _projectStatusList = [
    {
        label: "New Launch",
        value: "New Launch"
    } as ItemProp,
    {
        label: "Under Construction",
        value: "Under Construction"
    } as ItemProp,
    {
        label: "Ready to Move",
        value: "Ready to Move"
    } as ItemProp
]

const _unitTypeList = [
    { label: "Apartment", value: "Apartment" },
    { label: "Villa", value: "Villa" },
    { label: "Builder Floor", value: "Builder Floor" },
    { label: "Plot", value: "Plot" },
    { label: "Shop", value: "Shop" },
    { label: "Office Space", value: "Office Space" },
    { label: "Food Court", value: "Food Court" },
]

const _categoryTypeList = [
    { label: "Residential", value: "Residential" },
    { label: "Commercial", value: "Commercial" },
]

const initialState = {
    loading: false,
    projectSearchReq: {
        pageNumber: 1,
        itemsPerPage: 500
    } as ProjectSearchReq,
    projectList: [],
    employeeList: [],
    employeeSearchReq: {
        pageNumber: 1,
        itemsPerPage: 200
    } as EmployeeSearchReq,
    builderList: [],
    builderSearchReq: {
        pageNumber: 1,
        itemsPerPage: 200
    } as BuilderSearchReq,
    localityList: [],
    localitySearchReq: {
        pageNumber: 1,
        itemsPerPage: 200
    } as LocalitySearchReq,
    projectStatusList: _projectStatusList,
    amenitiesList: [],
    amenitiesSearchReq: {
        pageNumber: 1,
        itemsPerPage: 200
    } as AmenitiesSearchReq,
    unitTypeList: _unitTypeList,
    categoryTypeList: _categoryTypeList,
} as ProjectState

export const ProjectContext = createContext<any>(initialState);

export const ProjectStateProvider = ({ children }: any) => {
    const [state, dispatch] = useReducer(ProjectReducer, initialState)

    const fetchProjectList = async (projectSearchReq: ProjectSearchReq, onSuccess: () => void, onFailure: () => void) => {
        updateLoading(true)
        const [projectList, error] = await ProjectService.getProjectList(projectSearchReq)

        if (error) {
            onFailure()
        } else {
            dispatch({
                type: projectSearchReq.pageNumber == 1 ? ProjectAction.UPDATE_PROJECT_LIST : ProjectAction.ADD_PROJECT_LIST,
                payload: {
                    projectList: projectList
                }
            })
            if (projectList.length > 0) {
                dispatch({
                    type: ProjectAction.UPDATE_PROJECT_SEARCH_REQ,
                    payload: {
                        projectSearchReq: {
                            ...projectSearchReq,
                            pageNumber: projectSearchReq.pageNumber + 1
                        }
                    }
                })
            }
            onSuccess()
        }
    }


    const saveProject = async (projectRequest: ProjectRequest, onSuccess: () => void, onFailure: () => void) => {

        const [data, error] = await ProjectService.saveProject(projectRequest)

        if (error) {
            onFailure()
        } else {
            onSuccess()
        }
    };

    const updateLoading = (loading: Boolean) => {
        dispatch({
            type: ProjectAction.UPDATE_LOADING,
            payload: {
                loading: loading
            }
        })
    };

    const updateProject = async (id: string, projectRequest: ProjectRequest, onSuccess: () => void, onFailure: () => void) => {
        const [data, error] = await ProjectService.updateProject(id, projectRequest)

        if (error) {
            onFailure()
        } else {
            onSuccess()
        }
    };

    const fetchEmployeeList = async () => {
        const [employeeList, error] = await EmployeeService.getEmployeeList(state.employeeSearchReq)
        if (error) {

        } else {
            dispatch({
                type: ProjectAction.UPDATE_EMPLOYEE_LIST,
                payload: {
                    employeeList: employeeList
                }
            });
        }
    };

    const fetchBuilderList = async () => {
        const [builderList, error] = await BuilderService.getBuilderList(state.builderSearchReq)
        if (error) {

        } else {
            dispatch({
                type: ProjectAction.UPDATE_BUILDER_LIST,
                payload: {
                    builderList: builderList
                }
            });
        }
    };

    const fetchLocalityList = async () => {
        const [localityList, error] = await LocalityService.getLocalityList(state.localitySearchReq)
        if (error) {

        } else {
            dispatch({
                type: ProjectAction.UPDATE_LOCALITY_LIST,
                payload: {
                    localityList: localityList
                }
            });
        }
    };

    const fetchAmenitiesList = async () => {
        const [amenitiesList, error] = await AmenitiesService.getAmenitiesList(state.localitySearchReq)
        if (error) {

        } else {
            dispatch({
                type: ProjectAction.UPDATE_AMENITIES_LIST,
                payload: {
                    amenitiesList: amenitiesList
                }
            });
        }
    };

    const value = {
        projectList: state.projectList,
        loading: state.loading,
        employeeList: state.employeeList,
        projectSearchReq: state.projectSearchReq,
        employeeSearchReq: state.employeeSearchReq,
        builderList: state.builderList,
        builderSearchReq: state.builderSearchReq,
        projectStatusList: state.projectStatusList,
        localityList: state.localityList,
        amenitiesList: state.amenitiesList,
        unitTypeList: state.unitTypeList,
        categoryTypeList: state.categoryTypeList,
        updateLoading,
        saveProject,
        fetchProjectList,
        updateProject,
        fetchEmployeeList,
        fetchBuilderList,
        fetchLocalityList,
        fetchAmenitiesList
    };

    return <ProjectContext.Provider value={value}>{children}</ProjectContext.Provider>
}