import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { Location } from "../model/location.model";
import { useState } from "react";
import { LocationType } from "../enum/location-type.enum";

interface OnChangeHandler {
    (location: Location): void;
}

export interface LocationPickerViewProp {
    onChange: OnChangeHandler;
}

const LocationPickerView: React.FC<LocationPickerViewProp> = ({ onChange }) => {

    const defaultLatLng = { lat: 28.439002, lng: 77.104827 };

    const [marker, setMarker] = useState({ ...defaultLatLng })

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY!
    });

    return <>
        {isLoaded ? <GoogleMap
            zoom={8}
            mapContainerClassName="map-container"
            center={marker}
            onClick={(e: any) => {
                setMarker({ lat: e.latLng.lat(), lng: e.latLng.lng() })
                onChange({
                    type: LocationType.Point,
                    coordinates: [e.latLng.lat(), e.latLng.lng()]
                } as Location)
            }}>
            <Marker position={marker} />
        </GoogleMap> : <h1>Loading...</h1>}
    </>
}

export default LocationPickerView