import { Action } from "../../../model/action.model";
import { TemplateAction } from "./template.action";
import { TemplateState } from "./template.state";


export const TemplateReducer = (state: TemplateState, action: Action): TemplateState => {
    const { type, payload } = action

    switch (type) {
        case TemplateAction.UPDATE_LOADING:
            return {
                ...state,
                loading: payload.loading
            };
        case TemplateAction.UPDATE_TEMPLATE_LIST:
            return {
                ...state,
                templateList: [...payload.templateList]
            };

        case TemplateAction.ADD_TEMPLATE_LIST:
            return {
                ...state,
                templateList: [...state.templateList, ...payload.templateList]
            };

        case TemplateAction.UPDATE_TEMPLATE_SEARCH_REQ:
            return {
                ...state,
                templateSearchReq: { ...payload.templateSearchReq }
            };

        default:
            throw new Error(`No case for type ${type} found in TemplateReducer.`);
    }
}