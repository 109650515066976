import { useContext } from "react"
import { LocationContext } from "./location.context";


export const useMapLocation = () => {
    const context = useContext(LocationContext)

    if (context === undefined) {
        throw new Error(`Component is not inside LocationContext scope`);
    }
    return context
}