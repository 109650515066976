import { Button, Space, Spin, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { MenuRes } from '../../../response/menu.response';
import { useMenu } from '../menu.hook';
import { AddMenuComponent } from './add-menu.component';
import { UpdateMenuComponent } from './update-menu.component';
import { MenuFilterComponent } from './filter.component';

export const MainMenuTableComponent = () => {
    const { loading, menuList, fetchMenuList, menuSearchReq, updateLoading } = useMenu()
    const [addNewMenu, setOpenNewMenu] = useState(false)
    const [updateMenu, setUpdateMenu] = useState<MenuRes | undefined>(undefined)

    const cancelMenuEdit = () => {
        setUpdateMenu(prevVal => undefined);
    }
    const updateOpenNewMenu = () => {
        if (addNewMenu) {
            fetchMenuList({ ...menuSearchReq, pageNumber: 1 }, () => { updateLoading(false) }, () => { updateLoading(false) })
        }
        setOpenNewMenu(prevVal => !prevVal);
    }

    const columnList: ColumnsType<MenuRes> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Order',
            dataIndex: 'order',
            key: 'order',
        },
        {
            title: 'Path',
            dataIndex: 'path',
            key: 'path',
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            onCell: (menu, rowIndex) => {
                return {
                    onClick: (ev) => {
                        setUpdateMenu(menu)
                    },
                };
            },
            render: (id) => <a>Edit</a>,
        },
    ];

    useEffect(() => {
        fetchMenuList({ ...menuSearchReq, pageNumber: 1 }, () => { updateLoading(false) }, () => { updateLoading(false) })
    }, [])

    return <div style={{ marginTop: -42 }}>
        <Space direction="horizontal" style={{ width: '100%', justifyContent: 'end', marginBottom: 8 }}>
            <MenuFilterComponent />
            <Button type="primary" onClick={updateOpenNewMenu}>Add New</Button>
        </Space>
        <Table
            className='task-main-table task-table'
            columns={columnList} dataSource={menuList}
            pagination={{ defaultPageSize: 30, showSizeChanger: true, pageSizeOptions: ['20', '50', '100', '200', '300', '400', '500'] }}
            loading={{ indicator: <div><Spin /></div>, spinning: loading }} />
        {addNewMenu &&
            <AddMenuComponent open={addNewMenu} onCancel={updateOpenNewMenu} />
        }

        {updateMenu &&
            <UpdateMenuComponent menu={updateMenu} open={true} onCancel={cancelMenuEdit} />
        }
    </div>
}