import { CityStateProvider } from './city.context';
import { MainCityTableComponent } from './component/table.component';

const CityPageBase = () => {

  return <>
    <MainCityTableComponent />
  </>
}

export const CityPage = () => {
  return <CityStateProvider>
    <CityPageBase />
  </CityStateProvider>
}

