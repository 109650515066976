import { InputField } from "../../model/input-field.model"
import { SendOtpRes } from "../../response/auth.response"

export enum PageView {
    MOBILE_VIEW = "MOBILE_VIEW",
    OTP_VIEW = "OTP_VIEW",
}

export interface LogInState {
    mobile: InputField
    otp: InputField
    activeView: PageView
    loading: boolean
    sendOtpRes: SendOtpRes
}