import { Button, Spin, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useState } from 'react';
import { LeadRes } from '../../../response/lead.response';
import { useLead } from '../lead.hook';
import { TextEditorView } from '../../../view/text-editor.view';
import { Link } from 'react-router-dom';
import { UpdateLeadOwnerComponent } from './update-owner.component';
import { formatName } from '../../../util/name-helper';
import React from 'react';
import ImgIcon from '../../../view/img-icon';

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

export const MainLeadTableComponent = () => {

    const [updateOwner, setUpdateOwner] = useState(false)
    const { leadList, loading, fetchLeadList, leadSearchReq, updateLoading } = useLead()

    const columnList: ColumnsType<LeadRes> = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            render: (id) => <Link to={`/lead/${id}`} target={isMobile ? "" : "_blank"} rel={isMobile ? "" : "noopener noreferrer"}>{id.substring(0, 6)}...</Link>,
        },
        {
            title: 'Client Name',
            dataIndex: 'client',
            key: 'client',
            render: (client) => <span>{formatName(client.name)}</span>,
        },
        {
            title: 'Source',
            dataIndex: 'source',
            key: 'source',
        },
        {
            title: 'Requirement',
            dataIndex: 'requirement',
            render: (requirement) => <div style={{ minWidth: 374, maxWidth: 374 }}><TextEditorView initData={requirement} onChange={() => { }} readOnly={true} /></div>,
        },
        {
            title: 'Owner',
            dataIndex: 'owner',
            key: 'owner',
            render: (owner) => <span>{formatName(owner.assignedTo.name)}</span>,
        },
        {
            title: 'Project Name',
            dataIndex: 'project',
            key: 'project',
            render: (project) => <span>{project?.name}</span>,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => <React.Fragment>

                <span style={{ textTransform: 'capitalize', display: 'flex' }}>
                    {status.value === 'Pending' &&
                        <ImgIcon iconName="open.svg" />
                    }
                    {status.value === 'Closed' &&
                        <ImgIcon iconName="close.svg" />
                    }
                    {status.value === 'In Progress' &&
                        <ImgIcon iconName="inprogress.svg" />
                    }
                    {status.value === 'Not Interested' &&
                        <ImgIcon iconName="rejected.svg" />
                    }
                    {status.value === 'Dump / Spam' &&
                        <ImgIcon iconName="rejected.svg" />
                    }
                    {status.value}
                </span>
            </React.Fragment>,
        }
    ];

    const [selectedLeadIdList, setSelectedLeadIdList] = useState<string[]>([])

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: LeadRes[]) => {
            setSelectedLeadIdList(selectedRows.map((selectedRow: LeadRes) => { return selectedRow.id }))
        },
        getCheckboxProps: (leadRes: LeadRes) => ({
            disabled: false,
            name: leadRes.id,
        }),
    };

    const clearFilter = async () => {
        updateLoading(true)
        await fetchLeadList({
            ...leadSearchReq,
            statusList: [] as string[],
            assigneeIdList: [],
            pageNumber: 1
        }, () => { updateLoading(false) }, () => { updateLoading(false) })
    }

    return <>
        {selectedLeadIdList.length > 0 && <Button style={{ marginTop: 20, marginLeft: 20 }} onClick={() => setUpdateOwner(true)}>Assign To</Button>}

        {(leadList.length > 0 && !loading) ? <Table
            style={{ marginTop: 16 }}
            rowSelection={{
                type: 'checkbox',
                ...rowSelection,
            }}
            className='task-main-table task-table'
            columns={columnList} dataSource={leadList.map((lead: LeadRes) => { return { ...lead, key: lead.id } })}
            pagination={{
                defaultPageSize: 30,
                onChange(page, pageSize) {
                    console.log(page * pageSize > (leadList.length - pageSize))
                    if (page * pageSize > (leadList.length - pageSize) && leadList.length > pageSize) {
                        fetchLeadList({ ...leadSearchReq }, () => { updateLoading(false) }, () => { updateLoading(false) })
                    }
                },
                showSizeChanger: true,
                onShowSizeChange(current, size) {
                    console.log(current)
                    console.log(size)
                },
                pageSize: 50,
                pageSizeOptions: ['20', '30', '50']
            }}
            loading={{ indicator: <div><Spin /></div>, spinning: loading }} /> : loading ? <Spin style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '24vh' }} /> :
            <div style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '24vh', width: 'fit-content' }} >
                <p>No Data Found</p>
                <Button onClick={clearFilter} type='primary'>Clear Filter</Button>
            </div>}

        {updateOwner && <UpdateLeadOwnerComponent leadIdList={selectedLeadIdList} open={updateOwner} onCancel={() => { setUpdateOwner(false) }} onOwnerSuccess={() => { setUpdateOwner(false) }} />}
    </>
}