import { useContext } from "react"
import { ServiceContext } from "./service.context";


export const useService = () => {
    const  context = useContext(ServiceContext)
    if (context === undefined){
        throw new Error(`Component is not inside ServiceContext scope`);
    }
    return context
}