import React, { useEffect } from 'react';
import { Modal, Form, Input, Select } from 'antd';
import { useTeam } from '../team.hook';
import { TeamRequest } from '../../../request/team.request';
import { EmployeeRes } from '../../../response/employee.response';
import { ItemProp } from '../../../model/item-prop.model';
import { validateOfficeName } from '../../../validator/level.validator';
import { OfficeRes } from '../../../response/office.response';
import { TeamRes } from '../../../response/team.response';
import { handleFilter } from '../../../view/input.helper';
import { formatName } from '../../../util/name-helper';


export const UpdateTeamComponent: React.FC<{ team: TeamRes, open: boolean, onCancel: () => void }> = ({ team, open, onCancel }) => {
    const [form] = Form.useForm();
    const { loading, updateLoading, updateTeam, fetchEmployeeList, employeeList, teamTypeList, officeList,
        fetchOfficeList,
        fetchTeamTypeList } = useTeam()

    const handleOk = () => {
        form.validateFields().then(values => {
            updateLoading(true);
            const teamRequest = {
                name: values["name"],
                leaderId: values["leaderId"],
                employeeIdList: values["employeeIdList"],
                officeIdList: values["officeIdList"],
                type: values["type"],
                tagList: values["tagList"],
            } as TeamRequest
            updateTeam(team.id, teamRequest, onSuccess, onFailure)
        });
    };

    const onSuccess = () => {
        updateLoading(false);
        form.resetFields()
        onCancel();
    }

    const onFailure = () => {
        updateLoading(false);
    }

    useEffect(() => {
        fetchEmployeeList()
        fetchOfficeList()
        fetchTeamTypeList()
    }, [])

    return (
        <Modal
            open={open}
            title="Add New Team"
            onCancel={onCancel}
            confirmLoading={loading}
            onOk={handleOk}
            okText={"Submit"}
            cancelText={"Cancel"}
            style={{ top: 8, maxWidth: 768 }}
            width={'90vw'}
        >
            <Form form={form} layout="vertical">
                <Form.Item
                    name="name"
                    label="Name"
                    rules={[{ required: true, validator: validateOfficeName }]}
                    initialValue={team.name}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="leaderId"
                    label="Select Leader"
                    rules={[{ required: true, message: 'Please select Leader' }]}
                    initialValue={team.leaderId}
                >
                    <Select
                        showSearch={true}
                        value='leaderId'
                        options={employeeList.map((employee: EmployeeRes) => {
                            return {
                                label: `${formatName(employee.userInfo.name)} (${employee.employeeId})`,
                                value: employee.id
                            } as ItemProp
                        })}
                        filterOption={handleFilter} />
                </Form.Item>

                <Form.Item
                    name="employeeIdList"
                    label="Select Employee"
                    rules={[{ required: true, message: 'Please select Employee' }]}
                    initialValue={team.employeeIdList}
                >
                    <Select
                        mode='multiple'
                        value='employeeIdList'
                        options={employeeList.map((employee: EmployeeRes) => {
                            return {
                                label: `${formatName(employee.userInfo.name)} (${employee.employeeId})`,
                                value: employee.id
                            } as ItemProp
                        })}
                        maxTagCount='responsive'
                        filterOption={handleFilter} />
                </Form.Item>

                <Form.Item
                    name="officeIdList"
                    label="Select Office"
                    rules={[{ required: true, message: 'Please select Employee' }]}
                    initialValue={team.officeIdList}
                >
                    <Select
                        mode='multiple'
                        value='officeIdList'
                        options={officeList.map((office: OfficeRes) => {
                            return {
                                label: office.name,
                                value: office.id
                            } as ItemProp
                        })}
                        maxTagCount='responsive'
                        filterOption={handleFilter} />
                </Form.Item>

                <Form.Item
                    name="type"
                    label="Team  Type"
                    rules={[{ required: true, message: 'Please select team type' }]}
                    initialValue={team.type}
                >
                    <Select
                        showSearch={true}
                        value="type"
                        options={teamTypeList}
                        filterOption={handleFilter}
                    />
                </Form.Item>

                <Form.Item
                    name="tagList"
                    label="Tags"
                    rules={[{ required: true, message: 'Please add tag' }]}
                    initialValue={team.tagList}
                >
                    <Select
                        mode="tags"
                        value='tagList'
                        maxTagCount='responsive'
                        filterOption={handleFilter}
                    />
                </Form.Item>

            </Form>
        </Modal>
    );
}