import { Session } from "./model/session.model"

const Android = (window as any).Android

export const AndroidConnerctor = {
    getSession: (): string => {
        console.log("Android Connector", "getSession")
        if (Android) {
            try {
                return JSON.stringify(Android.getSession())
            } catch {
                return "{}"
            }
        }
        return "{}"
    },
    setSession: (session: Session) => {
        console.log("Android Connector", "setSession")
        if (Android) {
            Android.setSession(JSON.stringify(session))
        }
    },
    removeSession: () => {
        console.log("Android Connector", "removeSession")
        if (Android) {
            Android.logOut()
        }
    },
    getLocation: (): string => {
        console.log("Android Connector", "getLocation")
        if (Android) {
            try {
                return JSON.stringify(Android.getLocation())
            } catch {
                return "{}"
            }
        }
        return "{}"
    },
    anroid: (): boolean => {
        return Android ? true : false
    }
}