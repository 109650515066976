import { EnquiryNoteCallReq, EnquiryNoteRequest, EnquiryNoteSearchReq } from "../request/enquiry-note.request"
import { CallRes } from "../response/call.response"
import { EnquiryNoteRes } from "../response/enquiry-note.request"
import httpClient from "../util/http-client"

export const EnquiryNoteService = {
    saveEnquiryNote: async (enquiryNoteRequest: EnquiryNoteRequest): Promise<(EnquiryNoteRes | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/enquiry-note", enquiryNoteRequest)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    getEnquiryNoteList: async (enquiryNoteSearchReq: EnquiryNoteSearchReq): Promise<(EnquiryNoteRes[] | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/enquiry-note-search", enquiryNoteSearchReq)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    updateEnquiryNote: async (id: string, enquiryNoteRequest: EnquiryNoteRequest): Promise<(EnquiryNoteRes | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.put(`/enquiry-note/${id}`, enquiryNoteRequest)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    connectCall: async (enquiryNoteCallReq: EnquiryNoteCallReq):Promise<(CallRes | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/enquiry-note/call-client", enquiryNoteCallReq)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
}