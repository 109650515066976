import { CallStateProvider } from './call.context';
import { Outlet } from 'react-router-dom';

const CallPageBase = () => {

    return <>
        <Outlet />
    </>
}


export const CallPage = () => {
    return <CallStateProvider>
        <CallPageBase />
    </CallStateProvider>
}

