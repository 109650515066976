import React, { useEffect } from 'react';
import { Form, Select, Button } from 'antd';
import { TargetSearchReq } from '../../../request/target.request';
import { useTarget } from '../target.hook';
import { ItemProp } from '../../../model/item-prop.model';
import { handleFilter } from '../../../view/input.helper';
import { formatName } from '../../../util/name-helper';
import { EmployeeRes } from '../../../response/employee.response';

export const TargetFilterComponent: React.FC = () => {
    const [form] = Form.useForm();
    const { fetchTargetList, updateLoading, targetSearchReq,
        fetchEmployeeList,
        typeList, employeeList } = useTarget()

    const handleOk = () => {
        form.validateFields().then(values => {
            updateLoading(true);
            const _targetSearchReq = {
                ...targetSearchReq,
                employeeId: values["employeeId"],
                typeList: values["typeList"],
                pageNumber: 1,
                itemsPerPage: 100
            } as TargetSearchReq
            fetchTargetList(_targetSearchReq, onSuccess, onFailure)
        });
    };

    useEffect(() => {
        fetchEmployeeList()
    }, [])

    const onSuccess = () => {
        updateLoading(false);
    }

    const onFailure = () => {
        updateLoading(false);
    }

    return (
        <Form form={form} layout="inline">
            <Form.Item
                name="typeList"
                style={{ minWidth: 124 }}
                initialValue={targetSearchReq.officeIdList}
            >
                <Select
                    allowClear
                    mode="multiple"
                    value='typeList'
                    placeholder="Status"
                    maxTagCount='responsive'
                    options={typeList}
                    filterOption={handleFilter} />
            </Form.Item>
            <Form.Item
                name="employeeId"
                style={{ minWidth: 124 }}
                initialValue={targetSearchReq.targeterIdList}
            >
                <Select
                    allowClear
                    value='employeeId'
                    maxTagCount='responsive'
                    options={employeeList.map((employeeRes: EmployeeRes) => {
                        return {
                            label: `${formatName(employeeRes.userInfo.name)}`,
                            value: employeeRes.id
                        } as ItemProp
                    })}
                    placeholder="Employee"
                    filterOption={handleFilter} />
            </Form.Item>
            

            <Form.Item style={{ minWidth: 124 }}>
                <Button type="primary" htmlType="submit"
                    onClick={handleOk}>
                    Search
                </Button>
            </Form.Item>

        </Form>
    );
}
