import { Row, Col } from "antd"
import AttendanceHistoryComponent from "../../../dashboard/component/genericComponents/attendance-history.component"
import EnquireCardComponent from "../../../dashboard/component/genericComponents/enquireDetails/enquire-card.component"
import LeadCardComponent from "../../../dashboard/component/genericComponents/leadDetails/lead-card.component"
import ProfileComponent from "../../../dashboard/component/genericComponents/profile.component"
import TaskCardComponent from "../../../dashboard/component/genericComponents/taskDetails/task-card.component"
import DailyCallingStatsComponent from "./detailsComponents/charts/dailyStats/daily-calling-stats.component"
import LeadCoversionComponent from "./detailsComponents/charts/leadCoversion/lead-coversion.component"
import TimelineComponent from "./detailsComponents/timeline.component"
import { EmpDetailComponentProps } from "./emp-detail-mweb.component"
import { useMain } from "../../../main.hook"

const EmpDetailDWebComponent = (props: EmpDetailComponentProps) => {
    const { leadReportResList, enquiryReportResList, taskReportList, employeeRes, employeeId, filterData } = props

    const { userConfig } = useMain()

    return (
        <>
            <Row>
                <Col lg={12} xs={24} style={{
                    margin: '0 0 20px'
                }}>
                    <ProfileComponent employeeRes={employeeRes} leadCount={leadReportResList? leadReportResList.length : 0} />
                </Col>
                <Col lg={12} xs={24} className='d-padding' style={{
                    padding: '0 10px',
                    margin: '0 0 20px'
                }}>
                    <AttendanceHistoryComponent />
                </Col>
            </Row>

            <Row>
                {/* {(userConfig.employeeRes.employeeRole == "Manager" ||
                    userConfig.employeeRes.employeeRole == "Admin" ||
                    userConfig.employeeRes.employeeRole == "SuperAdmin") &&
                    <Col lg={12} xs={24} className='d-padding' style={{
                        padding: '0 10px',
                        margin: '0 0 20px'
                    }}>
                        <PermissionComponent />
                    </Col>
                } */}
                {(userConfig.employeeRes.employeeRole == "Admin" ||
                    userConfig.employeeRes.employeeRole == "SuperAdmin") &&
                    <Col lg={24} xs={24} className='d-padding' style={{
                        padding: '0 10px',
                        margin: '0 0 20px'
                    }}>
                        <TimelineComponent employeeId={employeeId}/>
                    </Col>
                }
            </Row>
            <Row>
                {/* <Col lg={12} xs={24} style={{
                    margin: '0 0 20px'
                }}>
                    <DailyCallingStatsComponent />
                </Col> */}
                {/* <Col lg={12} xs={24} style={{
                    padding: '0 10px',
                    margin: '0 0 20px'
                }}>
                    <LeadCoversionComponent />
                </Col> */}
            </Row>

            <Row>
                <Col lg={12} xs={24} style={{
                    margin: '0 0 20px'
                }}>
                    <LeadCardComponent leadReportResList={leadReportResList} filterData={filterData} />

                </Col>
                <Col lg={12} xs={24} className='d-padding' style={{
                    padding: '0 10px',
                    margin: '0 0 20px'
                }}>
                    <EnquireCardComponent enquiryReportResList={enquiryReportResList} filterData={filterData} />
                </Col>
            </Row>

            <TaskCardComponent taskReportList={taskReportList} filterData={filterData}/>
        </>
    )
}

export default EmpDetailDWebComponent