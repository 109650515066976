import { Col, DatePicker, Row, theme } from "antd"
import React, { useEffect, useState } from "react";
import ImgIcon from "../../../../view/img-icon"
import dayjs from "dayjs";
import { TaskService } from "../../../../service/task.service";
import { TaskRequest } from "../../../../request/task.request";
import { isMobile } from "../../../../util/device-helper";

const DueCreatedDateComponent = (props: any) => {

    const { task } = props;
    const { useToken } = theme;
    const { token } = useToken();
    const [selectedDateValue, setSelectedDateValue] = useState('')

    const [createdDate, setCreatedDate] = useState('')

    useEffect(() => {
        if (task) {
            let date = new Date(task.dueDate).toLocaleDateString('en', { year: 'numeric', month: 'long', day: 'numeric' })
            setSelectedDateValue(date)

            let createdOn = new Date(task.createdOn).toLocaleDateString('en', { year: 'numeric', month: 'long', day: 'numeric' })
            setCreatedDate(createdOn)
        }
    }, [])

    const updateDueDate = async (dueDate: any) => {
        const [data, err] = await TaskService.updateTask(task.id, {
            dueDate: dueDate
        } as TaskRequest)
    }

    return (
        <React.Fragment>
            <Row style={{ marginBottom: '20px' }}>
                <Col style={{ display: 'flex', alignItems: "center" }}>
                    <ImgIcon iconName="calender.svg" iconWidth="18px" />
                    Due date
                </Col>
                <Col style={{ marginLeft: 'auto' }}>
                    <DatePicker
                        inputReadOnly={isMobile()}
                        defaultValue={dayjs(task.dueDate)}
                        style={{ border: '1px solid #92B5D7', width: '175px', borderRadius: token.borderRadiusLG }}
                        placeholder="Due Date"
                        onSelect={(value: any) => {
                            console.log(value.date())
                            updateDueDate(dayjs(value))
                        }} />
                </Col>
            </Row>

            <Row style={{ marginBottom: '20px' }}>
                <Col style={{ display: 'flex', alignItems: "center" }}>
                    <ImgIcon iconName="calender.svg" iconWidth="18px" />
                    Created On
                </Col>
                <Col style={{ marginLeft: 'auto', width: '175px' }}>
                    <span>{createdDate}</span>
                </Col>
            </Row>


        </React.Fragment>
    )
}

export default DueCreatedDateComponent