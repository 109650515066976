import { useEffect, useState } from "react"
import { CallService } from "../../../service/call.service"
import { CallSearchReq, CallUpdateReq } from "../../../request/call.request"
import { CallRes } from "../../../response/call.response"
import { Button, Card, Col, Divider, Popconfirm, Row, Spin } from "antd"
import { useMain } from "../../main.hook"
import { formatName } from "../../../util/name-helper"
import dayjs from "dayjs"

export const CallOngoingPage = () => {

    const { userConfig } = useMain()

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);

    const showPopconfirm = () => {
        setOpen(true);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const [callList, setCallList] = useState([])

    const fetchCall = async () => {
        setLoading(true)
        const [callDataList, error] = await CallService.getCallList({
            statusList: ["In Progress", "Waiting"],
            itemsPerPage: 100,
            pageNumber: 1
        } as CallSearchReq)

        if (error) {
            setLoading(false)
        } else {
            setCallList(callDataList)
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchCall()
    }, [])

    const markAsMissed = async (callRes: CallRes) => {
        const [callDataList, error] = await CallService.updateCall(callRes.id, {
            status: "Missed / Not Answered"
        } as CallUpdateReq)

        if (error) {
            alert("Please try again")
        } else {
            fetchCall()
        }
    }

    return <>
        <Divider orientation="left" style={{marginTop: "-48px"}}>On going call Data</Divider>
        {(callList.length > 0 && !loading) ?
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{padding: "0px 16px"}}>
                {callList.map((callRes: CallRes) => {
                    return <Card style={{ margin: "4px auto", padding: 2, width: "24%", minWidth: "calc(min(96%, 300px))" }}>
                        <Row style={{ marginBottom: 8 }}>
                            <Col style={{
                                marginRight: 20,
                                color: '#6D6C68'
                            }}
                                flex="85px"
                            >Client Name</Col>
                            <Col style={{
                                color: '#000000'
                            }}>{formatName(callRes.client.userInfo.name)}</Col>
                        </Row>
                        <Row style={{ marginBottom: 8 }}>
                            <Col style={{
                                marginRight: 20,
                                color: '#6D6C68'
                            }}
                                flex="85px"
                            >Caller</Col>
                            <Col style={{
                                color: '#000000'
                            }}>{formatName(callRes.employee.userInfo.name)}</Col>
                        </Row>
                        <Row style={{ marginBottom: 8 }}>
                            <Col style={{
                                marginRight: 20,
                                color: '#6D6C68'
                            }}
                                flex="85px"
                            >Status</Col>
                            <Col style={{
                                color: '#000000'
                            }}>{callRes.status}</Col>
                        </Row>

                        <Row style={{ marginBottom: 8 }}>
                            <Col style={{
                                marginRight: 8,
                                color: '#6D6C68'
                            }}
                                flex="85px"
                            >Start At</Col>
                            <Col style={{
                                color: '#000000'
                            }}>{`${dayjs(callRes.createdOn).format('DD-MM-YYYY, hh:mm A')}`}</Col>
                        </Row>

                        {((callRes.status == "In Progress" || callRes.status == "Waiting") && userConfig.employeeRes.id == callRes.employee.id) &&
                            <Row style={{ marginBottom: 8 }}>
                                <Popconfirm
                                    title=" Mark as Missed / Not Answered"
                                    description="Are you you want to mark as Mark as Missed / Not Answered"
                                    open={open}
                                    onConfirm={() => { markAsMissed(callRes) }}
                                    okButtonProps={{ loading: loading }}
                                    onCancel={handleCancel}
                                >
                                    <Button type="link" style={{
                                        padding: 0,
                                        marginRight: 'auto',
                                        fontSize: 12
                                    }}
                                        onClick={showPopconfirm}
                                    >
                                        Mark as Missed / Not Answered
                                    </Button>
                                </Popconfirm>
                            </Row>
                        }
                    </Card>
                })}
            </Row> : loading ? <Spin style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '24vh', width: '54px' }} /> :
                <div style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '24vh', width: 'fit-content' }} >
                    <p>No ongoing or waiting call data found</p>
                </div>}
    </>
}