import { theme } from "antd";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useState, useEffect } from "react";
import { TaskReportRes } from "../../../../../response/task.response";
import { getGraphColor } from "../../../../../enum/static-data.enum";
import { useNavigate } from "react-router-dom";

export interface TaskChartComponentProp {
    taskReportList: TaskReportRes[]
    filterData: any
}


const TaskChartComponent = (props: TaskChartComponentProp) => {

    const navigate = useNavigate();

    const { taskReportList, filterData } = props

    const { useToken } = theme;
    const { token } = useToken();
    const [chartHeight, setChartHeight] = useState('280px')

    const dataList = taskReportList.map((taskReportRes: TaskReportRes) => {
        return {
            name: taskReportRes.value,
            y: taskReportRes.count,
            color: getGraphColor(taskReportRes.value),
            drilldown: taskReportRes.value
        }
    })

    const options = {
        chart: {
            type: 'pie',
            height: chartHeight
        },

        title: {
            text: '',
            align: 'left'
        },
        credits: {
            enabled: false
        },
        accessibility: {
            announceNewData: {
                enabled: true
            },
            point: {
                valueSuffix: ''
            }
        },
        plotOptions: {
            series: {
                borderRadius: 5,
                dataLabels: {
                    enabled: true,
                    format: '{point.name}: {point.y:.0f}'
                }
            }
        },

        series: [{
            name: 'Task',
            colorByPoint: true,
            data: dataList,
            point: {
                events: {
                    click: function (event: any) {
                        onPieClick((this as any).name)
                    }
                }
            }
        }]
    }

    useEffect(() => {
        const isMobile = window.innerWidth <= 768; // Adjust the breakpoint according to your needs
        const ch = isMobile ? '200px' : '280px';
        setChartHeight(ch)
    }, [])

    const onPieClick = (status: string) => {
        navigate('/task', {
            state: {
                filterData: filterData,
                status: status
            }
        });
    }

    return (
        <HighchartsReact highcharts={Highcharts} options={options} />
    )

}

export default TaskChartComponent