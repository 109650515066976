import { Col, Space, Progress, Typography, Card, Row, Spin, Avatar } from "antd"
import { ReportTypeEnum } from "../report.component";
import { getColor } from "../../../../enum/static-data.enum";
import { formatName } from "../../../../util/name-helper";

const ReportEmployeeComponent = (props: any) => {

    const { Text } = Typography;

    const { employeeRes } = props

    return (
        <>
            {(employeeRes && employeeRes.userInfo) ? <Card style={{
                height: '100%',
                padding: '0 20px',
                width: '100%',
                marginBottom: 10
            }}
                headStyle={{
                    padding: '10px 0 0',
                    minHeight: 44,
                    fontSize: 14
                }}
                bodyStyle={{
                    padding: '10px 0'
                }}
                title="ID: BL21524125"
                bordered={false}
                className="profile-wrapper p-mweb-card"
            >
                <Row>
                    <Col lg={24}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <>
                                <Avatar shape="circle" style={{ backgroundColor: getColor(employeeRes.userInfo.name[0]) }} size={64}>{employeeRes.userInfo.name[0]?.toUpperCase()}</Avatar>
                            </>

                            <div style={{
                                marginLeft: 16
                            }}>
                                <Space style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'baseline',
                                    marginBottom: 4
                                }}>
                                    <Text strong>Name</Text>
                                    <Text>{employeeRes.userInfo.name}</Text>
                                </Space>
                                <Space style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'baseline',
                                    marginBottom: 4
                                }}>
                                    <Text strong>Manager</Text>
                                    <Text title="Salesperson">{formatName(employeeRes.manager.userInfo.name)}</Text>
                                </Space>
                                <Space style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'baseline',
                                    marginBottom: 4
                                }}>
                                    <Text strong>Role</Text>
                                    <Text>{employeeRes.employeeRole}</Text>
                                </Space>
                                <Space style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'baseline',
                                    marginBottom: 4
                                }}>
                                    <Text strong>Phone Number</Text>
                                    <Text title="+91 99876 54321">+91 {employeeRes.userInfo.phone}</Text>
                                </Space>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Card> : <Spin />}
        </>
    )
}

export default ReportEmployeeComponent