import React, { useState } from 'react';
import { Modal, Form, Input, TreeSelect, Button, Row } from 'antd';
import { useMenu } from '../menu.hook';
import { validateLevel, validateLevelName } from '../../../validator/level.validator';
import { MenuRequest } from '../../../request/menu.request';
import { SubMenu } from '../../../response/menu.response';
const { SHOW_CHILD } = TreeSelect;

export const AddMenuComponent: React.FC<{ open: boolean, onCancel: () => void }> = ({ open, onCancel }) => {
    const [form] = Form.useForm();
    const { loading, updateLoading, saveMenu } = useMenu()

    const [subMenuList, setSubMenu] = useState<SubMenu[]>([])

    const handleOk = () => {
        form.validateFields().then(values => {
            updateLoading(true);
            const menuRequest = {
                name: values["name"],
                path: values["path"],
                order: Number(values["order"]),
                subMenuList: subMenuList
            } as MenuRequest
            saveMenu(menuRequest, onSuccess, onFailure)
        });
    };

    const onSuccess = () => {
        updateLoading(false);
        form.resetFields()
        onCancel();
    }

    const onFailure = () => {
        updateLoading(false);
    }

    const addSubmenu = () => {
        setSubMenu((prevState) => {
            const _newSate = [...prevState]
            _newSate.push({
                name: "",
                path: "",
                order: 0
            } as SubMenu)
            return _newSate
        });
    }

    const updateName = (value: any, currIndex: number) => {
        setSubMenu((prevState) => {
            return prevState.map((subMenu: SubMenu, index: number) => {
                if (currIndex == index) {
                    return { ...subMenu, name: value }
                } else {
                    return { ...subMenu }
                }
            })
        });
    }

    const updatePath = (value: any, currIndex: number) => {
        setSubMenu((prevState) => {
            return prevState.map((subMenu: SubMenu, index: number) => {
                if (currIndex == index) {
                    return { ...subMenu, path: value }
                } else {
                    return { ...subMenu }
                }
            })
        });
    }

    const updateOrder = (value: any, currIndex: number) => {
        setSubMenu((prevState) => {
            return prevState.map((subMenu: SubMenu, index: number) => {
                if (currIndex == index) {
                    return { ...subMenu, order: Number(value) }
                } else {
                    return { ...subMenu }
                }
            })
        });
    }

    return (
        <Modal
            open={open}
            title="Add New Menu"
            onCancel={onCancel}
            confirmLoading={loading}
            onOk={handleOk}
            okText={"Submit"}
            cancelText={"Cancel"}
            style={{ top: 8, maxWidth: 768 }}
            width={'90vw'}
        >
            <Form form={form} layout="vertical">
                <Form.Item
                    name="name"
                    label="Name"
                    rules={[{ required: true, validator: validateLevelName }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="path"
                    label="Path"
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="order"
                    label="Order"
                    rules={[{ required: true, validator: validateLevel }]}
                >
                    <Input placeholder="Enter order" />
                </Form.Item>
            </Form>

            <p>Sub Menu List</p>

            {subMenuList.map((subMenu: SubMenu, index: number) => {
                return <Row style={{ display: 'flex', padding: 4 }}>
                    <Input style={{ width: "32%", margin: 4 }} placeholder="Enter Name" defaultValue={subMenu.name}
                        onBlur={(e: any) => {
                            updateName(e.target.value, index)
                        }} />
                    <Input style={{ width: "32%", margin: 4 }} placeholder="Enter Path" defaultValue={subMenu.path}
                        onBlur={(e: any) => {
                            updatePath(e.target.value, index)
                        }} />
                    <Input style={{ width: "32%", margin: 4 }} placeholder="Enter order" defaultValue={subMenu.order}
                        onBlur={(e: any) => {
                            updateOrder(e.target.value, index)
                        }} />
                </Row>
            })}
            <Button onClick={() => {
                addSubmenu()
            }}>Add Sub Menu</Button>
        </Modal>
    );
}