import React from 'react';
import { Form, Input, Button } from 'antd';
import { useTemplate } from '../template.hook';
import { TemplateSearchReq } from '../../../../request/template.request';

export const TemplateFilterComponent: React.FC = () => {
    const [form] = Form.useForm();
    const { fetchTemplateList, updateLoading, templateSearchReq } = useTemplate()

    const handleOk = () => {
        form.validateFields().then(values => {
            updateLoading(true);
            const _templateSearchReq = {
                ...templateSearchReq,
                type: values["type"],
                pageNumber: 1,
                itemsPerPage: 100
            } as TemplateSearchReq
            fetchTemplateList(_templateSearchReq, onSuccess, onFailure)
        });
    };

    const onSuccess = () => {
        updateLoading(false);
    }

    const onFailure = () => {
        updateLoading(false);
    }

    return (
        <Form form={form} layout="inline">
            <Form.Item
                name="type"
                style={{ minWidth: 124 }}
                initialValue={templateSearchReq.templateId}
            >
                <Input placeholder='type' />
            </Form.Item>

            <Form.Item style={{ minWidth: 124 }}>
                <Button type="primary" htmlType="submit"
                    onClick={handleOk}>
                    Search
                </Button>
            </Form.Item>
        </Form>
    );
}
