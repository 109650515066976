import { Col, Form, Row, Select, Tag, theme } from "antd";
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import React from "react";
import ImgIcon from "../../../../view/img-icon";
import { handleFilter } from "../../../../view/input.helper";


const statusTagsRender = (props: CustomTagProps) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const getColor = () => {
        let color = '#001529'
        if(value.toLowerCase() === 'close') {
            color = '#1AA30E'
        } 
        if(value.toLowerCase() === 'open') {
            color = '#EC9007'
        } 
        if(value.toLowerCase() === 'in progress') {
            color = '#1677FF'
        } 
        if(value.toLowerCase() === 'rejected') {
            color = '#EC0707'
        } 

        return color
    }

    return (
        <Tag
            color={'#F4F4F4'}
            className="custom-tag"
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            bordered={true}
            style={{ margin: 3, textTransform: 'capitalize', border: `1px solid ${getColor()}`, color: getColor() }}
        >
            {label}
        </Tag>
    );
};


const StatusFilterComponents = (props: any) => {

    const { useToken } = theme;
    const { token } = useToken();

    return (
        <Row style={{ flexDirection: 'column', width: '100%' }}>
            <Col style={{ display: 'flex' }}>
                <ImgIcon iconName="status.svg" iconWidth="18px" />
                Status
            </Col>
            <Col style={{ marginTop: '10px' }}>
                <Form.Item
                    name="statusList"
                    initialValue={props.taskSearchReq.statusList}
                >

                    <Select
                        mode="multiple"
                        showArrow
                        tagRender={statusTagsRender}
                        className="custom-select"
                        style={{ width: '100%', border: '1px solid #92b5d7', borderRadius: token.borderRadiusSM }}
                        placeholder="Status"
                        options={props.statusList}
                        filterOption={handleFilter}
                    />

                </Form.Item>
            </Col>
        </Row>
    )
}

export default StatusFilterComponents