import React, { useState } from "react";
import { Form, Input, Button, theme, Drawer, Divider } from "antd";
import { UserSearchReq } from "../../../../request/user.request";
import { useUser } from "../../user.hook";
import ImgIcon from "../../../../view/img-icon";
import PerformanceFilterComponent from "./performance-filter.component";

export const UserFilterComponent: React.FC = () => {
  const [form] = Form.useForm();
  const { useToken } = theme;
  const { token } = useToken();
  const { fetchUserList, updateLoading, userSearchReq, userList } = useUser();
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);

  const handleOk = () => {
    form.validateFields().then((values) => {
      updateLoading(true);
      const _userSearchReq = {
        ...userSearchReq,
        name: values["name"],
        phone: values["phone"],
        email: values["email"],
        pageNumber: 1,
        itemsPerPage: 10,
      } as UserSearchReq;
      fetchUserList(_userSearchReq, onSuccess, onFailure);
    });
  };

  const onSuccess = () => {
    updateLoading(false);
  };

  const onFailure = () => {
    updateLoading(false);
  };
  const showFilterDrawer = () => {
    setOpenFilterDrawer(true);
  };

  const onFilterDrawerClose = () => {
    setOpenFilterDrawer(false);
  };
  return (
    <React.Fragment>
      <Button
        type="primary"
        style={{
          boxShadow: "none",
          borderRadius: token.borderRadiusSM,
          alignItems: "center",
          display: "flex",
        }}
        className={`${openFilterDrawer ? "open-filter-drawer" : ""} filter-btn`}
        onClick={showFilterDrawer}
      >
        <ImgIcon
          iconName="filters-white.svg"
          iconClass="mwm-0 mr-2 d-flex"
          iconWidth="12px"
        />
        <span className="mweb-hidden-elem">Filters</span>
      </Button>
      <Drawer
        title="Filters"
        size={"default"}
        placement="right"
        rootClassName="filter-drawer-wrapper"
        className="filter-drawer"
        closable={false}
        onClose={onFilterDrawerClose}
        open={openFilterDrawer}
        bodyStyle={{ background: "#F4F4F4" }}
        extra={
          <div className="drawer-fb-wrapper" style={{ background: "#F4F4F4" }}>
            <Button
              style={{
                border: "1px solid #000000",
                display: "flex",
                alignItems: "center",
              }}
              className="drawer-filter-btn"
            >
              <ImgIcon iconName="filters-black.svg" iconWidth="12px" />
              Filters
            </Button>
          </div>
        }
      >
        <Form form={form} className="filter-form" layout="inline">
          <div style={{ width: "100%" }} className="filter-form-items">
            <PerformanceFilterComponent phoneSearchReq={userSearchReq} />
          </div>
          <Form.Item className='apply-btn-styles'
            style={{ marginTop: "20px", marginBottom: "20px", width: "100%" }}
          >
            <Button
              type="primary"
              style={{
                width: "100%",
                background: token.colorInfo,
                boxShadow: "none",
              }}
              htmlType="submit"
              onClick={handleOk}
            >
              Apply
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </React.Fragment>
  );
};
