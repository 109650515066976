import { EnquiryStateProvider } from './enquiry.context';
import { Outlet } from 'react-router-dom';

const EnquiryPageBase = () => {

  return <>
    <Outlet />
  </>
}

export const EnquiryPage = () => {
  return <EnquiryStateProvider>
    <EnquiryPageBase />
  </EnquiryStateProvider>
}
