import React, { useEffect } from 'react';
import { Modal, Form, Input, TreeSelect } from 'antd';
import { useRole } from '../role.hook';
import { RoleRequest } from '../../../request/role.request';
import { validateLevel, validateLevelName } from '../../../validator/level.validator';
import { RoleRes } from '../../../response/role.response';
import { PermissionType } from '../../../enum/permission-type.enum';
import { Permission } from '../../../model/permission.model';
const { SHOW_CHILD } = TreeSelect;

export const UpdateRoleComponent: React.FC<{ role: RoleRes, open: boolean, onCancel: () => void }> = ({ role, open, onCancel }) => {
    const [form] = Form.useForm();
    const { loading, permissionList, updateLoading, updateRole, fetchPermissionList } = useRole()

    const handleOk = () => {
        form.validateFields().then(values => {
            updateLoading(true);
            const roleRequest = {
                name: values["name"],
                level: Number(values["level"]),
                permissionList: values["permissionList"],
            } as RoleRequest
            updateRole(role.id, roleRequest, onSuccess, onFailure)
        });
    };

    const onSuccess = () => {
        updateLoading(false);
        form.resetFields()
        onCancel();
    }

    const onFailure = () => {
        updateLoading(false);
    }

    useEffect(() => {
        fetchPermissionList()
    }, [])

    return (
        <Modal
            open={open}
            title="Add New Role"
            onCancel={onCancel}
            confirmLoading={loading}
            onOk={handleOk}
            okText={"Submit"}
            cancelText={"Cancel"}
            style={{ top: 8, maxWidth: 768 }}
            width={'90vw'}
        >
            <Form form={form} layout="vertical">
                <Form.Item
                    name="name"
                    label="Name"
                    rules={[{ required: true, validator: validateLevelName }]}
                    initialValue={role.name}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="level"
                    label="Level"
                    rules={[{ required: true, validator: validateLevel }]}
                    initialValue={role.level}
                >
                    <Input placeholder="Enter level" />
                </Form.Item>

                <Form.Item
                    name="permissionList"
                    label="Select Permissions"
                    rules={[{ required: true }]}
                    initialValue={role.permissionList}
                >
                    <TreeSelect
                        treeData={permissionList.map((permission: Permission) => {
                            let children = [
                                {
                                    title: `${permission.label}-Read`,
                                    value: `${permission.value}-${PermissionType.ReadPermission}`,
                                    key: `${permission.value}-${PermissionType.ReadPermission}`,
                                },
                                {
                                    title: `${permission.label}-Write`,
                                    value: `${permission.value}-${PermissionType.WritePermission}`,
                                    key: `${permission.value}-${PermissionType.WritePermission}`,
                                },
                                {
                                    title: `${permission.label}-Update`,
                                    value: `${permission.value}-${PermissionType.UpdatePermission}`,
                                    key: `${permission.value}-${PermissionType.UpdatePermission}`,
                                },
                                {
                                    title: `${permission.label}-Delete`,
                                    value: `${permission.value}-${PermissionType.DeletePermission}`,
                                    key: `${permission.value}-${PermissionType.DeletePermission}`,
                                },
                                {
                                    title: `${permission.label}-Import`,
                                    value: `${permission.value}-${PermissionType.ImportPermission}`,
                                    key: `${permission.value}-${PermissionType.ImportPermission}`,
                                },
                                {
                                    title: `${permission.label}-Export`,
                                    value: `${permission.value}-${PermissionType.ExportPermission}`,
                                    key: `${permission.value}-${PermissionType.ExportPermission}`,
                                },
                            ]

                            if (permission.value == "report") {
                                children = [
                                    {
                                        title: `${permission.label}-Read`,
                                        value: `${permission.value}-${PermissionType.ReadPermission}`,
                                        key: `${permission.value}-${PermissionType.ReadPermission}`,
                                    },
                                    {
                                        title: `${permission.label}-Export`,
                                        value: `${permission.value}-${PermissionType.ExportPermission}`,
                                        key: `${permission.value}-${PermissionType.ExportPermission}`,
                                    }
                                ]
                            }
                            return {
                                title: permission.label,
                                value: `${permission.value}`,
                                key: `${permission.value}`,
                                children: children
                            }
                        })}
                        value='permissionList'
                        treeCheckable={true}
                        showCheckedStrategy={SHOW_CHILD}
                    />
                </Form.Item>

            </Form>
        </Modal>
    );
}