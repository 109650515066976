import React from "react";
import { Col, Modal, Row, Space } from "antd";
import { OfficeRes } from "../../../response/office.response";

export const OfficeDetailComponent: React.FC<{
  officeDetail: OfficeRes;
  open: boolean;
  onCancel: () => void;
}> = ({ officeDetail, open, onCancel }) => {
  return (
    <Modal
      open={open}
      title={<h2>{officeDetail.id}</h2>}
      onCancel={onCancel}
      width={"90vw"}
      footer={false}
      style={{ top: 8, maxWidth: 768 }}
    >
      <Row gutter={16}>
        <Col span={7} style={{ paddingLeft: 16 }}>
          <Row gutter={16} style={{ display: "grid", marginBottom: 16 }}>
            <Space style={{ alignItems: "baseline" }}>
              <h3>Name : {officeDetail.name}</h3>
            </Space>
          </Row>
          <Row gutter={16} style={{ display: "grid", marginBottom: 16 }}>
            <h3>
              Address :<span> </span>
              {`${
                officeDetail.address?.lineOne
                  ? `${officeDetail.address?.lineOne} ,`
                  : ""
              } ${
                officeDetail.address?.lineTwo
                  ? `${officeDetail.address?.lineTwo} ,`
                  : ""
              } ${
                officeDetail.address?.street
                  ? `${officeDetail.address?.street} ,`
                  : ""
              } ${
                officeDetail.address?.city
                  ? `${officeDetail.address?.city} ,`
                  : ""
              } ${
                officeDetail.address?.state
                  ? `${officeDetail.address?.state}`
                  : ""
              }`}
            </h3>
            <Space style={{ alignItems: "baseline", marginLeft: 8 }}></Space>
          </Row>
          <Row gutter={16} style={{ display: "grid", marginBottom: 16 }}>
            <h3>Contact : {officeDetail.contact}</h3>
            <Space style={{ alignItems: "baseline", marginLeft: 8 }}></Space>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};
