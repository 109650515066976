import React, { useEffect } from 'react';
import { Modal, Form, Input, TreeSelect } from 'antd';
import { useBuilder } from '../builder.hook';
import { BuilderRequest } from '../../../request/builder.request';
import { validateLevel, validateLevelName } from '../../../validator/level.validator';
import FileUploadView from '../../../view/file-upload.view';

export const AddBuilderComponent: React.FC<{ open: boolean, onCancel: () => void }> = ({ open, onCancel }) => {
    const [form] = Form.useForm();
    const { loading, updateLoading, saveBuilder } = useBuilder()

    const handleOk = () => {
        form.validateFields().then(values => {
            updateLoading(true);
            const builderRequest = {
                name: values["name"],
                logo: values["logo"],
                order: Number(values["order"])
            } as BuilderRequest
            saveBuilder(builderRequest, onSuccess, onFailure)
        });
    };

    const onSuccess = () => {
        updateLoading(false);
        form.resetFields()
        onCancel();
    }

    const onFailure = () => {
        updateLoading(false);
    }

    return (
        <Modal
            open={open}
            title="Add New Builder"
            onCancel={onCancel}
            confirmLoading={loading}
            onOk={handleOk}
            okText={"Submit"}
            cancelText={"Cancel"}
            style={{ top: 8, maxWidth: 768 }}
            width={'90vw'}
        >
            <Form form={form} layout="vertical">
                <Form.Item
                    name="name"
                    label="Name"
                    rules={[{ required: true, validator: validateLevelName }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="logo"
                    label="Logo"
                    rules={[{ required: true, message: 'Please add logo' }]}
                >
                    <FileUploadView onChange={() => { }} />
                </Form.Item>

                <Form.Item
                    name="order"
                    label="Order"
                    rules={[{ required: true, validator: validateLevel }]}
                >
                    <Input placeholder="Enter order" />
                </Form.Item>

            </Form>
        </Modal>
    );
}