import { Action } from "../../model/action.model";
import { AmenitiesAction } from "./amenities.action";
import { AmenitiesState } from "./amenities.state";


export const AmenitiesReducer = (state: AmenitiesState, action: Action): AmenitiesState => {
    const { type, payload } = action

    switch (type) {
        case AmenitiesAction.UPDATE_LOADING:
            return {
                ...state,
                loading: payload.loading
            };
        case AmenitiesAction.ADD_AMENITIES_LIST:
            return {
                ...state,
                amenitiesList: [...state.amenitiesList, ...payload.amenitiesList]
            };
        case AmenitiesAction.UPDATE_AMENITIES_LIST:
            return {
                ...state,
                amenitiesList: [...payload.amenitiesList]
            };
        case AmenitiesAction.UPDATE_AMENITIES_SEARCH_REQ:
            return {
                ...state,
                amenitiesSearchReq: { ...payload.amenitiesSearchReq }
            };
        default:
            throw new Error(`No case for type ${type} found in AmenitiesReducer.`);
    }
}