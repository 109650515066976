import { Action } from "../../model/action.model";
import { ReportAction } from "./report.action";
import { ReportState } from "./report.state";


export const ReportReducer = (state: ReportState, action: Action): ReportState => {
    const { type, payload } = action

    switch (type) {
        case ReportAction.UPDATE_LOADING:
            return {
                ...state,
                loading: payload.loading
            };
        case ReportAction.UPDATE_EMPLOYEE_LIST:
            return {
                ...state,
                employeeList: [...payload.employeeList]
            };

        case ReportAction.ADD_EMPLOYEE_LIST:
            return {
                ...state,
                employeeList: [...payload.employeeList]
            };

        case ReportAction.UPDATE_EMPLOYEE_SEARCH_REQ:
            return {
                ...state,
                employeeSearchReq: { ...payload.employeeSearchReq }
            };
        case ReportAction.UPDATE_TEAM_LIST:
            return {
                ...state,
                teamList: [...payload.teamList]
            };
        case ReportAction.UPDATE_OFFICE_LIST:
            return {
                ...state,
                officeList: [...payload.officeList]
            };
        default:
            throw new Error(`No case for type ${type} found in ReportReducer.`);
    }
}