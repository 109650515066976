import { useContext } from "react"
import { OfficeContext } from "./office.context";


export const useOffice = () => {
    const  context = useContext(OfficeContext)
    if (context === undefined){
        throw new Error(`Component is not inside OfficeContext scope`);
    }
    return context
}