import React, { useEffect } from 'react';
import { Modal, Form, Select } from 'antd';
import { LeadRequest } from '../../../request/lead.request';
import { LargeStatus } from '../../../model/large-status.model';
import { ItemProp } from '../../../model/item-prop.model';
import { EmployeeRes } from '../../../response/employee.response';
import { ProjectRes } from '../../../response/project.response';
import { useEnquiry } from '../enquiry.hook';
import { EnquiryRes } from '../../../response/enquiry.response';
import { handleFilter } from '../../../view/input.helper';
import { formatName } from '../../../util/name-helper';

export const AddEnquiryLeadComponent: React.FC<{ enquiry: EnquiryRes, open: boolean, onCancel: () => void, onLeadSuccess: () => void }> = ({ enquiry, open, onCancel, onLeadSuccess }) => {
    const [form] = Form.useForm();
    const { loading, updateLoading, saveLead, fetchEmployeeList, employeeList, projectList, fetchProjectList,
        categoryTypeList } = useEnquiry()

    const handleOk = () => {
        form.validateFields().then(values => {
            updateLoading(true);
            const leadRequest = {
                enquiryId: enquiry.id,
                status: {
                    value: "Pending",
                    note: "Enquiry created"
                } as LargeStatus,
                category: values["category"],
                ownerId: values["ownerId"],
                projectId: values["projectId"],
                tagList: values["tagList"]
            } as LeadRequest
            saveLead(leadRequest, onSuccess, onFailure)
        });
    }

    const onSuccess = async () => {
        onLeadSuccess()
        form.resetFields()
        onCancel();
    }

    const onFailure = () => {
        updateLoading(false);
    }

    useEffect(() => {
        fetchEmployeeList()
        fetchProjectList()
    }, [])


    return (
        <Modal
            open={open}
            title="Add New Lead"
            onCancel={onCancel}
            confirmLoading={loading}
            onOk={handleOk}
            okText={"Submit"}
            cancelText={"Cancel"}
        >
            <Form form={form} layout="vertical">
                <Form.Item
                    name="tagList"
                    label="Tags"
                    rules={[{ required: false }]}
                >
                    <Select
                        mode="tags"
                        value='tagList'
                        maxTagCount='responsive'
                        filterOption={handleFilter}
                    />
                </Form.Item>
                <Form.Item
                    name="category"
                    label="Category"
                    rules={[{ required: true, message: 'Please enter category' }]}
                >
                    <Select
                        showSearch={true}
                        value="category"
                        options={categoryTypeList}
                        filterOption={handleFilter} />
                </Form.Item>

                {/* <Form.Item
                    name="status"
                    label="Status"
                    rules={[{ required: true, message: 'status' }]}
                    initialValue={"Pending"}
                >
                    <Select
                        value='status'
                        options={statusList} />
                </Form.Item> */}

                <Form.Item
                    name="projectId"
                    label="Project"
                    rules={[{ required: false }]}
                >
                    <Select
                        showSearch={true}
                        value='projectId'
                        options={projectList.map((projectRes: ProjectRes) => {
                            return {
                                label: `${projectRes.name}`,
                                value: projectRes.id
                            } as ItemProp
                        })}
                        filterOption={handleFilter} />
                </Form.Item>

                <Form.Item
                    name="ownerId"
                    label="Owner"
                    rules={[{ required: false }]}
                >
                    <Select
                        showSearch={true}
                        value='ownerId'
                        options={employeeList.map((employee: EmployeeRes) => {
                            return {
                                label: `${formatName(employee.userInfo.name)}`,
                                value: employee.id
                            } as ItemProp
                        })}
                        filterOption={handleFilter} />
                </Form.Item>
            </Form>
        </Modal>
    );
}