import { theme } from "antd";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useState } from "react";
import { LeadReportRes } from "../../../../../response/lead.response";
import { getGraphColor } from "../../../../../enum/static-data.enum";
import { useNavigate } from "react-router-dom";

export interface LeadChartComponentProp {
    leadReportResList: LeadReportRes[],
    filterData: any
}

const LeadChartComponent = (props: LeadChartComponentProp) => {

    const navigate = useNavigate();
    
    const { leadReportResList, filterData } = props

    const { useToken } = theme;
    const { token } = useToken();
    const [chartHeight, setChartHeight] = useState('280px')

    const dataList = leadReportResList.map((leadReportRes: LeadReportRes) => {
        return {
            name: leadReportRes.value,
            y: leadReportRes.count,
            color: getGraphColor(leadReportRes.value),
            drilldown: leadReportRes.value
        }
    })

    const options = {
        chart: {
            type: 'pie',
            height: chartHeight
        },

        title: {
            text: '',
            align: 'left'
        },
        credits: {
            enabled: false
        },
        accessibility: {
            announceNewData: {
                enabled: true
            },
            point: {
                valueSuffix: ''
            }
        },
        plotOptions: {
            series: {
                borderRadius: 5,
                dataLabels: {
                    enabled: true,
                    format: '{point.name}: {point.y:.0f}'
                }
            }
        },

        series: [{
            name: 'Lead',
            colorByPoint: true,
            data: dataList,
            point: {
                events: {
                    click: function (event: any) {
                        onPieClick((this as any).name)
                    }
                }
            }
        }]

    }

    useEffect(() => {
        const isMobile = window.innerWidth <= 768; // Adjust the breakpoint according to your needs
        const ch = isMobile ? '200px' : '280px';
        setChartHeight(ch)
    }, [])

    const onPieClick = (status: string) => {
        navigate('/lead', {
            state: {
                filterData: filterData,
                status: status
            }
        });
    }

    return (
        <HighchartsReact highcharts={Highcharts} options={options} />
    )
}

export default LeadChartComponent