import { ImageAndCard } from "./ImageandData.component";
export const AccessDeniedPage = () => {
  const imageSrc = "/images/access-denied.jpeg";
  const cardfirst = true;
  const cardData = {
    title: "No Access",
    description1:
      ' Uh-oh!! Seems like you don’t have permission to access this URL.{" "}.',
    description2: "Please contact the HR for permission access.",
  };
  return (
    <div>
      <ImageAndCard
        cardData={cardData}
        imageSrc={imageSrc}
        cardfirst={cardfirst}
      />
    </div>
  );
};
