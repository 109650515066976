import { useState, useEffect } from "react";
import { theme } from "antd";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { CallReportRes } from "../../../../../../../response/call.response";

export interface DailyStatsChartComponentProps {
    callReportResList: CallReportRes[]
    chartHeight: any
}

const DailyStatsChartComponent = (props: DailyStatsChartComponentProps) => {

    const { callReportResList, chartHeight } = props

    const [_chartHeight, setChartHeight] = useState(props.chartHeight)

    const options = {
        chart: {
            type: 'column'
        },
        title: {
            align: 'left',
            text: ''
        },
        subtitle: {
            align: 'left',
            text: ''
        },
        accessibility: {
            announceNewData: {
                enabled: true
            }
        },
        xAxis: {
            type: 'category'
        },
        yAxis: {
            title: {
                text: 'Number of calls'
            }

        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: '{point.y:.0f}'
                }
            }
        },

        tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.0f}</b> of total<br/>'
        },

        series: [
            {
                name: '',
                colorByPoint: true,
                data: callReportResList.map((callReportRes: CallReportRes) => {
                    return {
                        name: callReportRes.value,
                        y: callReportRes.count,
                        drilldown: callReportRes.value
                    }
                })
            }
        ]
    };

    // const options = {
    //     chart: {
    //         type: 'column',
    //         height: _chartHeight
    //     },
    //     title: {
    //         text: '',
    //     },
    //     xAxis: {
    //         categories: callReportResList.map((callReportRes: CallReportRes) => {
    //             return callReportRes.value
    //         })
    //     },
    //     credits: {
    //         enabled: false
    //     },
    //     yAxis: {
    //         min: 0,
    //         title: {
    //             text: ''
    //         },
    //         stackLabels: {
    //             enabled: true
    //         }
    //     },
    //     tooltip: {
    //         headerFormat: '<b>{point.x}</b><br/>',
    //         pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
    //     },
    //     plotOptions: {
    //         column: {
    //             stacking: 'normal',
    //             dataLabels: {
    //                 enabled: true
    //             }
    //         }
    //     },
    //     series: callReportResList.map((callReportRes: CallReportRes) => {
    //     return {
    //         name: callReportRes.value,
    //         data: [callReportRes.count]
    //     }
    // })
    //     // series: [{
    //     //     name: 'BPL',
    //     //     data: [3, 5, 1, 13]
    //     // }, {
    //     //     name: 'FA Cup',
    //     //     data: [14, 8, 8, 12]
    //     // }, {
    //     //     name: 'CL',
    //     //     data: [0, 2, 6, 3]
    //     // }]
    // }

    useEffect(() => {
        const isMobile = window.innerWidth <= 768;
        const ch = isMobile ? '200px' : props.chartHeight;
        setChartHeight(ch)
    }, [])

    return (
        <HighchartsReact highcharts={Highcharts} options={options} />
    )
}

export default DailyStatsChartComponent