import { RuleObject } from "antd/es/form";
import { StoreValue } from "antd/es/form/interface";

export const validateLevelName = async (rule: RuleObject, value: StoreValue) => {
    if (!value) {
        return Promise.reject('Please enter name');
    }
    if (value && value.length < 3) {
        return Promise.reject('Please enter more then 3 digit name');
    }

    if (value && value.length > 30) {
        return Promise.reject('Please enter less then 30 digit name');
    }
    return Promise.resolve();
}

export const validateLevel = async (rule: RuleObject, value: StoreValue) => {
    if (!value) {
        return Promise.reject('Please enter score');
    }
    return Promise.resolve();
}

export const validateOfficeName = async (rule: RuleObject, value: StoreValue) => {
    if (!value) {
        return Promise.reject('Please enter office name');
    }
    return Promise.resolve();
}