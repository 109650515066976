import { useContext } from "react"
import { ReportContext } from "./report.context";


export const useReport = () => {
    const context = useContext(ReportContext)
    if (context === undefined) {
        throw new Error(`Component is not inside ReportContext scope`);
    }
    return context
}