import React, { useEffect, useState } from 'react';
import { Form, theme, Modal, Select } from 'antd';
import { EmployeeRes } from '../../../response/employee.response';
import { ItemProp } from '../../../model/item-prop.model';
import { formatName } from '../../../util/name-helper';
import { handleFilter } from '../../../view/input.helper';
import { EmployeeService } from '../../../service/employee.service';
import { EmployeeSearchReq } from '../../../request/employee.request';

const reCreateTypeList = [
{
    label: "With History",
    value: "with-history"
},
{
    label: "With Out History",
    value: "with-out-history"
},
{
    label: "Fresh Creation",
    value: "fresh-creation"
}
]

export const ReCreateEnquiryComponent: React.FC<{ open: boolean, onCancel: () => void, onSuccess: (employeeIdList: string[], reCreateType: string) => void }> = ({ open, onCancel, onSuccess }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)
    const [employeeList, setEmployeeList] = useState([])

    const fetchReportee = async () => {
        setLoading(true)
        const [reporteeList, error] = await EmployeeService.getEmployeeList({
            pageNumber: 1,
            itemsPerPage: 200
        } as EmployeeSearchReq)

        if (error) {

        } else {
            setEmployeeList(reporteeList)
        }
        setLoading(false)
    };


    const handleOk = () => {
        form.validateFields().then(async (values) => {
            setLoading(true)
            const employeeIdList = values["employeeIdList"]
            const reCreateType = values["reCreateType"]
            onSuccess(employeeIdList, reCreateType)
        });
    };

    useEffect(() => {
        fetchReportee()
    }, [])

    return (
        <Modal
            open={open}
            title="Re Create Enquiry(Please make sure before click submit)"
            onCancel={onCancel}
            confirmLoading={loading}
            onOk={handleOk}
            okText={"Submit"}
            cancelText={"Cancel"}
            style={{ top: 8, maxWidth: 768 }}
            width={'90vw'}
        >
            <Form form={form} layout="inline">
                <Form.Item
                    name="employeeIdList"
                    style={{ minWidth: 124 }}
                >
                    <Select
                        allowClear
                        mode="multiple"
                        value='employeeIdList'
                        maxTagCount='responsive'
                        options={employeeList.map((employeeRes: EmployeeRes) => {
                            return {
                                label: `${formatName(employeeRes.userInfo.name)}`,
                                value: employeeRes.id
                            } as ItemProp
                        })}
                        placeholder="Employee"
                        filterOption={handleFilter} />
                </Form.Item>

                <Form.Item
                    name="reCreateType"
                    style={{ minWidth: 124 }}
                    initialValue={"with-history"}
                >
                    <Select
                        value='reCreateType'
                        options={reCreateTypeList}
                        placeholder="Creattion Mode" />
                </Form.Item>

            </Form>
        </Modal>
    );
}
