import { BulkEnquiryUpdateReq, EnquiryReportReq, EnquiryRequest, EnquirySearchReq } from "../request/enquiry.request";
import { EnquiryReportRes, EnquiryRes } from "../response/enquiry.response";
import httpClient from "../util/http-client"
const FileDownload = require('js-file-download');

export const EnquiryService = {
    saveEnquiry: async (enquiryRequest: EnquiryRequest): Promise<(EnquiryRes | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/enquiry", enquiryRequest)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    getEnquiryById: async (id: string): Promise<(EnquiryRes | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.get(`/enquiry/${id}`)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    getEnquiryList: async (enquirySearchReq: EnquirySearchReq): Promise<(EnquiryRes[] | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/enquiry-search", enquirySearchReq)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    downLoadList: async (enquirySearchReq: EnquirySearchReq) => {
        httpClient.post("/enquiry-download", enquirySearchReq, { responseType: 'blob' }).then((response: any) => {
            FileDownload(response.data, 'enquiry.csv');
        })
    },
    updateEnquiry: async (id: string, enquiryRequest: EnquiryRequest): Promise<(EnquiryRes | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.put(`/enquiry/${id}`, enquiryRequest)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    updateBulkEnquiry: async (bulkEnquiryUpdateReq: BulkEnquiryUpdateReq): Promise<(any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post(`/enquiry-bulk-update`, bulkEnquiryUpdateReq)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    reCreateEnquiry: async (enquirySearchReq: EnquirySearchReq, reCreateType: string): Promise<(any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post(`/enquiry-recreate?reCreateType=${reCreateType}`, enquirySearchReq)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    getReport: async (enquiryReportReq: EnquiryReportReq): Promise<(EnquiryReportRes[])[]> => {
        let data
        let err
        try {
            const res = await httpClient.post(`/enquiry-report`, enquiryReportReq)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
}

