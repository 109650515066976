import { AmenitiesStateProvider } from './amenities.context';
import { MainAmenitiesTableComponent } from './component/table.component';

const AmenitiesPageBase = () => {

  return <>
    <MainAmenitiesTableComponent />
  </>
}

export const AmenitiesPage = () => {
  return <AmenitiesStateProvider>
    <AmenitiesPageBase />
  </AmenitiesStateProvider>
}

