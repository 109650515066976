import { createContext, useReducer } from "react";
import { UserState } from "./user.state";
import { UserReducer } from "./user.reducer";
import { UserAction } from "./user.action";
import { UserService } from "../../service/user.service";
import { UserRes } from "../../response/user.response";
import { UserRequest, UserSearchReq } from "../../request/user.request";

const initialState = {
    loading: false,
    userSearchReq: {
        pageNumber: 1,
        itemsPerPage: 100
    } as UserSearchReq,
    userList: [],
    updateUserData: {} as UserRes
} as UserState

export const UserContext = createContext<any>(initialState);

export const UserStateProvider = ({ children }: any) => {

    const [state, dispatch] = useReducer(UserReducer, initialState)


    const updateLoading = (loading: Boolean) => {
        dispatch({
            type: UserAction.UPDATE_LOADING,
            payload: {
                loading: loading
            }
        });
    };

    const fetchUserList = async (userSearchReq: UserSearchReq, onSuccess: () => void, onFailure: () => void) => {
        updateLoading(true)
        const [userList, error] = await UserService.getUserList(userSearchReq)

        if (error) {
            onFailure()
        } else {
            dispatch({
                type: userSearchReq.pageNumber == 1 ? UserAction.UPDATE_USER_LIST : UserAction.ADD_USER_LIST,
                payload: {
                    userList: userList
                }
            })
            if (userList.length > 0) {
                dispatch({
                    type: UserAction.UPDATE_USER_SEARCH_REQ,
                    payload: {
                        userSearchReq: {
                            ...userSearchReq,
                            pageNumber: userSearchReq.pageNumber + 1
                        }
                    }
                })
            }
            onSuccess()
        }
    };

    const saveUser = async (userRequest: UserRequest, onSuccess: () => void, onFailure: () => void) => {
        const [data, error] = await UserService.saveUser(userRequest)

        if (error) {
            onFailure()
        } else {
            onSuccess()
        }
    };

    const updateUser = async (id: string, userRequest: UserRequest, onSuccess: () => void, onFailure: () => void) => {
        const [data, error] = await UserService.updateUser(id, userRequest)

        if (error) {
            onFailure()
        } else {
            onSuccess()
        }
    };

    const value = {
        userList: state.userList,
        loading: state.loading,
        userSearchReq: state.userSearchReq,
        updateLoading,
        fetchUserList,
        saveUser,
        updateUser
    };

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>
};