import { useEffect } from "react";
import { initializeApp } from "firebase/app";

export const useAnalytics = () => {
    const firebaseConfig = {
        apiKey: "AIzaSyDs473oKt8S0VRkoQjcX_fwpi710fZ_U-w",
        authDomain: "realtybulls-f8472.firebaseapp.com",
        projectId: "realtybulls-f8472",
        storageBucket: "realtybulls-f8472.appspot.com",
        messagingSenderId: "1080170097044",
        appId: "1:1080170097044:web:9a5ec3e2743bb3e4a1c781",
        measurementId: "G-2EHLTY8YYC"
    };

    useEffect(() => {
        // Initialize Firebase
        const app = initializeApp(firebaseConfig);
    }, [])
}