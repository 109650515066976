import { useContext } from "react"
import { ArticleContext } from "./article.context";


export const useArticle = () => {
    const  context = useContext(ArticleContext)
    if (context === undefined){
        throw new Error(`Component is not inside ArticleContext scope`);
    }
    return context
}