import { createContext, useReducer } from "react";
import { CallAction } from "./call.action";
import { CallReducer } from "./call.reducer";
import { CallService } from "../../service/call.service";
import { CallState } from "./call.state";
import { CallSearchReq } from "../../request/call.request";
import { EmployeeService } from "../../service/employee.service";
import { EmployeeSearchReq } from "../../request/employee.request";

const _statusList = [
    { label: "In Progress", value: "In Progress" },
    { label: "Waiting", value: "Waiting" },
    { label: "Missed / Not Answered", value: "Missed / Not Answered" },
    { label: "Answered", value: "Answered" },
]

const initialState = {
    loading: false,
    callSearchReq: {
        pageNumber: 1,
        itemsPerPage: 100
    } as CallSearchReq,
    callList: [],
    employeeList: [],
    employeeSearchReq: {
        pageNumber: 1,
        itemsPerPage: 200
    } as EmployeeSearchReq,
    statusList: _statusList,
} as CallState



export const CallContext = createContext<any>(initialState);

export const CallStateProvider = ({ children }: any) => {

    const [state, dispatch] = useReducer(CallReducer, initialState)

    const fetchCallList = async (callSearchReq: CallSearchReq, onSuccess: () => void, onFailure: () => void) => {
        const [callList, error] = await CallService.getCallList(callSearchReq);
        if (error) {
            onFailure()
        } else {
            dispatch({
                type: callSearchReq.pageNumber == 1 ? CallAction.UPDATE_CALL_LIST : CallAction.ADD_CALL_LIST,
                payload: {
                    callList: callList
                }
            })
            if (callList.length > 0) {
                dispatch({
                    type: CallAction.UPDATE_CALL_SEARCH_REQ,
                    payload: {
                        callSearchReq: {
                            ...callSearchReq,
                            pageNumber: callSearchReq.pageNumber + 1
                        }
                    }
                })
            }
            onSuccess()
        }
    }

    const updateLoading = (loading: Boolean) => {
        dispatch({
            type: CallAction.UPDATE_LOADING,
            payload: {
                loading: loading
            }
        })
    };

    const fetchEmployeeList = async () => {
        const [data, error] = await EmployeeService.getEmployeeList(state.employeeSearchReq)
        if (error) {

        } else {
            dispatch({
                type: CallAction.UPDATE_EMPLOYEE_LIST,
                payload: {
                    employeeList: data
                }
            });
        }
    };

    const value = {
        callList: state.callList,
        loading: state.loading,
        employeeList: state.employeeList,
        statusList: state.statusList,
        callSearchReq: state.callSearchReq,
        updateLoading,
        fetchCallList,
        fetchEmployeeList
    };

    return <CallContext.Provider value={value}>{children}</CallContext.Provider>
};



