import "./common.css";
import { ImageAndCard } from "./ImageandData.component";
export const NotFoundPage = () => {
  const imageSrc = "/images/error404.png";
  const cardfirst = false;
  const cardData = {
    title: "ERROR 404",
    description1:
      "It looks like we can’t go any further than this. We’d suggest you to go back to the homepage.",
  };
  return (
    <div>
      <ImageAndCard
        cardData={cardData}
        imageSrc={imageSrc}
        cardfirst={cardfirst}
      />
    </div>
  );
};
