import { MailOutlined, MobileOutlined, DatabaseOutlined } from "@ant-design/icons"
import { Col, Card, Button, Typography, theme, Select, Input, Row, DatePicker, Radio, Form } from "antd"
import Meta from "antd/es/card/Meta"
import dayjs from "dayjs"
import React, { useState } from "react"
import ImgIcon from "../../../../../view/img-icon"
import { UserRes } from "../../../../../response/user.response"
import { useLead } from "../../../lead.hook"
import { UserService } from "../../../../../service/user.service"
import { UserRequest } from "../../../../../request/user.request"
import { validateEmail, validateName, validatePhone } from "../../../../../validator/user-info.validator"
import { UserInfo } from "../../../../../model/user-info.model"
import { handleFilter } from "../../../../../view/input.helper"
import { CommunicationMode } from "../../../../../enum/mode.enum"
import { formatName } from "../../../../../util/name-helper"
import { isMobile } from "../../../../../util/device-helper"

interface UserPersonalDetailProp {
    client: UserRes
}

export const UserPersonalDetail = (props: UserPersonalDetailProp) => {

    const [form] = Form.useForm();

    const { client } = props
    const { useToken } = theme;
    const { token } = useToken();

    const [isPersonalDetailsEdit, setIsPersonalDetailsEdit] = useState(false);

    const handlePersonalDetailEdit = () => {
        setIsPersonalDetailsEdit(true)
    }

    const { modeOfCommunicationList, genderList, fetchClient } = useLead()

    const savePersonalDetail = () => {
        form.validateFields().then(async values => {
            const userRequest = {
                userInfo: {
                    phone: "7755058016",
                    name: values["name"],
                    email: values["email"]
                } as UserInfo,
                altPhone: values["altPhone"],
                altEmail: values["altEmail"],
                gender: values["gender"],
                mode: values["mode"],
                birthDate: values["birthDate"]
            } as UserRequest
            const [data, err] = await UserService.updateUser(client.id, userRequest)
            if (err) {

            } else {
                fetchClient(client.id, () => { setIsPersonalDetailsEdit(false) }, () => { setIsPersonalDetailsEdit(false) })
            }
        });
    }

    return (
        <React.Fragment>
            <Col lg={12} xs={24}>
                <Card title="Personal Details" bordered={false}
                    extra={!isPersonalDetailsEdit &&
                        <Button style={{
                            display: 'flex',
                            alignItems: 'center',
                            border: '0',
                            background: '#E0EEF7',
                            color: '#000000'
                        }} onClick={handlePersonalDetailEdit}>
                            <ImgIcon iconName="edit.svg" />
                            Edit
                        </Button>
                    }
                    style={{
                        padding: '0 20px',
                        height: '100%'
                    }}
                    headStyle={{
                        padding: 0
                    }}
                    bodyStyle={{
                        padding: '15px 0'
                    }}
                    className="lead-personal-details wrapper-class"
                >
                    <React.Fragment>
                        {isPersonalDetailsEdit &&
                            <Form form={form}>
                                <Row style={{
                                    marginBottom: '20px'
                                }}>
                                    <Col md={12} xs={24} >

                                        <Typography.Title level={5} style={{
                                            fontSize: '14px'
                                        }}>Mobile Number</Typography.Title>
                                        <Form.Item
                                            name="phone"
                                            style={{
                                                margin: 0
                                            }}
                                            rules={[{ required: false }]}
                                            initialValue={client.userInfo.phone}
                                        >
                                            <Input
                                                type="text"
                                                readOnly={true}
                                                placeholder="9987654321"
                                                style={{
                                                    width: '94%',
                                                    border: '1px solid #92B5D7',
                                                    borderRadius: token.borderRadius,
                                                    boxShadow: 'none'
                                                }}
                                                prefix={<Select
                                                    showSearch={true}
                                                    value="+91"
                                                    className="form-custom-select"
                                                    style={{
                                                        width: 70,
                                                        borderRadius: token.borderRadius,
                                                        boxShadow: 'none'
                                                    }}
                                                    options={[
                                                        { value: '+91', label: '+91' }
                                                    ]}
                                                    filterOption={handleFilter}
                                                />}
                                            />

                                        </Form.Item>

                                    </Col>
                                    <Col md={12} xs={24} >
                                        <Typography.Title level={5} style={{
                                            fontSize: '14px'
                                        }}>Alternate Mobile Number</Typography.Title>
                                        <Form.Item
                                            name="altPhone"
                                            style={{
                                                margin: 0
                                            }}
                                            rules={[{ required: false }]}
                                            initialValue={client.altPhone}
                                        >
                                            <Input
                                                type="text"
                                                placeholder="9987654321"
                                                style={{
                                                    width: '94%',
                                                    border: '1px solid #92B5D7',
                                                    borderRadius: token.borderRadius,
                                                    boxShadow: 'none'
                                                }}
                                                prefix={<Select
                                                    showSearch={true}
                                                    value="+91"
                                                    className="form-custom-select"
                                                    style={{
                                                        width: 70,
                                                        borderRadius: token.borderRadius,
                                                        boxShadow: 'none'
                                                    }}
                                                    options={[
                                                        { value: '+91', label: '+91' }
                                                    ]}
                                                    filterOption={handleFilter}
                                                />}
                                            />

                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Typography.Title level={5} style={{
                                    fontSize: '14px'
                                }}>Name</Typography.Title>
                                <Row style={{
                                    marginBottom: '20px'
                                }}>
                                    <Col md={12} xs={24} >
                                        <Form.Item
                                            name="name"
                                            style={{
                                                margin: 0
                                            }}
                                            rules={[{ required: true, validator: validateName }]}
                                            initialValue={client.userInfo.name}
                                        >

                                            <Input
                                                type="text"
                                                placeholder="Name"
                                                style={{
                                                    width: '94%',
                                                    border: '1px solid #92B5D7',
                                                    borderRadius: token.borderRadius,
                                                    boxShadow: 'none'
                                                }}
                                            />
                                        </Form.Item>

                                    </Col>
                                    {/* <Col md={12} xs={24} >
                                        <Form.Item
                                            name="lastname"
                                            style={{
                                                margin: 0
                                            }}
                                            rules={[{ required: true, message: 'Please input your username!' }]}
                                        >
                                            <Input
                                                type="text"
                                                value=""
                                                placeholder="Last Name"
                                                style={{
                                                    width: '98%',
                                                    border: '1px solid #92B5D7',
                                                    borderRadius: token.borderRadius,
                                                    boxShadow: 'none'
                                                }}
                                            />
                                        </Form.Item>

                                    </Col> */}
                                </Row>
                                <Row style={{
                                    marginBottom: '20px'
                                }}>
                                    <Col md={12} xs={24} >
                                        <Typography.Title level={5} style={{
                                            fontSize: '14px'
                                        }}>Email address</Typography.Title>
                                        <Form.Item
                                            name="email"
                                            style={{
                                                margin: 0
                                            }}
                                            rules={[{ required: false }]}
                                            initialValue={client.userInfo.email}
                                        >

                                            <Input
                                                type="text"
                                                placeholder="janedoe890@ymail.com"
                                                style={{
                                                    width: '94%',
                                                    border: '1px solid #92B5D7',
                                                    borderRadius: token.borderRadius,
                                                    boxShadow: 'none'
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col md={12} xs={24} >
                                        <Typography.Title level={5} style={{
                                            fontSize: '14px'
                                        }}>Alternate email address</Typography.Title>
                                        <Form.Item
                                            name="altEmail"
                                            style={{
                                                margin: 0
                                            }}
                                            rules={[{ required: false }]}
                                            initialValue={client.altEmail}
                                        >
                                            <Input
                                                type="text"
                                                placeholder="janedoe890@ymail.com"
                                                style={{
                                                    width: '98%',
                                                    border: '1px solid #92B5D7',
                                                    borderRadius: token.borderRadius,
                                                    boxShadow: 'none'
                                                }}
                                            />
                                        </Form.Item>

                                    </Col>
                                </Row>
                                <Row style={{
                                    marginBottom: '20px'
                                }}>
                                    <Col md={12} xs={24} >
                                        <Typography.Title level={5} style={{
                                            fontSize: '14px'
                                        }}>Date of Birth</Typography.Title>
                                        <Form.Item
                                            name="birthDate"
                                            style={{
                                                margin: 0
                                            }}
                                            rules={[{ required: false }]}
                                            initialValue={dayjs(client.birthDate ? client.birthDate : dayjs())}
                                        >
                                            <DatePicker
                                                inputReadOnly={isMobile()}
                                                style={{
                                                    border: '1px solid #92B5D7',
                                                    width: '70%',
                                                    borderRadius: token.borderRadius,
                                                    boxShadow: 'none'
                                                }}
                                                placeholder="Select date" />

                                        </Form.Item>

                                    </Col>
                                    <Col md={12} xs={24} >
                                        <Typography.Title level={5} style={{
                                            fontSize: '14px'
                                        }}>Gender</Typography.Title>
                                        <Form.Item
                                            name="gender"
                                            style={{
                                                margin: 0
                                            }}
                                            rules={[{ required: false }]}
                                            initialValue={client.gender}
                                        >
                                            <Radio.Group
                                                options={genderList} />

                                        </Form.Item>

                                    </Col>
                                </Row>
                                <Row style={{
                                    marginBottom: '20px'
                                }}>
                                    <Col>
                                        <Typography.Title level={5} style={{
                                            fontSize: '14px'
                                        }}>Contact</Typography.Title>
                                        <Form.Item
                                            name="mode"
                                            style={{
                                                margin: 0
                                            }}
                                            rules={[{ required: true, message: 'Please input your mode!' }]}
                                            initialValue={client.mode}
                                        >
                                            <Radio.Group
                                                options={modeOfCommunicationList}

                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row className="action-row" style={{
                                    marginBottom: '20px',
                                    justifyContent: 'end'
                                }}>
                                    <Col style={{ marginRight: 16 }}>
                                        <Button style={{
                                            border: '0',
                                            background: '#E0EEF7',
                                            color: '#000000'
                                        }}
                                            onClick={() => setIsPersonalDetailsEdit(false)}
                                        >
                                            Cancel
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button type="primary" block style={{
                                            background: token.colorInfo,
                                            boxShadow: 'none'
                                        }}
                                            onClick={savePersonalDetail}
                                        >
                                            Save Changes
                                        </Button>
                                    </Col>

                                </Row>

                            </Form>
                        }
                        {!isPersonalDetailsEdit &&
                            <React.Fragment>
                                <Row style={{
                                    marginBottom: '20px'
                                }}>
                                    <Col lg={12} xs={24} >
                                        <Meta
                                            title={"Name"}
                                            description={formatName(client.userInfo.name)}
                                        />
                                    </Col>
                                    <Col lg={12} xs={24} className="mt-responsive-4" >
                                        <Meta
                                            title={"Gender"}
                                            description={client.gender}
                                            className="ml-responsive-8"
                                        />
                                    </Col>
                                </Row>

                                <Row style={{
                                    marginBottom: '20px'
                                }}>
                                    <Col span={24}>
                                        <Meta
                                            avatar={<ImgIcon iconName="email.svg" iconClass="m-0" />}
                                            title={"Email"}
                                            description={client.userInfo.email}
                                        />
                                    </Col>

                                </Row>
                                <Row style={{
                                    marginBottom: '20px'
                                }}>
                                    <Col lg={12} xs={24}>
                                        <Meta
                                            avatar={<ImgIcon iconName="phone-black.svg" iconClass="m-0" />}
                                            title={"Mobile Number"}
                                            description={client.userInfo.phone}
                                        />
                                    </Col>
                                    <Col lg={12} xs={24} className="mt-responsive-4">
                                        <Meta
                                            title={"Alternate Mobile Number"}
                                            description={client.altPhone}
                                            className="ml-responsive-8"
                                        />
                                    </Col>
                                </Row>
                                <Row style={{
                                    marginBottom: '20px'
                                }}>
                                    <Col span={24}>
                                        <Meta
                                            avatar={<ImgIcon iconName="dob.svg" iconClass="m-0" />}
                                            title={"D.O.B"}
                                            description={dayjs(client.birthDate).format('DD-MM-YYYY')}
                                        />
                                    </Col>
                                </Row>

                                <Row style={{
                                    marginBottom: '20px'
                                }}>
                                    <Col span={24}>
                                        <Meta
                                            title={"Address"}
                                            description={`${client.address?.lineOne ? `${client.address?.lineOne} ,` : ""} ${client.address?.lineTwo ? `${client.address?.lineTwo} ,` : ""} ${client.address?.street ? `${client.address?.street} ,` : ""} ${client.address?.city ? `${client.address?.city} ,` : ""} ${client.address?.state ? `${client.address?.state}` : ""}`}
                                        />
                                    </Col>
                                </Row>
                                <Row style={{
                                    marginBottom: '20px'
                                }}>
                                    <Col span={24}>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                            <Typography.Title className="two-col-heading" level={5} style={{
                                                marginBottom: 0,
                                                fontSize: '14px'
                                            }}>Mode of Contact</Typography.Title>
                                            <span style={{
                                                background: token.colorInfo,
                                                color: '#ffffff',
                                                padding: '2px 10px',
                                                fontSize: '12px',
                                                marginLeft: '10px',
                                                borderRadius: token.borderRadiusSM
                                            }} >
                                                {client.mode === CommunicationMode.PHONE &&
                                                    <ImgIcon iconName="phone-white.svg" />
                                                }
                                                {client.mode === CommunicationMode.EMAIL &&
                                                    <ImgIcon iconName="email-white.svg" />
                                                }
                                                {client.mode === CommunicationMode.SMS &&
                                                    <ImgIcon iconName="sms-white.svg" />
                                                }
                                                {client.mode}
                                            </span>
                                        </div>
                                    </Col>
                                </Row>


                            </React.Fragment>
                        }
                    </React.Fragment>

                </Card>
            </Col>
        </React.Fragment >
    )
}