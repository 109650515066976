export enum LeadAction {
    UPDATE_LOADING = "UPDATE_LOADING",
    UPDATE_LEAD_LIST = "UPDATE_LEAD_LIST",
    UPDATE_LEAD = "UPDATE_LEAD",
    ADD_LEAD_LIST = "ADD_LEAD_LIST",
    UPDATE_LEAD_SEARCH_REQ = "UPDATE_LEAD_SEARCH_REQ",
    SET_UPDATE_LEAD = "SET_UPDATE_LEAD",
    UPDATE_EMPLOYEE_LIST = "UPDATE_EMPLOYEE_LIST",
    UPDATE_USER_LIST = "UPDATE_USER_LIST",
    UPDATE_PROJECT_LIST = "UPDATE_PROJECT_LIST",
    UPDATE_CLIENT = "UPDATE_CLIENT"
}