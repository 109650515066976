import { ServiceRequest, ServiceSearchReq } from "../request/service.request"
import { ServiceRes } from "../response/service.response"
import httpClient from "../util/http-client"

export const ServiceService = {
    saveService: async (serviceRequest: ServiceRequest): Promise<(ServiceRes|any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/service", serviceRequest)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    getServiceList: async (serviceSearchReq: ServiceSearchReq): Promise<(ServiceRes[]|any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/service-search", serviceSearchReq)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    updateService: async (id: string, serviceRequest: ServiceRequest): Promise<(ServiceRes|any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.put(`/service/${id}`, serviceRequest)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
}