import React from 'react';
import { Modal, Form, Input, TreeSelect, Select } from 'antd';
import { useArticle } from '../article.hook';
import { ArticleRequest } from '../../../request/article.request';
import { ArticleRes } from '../../../response/article.response';
import { TextEditorView } from '../../../view/text-editor.view';
import FileUploadView from '../../../view/file-upload.view';
import { handleFilter } from '../../../view/input.helper';

export const UpdateArticleComponent: React.FC<{ article: ArticleRes, open: boolean, onCancel: () => void }> = ({ article, open, onCancel }) => {
    const [form] = Form.useForm();
    const { loading, updateLoading, updateArticle } = useArticle()

    const handleOk = () => {
        form.validateFields().then(values => {
            updateLoading(true);
            const articleRequest = {
                title: values["title"],
                thumbnail: values["thumbnail"],
                miniDescription: values["miniDescription"],
                description: values["description"],
                tagList: values["tagList"]
            } as ArticleRequest
            updateArticle(article.id, articleRequest, onSuccess, onFailure)
        });
    };

    const onSuccess = () => {
        updateLoading(false);
        form.resetFields()
        onCancel();
    }

    const onFailure = () => {
        updateLoading(false);
    }

    return (
        <Modal
            open={open}
            title="Add New Article"
            onCancel={onCancel}
            confirmLoading={loading}
            onOk={handleOk}
            okText={"Submit"}
            cancelText={"Cancel"}
            style={{ top: 8, maxWidth: 768 }}
            width={'90vw'}
        >
            <Form form={form} layout="vertical">
                <Form.Item
                    name="title"
                    label="Title"
                    rules={[{ required: true }]}
                    initialValue={article.title}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="thumbnail"
                    label="Thumbnail"
                    rules={[{ required: true, message: 'Please add thumbnail' }]}
                    initialValue={article.thumbnail}
                >
                    <FileUploadView onChange={() => { }} />
                </Form.Item>

                <Form.Item
                    name="miniDescription"
                    label="Mini Description"
                    rules={[{ required: true }]}
                    initialValue={article.miniDescription}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="tagList"
                    label="Tags"
                    rules={[{ required: true, message: 'Enter Tags' }]}
                    initialValue={article.tagList}
                >
                    <Select
                        mode="tags"
                        value='tagList'
                        maxTagCount='responsive'
                        filterOption={handleFilter}
                    />
                </Form.Item>

                <Form.Item
                    name="description"
                    label="Description"
                    rules={[{ required: true }]}
                    initialValue={article.description}
                >
                    <TextEditorView readOnly={false} initData={article.description} />
                </Form.Item>
            </Form>
        </Modal>
    );
}