import { Card, Table, Tag, theme } from "antd"
import { LeadNoteRes } from "../../../../response/lead-note.request";
import { MiniUser } from "../../../../model/mini-user";
import { MiniProject } from "../../../../model/mini-project";
import TableCardViewComponent from "./lead-table-card-view/table-card-view.component";
import { Link, useNavigate } from "react-router-dom";
import { LeadRes } from "../../../../response/lead.response";
import { ColumnsType } from "antd/es/table";
import { formatName } from "../../../../util/name-helper";


export const LeadTableList = (props: any) => {

    const navigate = useNavigate()
    
    const { leadList, title } = props

    const { useToken } = theme;
    const { token } = useToken();

    const getRowClassName = (record: any) => {
        if (record.status === 'inprogress') {
            return 'inprogress'; // CSS class for active rows
        } else if (record.status === 'pending') {
            return 'pending'; // CSS class for inactive rows
        } else if (record.status === 'rejected') {
            return 'rejected'; // CSS class for inactive rows
        } else if (record.status === 'close') {
            return 'close'; // CSS class for inactive rows
        }

        return '';
        // Add more conditions for other statuses and CSS classes if needed
    };

    const columnList: ColumnsType<LeadRes> = [
        {
            title: 'Name',
            dataIndex: 'client',
            key: 'client',
            render: (client: MiniUser) => <span>{formatName(client.name)}</span>,
        },
        {
            title: 'Requirement',
            dataIndex: 'requirement',
            key: 'requirement',
            render: (requirement: string) => <span>{requirement}</span>,
        },
        {
            title: 'Next action',
            dataIndex: 'currentNote',
            key: 'currentNote',
            render: (currentNote: LeadNoteRes) => {
                return (
                    <Tag key={"sitevisit"} style={{ border: '1px solid #EC0707', color: '#EC0707', borderRadius: token.borderRadius }}>
                        {currentNote.type}
                    </Tag>
                );
            }
        },
        {
            title: 'Project',
            dataIndex: 'project',
            key: 'project',
            render: (project: MiniProject) => <span>{project.name}</span>,
        },
        {
            title: 'Due date',
            dataIndex: 'currentNote',
            key: 'currentNote',
            render: (currentNote: LeadNoteRes) => <span>{new Date(currentNote.dueDate).toLocaleDateString('en', { year: 'numeric', month: 'long', day: 'numeric' })}</span>,
        },
        {
            title: 'Connect',
            dataIndex: 'id',
            key: 'id',
            render: (id) => <Link to={`/lead/${id}`} target="_blank" rel="noopener noreferrer">Detail</Link>,
        },
    ];


    return (
        <Card className="p-mweb-card" style={{
            height: '100%',
            padding: '0 20px 10px',
            marginBottom: 20
        }}
            headStyle={{
                padding: '10px 0 0',
                minHeight: 44
            }}
            bodyStyle={{
                padding: '10px 0',
                color: '#000'
            }}
            title={title}
            bordered={false}
            extra={<a onClick={() => {navigate("/lead")}} style={{ textDecoration: 'underline' }}>View all</a>}
        >
            <Table
                columns={columnList} dataSource={leadList}
                className='generic-table d-web-show'
                pagination={false}
                rowClassName={getRowClassName}
            />

            <div className="m-web-show">
                <TableCardViewComponent getRowClassName={getRowClassName} leadList={leadList} />
            </div>


        </Card>
    )
}