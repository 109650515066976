import { Card, Badge, theme } from "antd"
import React, { useCallback, useEffect, useState } from "react"
import ImgIcon from "../../../../view/img-icon"
import { EnquireRowDetailItem } from "./lead-row-detail-item.component";
import { LeadRes } from "../../../../response/lead.response";
import { useLead } from "../../lead.hook";
import InfiniteScroll from "react-infinite-scroll-component";

export interface LeadGridDetailProp {
    leadList: LeadRes[]
}

export const LeadGridDetail: React.FC<LeadGridDetailProp> = ({ leadList }) => {

    const [pageNumber, setPageNumber] = useState(1)

    const { useToken } = theme;
    const { token } = useToken();

    const getIcon = (status: string) => {
        switch (status) {
            case "Pending":
                return "open.svg"
            case "Closed":
                return "close.svg"
            case "In Progress":
                return "inprogress.svg"
            case "Not Interested":
                return "rejected.svg"
            case "Dump / Spam":
                return "rejected.svg"
        }

        return "inprogress.svg"
    }

    const { fetchLeadList, leadSearchReq, updateLoading, statusList } = useLead()

    const onLeadSuccess = useCallback(async () => {
        fetchLeadList({ ...leadSearchReq, pageNumber: 1 }, () => { updateLoading(false) }, () => { updateLoading(false) })
    }, [])

    const getColumnView = React.useMemo(() => {
        return <InfiniteScroll
            dataLength={pageNumber * 10}
            next={() => {
                setPageNumber(prevPage => prevPage + 1);
            }}
            hasMore={pageNumber * 10 < leadList.length}
            loader={<h4>Loading...</h4>}
        >
            {leadList.slice(0, Math.min(pageNumber * 10, leadList.length)).map((lead: LeadRes, index: number) => {
                return <EnquireRowDetailItem lead={lead} lastItem={index == leadList.length - 1} onLeadSuccess={onLeadSuccess} statusList={statusList} />
            })}
        </InfiniteScroll>
    }, [leadList, pageNumber])

    return (
        <React.Fragment>

            <Card bodyStyle={{
                padding: 10
            }}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: '#E0EEF7',
                    color: '#343433',
                    padding: '6px 10px',
                    borderRadius: token.borderRadius,
                    marginBottom: 8
                }}>
                    {leadList.length > 0 && <>
                        <ImgIcon iconName={getIcon(leadList[0].status.value)} iconWidth="16px" />
                        <span>{leadList[0].status.value}</span>
                    </>}
                    <Badge count={leadList.length} style={{
                        background: '#6D6C68',
                        padding: '0 4px',
                        boxShadow: 'none',
                        margin: '0 6px'
                    }}
                        overflowCount={9999} />
                </div>
                {
                    getColumnView
                }

            </Card>
        </React.Fragment>
    )
}