import { TextEditorView } from '../../../view/text-editor.view';
import { Button, Form, theme } from 'antd';
import { useTask } from '../task.hook';
import React, { useEffect } from 'react';
import { TaskDiscussionRequest } from '../../../request/task-discussion.request';
import { TaskDiscussionRes } from '../../../response/task-discussion.response';
import './discussion.css';
import { Avatar, Tooltip } from 'antd';
import { Comment } from '@ant-design/compatible';
import { getColor } from '../../../enum/static-data.enum';
import ImgIcon from '../../../view/img-icon';

export const DiscussionComponent: React.FC<{ taskId: string }> = ({ taskId }) => {
    const [form] = Form.useForm();
    const { useToken } = theme;
    const { token } = useToken();

    const comment = Form.useWatch('comment', form);

    const { loading, discussionList, fetchDiscussionList, saveDiscussion, updateLoading } = useTask()

    const handleOk = () => {
        form.validateFields().then(values => {
            updateLoading(true);
            const taskRequest = {
                taskId: taskId,
                description: values["comment"],
            } as TaskDiscussionRequest
            saveDiscussion(taskRequest, onSuccess, onFailure)
        });
    };

    const onSuccess = () => {
        updateLoading(false);
        form.resetFields()
    }

    const onFailure = () => {
        updateLoading(false);
    }

    const onCancel = () => {
        form.resetFields()
    }

    useEffect(() => {
        fetchDiscussionList(taskId)
    }, [])

    return (
        <React.Fragment>
            <div style={{
                display: 'flex',
                alignItems: 'baseline'
            }}>
                <div className='custom-avatar'>
                    <ImgIcon iconName="users-black.svg" iconWidth="18px" />
                </div>


                <Form form={form} layout="vertical" className='add-comment-editor-form editor-custom-view'
                    onSubmitCapture={handleOk}
                    onLoad={loading}>
                    <Form.Item
                        name="comment"
                        rules={[{ required: true, message: 'Please enter description' }]}
                    >
                        <TextEditorView placeholder={'Add a comment'} onChange={(data: string) => { }}
                            readOnly={false} />
                    </Form.Item>
                    {comment && comment.length > 0 && <>
                        <Form.Item style={{
                            textAlign: 'right',
                            paddingRight: '20px',
                            background: '#ffffff'
                        }}>
                            <Button type="primary" htmlType="submit" style={{ background: token.colorInfo, boxShadow: 'none', borderRadius: token.borderRadiusSM, padding: '2px 8px' }}>{"Add Comment"}</Button>
                        </Form.Item>
                    </>}

                </Form>
            </div>


            {discussionList.map((discussion: TaskDiscussionRes) => {
                return (
                    <Comment
                        author={<span>{discussion.createdBy.name}</span>}
                        className='read-only'
                        style={{
                            background: 'transparent'
                        }}
                        avatar={<Avatar style={{ backgroundColor: getColor(discussion.createdBy.name[0]) }}>{discussion.createdBy.name[0].toUpperCase()}</Avatar>}
                        content={
                            <TextEditorView initData={discussion.description} onChange={(data: string) => { }} readOnly={true} />
                        }
                        actions={[<span onClick={() => { }}>Edit</span>]}
                        datetime={
                            <Tooltip title="2016-11-22 11:22:33">
                                <span>{new Date(discussion.createdOn).toLocaleDateString('en', { year: 'numeric', month: 'long', day: 'numeric' })}</span>
                            </Tooltip>
                        }
                    />
                );
            })}
        </React.Fragment>
    )


}