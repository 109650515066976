import { RuleObject } from "antd/es/form";
import { StoreValue } from "antd/es/form/interface";

export const validateName = async (rule: RuleObject, value: StoreValue) => {
    if (!value) {
        return Promise.reject('Please enter name');
    }
    if (value && value.length < 3) {
        return Promise.reject('Please enter more then 3 digit name');
    }

    if (value && value.length > 30) {
        return Promise.reject('Please enter less then 30 digit name');
    }
    return Promise.resolve();
}

export const validatePhone = async (rule: RuleObject, value: StoreValue) => {
    if (!value) {
        return Promise.reject('Please enter phone');
    }
    if (value && value.length !== 10) {
        return Promise.reject('Please enter 10 digit Number');
    }
    return Promise.resolve();
}

export const validateEmail = async (rule: RuleObject, value: StoreValue) => {
    if (!value) {
        return Promise.reject('Please enter email');
    }
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        return Promise.reject('Please enter valid email');
    }
    return Promise.resolve();
}

export const validateAddressLineOne = async (rule: RuleObject, value: StoreValue) => {
    if (!value) {
        return Promise.reject('Please enter line one');
    }
    return Promise.resolve();
}

export const validateAddressLineTwo = async (rule: RuleObject, value: StoreValue) => {
    return Promise.resolve();
}

export const validateAddressStreet = async (rule: RuleObject, value: StoreValue) => {
    if (!value) {
        return Promise.reject('Please enter street');
    }
    return Promise.resolve();
}


export const validateCity = async (rule: RuleObject, value: StoreValue) => {
    if (!value) {
        return Promise.reject('Please enter city');
    }
    return Promise.resolve();
}

export const validateState = async (rule: RuleObject, value: StoreValue) => {
    if (!value) {
        return Promise.reject('Please enter state');
    }
    return Promise.resolve();
}

export const validatePinCode = async (rule: RuleObject, value: StoreValue) => {
    if (!value) {
        return Promise.reject('Please enter pin code');
    }
    if (value && value.length !== 6) {
        return Promise.reject('Please enter 6 digit Number');
    }
    return Promise.resolve();
}

export const validateScore = async (rule: RuleObject, value: StoreValue) => {
    if (!value) {
        return Promise.reject('Please enter score');
    }
    return Promise.resolve();
}