import { Action } from "../../model/action.model";
import { CityAction } from "./city.action";
import { CityState } from "./city.state";


export const CityReducer = (state: CityState, action: Action): CityState => {
    const { type, payload } = action

    switch (type) {
        case CityAction.UPDATE_LOADING:
            return {
                ...state,
                loading: payload.loading
            };
        case CityAction.ADD_CITY_LIST:
            return {
                ...state,
                cityList: [...state.cityList, ...payload.cityList]
            };
        case CityAction.UPDATE_CITY_LIST:
            return {
                ...state,
                cityList: [...payload.cityList]
            };
        case CityAction.UPDATE_CITY_SEARCH_REQ:
            return {
                ...state,
                citySearchReq: { ...payload.citySearchReq }
            };
        case CityAction.UPDATE_COUNTRY_LIST:
            return {
                ...state,
                countryList: [ ...payload.countryList ]
            };
        default:
            throw new Error(`No case for type ${type} found in CityReducer.`);
    }
}