import { TeamRequest, TeamSearchReq } from "../request/team.request"
import { TeamRes, TeamTypeRes } from "../response/team.response"
import httpClient from "../util/http-client"

export const TeamService = {
    getById: async (id: string): Promise<(TeamRes | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.get(`/team/${id}`)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    saveTeam: async (teamRequest: TeamRequest): Promise<(TeamRes | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/team", teamRequest)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    getTeamList: async (teamSearchReq: TeamSearchReq): Promise<(TeamRes[] | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/team-search", teamSearchReq)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    getTeamTypeList: async (): Promise<(TeamTypeRes[] | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.get("/team-type")
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    updateTeam: async (id: string, teamRequest: TeamRequest): Promise<(TeamRes | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.put(`/team/${id}`, teamRequest)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
}