import { createContext, useReducer } from "react";
import { BuilderState } from "./builder.state";
import { BuilderReducer } from "./builder.reducer";
import { BuilderAction } from "./builder.action";
import { BuilderService } from "../../service/builder.service";
import { BuilderRequest, BuilderSearchReq } from "../../request/builder.request";

const initialState = {
    loading: false,
    builderSearchReq: {
        pageNumber: 1,
        itemsPerPage: 200
    } as BuilderSearchReq,
    builderList: [],
} as BuilderState

export const BuilderContext = createContext<any>(initialState);

export const BuilderStateProvider = ({ children }: any) => {

    const [state, dispatch] = useReducer(BuilderReducer, initialState)

    const updateLoading = (loading: Boolean) => {
        dispatch({
            type: BuilderAction.UPDATE_LOADING,
            payload: {
                loading: loading
            }
        });
    };

    const fetchBuilderList = async (builderSearchReq: BuilderSearchReq, onSuccess: () => void, onFailure: () => void) => {
        updateLoading(true)
        const [builderList, error] = await BuilderService.getBuilderList(builderSearchReq)

        if (error) {
            onFailure()
        } else {
            dispatch({
                type: builderSearchReq.pageNumber == 1 ? BuilderAction.UPDATE_BUILDER_LIST : BuilderAction.ADD_BUILDER_LIST,
                payload: {
                    builderList: builderList
                }
            })
            if (builderList.length > 0) {
                dispatch({
                    type: BuilderAction.UPDATE_BUILDER_SEARCH_REQ,
                    payload: {
                        builderSearchReq: {
                            ...builderSearchReq,
                            pageNumber: builderSearchReq.pageNumber + 1
                        }
                    }
                })
            }
            onSuccess()
        }
    };

    const saveBuilder = async (builderRequest: BuilderRequest, onSuccess: () => void, onFailure: () => void) => {
        const [data, error] = await BuilderService.saveBuilder(builderRequest)

        if (error) {
            onFailure()
        } else {
            onSuccess()
        }
    }

    const updateBuilder = async (id: string, builderRequest: BuilderRequest, onSuccess: () => void, onFailure: () => void) => {
        const [data, error] = await BuilderService.updateBuilder(id, builderRequest)

        if (error) {
            onFailure()
        } else {
            onSuccess()
        }
    }

    const value = {
        builderList: state.builderList,
        loading: state.loading,
        builderSearchReq: state.builderSearchReq,
        updateLoading,
        fetchBuilderList,
        saveBuilder,
        updateBuilder
    };

    return <BuilderContext.Provider value={value}>{children}</BuilderContext.Provider>
};