import { Action } from "../../model/action.model";
import { CountryAction } from "./country.action";
import { CountryState } from "./country.state";


export const CountryReducer = (state: CountryState, action: Action): CountryState => {
    const { type, payload } = action

    switch (type) {
        case CountryAction.UPDATE_LOADING:
            return {
                ...state,
                loading: payload.loading
            };
        case CountryAction.ADD_COUNTRY_LIST:
            return {
                ...state,
                countryList: [...state.countryList, ...payload.countryList]
            };
        case CountryAction.UPDATE_COUNTRY_LIST:
            return {
                ...state,
                countryList: [...payload.countryList]
            };
        case CountryAction.UPDATE_COUNTRY_SEARCH_REQ:
            return {
                ...state,
                countrySearchReq: { ...payload.countrySearchReq }
            };
        default:
            throw new Error(`No case for type ${type} found in CountryReducer.`);
    }
}