import { ArticleRequest, ArticleSearchReq } from "../request/article.request"
import { ArticleRes } from "../response/article.response"
import httpClient from "../util/http-client"

export const ArticleService = {
    saveArticle: async (articleRequest: ArticleRequest): Promise<(ArticleRes|any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/article", articleRequest)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    getArticleList: async (articleSearchReq: ArticleSearchReq): Promise<(ArticleRes[]|any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/article-search", articleSearchReq)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    updateArticle: async (id: string, articleRequest: ArticleRequest): Promise<(ArticleRes|any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.put(`/article/${id}`, articleRequest)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
}