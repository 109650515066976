export enum TaskAction {
    UPDATE_LOADING = "UPDATE_LOADING",
    UPDATE_TASK_LIST = "UPDATE_TASK_LIST",
    UPDATE_TASK = "UPDATE_TASK",
    UPDATE_TASK_SEARCH_REQ = "UPDATE_TASK_SEARCH_REQ",
    ADD_TASK_LIST = "ADD_TASK_LIST",
    SET_UPDATE_TASk = "SET_UPDATE_TASk",
    UPDATE_EMPLOYEE_LIST = "UPDATE_EMPLOYEE_LIST",
    UPDATE_DISCUSSION_LIST = "UPDATE_DISCUSSION_LIST",
    UPDATE_HISTORY_LIST = "UPDATE_HISTORY_LIST"
}