import { useContext } from "react"
import { LocalityContext } from "./locality.context";


export const useLocality = () => {
    const  context = useContext(LocalityContext)
    if (context === undefined){
        throw new Error(`Component is not inside LocalityContext scope`);
    }
    return context
}