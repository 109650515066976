import { useState } from "react";
import { Col, Row, Select, theme } from "antd"
import ImgIcon from "../../../../view/img-icon";
import { TaskService } from "../../../../service/task.service";
import { TaskRequest } from "../../../../request/task.request";
import { handleFilter } from "../../../../view/input.helper";

const StatusListComponent = (props: any) => {

    const { useToken } = theme;
    const { token } = useToken();

    const { task, statusList } = props;
    const [selectedValue, setSelectedValue] = useState(task.status)

    const handleChange = (value: string) => {
        setSelectedValue(value)
        updateStatus(value)
    }

    const updateStatus = async (status: string) => {
        const [data, err] = await TaskService.updateTask(task.id, {
            status: status
        } as TaskRequest)
    }

    return (
        <Row style={{ marginBottom: '20px' }}>
            <Col style={{ display: 'flex', alignItems: "center" }}>
                <ImgIcon iconName="status.svg" iconWidth="18px" />
                Status
            </Col>
            <Col style={{ marginLeft: 'auto' }}>
                <div style={{border: '1px solid #92B5D7', borderRadius: token.borderRadiusLG, display: 'flex', padding: '2px 10px', width: '175px'}}>
                    <span style={{ textTransform: 'capitalize', display: 'flex' }}>
                        {selectedValue.toLowerCase() === 'close' &&
                            <ImgIcon iconName="close.svg" iconClass="d-flex" />
                        }
                        {selectedValue.toLowerCase() === 'open' &&
                            <ImgIcon iconName="open.svg" iconClass="d-flex" />
                        }
                        {selectedValue.toLowerCase() === 'in progress' &&
                            <ImgIcon iconName="inprogress.svg" iconClass="d-flex" />
                        }
                        {selectedValue.toLowerCase() === 'rejected' &&
                            <ImgIcon iconName="rejected.svg" iconClass="d-flex" />
                        }
                    </span>

                    <Select
                        showSearch={true}
                        defaultValue={selectedValue}
                        style={{ textTransform: 'capitalize', width: '100%', textOverflow: 'ellipsis' }}
                        className="custom-select task-details-select"
                        onChange={handleChange}
                        options={statusList}
                        filterOption={handleFilter}
                    />
                </div>



            </Col>
        </Row>
    )
}

export default StatusListComponent