import { Button, Space, Spin, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { LocalityRes } from '../../../response/locality.response';
import { useLocality } from '../locality.hook';
import { AddLocalityComponent } from './add-locality.component';
import { UpdateLocalityComponent } from './update-locality.component';
import { LocalityFilterComponent } from './filter.component';

export const MainLocalityTableComponent = () => {
    const { loading, localityList, fetchLocalityList, localitySearchReq, updateLoading } = useLocality()
    const [addNewLocality, setOpenNewLocality] = useState(false)
    const [updateLocality, setUpdateLocality] = useState<LocalityRes | undefined>(undefined)

    const cancelLocalityEdit = () => {
        setUpdateLocality(prevVal => undefined);
    }

    const updateOpenNewLocality = () => {
        if (addNewLocality) {
            fetchLocalityList({ ...localitySearchReq, pageNumber: 1 }, () => { updateLoading(false) }, () => { updateLoading(false) })
        }
        setOpenNewLocality(prevVal => !prevVal);
    }

    const columnList: ColumnsType<LocalityRes> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Pin Code List',
            dataIndex: 'pinCodeList',
            key: 'pinCodeList',
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            onCell: (locality, rowIndex) => {
                return {
                    onClick: (ev) => {
                        setUpdateLocality(locality)
                    },
                };
            },
            render: (id) => <a>Edit</a>,
        },
    ];

    useEffect(() => {
        fetchLocalityList({ ...localitySearchReq, pageNumber: 1 }, () => { updateLoading(false) }, () => { updateLoading(false) })
    }, [])

    return <div style={{ marginTop: -42 }}>
        <Space direction="horizontal" style={{ width: '100%', justifyContent: 'end', marginBottom: 8 }}>
            <LocalityFilterComponent />
            <Button type="primary" onClick={updateOpenNewLocality}>Add New</Button>
        </Space>
        <Table
            className='task-main-table task-table'
            columns={columnList} dataSource={localityList}
            pagination={{ defaultPageSize: 30, showSizeChanger: true, pageSizeOptions: ['20', '50', '100', '200', '300', '400', '500'] }}
            loading={{ indicator: <div><Spin /></div>, spinning: loading }} />
        {addNewLocality &&
            <AddLocalityComponent open={addNewLocality} onCancel={updateOpenNewLocality} />
        }

        {updateLocality &&
            <UpdateLocalityComponent locality={updateLocality} open={true} onCancel={cancelLocalityEdit} />
        }
    </div>
}