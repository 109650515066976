import { Card, Row, Col, Typography } from "antd"
import EnquireChartComponent from "../../../dashboard/component/genericComponents/enquireDetails/enquire-chart.component"
import { EnquiryReportRes } from "../../../../response/enquiry.response"
import LeadChartComponent from "../../../dashboard/component/genericComponents/leadDetails/lead-chart.component"
import { LeadReportRes } from "../../../../response/lead.response"

export interface ReportTaskEnquiryComponentProp {
    leadReportResList: LeadReportRes[]
    enquiryReportList: EnquiryReportRes[],
    filterData: any
}

const ReportTaskEnquiryComponent = (props: ReportTaskEnquiryComponentProp) => {

    const { leadReportResList, enquiryReportList, filterData } = props

    const { Title, Text } = Typography;

    return (
        <Card style={{
            height: '100%',
            padding: '10px 20px',
            width: '100%',
            marginBottom: 10
        }}
            headStyle={{
                padding: '10px 0 0',
                minHeight: 44,
                fontSize: 14
            }}
            bodyStyle={{
                padding: '10px 0'
            }}
            bordered={false}
            className="profile-wrapper p-mweb-card"
        >

            <Row className="flex-flow-css">
                <Col lg={12} xs={24}>
                    <div style={{
                        marginRight: 25,
                        marginBottom: 40
                    }}>
                        <div style={{
                            borderBottom: '1px solid #f0f0f0',
                            display: 'flex',
                            marginBottom: 20
                        }}>
                            <Title style={{
                                fontSize: 14,
                                paddingBottom: 6,
                                color: '#000000',
                                margin: 0
                            }}>Lead Chart</Title>
                            <Text style={{
                                marginLeft: 'auto',
                                color: '#6D6C68',
                                fontWeight: 500
                            }}>Total: {leadReportResList.reduce((accumulator: number, leadReportRes: LeadReportRes) => accumulator + leadReportRes.count, 0)} lead</Text>
                        </div>

                        <LeadChartComponent leadReportResList={leadReportResList} filterData={filterData} />
                    </div>
                </Col>
                <Col lg={12} xs={24}>
                    <div style={{
                        marginLeft: 25,
                        marginBottom: 40
                    }}
                        className="margin-l-0"
                    >
                        <div style={{
                            borderBottom: '1px solid #f0f0f0',
                            display: 'flex',
                            marginBottom: 20
                        }}>
                            <Title style={{
                                fontSize: 14,
                                paddingBottom: 6,
                                color: '#000000',
                                margin: 0
                            }}>Enquiry Chart</Title>
                            <Text style={{
                                marginLeft: 'auto',
                                color: '#6D6C68',
                                fontWeight: 500
                            }}>Total: {enquiryReportList.reduce((accumulator: number, enquiryReportRes: EnquiryReportRes) => accumulator + enquiryReportRes.count, 0)} enquiry</Text>
                        </div>

                        <EnquireChartComponent enquiryReportResList={enquiryReportList} filterData={filterData} />
                    </div>
                </Col>

            </Row>

        </Card>
    )
}

export default ReportTaskEnquiryComponent