import { Typography, Card, Row, Col } from "antd";
import { TaskReportRes } from "../../../../response/task.response";
import TaskChartComponent from "../../../dashboard/component/genericComponents/taskDetails/task-chart.component";

export interface ReportLeadComponentProps {
    taskReportList: TaskReportRes[]
    filterData: any
}

const ReportLeadComponent = (props: ReportLeadComponentProps) => {

    const { taskReportList, filterData } = props

    const { Title, Text } = Typography;


    return (
        <Card style={{
            height: '100%',
            padding: '10px 20px',
            width: '100%',
            marginBottom: 10
        }}
            headStyle={{
                padding: '10px 0 0',
                minHeight: 44,
                fontSize: 14
            }}
            bodyStyle={{
                padding: '10px 0'
            }}
            bordered={false}
            className="profile-wrapper p-mweb-card"
        >

            <Row style={{
                marginBottom: 40,
                flexFlow: 'row'
            }}>
                <Col xs={24}>
                    <div style={{
                        borderBottom: '1px solid #f0f0f0',
                        display: 'flex',
                        marginBottom: 20
                    }}>
                        <Title style={{
                            fontSize: 14,
                            paddingBottom: 6,
                            color: '#000000',
                            margin: 0
                        }}>Task Chart</Title>
                        <Text style={{
                            marginLeft: 'auto',
                            color: '#6D6C68',
                            fontWeight: 500
                        }}>Total: {taskReportList.reduce((accumulator: number, taskReportRes: TaskReportRes) => accumulator + taskReportRes.count, 0)} tasks</Text>
                    </div>

                    <TaskChartComponent taskReportList={taskReportList} filterData={filterData}/>
                </Col>
            </Row>


        </Card>
    )
}

export default ReportLeadComponent