import { Button, Col, Tag, theme, Typography } from "antd"
import { useState } from "react";
import { Link } from "react-router-dom";
import { formatName } from "../../../../../util/name-helper";

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const TableCardColumnComponent = (props: any) => {

    const { lead } = props

    const { useToken } = theme;
    const { token } = useToken();
    const { Text } = Typography;

    const [isAllDetailShow, setIsAllDetailShow] = useState(false)

    const showAllDetails = () => {
        setIsAllDetailShow(true)
    }

    return (
        <>
            <Col span={8} style={{
                display: 'flex',
                flexDirection: 'column',
                padding: 10
            }}>
                {formatName(lead.client.name)}
            </Col>
            <Col span={8} style={{
                display: 'flex',
                flexDirection: 'column',
                padding: 10
            }}>
                <span style={{
                    marginBottom: (isAllDetailShow ? 10 : ''),
                    height: (isAllDetailShow ? 'auto' : 48),
                    overflow: 'auto'
                }} >{lead.requirement}</span>

                {!isAllDetailShow &&
                    <Button type="link" style={{
                        color: '#343433',
                        padding: 0,
                        borderRadius: 0,
                        height: 'auto',
                        textAlign: 'left'
                    }}
                        onClick={showAllDetails}
                    >
                        <span style={{ textDecoration: 'underline' }}>see all</span>
                    </Button>
                }


                {isAllDetailShow &&
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: 'auto'
                    }}
                    >
                        <Text style={{ color: '#000', fontWeight: 500 }}>Project</Text>
                        <Text style={{ color: '#6D6C68' }}>{lead.project.name}</Text>
                    </div>
                }


            </Col>
            <Col span={8} style={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                padding: 10
            }}>
                <div>
                    <Tag key={"sitevisit"} style={{ border: '1px solid #EC0707', color: '#EC0707', borderRadius: token.borderRadius, marginBottom: 15, marginRight: 0 }}>
                        {lead.currentNote.type}
                    </Tag>
                </div>

                {/* <a style={{ textDecoration: 'underline' }} >Call</a> */}
                <Link to={`/lead/${lead.id}`} target={isMobile ? "" : "_blank"} rel={isMobile ? "" : "noopener noreferrer"}>Detail</Link>

                {isAllDetailShow &&
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: 'auto'
                    }}
                    >
                        <Text style={{ color: '#000', fontWeight: 500 }}>Next date</Text>
                        <Text style={{ color: '#6D6C68' }}>{new Date(lead.currentNote.dueDate).toLocaleDateString('en', { year: 'numeric', month: 'long', day: 'numeric' })}</Text>
                    </div>
                }

            </Col>
        </>
    )
}

export default TableCardColumnComponent