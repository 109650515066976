import { Action } from "../../model/action.model";
import { UserAction } from "./user.action";
import { UserState } from "./user.state";


export const UserReducer = (state: UserState, action: Action): UserState => {
    const { type, payload } = action

    switch (type) {
        case UserAction.UPDATE_LOADING:
            return {
                ...state,
                loading: payload.loading
            };
        case UserAction.ADD_USER_LIST:
            return {
                ...state,
                userList: [...state.userList, ...payload.userList]
            };
        case UserAction.UPDATE_USER_LIST:
            return {
                ...state,
                userList: [...payload.userList]
            };
        case UserAction.UPDATE_USER_SEARCH_REQ:
            return {
                ...state,
                userSearchReq: { ...payload.userSearchReq }
            };
        case UserAction.SET_UPDATE_USER:
            return {
                ...state,
                updateUserData: { ...payload.updateUserData }
            };
        default:
            throw new Error(`No case for type ${type} found in UserReducer.`);
    }
}