import { Action } from "../../model/action.model";
import { BuilderAction } from "./builder.action";
import { BuilderState } from "./builder.state";


export const BuilderReducer = (state: BuilderState, action: Action): BuilderState => {
    const { type, payload } = action

    switch (type) {
        case BuilderAction.UPDATE_LOADING:
            return {
                ...state,
                loading: payload.loading
            };
        case BuilderAction.ADD_BUILDER_LIST:
            return {
                ...state,
                builderList: [...state.builderList, ...payload.builderList]
            };
        case BuilderAction.UPDATE_BUILDER_LIST:
            return {
                ...state,
                builderList: [...payload.builderList]
            };
        case BuilderAction.UPDATE_BUILDER_SEARCH_REQ:
            return {
                ...state,
                builderSearchReq: { ...payload.builderSearchReq }
            };
        default:
            throw new Error(`No case for type ${type} found in BuilderReducer.`);
    }
}