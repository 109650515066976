import React, { useEffect } from 'react';
import { Form, Select, Button } from 'antd';
import { CallSearchReq } from '../../../request/call.request';
import { useCall } from '../call.hook';
import { ItemProp } from '../../../model/item-prop.model';
import { handleFilter } from '../../../view/input.helper';
import { formatName } from '../../../util/name-helper';
import { EmployeeRes } from '../../../response/employee.response';

export const CallFilterComponent: React.FC = () => {
    const [form] = Form.useForm();
    const { fetchCallList, updateLoading, callSearchReq,
        fetchEmployeeList,
        statusList, employeeList } = useCall()

    const handleOk = () => {
        form.validateFields().then(values => {
            updateLoading(true);
            const _callSearchReq = {
                ...callSearchReq,
                callerIdList: values["employeeIdList"],
                statusList: values["statusList"],
                pageNumber: 1,
                itemsPerPage: 100
            } as CallSearchReq
            fetchCallList(_callSearchReq, onSuccess, onFailure)
        });
    };

    useEffect(() => {
        fetchEmployeeList()
    }, [])

    const onSuccess = () => {
        updateLoading(false);
    }

    const onFailure = () => {
        updateLoading(false);
    }

    return (
        <Form form={form} layout="inline">
            <Form.Item
                name="statusList"
                style={{ minWidth: 124 }}
                initialValue={callSearchReq.officeIdList}
            >
                <Select
                    allowClear
                    mode="multiple"
                    value='statusList'
                    placeholder="Status"
                    maxTagCount='responsive'
                    options={statusList}
                    filterOption={handleFilter} />
            </Form.Item>
            <Form.Item
                name="employeeIdList"
                style={{ minWidth: 124 }}
                initialValue={callSearchReq.callerIdList}
            >
                <Select
                    allowClear
                    mode="multiple"
                    value='employeeIdList'
                    maxTagCount='responsive'
                    options={employeeList.map((employeeRes: EmployeeRes) => {
                        return {
                            label: `${formatName(employeeRes.userInfo.name)}`,
                            value: employeeRes.id
                        } as ItemProp
                    })}
                    placeholder="Employee"
                    filterOption={handleFilter} />
            </Form.Item>
            

            <Form.Item style={{ minWidth: 124 }}>
                <Button type="primary" htmlType="submit"
                    onClick={handleOk}>
                    Search
                </Button>
            </Form.Item>

        </Form>
    );
}
