import { CountryStateProvider } from './country.context';
import { MainCountryTableComponent } from './component/table.component';

const CountryPageBase = () => {

  return <>
    <MainCountryTableComponent />
  </>
}

export const CountryPage = () => {
  return <CountryStateProvider>
    <CountryPageBase />
  </CountryStateProvider>
}

