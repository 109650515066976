import { createContext, useReducer } from "react";
import { TaskAction } from "./task.action";
import { TaskReducer } from "./task.reducer";
import { TaskService } from "../../service/task.service";
import { TaskState } from "./task.state";
import { TaskRes } from "../../response/task.response";
import { TaskRequest, TaskSearchReq } from "../../request/task.request";
import { EmployeeService } from "../../service/employee.service";
import { TaskDiscussionRequest, TaskDiscussionSearchReq } from "../../request/task-discussion.request";
import { TaskHistorySearchReq } from "../../request/task-history.request";
import { EmployeeSearchReq } from "../../request/employee.request";
import { SecurityManager } from "../../security/security-manager";


const _priorityList = [
    { label: "Lowest", value: "Lowest" },
    { label: "Low", value: "Low" },
    { label: "Medium", value: "Medium" },
    { label: "High", value: "High" },
    { label: "Highest", value: "Highest" }
]


const _statusList = [
    { label: "Pending", value: "Pending" },
    { label: "In Progress", value: "In Progress" },
    { label: "Rejected", value: "Rejected" },
    { label: "Closed", value: "Closed" },
]

const initialState = {
    loading: false,
    taskSearchReq: {
        assigneeIdList: [] as string[],
        pageNumber: 1,
        itemsPerPage: 100
    } as TaskSearchReq,
    taskList: [],
    currentTask: null,
    filterList: [],
    employeeList: [],
    employeeSearchReq: {
        pageNumber: 1,
        itemsPerPage: 200
    } as EmployeeSearchReq,
    updateTaskData: {} as TaskRes,
    priorityList: _priorityList,
    statusList: _statusList,
    discussionList: [],
    taskDiscussionSearchReq: {} as TaskDiscussionSearchReq,
    historyList: [],
    taskHistorySearchReq: {} as TaskHistorySearchReq,
    task: {} as TaskRes
} as TaskState



export const TaskContext = createContext<any>(initialState);

export const TaskStateProvider = ({ children }: any) => {

    const [state, dispatch] = useReducer(TaskReducer, initialState)

    const fetchTaskList = async (taskSearchReq: TaskSearchReq, onSuccess: () => void, onFailure: () => void) => {
        const [taskList, error] = await TaskService.getTaskList(taskSearchReq);
        if (error) {
            onFailure()
        } else {
            dispatch({
                type: taskSearchReq.pageNumber == 1 ? TaskAction.UPDATE_TASK_LIST : TaskAction.ADD_TASK_LIST,
                payload: {
                    taskList: taskList
                }
            })
            if (taskList.length > 0) {
                dispatch({
                    type: TaskAction.UPDATE_TASK_SEARCH_REQ,
                    payload: {
                        taskSearchReq: {
                            ...taskSearchReq,
                            pageNumber: taskSearchReq.pageNumber + 1
                        }
                    }
                })
            }
            onSuccess()
        }
    }

    const fetchTask = async (id: string, onSuccess: () => void, onFailure: () => void) => {
        const [task, error] = await TaskService.getTaskById(id)

        if (error) {
            onFailure()
        } else {
            dispatch({
                type: TaskAction.UPDATE_TASK,
                payload: {
                    task: {
                        ...task
                    }
                }
            })
            onSuccess()
        }
    };

    const saveTask = async (taskRequest: TaskRequest, onSuccess: () => void, onFailure: () => void) => {
        const [data, error] = await TaskService.saveTask(taskRequest)

        if (error) {
            onFailure()
        } else {
            onSuccess()
        }
    };

    const updateTask = async (id: string, taskRequest: TaskRequest, onSuccess: () => void, onFailure: () => void) => {
        const [data, error] = await TaskService.updateTask(id, taskRequest)

        if (error) {
            onFailure()
        } else {
            onSuccess()
        }
    };

    const updateLoading = (loading: Boolean) => {
        dispatch({
            type: TaskAction.UPDATE_LOADING,
            payload: {
                loading: loading
            }
        })
    };

    const fetchEmployeeList = async () => {
        const [data, error] = await EmployeeService.getEmployeeList(state.employeeSearchReq)
        if (error) {

        } else {
            dispatch({
                type: TaskAction.UPDATE_EMPLOYEE_LIST,
                payload: {
                    employeeList: data
                }
            });
        }
    };

    const saveDiscussion = async (taskDiscussionRequest: TaskDiscussionRequest, onSuccess: () => void, onFailure: () => void) => {
        const [data, error] = await TaskService.saveDiscussion(taskDiscussionRequest)

        if (error) {
            onFailure()
        } else {
            onSuccess()
        }
    };

    const fetchDiscussionList = async (taskId: string) => {

        const [data, error] = await TaskService.getTaskDiscussionList({
            ...state.taskDiscussionSearchReq,
            taskId: taskId
        })
        if (error) {

        } else {
            dispatch({
                type: TaskAction.UPDATE_DISCUSSION_LIST,
                payload: {
                    discussionList: data
                }
            });
        }
    };


    const fetchTaskHistoryList = async (taskId: string) => {
        const [data, error] = await TaskService.getTaskHistoryList({
            ...state.taskHistorySearchReq,
            taskId: taskId
        })
        if (error) {

        } else {
            dispatch({
                type: TaskAction.UPDATE_HISTORY_LIST,
                payload: {
                    historyList: data
                }
            });
        }
    };

    const value = {
        taskList: state.taskList,
        loading: state.loading,
        employeeList: state.employeeList,
        priorityList: state.priorityList,
        statusList: state.statusList,
        discussionList: state.discussionList,
        historyList: state.historyList,
        taskSearchReq: state.taskSearchReq,
        task: state.task,
        updateLoading,
        fetchTaskList,
        saveTask,
        fetchEmployeeList,
        updateTask,
        fetchDiscussionList,
        fetchTaskHistoryList,
        saveDiscussion,
        fetchTask
    };

    return <TaskContext.Provider value={value}>{children}</TaskContext.Provider>
};



