import React from 'react';
import { Form, Input, Button } from 'antd';
import { ArticleSearchReq } from '../../../request/article.request';
import { useArticle } from '../article.hook';

export const ArticleFilterComponent: React.FC = () => {
    const [form] = Form.useForm();
    const { fetchArticleList, updateLoading, articleSearchReq  } = useArticle()

    const handleOk = () => {
        form.validateFields().then(values => {
            updateLoading(true);
            const _articleSearchReq = {
                ...articleSearchReq,
                name: values["name"],
                pageNumber: 1,
                itemsPerPage: 10
            } as ArticleSearchReq
            fetchArticleList(_articleSearchReq, onSuccess, onFailure)
        });
    };

    const onSuccess = () => {
        updateLoading(false);
    }

    const onFailure = () => {
        updateLoading(false);
    }

    return (
        <Form form={form} layout="inline">
            <Form.Item
                name="name"
                style={{ minWidth: 124 }}
                initialValue={articleSearchReq.name}
            >
                <Input placeholder='Name' />
            </Form.Item>

            <Form.Item style={{ minWidth: 124 }}>
                <Button type="primary" htmlType="submit"
                    onClick={handleOk}>
                    Search
                </Button>
            </Form.Item>
        </Form>
    );
}
