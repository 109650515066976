import React from "react"
import { Row, Col, Tag, Button } from "antd"
import ImgIcon from "../../../../view/img-icon"
import { EnquiryRes } from "../../../../response/enquiry.response"
import dayjs from "dayjs"
import { Link } from "react-router-dom"
import { EnquiryNoteService } from "../../../../service/enquiry-note.service"
import { EnquiryNoteCallReq } from "../../../../request/enquiry-note.request"
import { formatName } from "../../../../util/name-helper"

export interface EnquireRowDetailItemProp {
    enquiry: EnquiryRes
    lastItem: boolean
    addLink: boolean
}

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

export const EnquireRowReadItemBase: React.FC<EnquireRowDetailItemProp> = ({ enquiry, lastItem, addLink }) => {

    const callClient = async () => {
        const [data, err] = await EnquiryNoteService.connectCall({
            id: enquiry.currentNote.id,
            enquiryId: enquiry.id
        } as EnquiryNoteCallReq)

        if (err) {
            alert(err.response.data.message)
        } else {
            alert("Please pick call in your phone")
        }
    }

    return (
        <React.Fragment>
            <div style={{
                borderBottom: lastItem ? 'none' : '1px solid #C6C6C6',
                marginBottom: lastItem ? 0 : 5,
                marginTop: 10
            }}>
                <Row style={{ marginBottom: 8 }}>
                    <Col style={{
                        marginRight: 20,
                        color: '#6D6C68'
                    }}
                        flex="85px"
                    >
                        {addLink == true ?
                            <Link to={`/enquiry/${enquiry.id}`} target={isMobile ? "" : "_blank"} rel={isMobile ? "" : "noopener noreferrer"}>#{enquiry.id}
                            </Link> : <Link to={"#"}>#{enquiry.id}
                            </Link>
                        }
                    </Col>
                </Row>
                <Row style={{ marginBottom: 8 }}>
                    <Col style={{
                        marginRight: 20,
                        color: '#6D6C68'
                    }}
                        flex="85px"
                    >Name</Col>
                    <Col style={{
                        color: '#000000'
                    }}>{formatName(enquiry.client.name)}</Col>
                </Row>
                <Row style={{ marginBottom: 8 }}>
                    <Col style={{
                        marginRight: 20,
                        color: '#6D6C68'
                    }}
                        flex="85px"
                    >Assigned to</Col>
                    <Col style={{
                        color: '#000000'
                    }}>{formatName(enquiry.owner.assignedTo.name)}</Col>
                </Row>
                <Row style={{ marginBottom: 8 }}>
                    <Col style={{
                        marginRight: 20,
                        color: '#6D6C68'
                    }}
                        flex="85px"
                    >Project</Col>
                    <Col style={{
                        color: '#000000'
                    }}>{enquiry.project.name}</Col>
                </Row>

                <Row style={{ marginBottom: 8 }}>
                    <Col style={{
                        marginRight: 20,
                        color: '#6D6C68'
                    }}
                        flex="85px"
                    >Source</Col>
                    <Col style={{
                        color: '#000000'
                    }}>{enquiry.source}</Col>
                </Row>

                <Row style={{ marginBottom: 8 }}>
                    {/* <Col style={{
                        marginRight: 20,
                        color: '#6D6C68'
                    }}
                        flex="85px"
                    >Action</Col> */}
                    <Col style={{
                        color: '#000000',
                        flexGrow: 1

                    }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'baseline'
                        }}>
                            <Tag color="magenta">{enquiry.currentNote.type}</Tag>
                            <span style={{ color: dayjs(enquiry.currentNote.dueDate).diff(dayjs()) < 60 * 60 * 1000 ? "red" : "" }}>on {`${dayjs(enquiry.currentNote.dueDate).format('DD MMMM YYYY, hh:mm A')}`}</span>
                            <Button type="default" style={{
                                marginLeft: 'auto',
                                border: '1px solid #6D6C68',
                                borderRadius: '4px',
                                padding: '4px 10px'
                            }}
                                onClick={callClient}>
                                <ImgIcon iconName="phone.svg" iconClass="m-0" />
                            </Button>
                        </div>
                    </Col>
                </Row>

                <Row style={{ marginBottom: 8 }}>
                    <Col style={{
                        marginRight: 20,
                        color: '#6D6C68'
                    }}
                    >Requirements</Col>
                    <Col style={{
                        color: '#000000',
                        fontSize: '12px'
                    }}>{enquiry.requirement}</Col>
                </Row>
                <Row style={{ marginBottom: 8 }}>
                    <Col style={{
                        marginRight: 20,
                        color: '#6D6C68'
                    }}
                        flex="85px"
                    >Assigned On</Col>
                    <Col style={{
                        color: '#000000'
                    }}>{`${dayjs(enquiry.owner.assignedAt).format('DD MMMM YYYY, hh:mm A')}`}</Col>
                </Row>
                <Row style={{ marginBottom: 8 }}>
                    <Col style={{
                        marginRight: 20,
                        color: '#6D6C68'
                    }}
                    >Status</Col>
                    <Col style={{
                        color: '#000000',
                        fontSize: '12px'
                    }}>{enquiry.status.value} | {enquiry.status.note} | {enquiry.status.description}</Col>
                </Row>
            </div>

        </React.Fragment>
    )
}

export const EnquireRowReadItem = React.memo(EnquireRowReadItemBase);