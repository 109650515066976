import { Col, DatePicker, Form, Input, Row, Select, theme, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import { isMobile } from '../../../../../util/device-helper';
import { ItemProp } from '../../../../../model/item-prop.model';
import { ProjectRes } from '../../../../../response/project.response';
import { handleFilter } from '../../../../../view/input.helper';

const paymentPlanList = [{
    label: "Plan one",
    value: "Plan one"
},
{
    label: "Plan Two",
    value: "Plan Two"
}]

const OtherDetailComponent = (props: any) => {

    const {projectList} = props

    const { useToken } = theme;
    const { token } = useToken();


    return <Row style={{ marginBottom: '10px' }}>
        <Col lg={6} md={12} xs={24} style={{ paddingRight: '20px', marginBottom: '20px' }}>
            <Typography.Title level={5} style={{
                fontSize: '14px'
            }}>Booking Date</Typography.Title>
            <Form.Item
                name="bookingdate"
                style={{
                    margin: 0
                }}
                rules={[{ required: false }]}
                initialValue={dayjs(dayjs())}
            >
                <DatePicker
                    inputReadOnly={isMobile()} style={{
                        border: '1px solid #92B5D7',
                        width: '70%',
                        borderRadius: token.borderRadius,
                        boxShadow: 'none',
                        height: '40px'
                    }}
                    placeholder="Select date" />

            </Form.Item>
        </Col>

        <Col lg={6} md={12} xs={24} style={{ paddingRight: '20px', marginBottom: '20px' }}>
            <Typography.Title level={5} style={{
                fontSize: '14px',
                fontWeight: 500
            }}>Project</Typography.Title>

            <Form.Item
                name="projectId"
                rules={[{ required: true, message: 'Select Project' }]}
            >
                <Select
                    showSearch={true}
                    value='projectId'
                    options={projectList.map((projectRes: ProjectRes) => {
                        return {
                            label: `${projectRes.name}`,
                            value: projectRes.id
                        } as ItemProp
                    })}
                    filterOption={handleFilter} />
            </Form.Item>
        </Col>

        <Col lg={6} md={12} xs={24} style={{ paddingRight: '20px', marginBottom: '20px' }}>
            <Typography.Title level={5} style={{
                fontSize: '14px',
                fontWeight: 500
            }}>Payment Plan</Typography.Title>
            <Form.Item
                style={{
                    margin: 0
                }}
                name="paymentPlan"
                label=""
                rules={[{ required: true, message: 'Please Enter Payment Plan' }]}
            >
                <Select
                    value='paymentPlan'
                    options={paymentPlanList} />
            </Form.Item>
        </Col>
        <Col lg={18} md={24} xs={24} style={{ paddingRight: '20px', marginBottom: '20px' }}>
            <Typography.Title level={5} style={{
                fontSize: '14px',
                fontWeight: 500
            }}>Remarks</Typography.Title>
            <Form.Item
                style={{
                    margin: 0
                }}
                name="remark"
                label=""
            >
                <TextArea value="remark" placeholder='Write something' rows={4} style={{
                    border: '1px solid #92B5D7',
                    borderRadius: token.borderRadiusSM
                }} />
            </Form.Item>
        </Col>

    </Row>
}

export default OtherDetailComponent