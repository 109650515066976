import { Breadcrumb } from "antd"
import "../../employee.css"
import EmpDetailDWebComponent from "./emp-detail-dweb.component"
import EmpDetailMWebComponent from "./emp-detail-mweb.component"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { EnquiryReportReq } from "../../../../request/enquiry.request"
import { LeadReportReq } from "../../../../request/lead.request"
import { TaskReportReq } from "../../../../request/task.request"
import { EnquiryService } from "../../../../service/enquiry.service"
import { LeadService } from "../../../../service/lead.service"
import { TaskService } from "../../../../service/task.service"
import { LeadReportRes } from "../../../../response/lead.response"
import { EnquiryReportRes } from "../../../../response/enquiry.response"
import { TaskReportRes } from "../../../../response/task.response"
import { EmployeeRes } from "../../../../response/employee.response"
import { EmployeeService } from "../../../../service/employee.service"


const EmployeeDetailsComponent = () => {

    const navigate = useNavigate()

    let { id } = useParams()

    const [employeeRes, setEmployeeRes] = useState<EmployeeRes>()

    const [filterData, setFilterData] = useState<any>({})

    const [leadReportResList, setLeadReportResList] = useState<LeadReportRes[]>([])
    const [enquiryReportResList, setEnquiryReportResList] = useState<EnquiryReportRes[]>([])
    const [taskReportList, setTaskReportList] = useState<TaskReportRes[]>([])

    const fetchEmployeeReport = async (employeeId: string) => {
        try {
            const [employeeRes, error] = await EmployeeService.getById(employeeId)
            if (!error) {
                setEmployeeRes({ ...employeeRes })
            }
        } catch (err: any) {

        }
        try {
            const [leadReportResList, error] = await LeadService.getReport({
                assigneeIdList: [employeeId]
            } as LeadReportReq)
            if (!error) {
                setLeadReportResList([...leadReportResList])
            }
        } catch (err: any) {

        }

        try {
            const [enquiryReportResList, error2] = await EnquiryService.getReport({
                assigneeIdList: [employeeId]
            } as EnquiryReportReq)
            if (!error2) {
                setEnquiryReportResList([...enquiryReportResList])
            }
        } catch (err: any) {

        }
        try {
            const [taskReportList, error3] = await TaskService.getReport({
                assigneeIdList: [employeeId]
            } as TaskReportReq)
            if (!error3) {
                setTaskReportList([...taskReportList])
            }
        } catch (err: any) {

        }

        setFilterData({
            assigneeIdList: [employeeId]
        })
    };


    useEffect(() => {
        if (id) {
            fetchEmployeeReport(id)
        }
    }, [])

    return (
        <div className="section-wrapper">
            <Breadcrumb
                className="breadcrum-section"
                items={[
                    {
                        onClick: () => {
                            navigate(`/employee/my-team`)
                        },
                        title: (<span>Employee Details</span>),
                    },
                    {
                        title: employeeRes?.employeeId ? `RB${String(employeeRes.employeeId).padStart(3, '0')}` : "",
                    },
                ]}
            />


            <section className="desktop-view" style={{ margin: '20px 0' }} >
                <EmpDetailDWebComponent
                    employeeRes={employeeRes}
                    leadReportResList={leadReportResList}
                    enquiryReportResList={enquiryReportResList}
                    taskReportList={taskReportList}
                    employeeId={id ? id : ""}
                    filterData={filterData} />
            </section>
            <section className="mweb-view emp-section-view">
                <EmpDetailMWebComponent
                    employeeRes={employeeRes}
                    leadReportResList={leadReportResList}
                    enquiryReportResList={enquiryReportResList}
                    taskReportList={taskReportList}
                    employeeId={id ? id : ""}
                    filterData={filterData}
                />
            </section>
        </div>
    )
}

export default EmployeeDetailsComponent