import { Action } from "../../model/action.model";
import { ProjectAction } from "./project.action";
import { ProjectState } from "./project.state";

export const ProjectReducer = (state: ProjectState, action: Action): ProjectState => {
    const { type, payload } = action

    switch (type) {
        case ProjectAction.ADD_PROJECT_LIST:
            return {
                ...state,
                projectList: [...state.projectList, ...payload.projectList]
            };
        case ProjectAction.UPDATE_PROJECT_LIST:
            return {
                ...state,
                projectList: [...payload.projectList]
            };
        case ProjectAction.UPDATE_LOADING:
            return {
                ...state,
                loading: payload.loading
            };
        case ProjectAction.UPDATE_PROJECT_SEARCH_REQ:
            return {
                ...state,
                projectSearchReq: { ...payload.projectSearchReq }
            };
        case ProjectAction.UPDATE_EMPLOYEE_LIST:
            return {
                ...state,
                employeeList: [...payload.employeeList]
            };
        case ProjectAction.UPDATE_BUILDER_LIST:
            return {
                ...state,
                builderList: [...payload.builderList]
            };
        case ProjectAction.UPDATE_LOCALITY_LIST:
            return {
                ...state,
                localityList: [...payload.localityList]
            };
            case ProjectAction.UPDATE_AMENITIES_LIST:
                return {
                    ...state,
                    amenitiesList: [...payload.amenitiesList]
                };
        default:
            throw new Error(`No case for type ${type} found in ProjectReducer.`);
    }
}