import { AuthTokenReq, SendOtpReq, ValidateOtpReq } from "../request/auth.request"
import { AuthTokenRes, SendOtpRes, ValidateOtpRes } from "../response/auth.response"
import { SecurityManager } from "../security/security-manager"
import httpClient from "../util/http-client"

export const AuthService = {
    sendOtp: async (sendOtpReq: SendOtpReq): Promise<(SendOtpRes | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/send-otp", sendOtpReq)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    validateOtp: async (validateOtpReq: ValidateOtpReq): Promise<(ValidateOtpRes | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/validate-otp", validateOtpReq)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    getAuthToken: async (): Promise<(AuthTokenRes | any)[]> => {
        let data
        let err
        try {
            const authTokenReq = {
                userId: SecurityManager.getUid(),
                refreshToken: SecurityManager.getRefreshToken()
            } as AuthTokenReq
            const res = await httpClient.post("/auth-token", authTokenReq)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    logOut: async (): Promise<any[]> => {
        let data
        let err
        try {
            const authTokenReq = {
                userId: SecurityManager.getUid(),
                refreshToken: SecurityManager.getRefreshToken(),
                authToken: SecurityManager.getAuthToken()
            } as AuthTokenReq
            const res = await httpClient.post("/log-out", authTokenReq)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
}