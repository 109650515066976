import React from 'react';
import { Card, Typography, Avatar } from 'antd';
import { TaskRes } from '../../../response/task.response';
import ImgIcon from '../../../view/img-icon';
import { formatName } from '../../../util/name-helper';
import { useNavigate } from 'react-router-dom';

const techCardStyle = {
    backgroundColor: 'rgb(0 22 40 / 5%)',
    color: '#000000',
    marginBottom: 8
};

export interface TaskItemComponentProps {
    task: TaskRes
}

export const TaskItemComponent: React.FC<TaskItemComponentProps> = ({ task }) => {
    const navigate = useNavigate();
    
    return <Card
        bodyStyle={{
            padding: "8px 12px 0px 12px"
        }}
        headStyle={{
            padding: "0px 8px 0px 8px"
        }} title={<a onClick={() => {
            navigate(`/task/${task.id}`)
        }} style={{ borderBottom: '1px solid' }}>{`Task${String(task.taskId).padStart(3, '0')}`}</a>} extra={<React.Fragment>
            <span style={{ textTransform: 'capitalize', display: 'flex' }}>
                {task.priority === "Highest" &&
                    <ImgIcon iconName="Highest.svg" />
                }
                {task.priority === "High" &&
                    <ImgIcon iconName="high.svg" />
                }
                {task.priority === "Medium" &&
                    <ImgIcon iconName="medium.svg" />
                }
                {task.priority === "Low" &&
                    <ImgIcon iconName="low.svg" />
                }
                {task.priority === "Lowest" &&
                    <ImgIcon iconName="Lowest.svg" />
                }
                {task.priority}
            </span>
        </React.Fragment>} style={techCardStyle}>
        <Typography.Paragraph>
            {task.title}
        </Typography.Paragraph>
        <p style={{ display: 'flex' }}><React.Fragment>

            <span style={{ textTransform: 'capitalize', display: 'flex' }}>
                {task.status === 'Closed' &&
                    <ImgIcon iconName="close.svg" />
                }
                {task.status === 'Pending' &&
                    <ImgIcon iconName="open.svg" />
                }
                {task.status === 'In Progress' &&
                    <ImgIcon iconName="inprogress.svg" />
                }
                {task.status === 'Rejected' &&
                    <ImgIcon iconName="rejected.svg" />
                }
            </span>
        </React.Fragment>
            {task.status}</p>
        <p style={{ display: 'flex' }}><ImgIcon iconName="calender.svg" /> {new Date(task.dueDate).toLocaleDateString('en', { year: 'numeric', month: 'long', day: 'numeric' })}</p>
        <p><div>
            <Avatar style={{ width: 32, height: 32, border: '1px solid #000000', background: '#ffffff', color: '#000000', marginRight: '6px' }}>{task.assignedTo.name[0].toUpperCase()}</Avatar>

            <span>{formatName(task.assignedTo.name)}</span>
        </div></p>
    </Card>
}