import React, { useRef, useState } from 'react';
import { Upload, Button, Modal } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import httpClient from '../util/http-client';
import { Media } from '../model/media.model';

interface OnChangeHandler {
    (media: Media): void;
}

export interface FileUploadViewProp {
    onChange?: OnChangeHandler;
}

const FileUploadView: React.FC<FileUploadViewProp> = ({ onChange }) => {

    const [media, setMedia] = useState<Media>()

    const [showConfirm, setConfirm] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false);

    const selectedFile = useRef<any>();

    const handleUpload = async (file: any) => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            httpClient.post("/upload", formData,
                { headers: { 'Content-Type': 'multipart/form-data' } })
                .then((response) => {
                    const data = response.data.data;
                    const media = {
                        id: data.ETag,
                        url: {
                            "main": data.Location
                        },
                        aspectRatio: 1,
                        type: "IMAGE",
                        tagList: [],
                        meta: {}
                    } as Media

                    if (onChange) {
                        onChange(media)
                        setMedia(media)
                    }
                    selectedFile.current = null;
                    setConfirmLoading(false)
                    setConfirm(false)
                })
                .catch((error) => {
                    selectedFile.current = null;
                    setConfirm(false)
                });

        } catch (error) {
            console.error('Error uploading file', error);
        }
    };

    const handleChange = (info: any) => {
        if (info.file.status === 'done') {
            alert(`${info.file.name} uploaded successfully.`);
        } else if (info.file.status === 'error') {
            alert(`${info.file.name} upload failed.`);
        }
    };

    return (
        <>
            {media ? <img
                src={media.url.main}
                style={{width: 100}}
                alt=""
            /> : <Upload
                customRequest={({ file, onSuccess, onError }) => {
                    selectedFile.current = file
                    setConfirm(true)
                }}
                onChange={handleChange}
                multiple={false}
                showUploadList={false}
            >
                <Button style={{ marginRight: '10px' }}>
                    <UploadOutlined /> Upload Files
                </Button>
            </Upload>}

            <Modal
                title="File Upload"
                open={showConfirm}
                onOk={() => {
                    setConfirmLoading(true);
                    handleUpload(selectedFile.current);
                }}
                confirmLoading={confirmLoading}
                onCancel={() => {
                    setConfirm(false)
                }}
            >
                <p>Are you sure want to upload this file. After ok you can not stop it</p>
            </Modal>
        </>
    );
};

export default FileUploadView;