import './App.css';
import 'antd/dist/reset.css';
import { RouterProvider } from "react-router-dom";
import { router } from './util/router-helper';
import "antd-css-utilities/utility.min.css"
import { useMapLocation } from './location/location.hook';
import { LocationDeniedComponent } from './location/location-denied.component';
import { useEffect } from 'react';
import { AndroidConnerctor } from './android.connecter';
import { useAnalytics } from './event/analytics.hook';
import { useNotification } from './util/notification.hook';
import { SecurityManager } from './security/security-manager';

const App = () => {
  const { openNotification } = useNotification()
  useAnalytics();
  const { initLocation, loading, permissionDenied } = useMapLocation()

  useEffect(() => {
    if (AndroidConnerctor.anroid()) {

    } else {
      initLocation()
    }
  }, [])

  function getToken() {
    try {
      (window as any).webkit.messageHandlers.bridge.postMessage(SecurityManager.getUid())
    } catch (err: any) {
      // openNotification('error', "=#####===>:", "")
    }
  }

  useEffect(() => {
    try {
      (window as any).webkit.messageHandlers.bridge.onMessage = (token: any) => {
        // openNotification('success', "from native====>:", token ? token : "")
      }
    } catch (err: any) {
      // openNotification('error', "=#####===>:", "")
    }

    setTimeout(() => {
      getToken()
    }, 2000)
  }, [])

  return (<>
    {permissionDenied ?
      <LocationDeniedComponent /> :
      <RouterProvider router={router} />
    }
  </>
  );
}

export default App;
