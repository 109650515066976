import { PlusCircleOutlined } from "@ant-design/icons"
import { Button, Card, Col, Form, Row } from "antd"
import React, { useEffect, useState } from "react"
import { EnquiryRes } from "../../../../response/enquiry.response"
import { AddFollowUpComponent } from "./follow-up/add-follow-up.component"
import { EnquiryNoteSearchReq } from "../../../../request/enquiry-note.request"
import { EnquiryNoteService } from "../../../../service/enquiry-note.service"
import { FollowUpListComponent } from "./follow-up/follow-up-list.component"
import { useEnquiry } from "../../enquiry.hook"
import { EnquiryRequest } from "../../../../request/enquiry.request"
import { EnquiryService } from "../../../../service/enquiry.service"
import { LargeStatus } from "../../../../model/large-status.model"

export interface FollowUpComponentProp {
    enquiry: EnquiryRes
}

export const FollowUpComponent = (props: FollowUpComponentProp) => {

    const [enquiryNoteList, setEnquiryNoteList] = useState([])

    const { fetchEnquiry } = useEnquiry()

    const [form] = Form.useForm()

    const { enquiry } = props

    const [isAddNewFollowUp, setIsAddNewFollowUp] = useState(false)

    const addNewFollowUpCard = () => {
        setIsAddNewFollowUp(true)
    }

    const fetchEnquiryNote = async (enquiryId: string) => {
        const [enquiryNoteList, error] = await EnquiryNoteService.getEnquiryNoteList({
            enquiryIdList: [enquiryId],
            pageNumber: 1,
            itemsPerPage: 50
        } as EnquiryNoteSearchReq)
        if (error) {
        } else {
            setEnquiryNoteList(enquiryNoteList)
        }
    }

    useEffect(() => {
        fetchEnquiryNote(enquiry.id)
    }, [enquiry])

    const handleOk = () => {
        form.validateFields().then(async values => {
            const enquiryRequest = {
                status: {
                    value: values["status"],
                    note: values["note"],
                    dateTime: new Date()
                } as LargeStatus
            } as EnquiryRequest

            const [data, error] = await EnquiryService.updateEnquiry(enquiry.id, enquiryRequest)

            if (error) {
                onFailure()
            } else {
                onSuccess()
            }
        });
    };

    const onSuccess = async () => {
        form.resetFields()
        await fetchEnquiry(enquiry.id, () => { }, () => { })
    }

    const onFailure = () => {
        fetchEnquiry(enquiry.id, () => { }, () => { })
    }

    return (
        <React.Fragment>
            {enquiryNoteList && enquiryNoteList.length > 0 &&
                <Card bordered={false}
                    style={{
                        padding: '0 20px',
                        marginBottom: '20px'
                    }}
                    headStyle={{
                        padding: 0
                    }}
                    bodyStyle={{
                        padding: '15px 0'
                    }}
                    className="follow-up-details wrapper-class common-space"
                >
                    <FollowUpListComponent enquiry={enquiry} enquiryNoteList={enquiryNoteList} fetchEnquiryNote={fetchEnquiryNote} />
                </Card>
            }

            {(enquiry.status.value == 'Pending' || enquiry.status.value == 'In Progress') &&
                <Card bordered={false}
                    style={{
                        padding: '0 20px',
                        marginBottom: '20px'
                    }}
                    headStyle={{
                        padding: 0
                    }}
                    bodyStyle={{
                        padding: isAddNewFollowUp ? '15px 0' : '8px 0'
                    }}
                    className="follow-up-details add-new-details wrapper-class"
                >
                    {<React.Fragment>
                        {isAddNewFollowUp &&
                            <AddFollowUpComponent setIsAddNewFollowUp={setIsAddNewFollowUp} enquiry={enquiry} fetchEnquiryNote={fetchEnquiryNote} />
                        }
                        {!isAddNewFollowUp &&
                            <Row>
                                <Col span={24}>
                                    <Button type="link" block style={{
                                        padding: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '100%'
                                    }}
                                        onClick={addNewFollowUpCard}
                                    >
                                        <PlusCircleOutlined /> Add Follow up
                                    </Button>
                                </Col>
                            </Row>
                        }
                    </React.Fragment>}
                </Card>
            }
        </React.Fragment>
    )
}