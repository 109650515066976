import { WhatsAppChatRequest, WhatsAppChatSearchReq } from "../request/whatsapp-chat.request"
import { WhatsAppChatRes } from "../response/whatsapp-chat.response"
import httpClient from "../util/http-client"

export const WhatsAppChatService = {
    getById: async (id: string): Promise<(WhatsAppChatRes | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.get(`/whatsAppChat/${id}`)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    saveWhatsAppChat: async (whatsAppChatRequest: WhatsAppChatRequest): Promise<(WhatsAppChatRes | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/whats-app-chat", whatsAppChatRequest)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    getWhatsAppChatList: async (whatsAppChatSearchReq: WhatsAppChatSearchReq): Promise<(WhatsAppChatRes[] | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/whats-app-chat-search", whatsAppChatSearchReq)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    updateWhatsAppChat: async (id: string, whatsAppChatRequest: WhatsAppChatRequest): Promise<(WhatsAppChatRes | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.put(`/whatsAppChat/${id}`, whatsAppChatRequest)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
}