import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Form, Input, Row, Space, Spin, Table, Tag, theme, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { TaskRes } from '../../../response/task.response';
import { useTask } from '../task.hook';
import { AddTaskComponent } from './add-task.component';
import { TaskFilterComponent } from './filters/filter.component';
import { useLocation, useNavigate } from 'react-router-dom';
import './table-task.css'
import ImgIcon from '../../../view/img-icon';
import dayjs from 'dayjs';
import PageHeader from '../../common/page-header.component';
import { pushEvent } from '../../../event/analytics.helper';
import { EventName } from '../../../event/event-name.enum';
import { SecurityManager } from '../../../security/security-manager';
import { formatName } from '../../../util/name-helper';
import { useMapLocation } from '../../../location/location.hook';
import { isIos } from '../../../util/device-helper';
import { TaskItemComponent } from './task-item.component';



export const MainTaskTableComponent = () => {

    const { state } = useLocation();
    const { location } = useMapLocation()

    const { Text } = Typography;
    const { useToken } = theme;
    const { token } = useToken();

    const { loading, taskList, fetchTaskList, taskSearchReq, updateLoading } = useTask()
    const [addNewTask, setOpenNewTask] = useState(false)
    const navigate = useNavigate()

    const updateOpenNewTask = () => {
        if (addNewTask) {
            fetchTaskList({ ...taskSearchReq, pageNumber: 1 }, () => { updateLoading(false) }, () => { updateLoading(false) })
        }
        setOpenNewTask(prevVal => !prevVal);
    }

    const columnList: ColumnsType<TaskRes> = [
        {
            title: (
                <div className='d-flex task-table-column'>
                    <ImgIcon iconName="task-id.svg" />
                    <Text ellipsis={true} className='w-50'>Task ID</Text>
                </div>
            ),
            dataIndex: 'taskId',
            key: 'taskId',
            onCell: (task, rowIndex) => {
                return {
                    onClick: (ev) => {
                        navigate(`/task/${task.id}`)
                    },
                };
            },
            render: (taskId) => <a style={{ borderBottom: '1px solid' }}>{`Task${String(taskId).padStart(3, '0')}`}</a>,
        },
        {
            title: (
                <div className='d-flex task-table-column'>
                    <ImgIcon iconName="title.svg" />
                    <Text ellipsis={true} className=''>Title</Text>
                </div>
            ),

            dataIndex: 'title',
            key: 'title',
        },
        {
            title: (
                <div className='d-flex task-table-column'>
                    <ImgIcon iconName="priority.svg" />
                    <Text ellipsis={true} className='w-50'>Priority</Text>
                </div>
            ),
            sorter: (a, b) => a.priority.localeCompare(b.priority),
            dataIndex: 'priority',
            key: 'priority',
            render: (priority) => (
                <React.Fragment>

                    <span style={{ textTransform: 'capitalize', display: 'flex' }}>
                        {priority === "Highest" &&
                            <ImgIcon iconName="Highest.svg" />
                        }
                        {priority === "High" &&
                            <ImgIcon iconName="high.svg" />
                        }
                        {priority === "Medium" &&
                            <ImgIcon iconName="medium.svg" />
                        }
                        {priority === "Low" &&
                            <ImgIcon iconName="low.svg" />
                        }
                        {priority === "Lowest" &&
                            <ImgIcon iconName="Lowest.svg" />
                        }
                        {priority}
                    </span>
                </React.Fragment>

            )
        },
        {
            title: (
                <div className='d-flex task-table-column'>
                    <ImgIcon iconName="taglist.svg" />
                    <Text ellipsis={true} className='w-50'>TagList</Text>
                </div>
            ),
            dataIndex: 'tagList',
            key: 'tagList',
            render: (_, { tagList }) => (
                <>
                    {tagList && tagList.map((tag) => {
                        let color = Math.random() > 0.5 ? 'geekblue' : 'green';
                        if (tag.toLocaleLowerCase() === 'hot') {
                            color = 'green';
                        }
                        if (tag.toLocaleLowerCase() === 'cold') {
                            color = 'blue';
                        }
                        return (
                            <Tag key={tag} style={{ border: '1px solid #92B5D7', borderRadius: token.borderRadiusLG }}>
                                {tag.toUpperCase()}
                            </Tag>
                        );
                    })}
                </>
            ),
        },
        {
            title: (
                <div className='d-flex task-table-column'>
                    <ImgIcon iconName="status.svg" />
                    <Text ellipsis={true} className='w-50'>Status</Text>
                </div>
            ),
            dataIndex: 'status',
            key: 'status',
            sorter: (a, b) => a.status.localeCompare(b.status),
            render: (status) => (
                <React.Fragment>

                    <span style={{ textTransform: 'capitalize', display: 'flex' }}>
                        {status === 'Closed' &&
                            <ImgIcon iconName="close.svg" />
                        }
                        {status === 'Pending' &&
                            <ImgIcon iconName="open.svg" />
                        }
                        {status === 'In Progress' &&
                            <ImgIcon iconName="inprogress.svg" />
                        }
                        {status === 'Rejected' &&
                            <ImgIcon iconName="rejected.svg" />
                        }
                        {status}
                    </span>
                </React.Fragment>

            )
        },
        {
            title: (
                <div className='d-flex task-table-column'>
                    <ImgIcon iconName="assigned.svg" />
                    <Text ellipsis={true} className='w-50'>Assigned To</Text>
                </div>
            ),
            dataIndex: 'assignedTo',
            key: 'assignedTo',
            render: (assignedTo) => (
                <div>
                    <Avatar style={{ border: '1px solid #000000', background: '#ffffff', color: '#000000', marginRight: '6px' }}>{assignedTo.name[0].toUpperCase()}</Avatar>

                    <span>{formatName(assignedTo.name)}</span>
                </div>
            ),
            sorter: (a, b) => a.assignedTo.name.localeCompare(b.assignedTo.name),

        },
        {
            title: (
                <div className='d-flex task-table-column'>
                    <ImgIcon iconName="calender.svg" />
                    <Text ellipsis={true} className='w-50'>Due Date</Text>
                </div>
            ),
            dataIndex: 'dueDate',
            key: 'dueDate',
            render: (dueDate) => <span>{new Date(dueDate).toLocaleDateString('en', { year: 'numeric', month: 'long', day: 'numeric' })}</span>,
        }
    ];

    useEffect(() => {
        if (state && state.status && state.status.length > 0) {
            const _taskSearchReq = { ...taskSearchReq }
            delete _taskSearchReq.startDate
            delete _taskSearchReq.endDate
            fetchTaskList({
                ..._taskSearchReq,
                statusList: [state.status],
                assigneeIdList: state.filterData?.assigneeIdList ? [...state.filterData?.assigneeIdList] : [],
                pageNumber: 1
            }, () => { updateLoading(false) }, () => { updateLoading(false) })
        } else {
            fetchTaskList({ ...taskSearchReq, pageNumber: 1 }, () => { updateLoading(false) }, () => { updateLoading(false) })
        }
    }, [state])

    const clearFilter = async () => {
        updateLoading(true)
        await fetchTaskList({
            ...taskSearchReq,
            priorityList: [],
            tagList: [],
            statusList: [],
            assigneeIdList: [],
            dueDate: undefined,
            pageNumber: 1
        }, () => { updateLoading(false) }, () => { updateLoading(false) })
    }

    useEffect(() => {
        pushEvent(EventName.TASK_PAGE_VIEW, {
            employeeId: SecurityManager.getEid(),
            location: location,
            createdOn: new Date()
        })
    }, [])

    return <>
        <PageHeader heading={"Task Manager"} search={taskSearchReq} addNewMethod={updateOpenNewTask} />

        {!isIos() && <>
            {taskList.length > 0 ?
                <Table
                    columns={columnList} dataSource={taskList}
                    loading={{ indicator: <div><Spin style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '24vh', width: 'fit-content' }} /></div>, spinning: loading }}
                    className='task-main-table task-table'
                    pagination={false}
                    style={{marginTop: 20}}
                /> : loading ? <Spin style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '24vh', width: 'fit-content' }} /> :
                    <div style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '24vh', width: 'fit-content' }} >
                        <p>No Data Found</p>
                        <Button onClick={clearFilter} type='primary'>Clear Filter</Button>
                    </div>
            }
        </>}

        {isIos() && <>
            {taskList.length > 0 ? <>
                {taskList.map((task: TaskRes) => {
                    return <TaskItemComponent task={task} />
                })
                }
            </> : loading ? <Spin style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '24vh', width: 'fit-content' }} /> :
                <div style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '24vh', width: 'fit-content' }} >
                    <p>No Data Found</p>
                    <Button onClick={clearFilter} type='primary'>Clear Filter</Button>
                </div>
            }
        </>}
        {addNewTask &&
            <AddTaskComponent open={addNewTask} onCancel={updateOpenNewTask} />
        }
    </>
}