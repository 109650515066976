import { createContext, useReducer } from "react";
import { RoleState } from "./role.state";
import { RoleReducer } from "./role.reducer";
import { RoleAction } from "./role.action";
import { RoleService } from "../../service/role.service";
import { RoleRequest, RoleSearchReq } from "../../request/role.request";
import { UserService } from "../../service/user.service";

const initialState = {
    loading: false,
    roleSearchReq: {
        pageNumber: 1,
        itemsPerPage: 100
    } as RoleSearchReq,
    roleList: [],
    permissionList: []
} as RoleState

export const RoleContext = createContext<any>(initialState);

export const RoleStateProvider = ({ children }: any) => {

    const [state, dispatch] = useReducer(RoleReducer, initialState)

    const updateLoading = (loading: Boolean) => {
        dispatch({
            type: RoleAction.UPDATE_LOADING,
            payload: {
                loading: loading
            }
        });
    };

    const fetchRoleList = async (roleSearchReq: RoleSearchReq, onSuccess: () => void, onFailure: () => void) => {
        updateLoading(true)
        const [roleList, error] = await RoleService.getRoleList(roleSearchReq)

        if (error) {
            onFailure()
        } else {
            dispatch({
                type: roleSearchReq.pageNumber == 1 ? RoleAction.UPDATE_ROLE_LIST : RoleAction.ADD_ROLE_LIST,
                payload: {
                    roleList: roleList
                }
            })
            if (roleList.length > 0) {
                dispatch({
                    type: RoleAction.UPDATE_ROLE_SEARCH_REQ,
                    payload: {
                        roleSearchReq: {
                            ...roleSearchReq,
                            pageNumber: roleSearchReq.pageNumber + 1
                        }
                    }
                })
            }
            onSuccess()
        }
    };

    const saveRole = async (roleRequest: RoleRequest, onSuccess: () => void, onFailure: () => void) => {
        const [data, error] = await RoleService.saveRole(roleRequest)

        if (error) {
            onFailure()
        } else {
            onSuccess()
        }
    }

    const updateRole = async (id: string, roleRequest: RoleRequest, onSuccess: () => void, onFailure: () => void) => {
        const [data, error] = await RoleService.updateRole(id, roleRequest)

        if (error) {
            onFailure()
        } else {
            onSuccess()
        }
    }

    const fetchPermissionList = async () => {
        const [permissionList, error] = await UserService.getPermissionList()

        if (error) {

        } else {
            dispatch({
                type: RoleAction.UPDATE_PERMISSION_LIST,
                payload: {
                    permissionList: permissionList
                }
            });
        }
    };

    const value = {
        roleList: state.roleList,
        loading: state.loading,
        roleSearchReq: state.roleSearchReq,
        permissionList: state.permissionList,
        updateLoading,
        fetchRoleList,
        saveRole,
        updateRole,
        fetchPermissionList
    };

    return <RoleContext.Provider value={value}>{children}</RoleContext.Provider>
};