import React, { useEffect } from 'react';
import { Form, Input, Select, Button } from 'antd';
import { TeamSearchReq } from '../../../request/team.request';
import { useTeam } from '../team.hook';
import { ItemProp } from '../../../model/item-prop.model';
import { OfficeRes } from '../../../response/office.response';
import { EmployeeRes } from '../../../response/employee.response';
import { handleFilter } from '../../../view/input.helper';
import { formatName } from '../../../util/name-helper';

export const TeamFilterComponent: React.FC = () => {
    const [form] = Form.useForm();
    const { fetchTeamList, updateLoading, teamSearchReq,
        fetchOfficeList,
        fetchEmployeeList,
        fetchTeamTypeList,
        officeList, teamTypeList, employeeList } = useTeam()

    const handleOk = () => {
        form.validateFields().then(values => {
            updateLoading(true);
            const _teamSearchReq = {
                ...teamSearchReq,
                name: values["name"],
                leaderIdList: values["leaderIdList"],
                employeeIdList: values["employeeIdList"],
                officeIdList: values["officeIdList"],
                tagList: values["tagList"],
                typeList: values["typeList"],
                pageNumber: 1,
                itemsPerPage: 10
            } as TeamSearchReq
            fetchTeamList(_teamSearchReq, onSuccess, onFailure)
        });
    };

    useEffect(() => {
        fetchOfficeList()
        fetchEmployeeList()
        fetchTeamTypeList()
    }, [])

    const onSuccess = () => {
        updateLoading(false);
    }

    const onFailure = () => {
        updateLoading(false);
    }

    return (
        <Form form={form} layout="inline">
            <Form.Item
                name="name"
                style={{ minWidth: 124 }}
                initialValue={teamSearchReq.name}
            >
                <Input placeholder='Name' />
            </Form.Item>

            <Form.Item
                name="officeIdList"
                style={{ minWidth: 124 }}
                initialValue={teamSearchReq.officeIdList}
            >
                <Select
                    allowClear
                    mode="multiple"
                    value='officeIdList'
                    placeholder="Office"
                    maxTagCount='responsive'
                    options={officeList.map((office: OfficeRes) => {
                        return {
                            label: office.name,
                            value: office.id
                        } as ItemProp
                    })}
                    filterOption={handleFilter} />
            </Form.Item>
            <Form.Item
                name="leaderIdList"
                style={{ minWidth: 124 }}
                initialValue={teamSearchReq.leaderIdList}
            >
                <Select
                    allowClear
                    mode="multiple"
                    value='leaderIdList'
                    options={employeeList.map((employee: EmployeeRes) => {
                        return {
                            label: `${formatName(employee.userInfo.name)}`,
                            value: employee.id
                        } as ItemProp
                    })}
                    maxTagCount='responsive'
                    placeholder="Leader" 
                    filterOption={handleFilter} />
            </Form.Item>

            <Form.Item
                name="employeeIdList"
                style={{ minWidth: 124 }}
                initialValue={teamSearchReq.leaderIdList}
            >
                <Select
                    allowClear
                    mode="multiple"
                    value='employeeIdList'
                    options={employeeList.map((employee: EmployeeRes) => {
                        return {
                            label: `${formatName(employee.userInfo.name)}`,
                            value: employee.id
                        } as ItemProp
                    })}
                    maxTagCount='responsive'
                    placeholder="Employee"
                    filterOption={handleFilter} />
            </Form.Item>

            <Form.Item
                name="typeList"
                style={{ minWidth: 124 }}
                initialValue={teamSearchReq.leaderIdList}
            >
                <Select
                    allowClear
                    mode="multiple"
                    value='typeList'
                    options={teamTypeList}
                    maxTagCount='responsive'
                    placeholder="Type"
                    filterOption={handleFilter} />
            </Form.Item>

            <Form.Item
                name="tagList"
                style={{ minWidth: 124 }}
                initialValue={teamSearchReq.tagList}
            >
                <Select
                    allowClear
                    mode="tags"
                    value='tagList'
                    maxTagCount='responsive'
                    placeholder="Tags"
                    filterOption={handleFilter}
                />
            </Form.Item>

            <Form.Item style={{ minWidth: 124 }}>
                <Button type="primary" htmlType="submit"
                    onClick={handleOk}>
                    Search
                </Button>
            </Form.Item>
        </Form>
    );
}
