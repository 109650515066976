import { Row, Col, Space, Typography, Form, Input, Card, Badge } from "antd"
import dayjs from "dayjs"
import { AttendanceComponent } from "../dashboard/component/attendance.component"
import { SearchOutlined } from "@ant-design/icons"
import { useRef, useState } from "react"
import { EnquiryService } from "../../service/enquiry.service"
import { EnquirySearchReq } from "../../request/enquiry.request"
import { LeadSearchReq } from "../../request/lead.request"
import { LeadService } from "../../service/lead.service"
import { EnquireRowDetailItem } from "../enquiry/component/gridView/enquire-row-detail-item.component"
import { EnquireRowReadItemBase } from "../enquiry/component/detail/enquiry-row-read-item.component"
import { EnquiryRes } from "../../response/enquiry.response"
import ImgIcon from "../../view/img-icon"
import { LeadRes } from "../../response/lead.response"
import { LeadRowReadItem } from "../lead/component/detail/lead-row-read-item.component"

const GlobalSearchComponent = () => {
    const [loading, setLoading] = useState(false)

    const [enquiryList, setEnquiryList] = useState<any>([])
    const [leadList, setLeadList] = useState<any>([])

    const searchInterval = useRef<any>()

    const searchReq = useRef<any>({})

    const searchFunction = (text: string) => {
        setLoading(true)
        if (searchInterval.current) {
            clearTimeout(searchInterval.current)
        }
        searchInterval.current = setTimeout(() => {
            fetchEnquiryList({ ...searchReq.current, searchText: text, pageNumber: 1 }, () => { setLoading(false) }, () => { setLoading(false) })
            fetchLeadList({ ...searchReq.current, searchText: text, pageNumber: 1 }, () => { setLoading(false) }, () => { setLoading(false) })
        }, 500)
    }

    const fetchEnquiryList = async (enquirySearchReq: EnquirySearchReq, onSuccess: () => void, onFailure: () => void) => {
        setLoading(true)
        const [_enquiryList, error] = await EnquiryService.getEnquiryList(enquirySearchReq)

        if (error) {
            onFailure()
        } else {
            if (enquirySearchReq.pageNumber == 1) {
                console.log(_enquiryList)
                setEnquiryList([..._enquiryList])
            } else {
                setEnquiryList([...enquiryList, ..._enquiryList])
            }
            if (enquiryList.length > 0) {
                searchReq.current = {
                    ...enquirySearchReq,
                    pageNumber: enquirySearchReq.pageNumber + 1
                }
            }
            onSuccess()
        }
    };

    const fetchLeadList = async (leadSearchReq: LeadSearchReq, onSuccess: () => void, onFailure: () => void) => {
        setLoading(true)
        const [_leadList, error] = await LeadService.getLeadList(leadSearchReq)

        if (error) {
            onFailure()
        } else {
            if (leadSearchReq.pageNumber == 1) {
                setLeadList([..._leadList])
            } else {
                setLeadList([...leadList, ..._leadList])
            }
            if (enquiryList.length > 0) {
                searchReq.current = {
                    ...leadSearchReq,
                    pageNumber: leadSearchReq.pageNumber + 1
                }
            }
            onSuccess()
        }
    };

    return (
        <div className="search--parent">
            <Form>
                <Form.Item
                    name="title"
                    initialValue={""}
                    className="m-0"
                    style={{ paddingRight: "10px", maxWidth: '374px', minWidth: '274px', width: '90%' }}
                    rootClassName="search-form-item"
                >
                    <Input
                        placeholder="Title"
                        style={{
                            border: "none",
                            borderRadius: 4,
                            padding: "5px 10px",
                            height: "40px",
                            marginBottom: '10px'
                        }}
                        onChange={(e: any) => {
                            searchFunction(e.target.value)
                        }}
                        suffix={<SearchOutlined />}
                    />
                </Form.Item>
            </Form>
            {enquiryList && enquiryList.length > 0 && (
                <Card style={{ padding: 0, position: 'absolute', zIndex: 99, width: 'min-content', left: 0, right: 0, boxShadow: '7px 6px 11px rgba(0,0,0,0.1)', height: '470px', overflowY: 'auto' }} rootClassName="search-list-section">

                    <Row style={{
                        overflow: 'auto',
                        flexFlow: 'nowrap'
                    }}

                        className='search--grid'
                    >
                        <Col id={`vertical_col_grid_ser${0}`} md={12} lg={6} xl={6} style={{
                            marginRight: 20,
                            minWidth: 332,
                            maxWidth: 364
                        }}>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                background: '#E0EEF7',
                                color: '#343433',
                                padding: '6px 10px',
                                borderRadius: 4,
                                marginBottom: 8
                            }}>
                                <span>Enquiry</span>

                                <Badge count={enquiryList.length} style={{
                                    background: '#6D6C68',
                                    padding: '0 4px',
                                    boxShadow: 'none',
                                    margin: '0 6px'
                                }}
                                    overflowCount={9999} />
                            </div>
                            {enquiryList.map((enquiry: EnquiryRes, index: number) => {
                                console.log(enquiry)
                                return <EnquireRowReadItemBase addLink={true} enquiry={enquiry} lastItem={index == enquiryList.length - 1} />
                            })}
                        </Col>
                        <Col id={`vertical_col_grid_ser${1}`} md={12} lg={6} xl={6} style={{
                            marginRight: 20,
                            minWidth: 332,
                            maxWidth: 364
                        }}>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                background: '#E0EEF7',
                                color: '#343433',
                                padding: '6px 10px',
                                borderRadius: 4,
                                marginBottom: 8
                            }}>
                                <span>Lead</span>

                                <Badge count={leadList.length} style={{
                                    background: '#6D6C68',
                                    padding: '0 4px',
                                    boxShadow: 'none',
                                    margin: '0 6px'
                                }}
                                    overflowCount={9999} />
                            </div>
                            {leadList.map((lead: LeadRes, index: number) => {
                                return <LeadRowReadItem addLink={true} lead={lead} lastItem={index == enquiryList.length - 1} />
                            })}
                        </Col>
                    </Row>
                </Card>
            )}
        </div>
    )
}

export default GlobalSearchComponent