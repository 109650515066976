import { createContext, useReducer } from "react";
import { WhatsAppChatState } from "./whatsapp-chat.state";
import { WhatsAppChatReducer } from "./whatsapp-chat.reducer";
import { WhatsAppChatRequest, WhatsAppChatSearchReq } from "../../request/whatsapp-chat.request";
import { WhatsAppChatRes } from "../../response/whatsapp-chat.response";
import { WhatsAppChatAction } from "./whatsapp-chat.action";
import { WhatsAppChatService } from "../../service/whatsapp-chat.service";


const initialState = {
    loading: false,
    whatsAppChatSearchReq: {
        pageNumber: 1,
        itemsPerPage: 40
    } as WhatsAppChatSearchReq,
    whatsAppChatList: [],
    updateWhatsAppChatData: {} as WhatsAppChatRes
} as WhatsAppChatState

export const WhatsAppChatContext = createContext<any>(initialState);

export const WhatsAppChatStateProvider = ({ children }: any) => {

    const [state, dispatch] = useReducer(WhatsAppChatReducer, initialState)


    const updateLoading = (loading: Boolean) => {
        dispatch({
            type: WhatsAppChatAction.UPDATE_LOADING,
            payload: {
                loading: loading
            }
        });
    };

    const fetchWhatsAppChatList = async (whatsAppChatSearchReq: WhatsAppChatSearchReq, onSuccess: () => void, onFailure: () => void) => {
        updateLoading(true)
        const [whatsAppChatList, error] = await WhatsAppChatService.getWhatsAppChatList(whatsAppChatSearchReq)

        if (error) {
            onFailure()
        } else {
            dispatch({
                type: whatsAppChatSearchReq.pageNumber == 1 ? WhatsAppChatAction.UPDATE_WHATS_APP_CHAT_LIST : WhatsAppChatAction.ADD_WHATS_APP_CHAT_LIST,
                payload: {
                    whatsAppChatList: whatsAppChatList
                }
            })
            if (whatsAppChatList.length > 0) {
                dispatch({
                    type: WhatsAppChatAction.UPDATE_WHATS_APP_CHAT_SEARCH_REQ,
                    payload: {
                        whatsAppChatSearchReq: {
                            ...whatsAppChatSearchReq,
                            pageNumber: whatsAppChatSearchReq.pageNumber + 1
                        }
                    }
                })
            }
            onSuccess()
        }
    };

    const saveWhatsAppChat = async (whatsAppChatRequest: WhatsAppChatRequest, onSuccess: () => void, onFailure: () => void) => {
        const [data, error] = await WhatsAppChatService.saveWhatsAppChat(whatsAppChatRequest)

        if (error) {
            onFailure()
        } else {
            onSuccess()
        }
    }

    const updateWhatsAppChat = async (id: string, whatsAppChatRequest: WhatsAppChatRequest, onSuccess: () => void, onFailure: () => void) => {
        const [data, error] = await WhatsAppChatService.updateWhatsAppChat(id, whatsAppChatRequest)

        if (error) {
            onFailure()
        } else {
            onSuccess()
        }
    }

    const value = {
        whatsAppChatList: state.whatsAppChatList,
        loading: state.loading,
        whatsAppChatSearchReq: state.whatsAppChatSearchReq,
        updateLoading,
        fetchWhatsAppChatList,
        saveWhatsAppChat,
        updateWhatsAppChat
    };

    return <WhatsAppChatContext.Provider value={value}>{children}</WhatsAppChatContext.Provider>
};