import { Action } from "../../model/action.model";
import { EmployeeAction } from "./employee.action";
import { EmployeeState } from "./employee.state";


export const EmployeeReducer = (state: EmployeeState, action: Action): EmployeeState => {
    const { type, payload } = action

    switch (type) {
        case EmployeeAction.UPDATE_LOADING:
            return {
                ...state,
                loading: payload.loading
            };
        case EmployeeAction.UPDATE_EMPLOYEE_LIST:
            return {
                ...state,
                employeeList: [...payload.employeeList]
            };

        case EmployeeAction.ADD_EMPLOYEE_LIST:
            return {
                ...state,
                employeeList: [...payload.employeeList]
            };

        case EmployeeAction.UPDATE_EMPLOYEE_SEARCH_REQ:
            return {
                ...state,
                employeeSearchReq: { ...payload.employeeSearchReq }
            };

        case EmployeeAction.SET_UPDATE_EMPLOYEE:
            return {
                ...state,
                updateEmployeeData: { ...payload.updateEmployeeData }
            };
        case EmployeeAction.UPDATE_USER_LIST:
            return {
                ...state,
                userList: [...payload.userList]
            };
        case EmployeeAction.UPDATE_TEAM_LIST:
            return {
                ...state,
                teamList: [...payload.teamList]
            };
        case EmployeeAction.UPDATE_EMPLOYEE_TEAM_LIST:
            return {
                ...state,
                employeeTeamList: [...payload.employeeTeamList]
            };
        case EmployeeAction.UPDATE_OFFICE_LIST:
            return {
                ...state,
                officeList: [...payload.officeList]
            };
        case EmployeeAction.UPDATE_PERMISSION_LIST:
            return {
                ...state,
                permissionList: [...payload.permissionList]
            };
        case EmployeeAction.UPDATE_ROLE_LIST:
            return {
                ...state,
                roleList: [...payload.roleList]
            };
        case EmployeeAction.UPDATE_REPORTEE_LIST:
            return {
                ...state,
                reporteeList: [...payload.reporteeList]
            };
        case EmployeeAction.UPDATE_TEAM_MEMBER_LIST:
            return {
                ...state,
                teamMemberList: [...payload.teamMemberList]
            };
        case EmployeeAction.UPDATE_ALL_EMPLOYEE_LIST:
            return {
                ...state,
                allEmployeeList: [...payload.allEmployeeList]
            };
        default:
            throw new Error(`No case for type ${type} found in EmployeeReducer.`);
    }
}