import { Action } from "../../model/action.model";
import { LocalityAction } from "./locality.action";
import { LocalityState } from "./locality.state";


export const LocalityReducer = (state: LocalityState, action: Action): LocalityState => {
    const { type, payload } = action

    switch (type) {
        case LocalityAction.UPDATE_LOADING:
            return {
                ...state,
                loading: payload.loading
            };
        case LocalityAction.ADD_LOCALITY_LIST:
            return {
                ...state,
                localityList: [...state.localityList, ...payload.localityList]
            };
        case LocalityAction.UPDATE_LOCALITY_LIST:
            return {
                ...state,
                localityList: [...payload.localityList]
            };
        case LocalityAction.UPDATE_LOCALITY_SEARCH_REQ:
            return {
                ...state,
                localitySearchReq: { ...payload.localitySearchReq }
            };
        case LocalityAction.UPDATE_COUNTRY_LIST:
            return {
                ...state,
                countryList: [...payload.countryList]
            };
        case LocalityAction.UPDATE_CITY_LIST:
            return {
                ...state,
                cityList: [...payload.cityList]
            };
        default:
            throw new Error(`No case for type ${type} found in LocalityReducer.`);
    }
}