import { Action } from "../../model/action.model";
import { groupList } from "../../util/group-convertor";
import { LeadAction } from "./lead.action";
import { LeadState } from "./lead.state";


export const LeadReducer = (state: LeadState, action: Action): LeadState => {
    const { type, payload } = action

    switch (type) {

        case LeadAction.ADD_LEAD_LIST:
            const updatedList = [...state.leadList, ...payload.leadList]
            const updatedGroupList = groupList([...updatedList], (lead) => lead.status.value)
            return {
                ...state,
                leadList: updatedList,
                leadGroupList: updatedGroupList
            };
        case LeadAction.UPDATE_LEAD_LIST:
            const newList = [...payload.leadList]
            const newGroupList = groupList([...newList], (lead) => lead.status.value)
            return {
                ...state,
                leadList: newList,
                leadGroupList: newGroupList
            };
        case LeadAction.UPDATE_LEAD_SEARCH_REQ:
            return {
                ...state,
                leadSearchReq: { ...payload.leadSearchReq }
            };
        case LeadAction.UPDATE_LOADING:
            return {
                ...state,
                loading: payload.loading
            };
        case LeadAction.UPDATE_EMPLOYEE_LIST:
            return {
                ...state,
                employeeList: [...payload.employeeList]
            };
        case LeadAction.UPDATE_USER_LIST:
            return {
                ...state,
                userList: [...payload.userList]
            };
        case LeadAction.UPDATE_PROJECT_LIST:
            return {
                ...state,
                projectList: [...payload.projectList]
            };
        case LeadAction.UPDATE_LEAD:
            return {
                ...state,
                lead: { ...payload.lead }
            };
        case LeadAction.UPDATE_CLIENT:
            return {
                ...state,
                client: { ...payload.client }
            };
        default:
            throw new Error(`No case for type ${type} found in LeadReducer.`);
    }
}