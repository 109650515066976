import { Button, Space, Spin, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { ArticleRes } from '../../../response/article.response';
import { useArticle } from '../article.hook';
import { AddArticleComponent } from './add-article.component';
import { UpdateArticleComponent } from './update-article.component';
import { ArticleFilterComponent } from './filter.component';
import { TextEditorView } from '../../../view/text-editor.view';

export const MainArticleTableComponent = () => {
    const { loading, articleList, fetchArticleList, articleSearchReq, updateLoading } = useArticle()
    const [addNewArticle, setOpenNewArticle] = useState(false)
    const [updateArticle, setUpdateArticle] = useState<ArticleRes | undefined>(undefined)

    const cancelArticleEdit = () => {
        setUpdateArticle(prevVal => undefined);
    }
    const updateOpenNewArticle = () => {
        if (addNewArticle) {
            fetchArticleList({ ...articleSearchReq, pageNumber: 1 }, () => { updateLoading(false) }, () => { updateLoading(false) })
        }
        setOpenNewArticle(prevVal => !prevVal);
    }

    const columnList: ColumnsType<ArticleRes> = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Mini Description',
            dataIndex: 'miniDescription',
            key: 'miniDescription',
        },
        {
            title: 'Description',
            key: 'description',
            dataIndex: 'description',
            render: (_, { description }) => (
                <>
                    <TextEditorView initData={description.substring(0, 100) + "More..."} readOnly={true} />
                </>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            onCell: (article, rowIndex) => {
                return {
                    onClick: (ev) => {
                        setUpdateArticle(article)
                    },
                };
            },
            render: (id) => <a>Edit</a>,
        },
    ];

    useEffect(() => {
        fetchArticleList({ ...articleSearchReq, pageNumber: 1 }, () => { updateLoading(false) }, () => { updateLoading(false) })
    }, [])

    return <div style={{ marginTop: -42 }}>
        <Space direction="horizontal" style={{ width: '100%', justifyContent: 'end', marginBottom: 8 }}>
            <ArticleFilterComponent />
            <Button type="primary" onClick={updateOpenNewArticle}>Add New</Button>
        </Space>
        <Table
            className='task-main-table task-table'
            columns={columnList} dataSource={articleList}
            pagination={{ defaultPageSize: 30, showSizeChanger: true, pageSizeOptions: ['20', '50', '100', '200', '300', '400', '500'] }}
            loading={{ indicator: <div><Spin /></div>, spinning: loading }} />
        {addNewArticle &&
            <AddArticleComponent open={addNewArticle} onCancel={updateOpenNewArticle} />
        }

        {updateArticle &&
            <UpdateArticleComponent article={updateArticle} open={true} onCancel={cancelArticleEdit} />
        }
    </div>
}