import { MenuStateProvider } from './menu.context';
import { MainMenuTableComponent } from './component/table.component';

const MenuPageBase = () => {

  return <>
    <MainMenuTableComponent />
  </>
}

export const MenuPage = () => {
  return <MenuStateProvider>
    <MenuPageBase />
  </MenuStateProvider>
}

