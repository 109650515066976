import { createContext, useReducer } from "react";
import { MenuState } from "./menu.state";
import { MenuReducer } from "./menu.reducer";
import { MenuAction } from "./menu.action";
import { UserService } from "../../service/user.service";
import { MenuRequest, MenuSearchReq } from "../../request/menu.request";
import { MenuService } from "../../service/menu.service";

const initialState = {
    loading: false,
    menuSearchReq: {
        pageNumber: 1,
        itemsPerPage: 100
    } as MenuSearchReq,
    menuList: [],
} as MenuState

export const MenuContext = createContext<any>(initialState);

export const MenuStateProvider = ({ children }: any) => {

    const [state, dispatch] = useReducer(MenuReducer, initialState)

    const updateLoading = (loading: Boolean) => {
        dispatch({
            type: MenuAction.UPDATE_LOADING,
            payload: {
                loading: loading
            }
        });
    };

    const fetchMenuList = async (menuSearchReq: MenuSearchReq, onSuccess: () => void, onFailure: () => void) => {
        updateLoading(true)
        const [menuList, error] = await MenuService.getMenuList(menuSearchReq)

        if (error) {
            onFailure()
        } else {
            dispatch({
                type: menuSearchReq.pageNumber == 1 ? MenuAction.UPDATE_MENU_LIST : MenuAction.ADD_MENU_LIST,
                payload: {
                    menuList: menuList
                }
            })
            if (menuList.length > 0) {
                dispatch({
                    type: MenuAction.UPDATE_MENU_SEARCH_REQ,
                    payload: {
                        menuSearchReq: {
                            ...menuSearchReq,
                            pageNumber: menuSearchReq.pageNumber + 1
                        }
                    }
                })
            }
            onSuccess()
        }
    };

    const saveMenu = async (menuRequest: MenuRequest, onSuccess: () => void, onFailure: () => void) => {
        const [data, error] = await MenuService.saveMenu(menuRequest)

        if (error) {
            onFailure()
        } else {
            onSuccess()
        }
    }

    const updateMenu = async (id: string, menuRequest: MenuRequest, onSuccess: () => void, onFailure: () => void) => {
        const [data, error] = await MenuService.updateMenu(id, menuRequest)

        if (error) {
            onFailure()
        } else {
            onSuccess()
        }
    }

    const value = {
        menuList: state.menuList,
        loading: state.loading,
        menuSearchReq: state.menuSearchReq,
        updateLoading,
        fetchMenuList,
        saveMenu,
        updateMenu,
    };

    return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>
};