import { TeamStateProvider } from './team.context';
import { MainTeamTableComponent } from './component/table.component';

const TeamPageBase = () => {

  return <>
    <MainTeamTableComponent />
  </>
}

export const TeamPage = () => {
  return <TeamStateProvider>
    <TeamPageBase />
  </TeamStateProvider>
}

