import React, { memo, useState } from 'react';
import { Button, Col, Row, Spin } from 'antd';
import ImgIcon from '../../../../view/img-icon';
import { useLead } from '../../lead.hook';
import { LeadGridDetail } from './lead-grid-details.component';
import { LeadRes } from '../../../../response/lead.response';

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

export const LeadGridViewComponent: React.FC = memo(() => {

    const { leadGroupList, loading, updateLoading, fetchLeadList, leadSearchReq } = useLead()

    const contentStyle: React.CSSProperties = {
        margin: 0,
        height: '160px',
        color: '#fff',
        lineHeight: '160px',
        textAlign: 'center',
        background: '#364d79',
    };

    const [activeIndex, setActiveIndex] = useState(0)

    const clearFilter = async () => {
        updateLoading(true)
        await fetchLeadList({
            ...leadSearchReq,
            statusList: [] as string[],
            assigneeIdList: [],
            pageNumber: 1
        }, () => { updateLoading(false) }, () => { updateLoading(false) })
    }

    const getGridView = React.useMemo(() => {
        window.scrollTo(0, 0);
        return leadGroupList.map((leadList: LeadRes[], index: number) => {
            return <Col id={`vertical_col_grid_${index}`} md={12} lg={6} xl={6} style={{
                marginRight: 20,
                minWidth: 332,
                maxWidth: 364,
                marginTop: 20
            }}>
                <LeadGridDetail leadList={leadList} />
            </Col>
        })
    }, [leadGroupList])

    const getMobileGridView = React.useMemo(() => {
        window.scrollTo(0, 0);
        return leadGroupList.length > 0 ? <div style={contentStyle}>
            <LeadGridDetail leadList={[...leadGroupList[activeIndex]]} />
        </div> : <></>
    }, [leadGroupList, activeIndex])

    return (
        <React.Fragment>
            {!isMobile &&
                <Row style={{
                    overflow: 'auto',
                    flexFlow: 'nowrap'
                }}

                    className='d-web-grid'
                >
                    {(leadGroupList.length > 0 && !loading) ? getGridView : loading ? <Spin style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '24vh' }} /> :
                        <div style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '24vh', width: 'fit-content' }} >
                            <p>No Data Found</p>
                            <Button onClick={clearFilter} type='primary'>Clear Filter</Button>
                        </div>}

                </Row>
            }

            {isMobile && <div className='m-web-slider' style={{
                marginBottom: 100
            }}>

                <div style={{ maxWidth: 364, margin: 'auto' }}>
                    {leadGroupList.length > 0 ? getMobileGridView
                        : loading ? <Spin style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '24vh' }} /> :
                            <div style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '24vh', width: 'fit-content' }} >
                                <p>No Data Found</p>
                                <Button onClick={clearFilter} type='primary'>Clear Filter</Button>
                            </div>}
                </div>

                {leadGroupList.length > 0 &&
                    <>
                        <Button className='slider-arrow left' onClick={() => {
                            setActiveIndex(prevIndex => {
                                return (prevIndex === 0) ?
                                    leadGroupList.length - 1 :
                                    prevIndex - 1
                            })
                        }}>
                            <ImgIcon iconName="left-arrow.svg" iconWidth="16px" />
                        </Button>
                        <Button className='slider-arrow right' onClick={() => {
                            setActiveIndex(prevIndex => {
                                return (prevIndex === leadGroupList.length - 1) ?
                                    0 :
                                    prevIndex + 1
                            })
                        }}>
                            <ImgIcon iconName="right-arrow.svg" iconWidth="16px" />
                        </Button>
                    </>
                }
            </div>}


        </React.Fragment>
    )
})