import { Button, Space, Spin, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { RoleRes } from '../../../response/role.response';
import { useRole } from '../role.hook';
import { AddRoleComponent } from './add-role.component';
import { UpdateRoleComponent } from './update-role.component';
import { RoleFilterComponent } from './filter.component';
import { getColorBynumber } from '../../../enum/static-data.enum';

export const MainRoleTableComponent = () => {
    const { loading, roleList, fetchRoleList, roleSearchReq, updateLoading } = useRole()
    const [addNewRole, setOpenNewRole] = useState(false)
    const [updateRole, setUpdateRole] = useState<RoleRes | undefined>(undefined)

    const cancelRoleEdit = () => {
        setUpdateRole(prevVal => undefined);
    }
    const updateOpenNewRole = () => {
        if (addNewRole) {
            fetchRoleList({ ...roleSearchReq, pageNumber: 1 }, () => { updateLoading(false) }, () => { updateLoading(false) })
        }
        setOpenNewRole(prevVal => !prevVal);
    }

    const columnList: ColumnsType<RoleRes> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Level',
            dataIndex: 'level',
            key: 'level',
        },
        {
            title: 'Permissions',
            key: 'permissionList',
            dataIndex: 'permissionList',
            render: (_, { permissionList }) => (
                <>
                    {permissionList.map((permission: string, index: number) => {
                        const color = getColorBynumber(index)
                        return (
                            <Tag color={color} key={permission}>
                                {permission.toUpperCase()}
                            </Tag>
                        );
                    })}
                </>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            onCell: (role, rowIndex) => {
                return {
                    onClick: (ev) => {
                        setUpdateRole(role)
                    },
                };
            },
            render: (id) => <a>Edit</a>,
        },
    ];

    useEffect(() => {
        fetchRoleList({ ...roleSearchReq, pageNumber: 1 }, () => { updateLoading(false) }, () => { updateLoading(false) })
    }, [])

    return <div style={{ marginTop: -42 }}>
        <Space direction="horizontal" style={{ width: '100%', justifyContent: 'end', marginBottom: 8 }}>
            <RoleFilterComponent />
            <Button type="primary" onClick={updateOpenNewRole}>Add New</Button>
        </Space>
        <Table
            className='task-main-table task-table'
            columns={columnList} dataSource={roleList}
            pagination={{ defaultPageSize: 30, showSizeChanger: true, pageSizeOptions: ['20', '50', '100', '200', '300', '400', '500'] }}
            loading={{ indicator: <div><Spin /></div>, spinning: loading }} />
        {addNewRole &&
            <AddRoleComponent open={addNewRole} onCancel={updateOpenNewRole} />
        }

        {updateRole &&
            <UpdateRoleComponent role={updateRole} open={true} onCancel={cancelRoleEdit} />
        }
    </div>
}