import { Card } from "antd"
import EnquireChartComponent from "./enquire-chart.component"
import { EnquiryReportRes } from "../../../../../response/enquiry.response"

export interface EnquireCardComponentProp {
    enquiryReportResList: EnquiryReportRes[]
    filterData: any
}

const EnquireCardComponent = (props: EnquireCardComponentProp) => {
    const { enquiryReportResList, filterData } = props

    return (
        <Card className="p-mweb-card" style={{
            height: '100%',
            padding: '0 20px'
        }}
            headStyle={{
                padding: '10px 0 0',
                minHeight: 44
            }}
            bodyStyle={{
                padding: '10px 0',
                color: '#000'
            }}
            title="Enquiry Chart"
            bordered={false}
            extra={<div>Total: {enquiryReportResList.reduce((accumulator: number, enquiryReportRes: EnquiryReportRes) => accumulator + enquiryReportRes.count, 0)} enquiry</div>}
        >
            <div>
                <EnquireChartComponent enquiryReportResList={enquiryReportResList} filterData={filterData} />
            </div>


        </Card>
    )
}

export default EnquireCardComponent