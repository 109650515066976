import { ServiceStateProvider } from './service.context';
import { MainServiceTableComponent } from './component/table.component';

const ServicePageBase = () => {

  return <>
    <MainServiceTableComponent />
  </>
}

export const ServicePage = () => {
  return <ServiceStateProvider>
    <ServicePageBase />
  </ServiceStateProvider>
}

