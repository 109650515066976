import React from "react";
import { Modal, Form, Input, Space } from "antd";
import { useUser } from "../user.hook";
import { UserRequest } from "../../../request/user.request";
import { Address } from "../../../model/address.model";
import { UserInfo } from "../../../model/user-info.model";
import {
  validateAddressLineOne,
  validateAddressLineTwo,
  validateAddressStreet,
  validateCity,
  validateEmail,
  validateName,
  validatePhone,
  validatePinCode,
  validateScore,
  validateState,
} from "../../../validator/user-info.validator";

export const AddUserComponent: React.FC<{
  open: boolean;
  onCancel: () => void;
}> = ({ open, onCancel }) => {
  const [form] = Form.useForm();
  const { loading, updateLoading, saveUser } = useUser();

  const handleOk = () => {
    form.validateFields().then((values) => {
      updateLoading(true);
      const userRequest = {
        userInfo: {
          name: values["name"],
          phone: values["phone"],
          email: values["email"],
        } as UserInfo,
        address: {
          lineOne: values["lineOne"],
          lineTwo: values["lineTwo"],
          street: values["street"],
          city: values["city"],
          state: values["state"],
          pinCode: values["pinCode"],
        } as Address,
        score: Number(values["score"]),
      } as UserRequest;
      saveUser(userRequest, onSuccess, onFailure);
    });
  };

  const onSuccess = () => {
    updateLoading(false);
    form.resetFields();
    onCancel();
  };

  const onFailure = () => {
    updateLoading(false);
  };

  return (
    <Modal
      open={open}
      title="Add New User"
      onCancel={onCancel}
      confirmLoading={loading}
      onOk={handleOk}
      okText={"Submit"}
      cancelText={"Cancel"}
      //   style={{ top: 8, maxWidth: 768 }}
      //   width={"90vw"}
    >
      <Form form={form} layout="vertical">
        <Space>
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, validator: validateName }]}
            style={{ display: "inline-block", width: "calc(50% - 8px)" }}
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            name="phone"
            label="Phone"
            rules={[{ required: true, validator: validatePhone }]}
            style={{ display: "inline-block", width: "calc(50% - 8px)" }}
          >
            <Input type="tel" />
          </Form.Item>
        </Space>
        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, validator: validateEmail }]}
        >
          <Input type="email" />
        </Form.Item>
        <Space>
          <Form.Item
            name="lineOne"
            label="Address Line One"
            rules={[{ required: true, validator: validateAddressLineOne }]}
            style={{ display: "inline-block", width: "calc(50% - 8px)" }}
          >
            <Input.TextArea rows={2} placeholder="Enter your address" />
          </Form.Item>
          <Form.Item
            name="lineTwo"
            label="Address Line Two"
            rules={[{ required: true, validator: validateAddressLineTwo }]}
            style={{ display: "inline-block", width: "calc(50% - 8px)" }}
          >
            <Input.TextArea rows={2} placeholder="Enter your address" />
          </Form.Item>
        </Space>
        <Form.Item
          name="street"
          label="Street"
          rules={[{ required: true, validator: validateAddressStreet }]}
        >
          <Input.TextArea rows={2} placeholder="Enter your street" />
        </Form.Item>
        <Form.Item
          name="city"
          label="City"
          rules={[{ required: true, validator: validateCity }]}
        >
          <Input placeholder="Enter your city" />
        </Form.Item>
        <Form.Item
          name="state"
          label="State"
          rules={[{ required: true, validator: validateState }]}
        >
          <Input placeholder="Enter your state" />
        </Form.Item>
        <Form.Item
          name="pinCode"
          label="Pin Code"
          rules={[{ required: true, validator: validatePinCode }]}
        >
          <Input type="number" placeholder="Enter your pin code" />
        </Form.Item>

        <Form.Item
          name="score"
          label="Score"
          rules={[{ required: true, validator: validateScore }]}
        >
          <Input type="number" placeholder="Enter score" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
