export enum EmployeeAction {
    UPDATE_LOADING = "UPDATE_LOADING",
    UPDATE_EMPLOYEE_LIST = "UPDATE_EMPLOYEE_LIST",
    ADD_EMPLOYEE_LIST = "ADD_EMPLOYEE_LIST",
    UPDATE_EMPLOYEE_SEARCH_REQ = "UPDATE_EMPLOYEE_SEARCH_REQ",
    SET_UPDATE_EMPLOYEE = "SET_UPDATE_EMPLOYEE",
    UPDATE_OFFICE_LIST = "UPDATE_OFFICE_LIST",
    UPDATE_TEAM_LIST = "UPDATE_TEAM_LIST",
    UPDATE_EMPLOYEE_TEAM_LIST = "UPDATE_EMPLOYEE_TEAM_LIST",
    UPDATE_USER_LIST = "UPDATE_USER_LIST",
    UPDATE_PERMISSION_LIST = "UPDATE_PERMISSION_LIST",
    UPDATE_ROLE_LIST = "UPDATE_ROLE_LIST",
    UPDATE_REPORTEE_LIST = "UPDATE_REPORTEE_LIST",
    UPDATE_TEAM_MEMBER_LIST = "UPDATE_TEAM_MEMBER_LIST",
    UPDATE_ALL_EMPLOYEE_LIST = "UPDATE_ALL_EMPLOYEE_LIST"
}