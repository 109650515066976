import React from 'react';
import { Form, Button, theme, DatePicker, Modal } from 'antd';
import { useEmployee } from '../employee.hook';
import { AttendanceService } from '../../../service/attendance.service';
import { AttendanceReportReq } from '../../../request/attendance.request';
import { isMobile } from '../../../util/device-helper';

export const DownloadAttendanceReportComponent: React.FC<{ open: boolean, onCancel: () => void }> = ({ open, onCancel }) => {
    const [form] = Form.useForm();
    const { useToken } = theme;
    const { token } = useToken();
    const { loading, updateLoading } = useEmployee()

    const handleOk = () => {
        form.validateFields().then(async values => {
            updateLoading(true);
            const _attendanceReportReq = {} as AttendanceReportReq
            if (values["startDate"]) {
                _attendanceReportReq.startDate = (() => {
                    const date = new Date(values["startDate"])
                    date.setHours(0, 0, 0)
                    return date
                })()
            }
            if (values["endDate"]) {
                _attendanceReportReq.endDate = (() => {
                    const date = new Date(values["endDate"])
                    date.setHours(23, 59, 59)
                    return date
                })()
            }
            await AttendanceService.downLoadReportList(_attendanceReportReq)
            updateLoading(false);
        });
    };

    return (
        <Modal
            open={open}
            title="Download Attendance Report"
            onCancel={onCancel}
            confirmLoading={loading}
            onOk={handleOk}
            okText={"Submit"}
            cancelText={"Cancel"}
            style={{ top: 8, maxWidth: 768 }}
            width={'90vw'}
        >
            <Form form={form} layout="inline">
                <Form.Item
                    name="startDate"
                    style={{ minWidth: 124 }}
                >
                    <DatePicker
                        inputReadOnly={isMobile()}
                        style={{
                            border: '1px solid #92B5D7',
                            width: '98%',
                            borderRadius: token.borderRadius,
                            boxShadow: 'none',

                        }}
                        placeholder="Select start date" />
                </Form.Item>

                <Form.Item
                    name="endDate"
                    style={{ minWidth: 124 }}
                >
                    <DatePicker
                        inputReadOnly={isMobile()}
                        style={{
                            border: '1px solid #92B5D7',
                            width: '98%',
                            borderRadius: token.borderRadius,
                            boxShadow: 'none',

                        }}
                        placeholder="Select end date" />
                </Form.Item>

            </Form>
        </Modal>
    );
}
