import {
  Button,
  Spin,
  Card,
  Row,
  Col,
  Space,
  Form as AntdForm,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { AuthService } from "../../service/auth.service";
import { SecurityManager } from "../../security/security-manager";
import { useNavigate } from "react-router-dom";
import { UserService } from "../../service/user.service";
import { MainAction } from "../main.action";
import "../login/login.css";

export const NotValidEmployeePage = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const loggedIn = SecurityManager.loggedIn();

  const reLoad = () => {
    navigate("/", { replace: true });
  };

  const logOut = async () => {
    setLoading(true);
    const [data, error] = await AuthService.logOut();
    if (error) {
      setLoading(false);
    } else {
      SecurityManager.logout();
      setLoading(false);
    }
  };

  const checkSession = async () => {
    setLoading(true);
    const [userConfig, error] = await UserService.getUserConfig();

    if (error) {
      setLoading(false);
    } else {
      if (userConfig.employee) {
        logOut();
      } else {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (loggedIn) {
      checkSession();
    } else {
      navigate("/log-in", { replace: true });
    }
  }, []);

  return (
    <>
      {loading ? (
        <Spin />
      ) : (
        <>
          <div className="background-container">
            <Row className="gradient">
              <Col
                className="primary-heading-column flex-align-item-center"
                xs={24}
                lg={12}
                span={12}
              >
                <Typography.Title className="main-heading font-color-white">
                  RealtyBulls
                </Typography.Title>
              </Col>
              <Col
                className="login-card-column flex-align-item-center "
                xs={24}
                lg={12}
                span={12}
              >
                <Space
                  direction="vertical"
                  style={{
                    margin: 20,
                  }}
                >
                  <Typography.Title level={4} className="font-color-white">
                    Not verified Yet!
                  </Typography.Title>
                  <Card className="reg-card" hoverable bordered={false}>
                    {!loading && (
                      <>
                        <AntdForm>
                          <Typography.Paragraph className="font-color-white">
                            Ohh no!! Seems like you are not an employee yet.
                          </Typography.Paragraph>
                          <Typography.Paragraph className="font-color-white">
                            Please contact your HR manager to get started.
                          </Typography.Paragraph>
                          <Space wrap>
                            <AntdForm.Item wrapperCol={{ span: 24 }}>
                              <Button onClick={reLoad} type="primary">
                                Reload
                              </Button>
                            </AntdForm.Item>
                            <AntdForm.Item wrapperCol={{ span: 24 }}>
                              <Button onClick={logOut} type="primary">
                                Logout
                              </Button>
                            </AntdForm.Item>
                          </Space>
                        </AntdForm>
                      </>
                    )}
                  </Card>
                </Space>
              </Col>
            </Row>
          </div>
        </>
      )}
    </>
  );
};
