import { TaskStateProvider } from './task.context';
import { Outlet } from 'react-router-dom';

const TaskPageBase = () => {

    return <>
        <Outlet />
    </>
}


export const TaskPage = () => {
    return <TaskStateProvider>
        <TaskPageBase />
    </TaskStateProvider>
}

