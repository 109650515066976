import React from 'react';
import { Form, Input, Button } from 'antd';
import { RoleSearchReq } from '../../../request/role.request';
import { useRole } from '../role.hook';

export const RoleFilterComponent: React.FC = () => {
    const [form] = Form.useForm();
    const { fetchRoleList, updateLoading, roleSearchReq  } = useRole()

    const handleOk = () => {
        form.validateFields().then(values => {
            updateLoading(true);
            const _roleSearchReq = {
                ...roleSearchReq,
                name: values["name"],
                pageNumber: 1,
                itemsPerPage: 10
            } as RoleSearchReq
            fetchRoleList(_roleSearchReq, onSuccess, onFailure)
        });
    };

    const onSuccess = () => {
        updateLoading(false);
    }

    const onFailure = () => {
        updateLoading(false);
    }

    return (
        <Form form={form} layout="inline">
            <Form.Item
                name="name"
                style={{ minWidth: 124 }}
                initialValue={roleSearchReq.name}
            >
                <Input placeholder='Name' />
            </Form.Item>

            <Form.Item style={{ minWidth: 124 }}>
                <Button type="primary" htmlType="submit"
                    onClick={handleOk}>
                    Search
                </Button>
            </Form.Item>
        </Form>
    );
}
