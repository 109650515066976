import { Action } from "../../model/action.model";
import { ServiceAction } from "./service.action";
import { ServiceState } from "./service.state";


export const ServiceReducer = (state: ServiceState, action: Action): ServiceState => {
    const { type, payload } = action

    switch (type) {
        case ServiceAction.UPDATE_LOADING:
            return {
                ...state,
                loading: payload.loading
            };
        case ServiceAction.ADD_SERVICE_LIST:
            return {
                ...state,
                serviceList: [...state.serviceList, ...payload.serviceList]
            };
        case ServiceAction.UPDATE_SERVICE_LIST:
            return {
                ...state,
                serviceList: [...payload.serviceList]
            };
        case ServiceAction.UPDATE_SERVICE_SEARCH_REQ:
            return {
                ...state,
                serviceSearchReq: { ...payload.serviceSearchReq }
            };
        default:
            throw new Error(`No case for type ${type} found in ServiceReducer.`);
    }
}