import { PriorityThingRes } from "../response/report.response"
import httpClient from "../util/http-client"

export const ReportService = {
    getPriorityThing: async (): Promise<(PriorityThingRes | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.get("/report/priority-thing")
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    }
}