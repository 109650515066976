import { Col, Form, Input, Row, theme, Typography } from 'antd';

const PropertyDetailComponent = () => {

    const { useToken } = theme;
    const { token } = useToken();


    return (
        <>
            <h3 style={{ borderBottom: "2px solid #D9D9D9", paddingBottom: '8px', marginBottom: '12px' }}>Property Details</h3>
            <Row style={{ marginBottom: '10px' }}>
                <Col lg={6} md={12} xs={24} style={{ paddingRight: '20px', marginBottom: '20px' }}>
                    <Typography.Title level={5} style={{
                        fontSize: '14px'
                    }}>Tower</Typography.Title>
                    <Form.Item
                        style={{
                            margin: 0
                        }}
                        name="tower"
                        label=""
                        rules={[{ required: true, message: 'Please Enter Tower' }]}
                    >
                        <Input
                            type="text"
                            placeholder="janedoe890@ymail.com"
                            style={{
                                width: '100%',
                                border: '1px solid #92B5D7',
                                borderRadius: token.borderRadius,
                                boxShadow: 'none',
                                height: '40px'
                            }}
                        />
                    </Form.Item>
                </Col>

                <Col lg={6} md={12} xs={24} style={{ paddingRight: '20px', marginBottom: '20px' }}>
                    <Typography.Title level={5} style={{
                        fontSize: '14px',
                        fontWeight: 500
                    }}>Floor</Typography.Title>
                    <Form.Item
                        style={{
                            margin: 0
                        }}
                        name="floor"
                        label=""
                        rules={[{ required: true, message: 'Please Enter Floor' }]}
                    >
                        <Input
                            type="text"
                            placeholder="janedoe890@ymail.com"
                            style={{
                                width: '100%',
                                border: '1px solid #92B5D7',
                                borderRadius: token.borderRadius,
                                boxShadow: 'none',
                                height: '40px'
                            }}
                        />
                    </Form.Item>
                </Col>

                <Col lg={6} md={12} xs={24} style={{ paddingRight: '20px', marginBottom: '20px' }}>
                    <Typography.Title level={5} style={{
                        fontSize: '14px',
                        fontWeight: 500
                    }}>Unit Number</Typography.Title>
                    <Form.Item
                        style={{
                            margin: 0
                        }}
                        name="unitNumber"
                        label=""
                        rules={[{ required: true, message: 'Please Enter Unit Number' }]}
                    >
                        <Input
                            type="text"
                            placeholder="janedoe890@ymail.com"
                            style={{
                                width: '100%',
                                border: '1px solid #92B5D7',
                                borderRadius: token.borderRadius,
                                boxShadow: 'none',
                                height: '40px'
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col lg={6} md={12} xs={24} style={{ paddingRight: '20px', marginBottom: '20px' }}>
                    <Typography.Title level={5} style={{
                        fontSize: '14px',
                        fontWeight: 500
                    }}>Unit Type</Typography.Title>
                    <Form.Item
                        style={{
                            margin: 0
                        }}
                        name="unitType"
                        label=""
                        rules={[{ required: true, message: 'Please Enter Unit Type' }]}
                    >
                        <Input
                            type="text"
                            placeholder="janedoe890@ymail.com"
                            style={{
                                width: '100%',
                                border: '1px solid #92B5D7',
                                borderRadius: token.borderRadius,
                                boxShadow: 'none',
                                height: '40px'
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col lg={6} md={12} xs={24} style={{ paddingRight: '20px', marginBottom: '20px' }}>
                    <Typography.Title level={5} style={{
                        fontSize: '14px',
                        fontWeight: 500
                    }}>Area</Typography.Title>
                    <Form.Item
                        style={{
                            margin: 0
                        }}
                        name="area"
                        label=""
                        rules={[{ required: true, message: 'Please Enter Area' }]}
                    >
                        <Input
                            type="number"
                            placeholder="1234"
                            style={{
                                width: '100%',
                                border: '1px solid #92B5D7',
                                borderRadius: token.borderRadius,
                                boxShadow: 'none',
                                height: '40px'
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col lg={6} md={12} xs={24} style={{ paddingRight: '20px', marginBottom: '20px' }}>
                    <Typography.Title level={5} style={{
                        fontSize: '14px',
                        fontWeight: 500
                    }}>UOM (Unit of measure)</Typography.Title>
                    <Form.Item
                        style={{
                            margin: 0
                        }}
                        name="uom"
                        label=""
                        rules={[{ required: true, message: 'Please Enter Unit of measure' }]}
                    >
                        <Input
                            type="text"
                            placeholder="janedoe890@ymail.com"
                            style={{
                                width: '100%',
                                border: '1px solid #92B5D7',
                                borderRadius: token.borderRadius,
                                boxShadow: 'none',
                                height: '40px'
                            }}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </>
    )
}

export default PropertyDetailComponent