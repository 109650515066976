import React from 'react';
import { Modal, Form, Input } from 'antd';
import { useOffice } from '../office.hook';
import { OfficeRequest } from '../../../request/office.request';
import { validateOfficeName } from '../../../validator/level.validator';
import { Address } from '../../../model/address.model';
import { validateAddressLineOne, validateAddressLineTwo, validateAddressStreet, validateCity, validatePhone, validatePinCode, validateState } from '../../../validator/user-info.validator';
import { OfficeRes } from '../../../response/office.response';

export const UpdateOfficeComponent: React.FC<{ office: OfficeRes, open: boolean, onCancel: () => void }> = ({ office, open, onCancel }) => {
    const [form] = Form.useForm();
    const { loading, updateLoading, updateOffice } = useOffice()

    const handleOk = () => {
        form.validateFields().then(values => {
            updateLoading(true);
            const officeRequest = {
                name: values["name"],
                address: {
                    lineOne: values["lineOne"],
                    lineTwo: values["lineTwo"],
                    street: values["street"],
                    city: values["city"],
                    state: values["state"],
                    pinCode: values["pinCode"]
                } as Address,
                contact: values["contact"]
            } as OfficeRequest
            updateOffice(office.id, officeRequest, onSuccess, onFailure)
        });
    };

    const onSuccess = () => {
        updateLoading(false);
        form.resetFields()
        onCancel();
    }

    const onFailure = () => {
        updateLoading(false);
    }

    return (
        <Modal
            open={open}
            title="Add New Office"
            onCancel={onCancel}
            confirmLoading={loading}
            onOk={handleOk}
            okText={"Submit"}
            cancelText={"Cancel"}
            style={{ top: 8, maxWidth: 768 }}
            width={'90vw'}
        >
            <Form form={form} layout="vertical">
                <Form.Item
                    name="name"
                    label="Name"
                    rules={[{ required: true, validator: validateOfficeName }]}
                    initialValue={office.name}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="lineOne"
                    label="Address Line One"
                    rules={[{ required: true, validator: validateAddressLineOne }]}
                    initialValue={office.address.lineOne}
                >
                    <Input.TextArea rows={2} placeholder="Enter your address" />
                </Form.Item>
                <Form.Item
                    name="lineTwo"
                    label="Address Line Two"
                    rules={[{ required: true, validator: validateAddressLineTwo }]}
                    initialValue={office.address.lineTwo}
                >
                    <Input.TextArea rows={2} placeholder="Enter your address" />
                </Form.Item>

                <Form.Item
                    name="street"
                    label="Street"
                    rules={[{ required: true, validator: validateAddressStreet }]}
                    initialValue={office.address.street}
                >
                    <Input.TextArea rows={2} placeholder="Enter your street" />
                </Form.Item>
                <Form.Item
                    name="city"
                    label="City"
                    rules={[{ required: true, validator: validateCity }]}
                    initialValue={office.address.city}
                >
                    <Input placeholder="Enter your city" />
                </Form.Item>
                <Form.Item
                    name="state"
                    label="State"
                    rules={[{ required: true, validator: validateState }]}
                    initialValue={office.address.state}
                >
                    <Input placeholder="Enter your state" />
                </Form.Item>
                <Form.Item
                    name="pinCode"
                    label="Pin Code"
                    rules={[{ required: true, validator: validatePinCode }]}
                    initialValue={office.address.pinCode}
                >
                    <Input type='number' placeholder="Enter your pin code" />
                </Form.Item>

                <Form.Item
                    name="contact"
                    label="Contact"
                    rules={[{ required: true, validator: validatePhone }]}
                    initialValue={office.contact}
                >
                    <Input type='number' placeholder="Enter your pin code" />
                </Form.Item>
            </Form>
        </Modal>
    );
}