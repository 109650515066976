import { Action } from "../../model/action.model";
import { DashboardAction } from "./dashboard.action";
import { DashBoardState } from "./dashboard.state";

export const DashboardReducer = (state: DashBoardState, action: Action): DashBoardState => {
    const { type, payload } = action

    switch (type) {
        case DashboardAction.UPDATE_TODAY_ATTENDANCE:
            return {
                ...state,
                attendanceConfigRes: { ...payload.attendanceConfigRes }
            };
        case DashboardAction.UPDATE_PRIORITY_THING:
            return {
                ...state,
                priorityThingRes: { ...payload.priorityThingRes }
            };
        case DashboardAction.UPDATE_LOADING:
            return {
                ...state,
                loading: payload.loading
            };
        case DashboardAction.UPDATE_LEAD_REPORT_RES:
            return {
                ...state,
                leadReportResList: [...payload.leadReportResList]
            };
        case DashboardAction.UPDATE_ENQUIRY_REPOERT_RES:
            return {
                ...state,
                enquiryReportResList: [...payload.enquiryReportResList]
            };
        case DashboardAction.UPDATE_TASK_REPOERT_RES:
            return {
                ...state,
                taskReportList: [...payload.taskReportList]
            };
        default:
            throw new Error(`No case for type ${type} found in DashboardReducer.`);
    }
}