import { createContext, useReducer } from "react";
import { Session } from "../../model/session.model";
import { SendOtpReq, ValidateOtpReq } from "../../request/auth.request";
import { SecurityManager } from "../../security/security-manager";
import { AuthService } from "../../service/auth.service";
import { LogInAction } from "./login.action";
import { LogInReducer } from "./login.reducer";
import { LogInState, PageView } from "./login.state";

const initialState = {
  mobile: {},
  otp: {},
  activeView: PageView.MOBILE_VIEW,
  loading: false,
} as LogInState;

export const LogInContext = createContext<any>(initialState);

export const LogInStateProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(LogInReducer, initialState);

  const updateActiveView = (pageView: PageView) => {
    dispatch({
      type: LogInAction.UPDATE_ACTIVE_VIEW,
      payload: {
        activeView: pageView,
      },
    });
  };

  const updateMobile = (mobile: string) => {
    dispatch({
      type: LogInAction.UPDATE_NUMBER,
      payload: {
        mobile: {
          value: mobile,
          err: false,
          errMsg: "",
        },
      },
    });
  };

  const validateMobile = () => {
    const valid = state.mobile.value.length == 10;

    dispatch({
      type: LogInAction.VALIDATE_NUMBER,
      payload: {
        mobile: {
          err: valid,
          errMsg: valid ? "" : "Please Enter Valid Number",
        },
      },
    });
  };

  const updateOtp = (otp: string) => {
    dispatch({
      type: LogInAction.UPDATE_OTP,
      payload: {
        otp: {
          value: otp,
          err: false,
          errMsg: "",
        },
      },
    });
  };

  const validateOtp = () => {
    const valid = state.otp.value.length == 4;

    dispatch({
      type: LogInAction.VALIDATE_OTP,
      payload: {
        otp: {
          err: valid,
          errMsg: valid ? "" : "Please Enter Valid Otp",
        },
      },
    });
  };

  const updateLoading = (loading: Boolean) => {
    dispatch({
      type: LogInAction.UPDATE_LOADING,
      payload: {
        loading: loading,
      },
    });
  };

  const sendOtp = async (mobile: string) => {
    updateMobile(mobile);
    updateLoading(true);

    const sendOtpReq = {
      phone: mobile,
    } as SendOtpReq;

    const [sendOtpRes, err] = await AuthService.sendOtp(sendOtpReq);

    if (!err) {
      dispatch({
        type: LogInAction.UPDATE_SEND_OTP_RES,
        payload: {
          sendOtpRes: sendOtpRes,
        },
      });
      updateActiveView(PageView.OTP_VIEW);
    }

    updateLoading(false);
  };

  const submitOtp = async (
    validateOtpReq: ValidateOtpReq,
    onSuccess: () => void,
    onFailure: () => void
  ) => {
    const [validateOtpRes, error] = await AuthService.validateOtp(
      validateOtpReq
    );

    if (error) {
      onFailure();
    } else {
      onSuccess();
    }

    const session = {
      id: validateOtpRes.userId,
      name: "",
      isLoggedIn: true,
      authToken: validateOtpRes.authToken,
      refreshToken: validateOtpRes.refreshToken,
      phone: validateOtpRes.phone,
      verified: true,
      employeeId: validateOtpRes.employeeId,
    } as Session;

    SecurityManager.setSession(session);
  };

  const value = {
    activeView: state.activeView,
    mobile: state.mobile,
    otp: state.otp,
    loading: state.loading,
    sendOtpRes: state.sendOtpRes,
    updateActiveView,
    updateMobile,
    validateMobile,
    updateOtp,
    validateOtp,
    updateLoading,
    sendOtp,
    submitOtp,
  };

  return (
    <LogInContext.Provider value={value}>{children}</LogInContext.Provider>
  );
};