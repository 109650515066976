import axios from 'axios';
import { SecurityManager } from '../security/security-manager';
import { AuthService } from '../service/auth.service';
import { BASE_URL } from './env-helper';

const httpClient = axios.create({
    baseURL: BASE_URL,
    timeout: 120000,
    headers: {
        "Content-Type": "application/json"
    }
});

httpClient.interceptors.request.use(
    async (config) => {
        const token = SecurityManager.getAuthToken();
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

httpClient.interceptors.response.use(
    (response) => {
        return response;
    },
    async function (error) {
        const originalRequest = error.config;
        if (error.response.status === 403 && !originalRequest._retry) {
            originalRequest._retry = true;

            const [authTokenRes, err] = await AuthService.getAuthToken()

            SecurityManager.updateAuthToken(authTokenRes.authToken);
            httpClient.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${SecurityManager.getAuthToken()}`;
            return httpClient(originalRequest);
        } else if (error.response.status === 401) {
            SecurityManager.logout()
        }
        return Promise.reject(error);
    }
);

export default httpClient