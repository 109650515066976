import { Button, Space, Spin, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { OfficeRes } from "../../../response/office.response";
import { useOffice } from "../office.hook";
import { AddOfficeComponent } from "./add-office.component";
import { UpdateOfficeComponent } from "./update-office.component";
import { OfficeFilterComponent } from "./filter.component";
import { OfficeDetailComponent } from "./office-detail.component";

export const MainOfficeTableComponent = () => {
  const {
    loading,
    officeList,
    fetchOfficeList,
    officeSearchReq,
    updateLoading,
  } = useOffice();
  const [addNewOffice, setOpenNewOffice] = useState(false);
  const [officeDetail, setOfficeDetail] = useState<OfficeRes | undefined>(
    undefined
  );
  const [updateOffice, setUpdateOffice] = useState<OfficeRes | undefined>(
    undefined
  );

  const cancelOfficeEdit = () => {
    setUpdateOffice((prevVal) => undefined);
  };

  const closeOfficeDetail = () => {
    setOfficeDetail((prevVal) => undefined);
  };

  const updateOpenNewOffice = () => {
    if (addNewOffice) {
      fetchOfficeList(
        { ...officeSearchReq, pageNumber: 1 },
        () => {
          updateLoading(false);
        },
        () => {
          updateLoading(false);
        }
      );
    }
    setOpenNewOffice((prevVal) => !prevVal);
  };

  useEffect(() => {
    fetchOfficeList(
      { ...officeSearchReq, pageNumber: 1 },
      () => {
        updateLoading(false);
      },
      () => {
        updateLoading(false);
      }
    );
  }, []);

  const columnList: ColumnsType<OfficeRes> = [
    {
      title: "Office id",
      dataIndex: "id",
      key: "id",
      onCell: (office, rowIndex) => {
        return {
          onClick: (ev) => {
            setOfficeDetail(office);
          },
        };
      },
      render: (id) => (
        <a>{`${String(id.substring(0, 4) + "...").padStart(3, "0")}`}</a>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name) => <span>{name}</span>,
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (address) => (
        <span>{`${address?.lineOne ? `${address?.lineOne} ,` : ""} ${address?.lineTwo ? `${address?.lineTwo} ,` : ""
          } ${address?.street ? `${address?.street} ,` : ""} ${address?.city ? `${address?.city} ,` : ""
          } ${address?.state ? `${address?.state}` : ""}`}</span>
      ),
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
      render: (contact) => <span>+91 {contact}</span>,
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      onCell: (office, rowIndex) => {
        return {
          onClick: (ev) => {
            setUpdateOffice(office);
          },
        };
      },
      render: (id) => <a>Edit</a>,
    },
  ];

  return (
    <div style={{ marginTop: -42 }}>
      <Space
        direction="horizontal"
        style={{ width: "100%", justifyContent: "end", marginBottom: 8 }}
      >
        <OfficeFilterComponent />
        <Button type="primary" onClick={updateOpenNewOffice}>
          Add New
        </Button>
      </Space>
      <Table
        className="task-main-table task-table"
        columns={columnList}
        dataSource={officeList}
        pagination={{
          defaultPageSize: 30,
          showSizeChanger: true,
          pageSizeOptions: ["20", "50", "100", "200", "300", "400", "500"],
        }}
        loading={{
          indicator: (
            <div>
              <Spin />
            </div>
          ),
          spinning: loading,
        }}
      />

      {addNewOffice && (
        <AddOfficeComponent
          open={addNewOffice}
          onCancel={updateOpenNewOffice}
        />
      )}
      {officeDetail && (
        <OfficeDetailComponent
          officeDetail={officeDetail}
          open={true}
          onCancel={closeOfficeDetail}
        />
      )}
      {updateOffice && (
        <UpdateOfficeComponent
          office={updateOffice}
          open={true}
          onCancel={cancelOfficeEdit}
        />
      )}
    </div>
  );
};
