import { createContext, useReducer } from "react";
import { OfficeState } from "./office.state";
import { OfficeReducer } from "./office.reducer";
import { OfficeAction } from "./office.action";
import { OfficeService } from "../../service/office.service";
import { OfficeRes } from "../../response/office.response";
import { OfficeRequest, OfficeSearchReq } from "../../request/office.request";

const initialState = {
    loading: false,
    officeSearchReq: {
        pageNumber: 1,
        itemsPerPage: 100
    } as OfficeSearchReq,
    officeList: [],
    updateOfficeData: {} as OfficeRes
} as OfficeState

export const OfficeContext = createContext<any>(initialState);

export const OfficeStateProvider = ({ children }: any) => {

    const [state, dispatch] = useReducer(OfficeReducer, initialState)


    const updateLoading = (loading: Boolean) => {
        dispatch({
            type: OfficeAction.UPDATE_LOADING,
            payload: {
                loading: loading
            }
        });
    };

    const fetchOfficeList = async (officeSearchReq: OfficeSearchReq, onSuccess: () => void, onFailure: () => void) => {
        updateLoading(true)
        const [officeList, error] = await OfficeService.getOfficeList(officeSearchReq)

        if (error) {
            onFailure()
        } else {
            dispatch({
                type: officeSearchReq.pageNumber == 1 ? OfficeAction.UPDATE_OFFICE_LIST : OfficeAction.ADD_OFFICE_LIST,
                payload: {
                    officeList: officeList
                }
            })
            if (officeList.length > 0) {
                dispatch({
                    type: OfficeAction.UPDATE_OFFICE_SEARCH_REQ,
                    payload: {
                        officeSearchReq: {
                            ...officeSearchReq,
                            pageNumber: officeSearchReq.pageNumber + 1
                        }
                    }
                })
            }
            onSuccess()
        }
    };

    const saveOffice = async (officeRequest: OfficeRequest, onSuccess: () => void, onFailure: () => void) => {
        const [data, error] = await OfficeService.saveOffice(officeRequest)

        if (error) {
            onFailure()
        } else {
            onSuccess()
        }
    }

    const updateOffice = async (id: string, officeRequest: OfficeRequest, onSuccess: () => void, onFailure: () => void) => {
        const [data, error] = await OfficeService.updateOffice(id, officeRequest)

        if (error) {
            onFailure()
        } else {
            onSuccess()
        }
    }

    const value = {
        officeList: state.officeList,
        loading: state.loading,
        officeSearchReq: state.officeSearchReq,
        updateLoading,
        fetchOfficeList,
        saveOffice,
        updateOffice
    };

    return <OfficeContext.Provider value={value}>{children}</OfficeContext.Provider>
};