import { createContext, useReducer } from "react";
import { EnquiryState } from "./enquiry.state";
import { EnquiryReducer } from "./enquiry.reducer";
import { EnquiryAction } from "./enquiry.action";
import { EnquiryService } from "../../service/enquiry.service";
import { BulkEnquiryUpdateReq, EnquiryRequest, EnquirySearchReq } from "../../request/enquiry.request";
import { EnquiryRes } from "../../response/enquiry.response";
import { EmployeeSearchReq } from "../../request/employee.request";
import { EmployeeService } from "../../service/employee.service";
import { ProjectSearchReq } from "../../request/project.request";
import { ProjectService } from "../../service/project.service";
import { LeadService } from "../../service/lead.service";
import { LeadRequest } from "../../request/lead.request";
import { UserService } from "../../service/user.service";
import { UserRes } from "../../response/user.response";
import { ItemProp } from "../../model/item-prop.model";
import { SecurityManager } from "../../security/security-manager";
import dayjs from "dayjs";
import { getDataInLocalStorage, setDataInLocalStorage } from "../../util/local-storage-helper";


const _statusList = [
    { label: "Pending", value: "Pending" },
    { label: "In Progress", value: "In Progress" },
    { label: "Dump / Spam", value: "Dump/Spam" },
    { label: "Not Interested", value: "Not Interested" },
    { label: "Closed", value: "Closed" },
]

const _modeOfCommunicationList = [
    { label: 'Phone', value: 'Phone' },
    { label: 'SMS', value: 'SMS' },
    { label: 'Email', value: 'Email' },
]

const _noteStatusList = {
    "Follow Up": [
        { label: 'Scheduled', value: 'Scheduled' } as ItemProp,
        { label: 'In Progress', value: 'In Progress' } as ItemProp,
        { label: 'ReScheduled', value: 'ReScheduled' } as ItemProp,
        { label: 'Client Call Missed', value: 'Client Call Missed' } as ItemProp,
        { label: 'Agent Call Missed', value: 'Agent Call Missed' } as ItemProp,
        { label: 'Closed', value: 'Closed' } as ItemProp
    ]
}

const _noteTypeList = [
    { label: 'Follow Up', value: 'Follow Up' },
]

const _genderList = [
    { label: 'Male', value: 'Male' },
    { label: 'Female', value: 'Female' },
]

const _sourceList = [
    { label: 'Portal', value: 'Portal' },
    { label: 'Housing', value: 'Housing' },
    { label: '99Acre', value: 'Acre' },
    { label: 'Facebook', value: 'Facebook' },
    { label: 'Google', value: 'Google' },
    { label: 'SMS', value: 'SMS' },
    { label: 'EMail', value: 'EMail' },
    { label: 'IVR', value: 'IVR' },
    { label: 'Website', value: 'Website' },
    { label: 'Other', value: 'Other' },
]

const _unitTypeList = [
    { label: "Apartment", value: "Apartment" },
    { label: "Villa", value: "Villa" },
    { label: "Builder Floor", value: "Builder Floor" },
    { label: "Plot", value: "Plot" },
    { label: "Shop", value: "Shop" },
    { label: "Office Space", value: "Office Space" },
    { label: "Food Court", value: "Food Court" },
]

const _categoryTypeList = [
    { label: "Residential", value: "Residential" },
    { label: "Commercial", value: "Commercial" },
]

const _rejectResonList = {
    "Dump/Spam": [
        { label: "Dump / Spam", value: "Dump / Spam" },
        { label: "Invalid Number", value: "Invalid Number" },
        { label: "Wrong Number", value: "Wrong Number" },
        { label: "Not Related to Property", value: "Not Related to Property" },
        { label: "Broker", value: "Broker" },
    ],
    "Not Interested": [
        { label: "Dropped or Postponed the idea to invest", value: "Dropped or Postponed the idea to invest" },
        { label: "Not Interested", value: "Not Interested" },
        { label: "Always Ringing No Response", value: "Always Ringing No Response" },
        { label: "Always Phone Switched Off", value: "Always Phone Switched Off" },
        { label: "Always Not Reachable", value: "Always Not Reachable" },
        { label: "Enquired by Mistake", value: "Enquired by Mistake" },
        { label: "Out of budget", value: "Out of budget" },
        { label: "Already bought", value: "Already bought" }
    ]
}

const today = new Date()
const lastDay = new Date()
lastDay.setDate(today.getDate() - 50)

const defaultReq = {
    searchText: "",
    statusList: [] as string[],
    assigneeIdList: [SecurityManager.getEid()],
    // assignedStartDate: lastDay,
    // assignedEndDate: today,
    pageNumber: 1,
    itemsPerPage: 1000
} as EnquirySearchReq

const initialState = {
    loading: false,
    enquirySearchReq: getDataInLocalStorage<EnquirySearchReq>("ENQUIRY_PAGE_FILTER", defaultReq),
    statusList: _statusList,
    enquiryList: [],
    enquiryGroupList: [],
    updateEnquiryData: {} as EnquiryRes,
    employeeList: [],
    employeeSearchReq: {
        pageNumber: 1,
        itemsPerPage: 200
    } as EmployeeSearchReq,
    projectList: [],
    projectSearchReq: {
        pageNumber: 1,
        itemsPerPage: 500
    } as ProjectSearchReq,
    enquiry: {} as EnquiryRes,
    modeOfCommunicationList: _modeOfCommunicationList,
    noteTypeList: _noteTypeList,
    noteStatusList: _noteStatusList,
    genderList: _genderList,
    client: {} as UserRes,
    sourceList: _sourceList,
    unitTypeList: _unitTypeList,
    categoryTypeList: _categoryTypeList,
    rejectResonList: _rejectResonList
} as EnquiryState

export const EnquiryContext = createContext<any>(initialState);

export const EnquiryStateProvider = ({ children }: any) => {

    const [state, dispatch] = useReducer(EnquiryReducer, initialState)

    const updateLoading = (loading: Boolean) => {
        dispatch({
            type: EnquiryAction.UPDATE_LOADING,
            payload: {
                loading: loading
            }
        });
    };

    const fetchEnquiryList = async (enquirySearchReq: EnquirySearchReq, onSuccess: () => void, onFailure: () => void) => {
        updateLoading(true)
        const [enquiryList, error] = await EnquiryService.getEnquiryList(enquirySearchReq)

        if (error) {
            onFailure()
        } else {
            dispatch({
                type: enquirySearchReq.pageNumber == 1 ? EnquiryAction.UPDATE_ENQUIRY_LIST : EnquiryAction.ADD_ENQUIRY_LIST,
                payload: {
                    enquiryList: enquiryList
                }
            })
            if (enquirySearchReq.pageNumber == 1) {
                setDataInLocalStorage("ENQUIRY_PAGE_FILTER", enquirySearchReq)
            }
            if (enquiryList.length > 0) {
                dispatch({
                    type: EnquiryAction.UPDATE_ENQUIRY_SEARCH_REQ,
                    payload: {
                        enquirySearchReq: {
                            ...enquirySearchReq,
                            pageNumber: enquirySearchReq.pageNumber + 1
                        }
                    }
                })
            }
            onSuccess()
        }
    };

    const fetchEnquiry = async (id: string, onSuccess: () => void, onFailure: () => void) => {
        const [enquiry, error] = await EnquiryService.getEnquiryById(id)

        if (error) {
            onFailure()
        } else {
            dispatch({
                type: EnquiryAction.UPDATE_ENQUIRY,
                payload: {
                    enquiry: {
                        ...enquiry
                    }
                }
            })
            onSuccess()
        }
    };

    const saveEnquiry = async (enquiryRequest: EnquiryRequest, onSuccess: () => void, onFailure: () => void) => {
        const [data, error] = await EnquiryService.saveEnquiry(enquiryRequest)

        if (error) {
            onFailure()
        } else {
            onSuccess()
        }
    }

    const updateEnquiry = async (id: string, enquiryRequest: EnquiryRequest, onSuccess: () => void, onFailure: () => void) => {
        const [data, error] = await EnquiryService.updateEnquiry(id, enquiryRequest)

        if (error) {
            onFailure()
        } else {
            onSuccess()
        }
    }

    const fetchEmployeeList = async () => {
        const [employeeList, error] = await EmployeeService.getReporteeList()
        if (error) {

        } else {
            dispatch({
                type: EnquiryAction.UPDATE_EMPLOYEE_LIST,
                payload: {
                    employeeList: employeeList
                }
            });
        }
    };

    const fetchProjectList = async () => {
        const [data, error] = await ProjectService.getProjectList(state.projectSearchReq)
        if (error) {

        } else {
            dispatch({
                type: EnquiryAction.UPDATE_PROJECT_LIST,
                payload: {
                    projectList: data
                }
            });
        }
    };

    const saveLead = async (leadRequest: LeadRequest, onSuccess: () => void, onFailure: () => void) => {
        const [data, error] = await LeadService.saveLead(leadRequest)

        if (error) {
            onFailure()
        } else {
            onSuccess()
        }
    }

    const fetchClient = async (id: string, onSuccess: () => void, onFailure: () => void) => {
        const [client, error] = await UserService.getUserById(id)

        if (error) {
            onFailure()
        } else {
            dispatch({
                type: EnquiryAction.UPDATE_CLIENT,
                payload: {
                    client: {
                        ...client
                    }
                }
            })
            onSuccess()
        }
    };

    const updateBulkEnquiry = async (bulkEnquiryUpdateReq: BulkEnquiryUpdateReq, onSuccess: () => void, onFailure: () => void) => {
        const [data, error] = await EnquiryService.updateBulkEnquiry(bulkEnquiryUpdateReq)

        if (error) {
            onFailure()
        } else {
            onSuccess()
        }
    }

    const value = {
        enquiryList: state.enquiryList,
        enquiryGroupList: state.enquiryGroupList,
        enquirySearchReq: state.enquirySearchReq,
        loading: state.loading,
        employeeList: state.employeeList,
        statusList: state.statusList,
        projectList: state.projectList,
        enquiry: state.enquiry,
        modeOfCommunicationList: state.modeOfCommunicationList,
        noteStatusList: state.noteStatusList,
        noteTypeList: state.noteTypeList,
        genderList: state.genderList,
        client: state.client,
        sourceList: state.sourceList,
        unitTypeList: state.unitTypeList,
        categoryTypeList: state.categoryTypeList,
        rejectResonList: state.rejectResonList,
        updateLoading,
        saveEnquiry,
        fetchEnquiryList,
        fetchEmployeeList,
        fetchProjectList,
        saveLead,
        fetchEnquiry,
        fetchClient,
        updateBulkEnquiry
    };

    return <EnquiryContext.Provider value={value}>{children}</EnquiryContext.Provider>
};