import { createContext, useReducer } from "react";
import { ServiceState } from "./service.state";
import { ServiceReducer } from "./service.reducer";
import { ServiceAction } from "./service.action";
import { ServiceService } from "../../service/service.service";
import { ServiceRequest, ServiceSearchReq } from "../../request/service.request";
import { UserService } from "../../service/user.service";

const initialState = {
    loading: false,
    serviceSearchReq: {
        pageNumber: 1,
        itemsPerPage: 100
    } as ServiceSearchReq,
    serviceList: [],
} as ServiceState

export const ServiceContext = createContext<any>(initialState);

export const ServiceStateProvider = ({ children }: any) => {

    const [state, dispatch] = useReducer(ServiceReducer, initialState)

    const updateLoading = (loading: Boolean) => {
        dispatch({
            type: ServiceAction.UPDATE_LOADING,
            payload: {
                loading: loading
            }
        });
    };

    const fetchServiceList = async (serviceSearchReq: ServiceSearchReq, onSuccess: () => void, onFailure: () => void) => {
        updateLoading(true)
        const [serviceList, error] = await ServiceService.getServiceList(serviceSearchReq)

        if (error) {
            onFailure()
        } else {
            dispatch({
                type: serviceSearchReq.pageNumber == 1 ? ServiceAction.UPDATE_SERVICE_LIST : ServiceAction.ADD_SERVICE_LIST,
                payload: {
                    serviceList: serviceList
                }
            })
            if (serviceList.length > 0) {
                dispatch({
                    type: ServiceAction.UPDATE_SERVICE_SEARCH_REQ,
                    payload: {
                        serviceSearchReq: {
                            ...serviceSearchReq,
                            pageNumber: serviceSearchReq.pageNumber + 1
                        }
                    }
                })
            }
            onSuccess()
        }
    };

    const saveService = async (serviceRequest: ServiceRequest, onSuccess: () => void, onFailure: () => void) => {
        const [data, error] = await ServiceService.saveService(serviceRequest)

        if (error) {
            onFailure()
        } else {
            onSuccess()
        }
    }

    const updateService = async (id: string, serviceRequest: ServiceRequest, onSuccess: () => void, onFailure: () => void) => {
        const [data, error] = await ServiceService.updateService(id, serviceRequest)

        if (error) {
            onFailure()
        } else {
            onSuccess()
        }
    }

    const value = {
        serviceList: state.serviceList,
        loading: state.loading,
        serviceSearchReq: state.serviceSearchReq,
        updateLoading,
        fetchServiceList,
        saveService,
        updateService,
    };

    return <ServiceContext.Provider value={value}>{children}</ServiceContext.Provider>
};