import { Action } from "../../model/action.model";
import { TeamAction } from "./team.action";
import { TeamState } from "./team.state";


export const TeamReducer = (state: TeamState, action: Action): TeamState => {
    const { type, payload } = action

    switch (type) {
        case TeamAction.UPDATE_LOADING:
            return {
                ...state,
                loading: payload.loading
            };
        case TeamAction.UPDATE_TEAM_LIST:
            return {
                ...state,
                teamList: [...payload.teamList]
            };
        case TeamAction.ADD_TEAM_LIST:
            return {
                ...state,
                teamList: [...state.teamList, ...payload.teamList]
            };
        case TeamAction.UPDATE_TEAM_SEARCH_REQ:
            return {
                ...state,
                teamSearchReq: { ...payload.teamSearchReq }
            };
        case TeamAction.SET_UPDATE_TEAM:
            return {
                ...state,
                updateTeamData: { ...payload.updateTeamData }
            };
        case TeamAction.UPDATE_EMPLOYEE_LIST:
            return {
                ...state,
                employeeList: [...state.employeeList, ...payload.employeeList]
            };
        case TeamAction.UPDATE_OFFICE_LIST:
            return {
                ...state,
                officeList: [...state.officeList, ...payload.officeList]
            };
        case TeamAction.UPDATE_TEAM_TYPE_LIST:
            return {
                ...state,
                teamTypeList: [...state.teamTypeList, ...payload.teamTypeList]
            };
        default:
            throw new Error(`No case for type ${type} found in TeamReducer.`);
    }
}