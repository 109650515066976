import React from "react";
import { Col, Form, Row, Select, Tag, theme } from "antd";
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import ImgIcon from "../../../../view/img-icon";
import { handleFilter } from "../../../../view/input.helper";


const tagListTagsRender = (props: CustomTagProps) => {
    const { label, value, closable, onClose } = props;
    
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const colorObj = "#002A52"

    return (
        <Tag
            color={colorObj}
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            style={{ margin: 3, textTransform: 'capitalize' }}
        >
            {label}
        </Tag>
    );
};


const TagListFilterComponent = (props: any) => {

    const { useToken } = theme;
    const { token } = useToken();

    return (
        <Row style={{ flexDirection: 'column', width: '100%' }}>
            <Col style={{ display: 'flex' }}>
                <ImgIcon iconName="taglist.svg" iconWidth="18px" />
                Tags
            </Col>
            <Col style={{ marginTop: '10px' }}>
                <Form.Item
                    name="tagList"
                    initialValue={props.taskSearchReq.tagList}
                >

                    <Select
                        mode="tags"
                        showArrow
                        tagRender={tagListTagsRender}
                        className="custom-select"
                        style={{ width: '100%', border: '1px solid #92b5d7', borderRadius: token.borderRadiusSM }}
                        placeholder="Tags"
                        filterOption={handleFilter}
                    />

                </Form.Item>
            </Col>
        </Row>
    )
}

export default TagListFilterComponent