import React, { useEffect } from 'react';
import { Modal, Form, Input, TreeSelect } from 'antd';
import { useService } from '../service.hook';
import { ServiceRequest } from '../../../request/service.request';
import { validateLevel, validateLevelName } from '../../../validator/level.validator';
import { ServiceRes } from '../../../response/service.response';
import { TextEditorView } from '../../../view/text-editor.view';
import FileUploadView from '../../../view/file-upload.view';
import { Media } from '../../../model/media.model';

export const UpdateServiceComponent: React.FC<{ service: ServiceRes, open: boolean, onCancel: () => void }> = ({ service, open, onCancel }) => {
    const [form] = Form.useForm();
    const { loading, updateLoading, updateService } = useService()

    const handleOk = () => {
        form.validateFields().then(values => {
            updateLoading(true);
            const serviceRequest = {
                name: values["name"],
                order: Number(values["order"]),
                description: values["description"],
                thumbnail: values["thumbnail"]
            } as ServiceRequest
            updateService(service.id, serviceRequest, onSuccess, onFailure)
        });
    };

    const onSuccess = () => {
        updateLoading(false);
        form.resetFields()
        onCancel();
    }

    const onFailure = () => {
        updateLoading(false);
    }

    return (
        <Modal
            open={open}
            title="Add New Service"
            onCancel={onCancel}
            confirmLoading={loading}
            onOk={handleOk}
            okText={"Submit"}
            cancelText={"Cancel"}
            style={{ top: 8, maxWidth: 768 }}
            width={'90vw'}
        >
            <Form form={form} layout="vertical">
                <Form.Item
                    name="name"
                    label="Name"
                    rules={[{ required: true, validator: validateLevelName }]}
                    initialValue={service.name}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="order"
                    label="Order"
                    rules={[{ required: true, validator: validateLevel }]}
                    initialValue={service.order}
                >
                    <Input placeholder="Enter level" />
                </Form.Item>

                <Form.Item
                    name="description"
                    label="Description"
                    rules={[{ required: true, message: 'Please enter description' }]}
                >
                    <TextEditorView placeholder={'Please enter description'}
                        classes="editor-custom-view"
                        onChange={(data: string) => { }}
                        readOnly={false}
                        initData={service.description} />
                </Form.Item>

                <Form.Item
                    name="thumbnail"
                    label="Thumbnail"
                    initialValue={service.thumbnail}
                >
                    <FileUploadView onChange={(media: Media) => { }} />
                </Form.Item>

            </Form>
        </Modal>
    );
}