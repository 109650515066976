import { createContext, useReducer } from "react";
import { AmenitiesState } from "./amenities.state";
import { AmenitiesReducer } from "./amenities.reducer";
import { AmenitiesAction } from "./amenities.action";
import { AmenitiesService } from "../../service/amenities.service";
import { AmenitiesRequest, AmenitiesSearchReq } from "../../request/amenities.request";
import { UserService } from "../../service/user.service";

const initialState = {
    loading: false,
    amenitiesSearchReq: {
        pageNumber: 1,
        itemsPerPage: 100
    } as AmenitiesSearchReq,
    amenitiesList: [],
    permissionList: []
} as AmenitiesState

export const AmenitiesContext = createContext<any>(initialState);

export const AmenitiesStateProvider = ({ children }: any) => {

    const [state, dispatch] = useReducer(AmenitiesReducer, initialState)

    const updateLoading = (loading: Boolean) => {
        dispatch({
            type: AmenitiesAction.UPDATE_LOADING,
            payload: {
                loading: loading
            }
        });
    };

    const fetchAmenitiesList = async (amenitiesSearchReq: AmenitiesSearchReq, onSuccess: () => void, onFailure: () => void) => {
        updateLoading(true)
        const [amenitiesList, error] = await AmenitiesService.getAmenitiesList(amenitiesSearchReq)

        if (error) {
            onFailure()
        } else {
            dispatch({
                type: amenitiesSearchReq.pageNumber == 1 ? AmenitiesAction.UPDATE_AMENITIES_LIST : AmenitiesAction.ADD_AMENITIES_LIST,
                payload: {
                    amenitiesList: amenitiesList
                }
            })
            if (amenitiesList.length > 0) {
                dispatch({
                    type: AmenitiesAction.UPDATE_AMENITIES_SEARCH_REQ,
                    payload: {
                        amenitiesSearchReq: {
                            ...amenitiesSearchReq,
                            pageNumber: amenitiesSearchReq.pageNumber + 1
                        }
                    }
                })
            }
            onSuccess()
        }
    };

    const saveAmenities = async (amenitiesRequest: AmenitiesRequest, onSuccess: () => void, onFailure: () => void) => {
        const [data, error] = await AmenitiesService.saveAmenities(amenitiesRequest)

        if (error) {
            onFailure()
        } else {
            onSuccess()
        }
    }

    const updateAmenities = async (id: string, amenitiesRequest: AmenitiesRequest, onSuccess: () => void, onFailure: () => void) => {
        const [data, error] = await AmenitiesService.updateAmenities(id, amenitiesRequest)

        if (error) {
            onFailure()
        } else {
            onSuccess()
        }
    }

    const value = {
        amenitiesList: state.amenitiesList,
        loading: state.loading,
        amenitiesSearchReq: state.amenitiesSearchReq,
        updateLoading,
        fetchAmenitiesList,
        saveAmenities,
        updateAmenities,
    };

    return <AmenitiesContext.Provider value={value}>{children}</AmenitiesContext.Provider>
};