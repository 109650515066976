import { Avatar, Button, Card, Col, Progress, Row, theme, Typography } from "antd"
import { EmployeeRes } from "../../../../response/employee.response";
import { TeamRes } from "../../../../response/team.response";
import { UserOutlined } from "@ant-design/icons";
import { getColor } from "../../../../enum/static-data.enum";
import { useNavigate } from "react-router-dom";
import { formatName } from "../../../../util/name-helper";

export interface EmpCardViewComponentProps {
    employeeRes: EmployeeRes
}

export const EmpCardViewComponent = (props: EmpCardViewComponentProps) => {
    const { useToken } = theme;
    const { token } = useToken();
    const navigate = useNavigate();

    const { employeeRes } = props

    const progressColorObj = {
        35: '#EC5A07',
        50: '#EC9007',
        75: '#DAEC07',
        90: '#95EC07',
    }

    const viewEmployeeReport = (employeeId: string) => {
        navigate(`/employee/${employeeId}/report`)
    }

    return (
        <Col md={12} xs={24} style={{
            background: 'transparent'
        }}>
            <Card
                style={{
                    margin: '0 5px 10px',
                }}
                bodyStyle={{
                    padding: 15
                }}
                title=""
                bordered={false}
            >
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    color: token.colorTextBase,
                    borderBottom: '1px solid #D9D9D9'
                }}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <span style={{
                            fontWeight: 500
                        }}>{'ID'}</span>
                        <Typography.Title style={{
                            color: token.colorInfo,
                            fontSize: 14,
                            margin: '0 0 0 5px'
                        }} level={5}>{`RB${String(employeeRes.employeeId).padStart(3, '0')}`}</Typography.Title>
                    </div>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: 'auto'
                    }}>
                        <span style={{
                            width: 6,
                            height: 6,
                            background: `${employeeRes.online ? token.colorSuccess : token.colorBgMask}`,
                            borderRadius: '100%',
                            display: 'flex',
                            marginRight: 5
                        }}></span>
                        {employeeRes.online ? "Online" : "Offline"}
                    </div>
                </div>

                <div className="emp-body-wrapper" style={{
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '15px 0 0'
                }}>
                    <div style={{ marginBottom: 64 }}>
                        {(employeeRes.userInfo.profilePic?.url?.main) ? <Avatar size={64} src={employeeRes.userInfo.profilePic?.url?.main} style={{ backgroundColor: getColor(employeeRes.userInfo.name) }} />
                            : <Avatar shape="circle" style={{ backgroundColor: getColor(employeeRes.userInfo.name[0]) }} size={64}>{employeeRes.userInfo.name[0]?.toUpperCase()}</Avatar>}
                    </div>
                    <Row className="emp-card-wrapper" style={{
                        marginLeft: 15
                    }}>
                        <Col md={8} xs={24} style={{
                            marginBottom: 5
                        }}>
                            <div style={{
                                fontWeight: 600
                            }}>Name</div>
                            <div>{formatName(employeeRes.userInfo.name)}</div>
                        </Col>
                        <Col md={16} xs={24} style={{
                            marginBottom: 5
                        }}>
                            <div style={{
                                fontWeight: 600
                            }}>Email</div>
                            <div>{employeeRes.userInfo.email}</div>
                        </Col>
                        <Col md={8} xs={24} style={{
                            marginBottom: 5
                        }}>
                            <div style={{
                                fontWeight: 600
                            }}>Team</div>
                            <div>{employeeRes.teamList.map((team: TeamRes) => {
                                return team.name
                            }).join(', ')}</div>
                        </Col>
                        <Col md={16} xs={24} style={{
                            marginBottom: 5
                        }}>
                            <div style={{
                                fontWeight: 600
                            }}>Mobile Number</div>
                            <div>+91 {employeeRes.userInfo.phone}</div>
                        </Col>
                        <Col md={8} xs={24} style={{
                            marginBottom: 5
                        }}>
                            <div style={{
                                fontWeight: 600
                            }}>Reporting Manager</div>
                            <div>{employeeRes.manager.userInfo.name}</div>
                        </Col>
                        <Col md={16} xs={24} style={{
                            marginBottom: 5
                        }}>
                            <div style={{
                                fontWeight: 600
                            }}>Role</div>
                            <div>{employeeRes.role.name}</div>
                        </Col>
                    </Row>
                </div>
                <div style={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <div style={{
                        display: 'flex',
                        width: "60%"
                    }}>
                        <div style={{
                            fontWeight: 600,
                            marginRight: 5
                        }}>Score</div>
                        <Progress percent={employeeRes.score} status="active" strokeColor={progressColorObj[90]} style={{
                            margin: 0
                        }} />
                    </div>

                    <Button type="link" style={{
                        marginLeft: 'auto'
                    }} onClick={() => { viewEmployeeReport(employeeRes.id) }}>
                        View Details {'>>>'}
                    </Button>
                </div>
            </Card>
        </Col>
    )
}