import { notification } from "antd";

export const useNotification = () => {

    const openNotification = (type: 'success' | 'info' | 'warning' | 'error', message: string, description: string) => {
        notification[type]({
            message: message,
            description: description
        });
    }

    return {
        openNotification
    }
}