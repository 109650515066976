import React from 'react';
import { Modal, Form, Input, Select } from 'antd';
import { useAmenities } from '../amenities.hook';
import { AmenitiesRequest } from '../../../request/amenities.request';
import FileUploadView from '../../../view/file-upload.view';
import { TextEditorView } from '../../../view/text-editor.view';
import { handleFilter } from '../../../view/input.helper';
import { ItemProp } from '../../../model/item-prop.model';

export const AddAmenitiesComponent: React.FC<{ open: boolean, onCancel: () => void }> = ({ open, onCancel }) => {
    const [form] = Form.useForm();
    const { loading, updateLoading, saveAmenities } = useAmenities()

    const handleOk = () => {
        form.validateFields().then(values => {
            updateLoading(true);
            const amenitiesRequest = {
                name: values["name"],
                icon: values["icon"],
                exist: true,
                group: values["group"]
            } as AmenitiesRequest
            saveAmenities(amenitiesRequest, onSuccess, onFailure)
        });
    };

    const onSuccess = () => {
        updateLoading(false);
        form.resetFields()
        onCancel();
    }

    const onFailure = () => {
        updateLoading(false);
    }

    return (
        <Modal
            open={open}
            title="Add New Amenities"
            onCancel={onCancel}
            confirmLoading={loading}
            onOk={handleOk}
            okText={"Submit"}
            cancelText={"Cancel"}
            style={{ top: 8, maxWidth: 768 }}
            width={'90vw'}
        >
            <Form form={form} layout="vertical">
                <Form.Item
                    name="name"
                    label="Name"
                    rules={[{ required: true }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="icon"
                    label="Icon"
                    rules={[{ required: true, message: 'Please add icon' }]}
                >
                    <FileUploadView onChange={() => { }} />
                </Form.Item>

                <Form.Item
                    name="group"
                    label="Group"
                    rules={[{ required: true }]}
                >
                    <Select
                        value='group'
                        options={[{
                                label: 'Convenience',
                                value: 'Convenience',
                            } as ItemProp,
                            {
                                label: 'Safety',
                                value: 'Safety',
                            } as ItemProp,
                            {
                                label: 'Environment',
                                value: 'Environment',
                            } as ItemProp,
                            {
                                label: 'Leisure',
                                value: 'Leisure',
                            } as ItemProp
                        ]}
                        allowClear />
                </Form.Item>
            </Form>
        </Modal>
    );
}