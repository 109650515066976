import { Space, Spin, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect } from 'react';
import { useTarget } from '../target.hook';
import { formatName } from '../../../util/name-helper';
import { TargetFilterComponent } from './target-filter.component';
import { TargetRes } from '../../../response/target.response';
import dayjs from 'dayjs';


export const TargetTableComponent = () => {
    const { loading, targetList, fetchTargetList, targetSearchReq, updateLoading } = useTarget()

    useEffect(() => {
        fetchTargetList({ ...targetSearchReq, pageNumber: 1 }, () => { updateLoading(false) }, () => { updateLoading(false) })
    }, [])

    const columnList: ColumnsType<TargetRes> = [
        {
            title: 'Target Id',
            dataIndex: 'id',
            key: 'id',
            render: (id) => <span>{`${String(id).padStart(3, '0')}`}</span>,
        },
        {
            title: 'Targeter/Employee Name',
            dataIndex: 'employee',
            key: 'employee',
            render: (employee) => <span>{formatName(employee.userInfo.name)}</span>,
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (type) => <span>{type}</span>,
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
            render: (value) => <span>{value}</span>,
        },
        {
            title: 'Start At',
            dataIndex: 'startAt',
            key: 'startAt',
            render: (startAt) => <span>{dayjs(startAt).format('DD MMMM YYYY')}</span>,
        },
        {
            title: 'End At',
            dataIndex: 'endAt',
            key: 'endAt',
            render: (endAt) => <span>{dayjs(endAt).format('DD MMMM YYYY')}</span>,
        },
    ];

    return <div style={{ marginTop: -42 }}>
        <Space direction="horizontal" style={{ width: '100%', justifyContent: 'end', marginBottom: 8 }}>
            <TargetFilterComponent />
        </Space>

        <Table

            className='task-main-table task-table'
            columns={columnList} dataSource={targetList}
            pagination={{ defaultPageSize: 30, showSizeChanger: true, pageSizeOptions: ['20', '50', '100', '200', '300', '400', '500'] }}
            loading={{ indicator: <div><Spin /></div>, spinning: loading }} />

    </div>
}