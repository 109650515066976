import { Avatar, Col, Row, theme, Typography } from "antd";
import { useEffect, useState } from "react";
import { getColor } from "../../../../enum/static-data.enum";
import ImgIcon from "../../../../view/img-icon";

const CreatedonComponent = (props: any) => {

    const { task } = props;
    const { useToken } = theme;
    const { token } = useToken();
    const [selectedValue, setSelectedValue] = useState('Khem raj')


    useEffect(() => {
        if (task && task.priority) {
            setSelectedValue(task.createdBy.name)
        }
    }, [])


    return (
        <Row style={{ marginBottom: '5px' }}>
            <Col style={{ display: 'flex', alignItems: "center" }}>
                <ImgIcon iconName="users-black.svg" iconWidth="18px" />
                Created By
            </Col>
            <Col style={{ marginLeft: 'auto' }}>
                <div style={{ display: 'flex' }}>
                    {selectedValue &&
                        <span style={{ marginRight: '8px' }}>
                            <Avatar style={{ backgroundColor: getColor(selectedValue[0]) }}>{selectedValue[0].toUpperCase()}</Avatar>
                        </span>
                    }


                    <span style={{ width: '130px', padding: '2px', height: '30px', display: 'flex', textOverflow: 'ellipsis' }}>
                        <Typography.Title level={5} style={{ margin: 0, width: '110px', fontWeight: 'normal', fontSize: token.fontSize }} ellipsis={true} >
                            {selectedValue}

                        </Typography.Title>
                    </span>
                </div>
            </Col>
        </Row>
    )
}

export default CreatedonComponent