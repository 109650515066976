import React, { useEffect, useState } from "react"
import { LeadNoteRes } from "../../../../../response/lead-note.request"
import { FollowUpCardViewComponent } from "./follow-up-item.component"
import { LeadRes } from "../../../../../response/lead.response"
import { EnquiryNoteService } from "../../../../../service/enquiry-note.service"
import { EnquiryNoteSearchReq } from "../../../../../request/enquiry-note.request"
import { EnquiryNoteRes } from "../../../../../response/enquiry-note.request"
import { EnquiryFollowUpCardViewComponent } from "./enquiry-follow-up-item"


export interface FollowUpListComponentProp {
    lead: LeadRes
    leadNoteList: LeadNoteRes[]
    fetchLeadNote: (leadId: string) => void
}

export const FollowUpListComponent = (props: FollowUpListComponentProp) => {

    const [enquiryNoteList, setEnquiryNoteList] = useState([])

    const { lead, leadNoteList, fetchLeadNote } = props

    const fetchEnquiryNote = async (enquiryId: string) => {
        const [enquiryNoteList, error] = await EnquiryNoteService.getEnquiryNoteList({
            enquiryIdList: [enquiryId],
            pageNumber: 1,
            itemsPerPage: 50
        } as EnquiryNoteSearchReq)
        if (error) {
        } else {
            enquiryNoteList.pop()
            setEnquiryNoteList(enquiryNoteList)
        }
    }

    useEffect(() => {
        fetchEnquiryNote(lead.enquiryId)
    }, [lead.enquiryId])

    return (
        <React.Fragment>
            {enquiryNoteList.map((enquiryNote: EnquiryNoteRes) => {
                return <EnquiryFollowUpCardViewComponent enquiryNote={enquiryNote} />
            })}
            {leadNoteList.map((leadNote: LeadNoteRes) => {
                return <FollowUpCardViewComponent lead={lead} leadNote={leadNote} fetchLeadNote={fetchLeadNote} />
            })}
        </React.Fragment>
    )
}