import { Col, Row, Typography } from "antd"


const PermissionSliderComponent = () => {

    const { Text } = Typography;

    return (
        <Row className="permission-carousel" style={{ padding: '0 6px', overflowX: 'auto', flexWrap: 'nowrap' }}>
            <Row style={{ display: 'flex', minWidth: '100%' }}>
                <Col xs={8}>
                    <div className="permission-tag selected-p-tag">
                        <Text>Users</Text>
                    </div>
                </Col>
                <Col xs={8}>
                    <div className="permission-tag">
                        <Text>Employee</Text>
                    </div>
                </Col>
                <Col xs={8}>
                    <div className="permission-tag">
                        <Text>Permission</Text>
                    </div>
                </Col>
                <Col xs={8}>
                    <div className="permission-tag">
                        <Text>Attendance</Text>
                    </div>
                </Col>
                <Col xs={8}>
                    <div className="permission-tag">
                        <Text>Role</Text>
                    </div>
                </Col>
                <Col xs={8}>
                    <div className="permission-tag">
                        <Text>Office</Text>
                    </div>
                </Col>
            </Row>

            <Row style={{ display: 'flex', minWidth: '100%' }}>
                <Col xs={8}>
                    <div className="permission-tag">
                        <Text>Users</Text>
                    </div>
                </Col>
                <Col xs={8}>
                    <div className="permission-tag">
                        <Text>Users</Text>
                    </div>
                </Col>
                <Col xs={8}>
                    <div className="permission-tag">
                        <Text>Users</Text>
                    </div>
                </Col>
                <Col xs={8}>
                    <div className="permission-tag">
                        <Text>Users</Text>
                    </div>
                </Col>
                <Col xs={8}>
                    <div className="permission-tag">
                        <Text>Users</Text>
                    </div>
                </Col>
                <Col xs={8}>
                    <div className="permission-tag">
                        <Text>Users</Text>
                    </div>
                </Col>
            </Row>
        </Row>

    )
}

export default PermissionSliderComponent