import { Avatar, Button, Card, Col, Progress, Row, Space, Typography } from "antd"
import { useMain } from "../../../main.hook";
import { EmployeeRes } from "../../../../response/employee.response";
import { formatName } from "../../../../util/name-helper";
import FileUploadView from "../../../../view/file-upload.view";
import { Media } from "../../../../model/media.model";
import { EmployeeService } from "../../../../service/employee.service";
import { getColor } from "../../../../enum/static-data.enum";
import { useState } from "react";
import { isMobile } from "../../../../util/device-helper";

export interface ProfileComponentProps {
    employeeRes?: EmployeeRes
    leadCount: number
}

const ProfileComponent = (props: ProfileComponentProps) => {

    const { employeeRes, leadCount } = props
    const [editPic, setEditPic] = useState(false)

    const { Text } = Typography;

    return (<>
        {employeeRes ? <Card style={{
            height: '100%',
            padding: '0 20px',
            width: '100%'
        }}
            headStyle={{
                padding: '10px 0 0',
                minHeight: 44
            }}
            bodyStyle={{
                padding: '10px 0'
            }}
            title="Profile"
            extra={
                <>
                    {(employeeRes.userInfo.profilePic?.url?.main) && <Avatar src={employeeRes.userInfo.profilePic?.url?.main} style={{ backgroundColor: getColor(employeeRes.userInfo.name) }} />}
                </>
            }
            bordered={false}
            className="profile-wrapper p-mweb-card"
        >
            <Row>
                <Col md={12}>
                    <Space style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'baseline',
                        marginBottom: 6
                    }}>
                        <Text strong>Name</Text>
                        <Text>{formatName(employeeRes.userInfo.name)}</Text>
                    </Space>
                    <Space style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'baseline',
                        marginBottom: 6
                    }}>
                        <Text strong>Email</Text>
                        <Text title="janedoe999@gmail.com">{employeeRes.userInfo.email}</Text>
                    </Space>

                </Col>
                <Col md={11} style={{ marginLeft: 10 }}>
                    <Space style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'baseline',
                        marginBottom: 6,
                    }}>
                        <Text strong>Mobile Number</Text>
                        <Text>+91 {employeeRes.userInfo.phone}</Text>
                    </Space>
                    <Space style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'baseline',
                        marginBottom: 6
                    }}>
                        <Text strong>Gender</Text>
                        <Text title="janedoe999@gmail.com">{employeeRes.gender}</Text>
                    </Space>
                </Col>
            </Row>
            {/* <Row style={{
                alignItems: 'center',
                margin: '14px 0',
            }}>
                <Col md={12}>
                    <div className="circle-progrss-wrapper">
                        <Progress type="circle" percent={75} strokeColor={'#FFCB44'} size={100} />
                    </div>
                </Col>
                <Col md={11} style={{ marginLeft: 10 }}>
                    <Space style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'baseline',
                        marginBottom: 6,
                    }}>
                        <Text strong>15</Text>
                        <Text>Score last week</Text>
                    </Space>
                    <Space style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'baseline',
                        marginBottom: 6
                    }}>
                        <Text strong>{leadCount}</Text>
                        <Text title="janedoe999@gmail.com">Lead Progresses</Text>
                    </Space>
                </Col>
            </Row> */}
        </Card> : <></>
        }
    </>
    )
}

export default ProfileComponent