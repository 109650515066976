import { Button, Space, Spin, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { ServiceRes } from '../../../response/service.response';
import { useService } from '../service.hook';
import { AddServiceComponent } from './add-service.component';
import { UpdateServiceComponent } from './update-service.component';
import { ServiceFilterComponent } from './filter.component';
import { getColorBynumber } from '../../../enum/static-data.enum';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Editor } from '@ckeditor/ckeditor5-core';
import { TextEditorView } from '../../../view/text-editor.view';

export const MainServiceTableComponent = () => {
    const { loading, serviceList, fetchServiceList, serviceSearchReq, updateLoading } = useService()
    const [addNewService, setOpenNewService] = useState(false)
    const [updateService, setUpdateService] = useState<ServiceRes | undefined>(undefined)

    const cancelServiceEdit = () => {
        setUpdateService(prevVal => undefined);
    }
    const updateOpenNewService = () => {
        if (addNewService) {
            fetchServiceList({ ...serviceSearchReq, pageNumber: 1 }, () => { updateLoading(false) }, () => { updateLoading(false) })
        }
        setOpenNewService(prevVal => !prevVal);
    }

    const columnList: ColumnsType<ServiceRes> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Order',
            dataIndex: 'order',
            key: 'order',
        },
        {
            title: 'Description',
            key: 'description',
            dataIndex: 'description',
            render: (_, { description }) => (
                <>
                    <TextEditorView initData={description} readOnly={true}/>
                </>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            onCell: (service, rowIndex) => {
                return {
                    onClick: (ev) => {
                        setUpdateService(service)
                    },
                };
            },
            render: (id) => <a>Edit</a>,
        },
    ];

    useEffect(() => {
        fetchServiceList({ ...serviceSearchReq, pageNumber: 1 }, () => { updateLoading(false) }, () => { updateLoading(false) })
    }, [])

    return <div style={{ marginTop: -42 }}>
        <Space direction="horizontal" style={{ width: '100%', justifyContent: 'end', marginBottom: 8 }}>
            <ServiceFilterComponent />
            <Button type="primary" onClick={updateOpenNewService}>Add New</Button>
        </Space>
        <Table
            className='task-main-table task-table'
            columns={columnList} dataSource={serviceList}
            pagination={{ defaultPageSize: 30, showSizeChanger: true, pageSizeOptions: ['20', '50', '100', '200', '300', '400', '500'] }}
            loading={{ indicator: <div><Spin /></div>, spinning: loading }} />
        {addNewService &&
            <AddServiceComponent open={addNewService} onCancel={updateOpenNewService} />
        }

        {updateService &&
            <UpdateServiceComponent service={updateService} open={true} onCancel={cancelServiceEdit} />
        }
    </div>
}