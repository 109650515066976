import { createContext, useReducer } from "react";
import { DashBoardState } from "./dashboard.state";
import { AttendanceConfigRes } from "../../response/attendance.response";
import { DashboardReducer } from "./dashboard.reducer";
import { DashboardAction } from "./dashboard.action";
import { AttendanceService } from "../../service/attendance.service";
import { EndDayReq, StartDayReq } from "../../request/attendance.request";
import { PriorityThingRes } from "../../response/report.response";
import { ReportService } from "../../service/report.service";
import { LeadService } from "../../service/lead.service";
import { LeadReportReq } from "../../request/lead.request";
import { SecurityManager } from "../../security/security-manager";
import { EnquiryService } from "../../service/enquiry.service";
import { EnquiryReportReq } from "../../request/enquiry.request";
import { TaskService } from "../../service/task.service";
import { TaskReportReq } from "../../request/task.request";
import { EventName } from "../../event/event-name.enum";
import { pushEvent } from "../../event/analytics.helper";
import { useMapLocation } from "../../location/location.hook";

const initialState = {
    loading: false,
    attendanceConfigRes: {} as AttendanceConfigRes,
    priorityThingRes: {} as PriorityThingRes,
    leadReportResList: [],
    enquiryReportResList: [],
    taskReportList: [],
    filterData: {
        assigneeIdList: [SecurityManager.getEid()]
    }
} as DashBoardState

export const DashBoardContext = createContext<any>(initialState);

export const DashBoardStateProvider = ({ children }: any) => {

    const [state, dispatch] = useReducer(DashboardReducer, initialState)
    const { location } = useMapLocation()

    const fetchTodayAttendance = async () => {
        updateLoading(true)
        const [data, error] = await AttendanceService.todayReading()
        if (error) {
        } else {
            dispatch({
                type: DashboardAction.UPDATE_TODAY_ATTENDANCE,
                payload: {
                    attendanceConfigRes: data
                }
            });
        }
        updateLoading(false)
    };

    const updateLoading = (loading: Boolean) => {
        dispatch({
            type: DashboardAction.UPDATE_LOADING,
            payload: {
                loading: loading
            }
        });
    };

    const startDay = async (startDayReq: StartDayReq) => {
        updateLoading(true)
        const [data, error] = await AttendanceService.startDay(startDayReq)
        await fetchTodayAttendance()
        if (error) {

        } else {
            pushEvent(EventName.START_DAY, {
                employeeId: startDayReq.employeeId,
                location: location,
                createdOn: new Date()
            })
        }
    };

    const endDay = async (endDayReq: EndDayReq) => {
        updateLoading(true)
        const [data, error] = await AttendanceService.endDay(endDayReq)
        if (error) {

        } else {
            pushEvent(EventName.END_DAY, {
                employeeId: endDayReq.employeeId,
                location: location,
                createdOn: new Date()
            })
        }
        await fetchTodayAttendance()
    };

    const fetchPriorityThing = async () => {
        updateLoading(true)
        const [priorityThingRes, error] = await ReportService.getPriorityThing()
        if (error) {
        } else {
            dispatch({
                type: DashboardAction.UPDATE_PRIORITY_THING,
                payload: {
                    priorityThingRes: priorityThingRes
                }
            });
        }
        updateLoading(false)
    };

    const fetchSelfReport = async () => {
        try {
            const [leadReportResList, error] = await LeadService.getReport({
                assigneeIdList: [SecurityManager.getEid()]
            } as LeadReportReq)
            if (!error) {
                dispatch({
                    type: DashboardAction.UPDATE_LEAD_REPORT_RES,
                    payload: {
                        leadReportResList: leadReportResList ? leadReportResList : []
                    }
                });
            }
        } catch (err: any) {

        }
        try {
            const [enquiryReportResList, error2] = await EnquiryService.getReport({
                assigneeIdList: [SecurityManager.getEid()]
            } as EnquiryReportReq)
            if (!error2) {
                dispatch({
                    type: DashboardAction.UPDATE_ENQUIRY_REPOERT_RES,
                    payload: {
                        enquiryReportResList: enquiryReportResList ? enquiryReportResList : []
                    }
                });
            }
        } catch (err: any) {

        }
        try {

            const [taskReportList, error3] = await TaskService.getReport({
                assigneeIdList: [SecurityManager.getEid()]
            } as TaskReportReq)
            if (!error3) {
                dispatch({
                    type: DashboardAction.UPDATE_TASK_REPOERT_RES,
                    payload: {
                        taskReportList: taskReportList ? taskReportList : []
                    }
                });
            }
        } catch (err: any) {

        }
    };

    const value = {
        attendanceConfigRes: state.attendanceConfigRes,
        loading: state.loading,
        priorityThingRes: state.priorityThingRes,
        leadReportResList: state.leadReportResList,
        enquiryReportResList: state.enquiryReportResList,
        taskReportList: state.taskReportList,
        filterData: state.filterData,
        updateLoading,
        fetchTodayAttendance,
        startDay,
        endDay,
        fetchPriorityThing,
        fetchSelfReport
    };

    return <DashBoardContext.Provider value={value}>{children}</DashBoardContext.Provider>
};