import { Action } from "../../model/action.model";
import { TargetAction } from "./target.action";
import { TargetState } from "./target.state";

export const TargetReducer = (state: TargetState, action: Action): TargetState => {
    const { type, payload } = action

    switch (type) {
        case TargetAction.UPDATE_TARGET_LIST:
            return {
                ...state,
                targetList: [...payload.targetList]
            };
        case TargetAction.ADD_TARGET_LIST:
            return {
                ...state,
                targetList: [...state.targetList, ...payload.targetList]
            };
        case TargetAction.UPDATE_TARGET_SEARCH_REQ:
            return {
                ...state,
                targetSearchReq: { ...payload.targetSearchReq }
            };
        case TargetAction.UPDATE_LOADING:
            return {
                ...state,
                loading: payload.loading
            };
        case TargetAction.UPDATE_EMPLOYEE_LIST:
            return {
                ...state,
                employeeList: [...payload.employeeList]
            };
        default:
            throw new Error(`No case for type ${type} found in TargetReducer.`);
    }
}