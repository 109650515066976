import React from 'react';
import { Form, Input, Button } from 'antd';
import { OfficeSearchReq } from '../../../request/office.request';
import { useOffice } from '../office.hook';

export const OfficeFilterComponent: React.FC = () => {
    const [form] = Form.useForm();
    const { fetchOfficeList, updateLoading, officeSearchReq  } = useOffice()

    const handleOk = () => {
        form.validateFields().then(values => {
            updateLoading(true);
            const _officeSearchReq = {
                ...officeSearchReq,
                name: values["name"],
                contact: values["contact"],
                pageNumber: 1,
                itemsPerPage: 10
            } as OfficeSearchReq
            fetchOfficeList(_officeSearchReq, onSuccess, onFailure)
        });
    };

    const onSuccess = () => {
        updateLoading(false);
    }

    const onFailure = () => {
        updateLoading(false);
    }

    return (
        <Form form={form} layout="inline">
            <Form.Item
                name="name"
                style={{ minWidth: 124 }}
                initialValue={officeSearchReq.officeId}
            >
                <Input placeholder='name' />
            </Form.Item>

            <Form.Item
                name="contact"
                style={{ minWidth: 124 }}
                initialValue={officeSearchReq.phone}
            >
                <Input placeholder='contact' />
            </Form.Item>
           
            <Form.Item style={{ minWidth: 124 }}>
                <Button type="primary" htmlType="submit"
                    onClick={handleOk}>
                    Search
                </Button>
            </Form.Item>
        </Form>
    );
}
