import { RoleRequest, RoleSearchReq } from "../request/role.request"
import { RoleRes } from "../response/role.response"
import httpClient from "../util/http-client"

export const RoleService = {
    saveRole: async (roleRequest: RoleRequest): Promise<(RoleRes|any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/role", roleRequest)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    getRoleList: async (roleSearchReq: RoleSearchReq): Promise<(RoleRes[]|any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/role-search", roleSearchReq)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    updateRole: async (id: string, roleRequest: RoleRequest): Promise<(RoleRes|any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.put(`/role/${id}`, roleRequest)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
}