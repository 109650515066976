import { useContext } from "react"
import { CountryContext } from "./country.context";


export const useCountry = () => {
    const  context = useContext(CountryContext)
    if (context === undefined){
        throw new Error(`Component is not inside CountryContext scope`);
    }
    return context
}