import { Col, FloatButton, Row } from 'antd';
import { DashBoardStateProvider } from './dashboard.context';
import ProfileComponent from './component/genericComponents/profile.component';
import "./component/dashboard.css"
import { UpcomingTaskTable } from './component/genericComponents/upcoming-task-table.component';
import AttendanceHistoryComponent from './component/genericComponents/attendance-history.component';
import { LeadTableList } from './component/genericComponents/lead-table-list.component';
import LeadCardComponent from './component/genericComponents/leadDetails/lead-card.component';
import EnquireCardComponent from './component/genericComponents/enquireDetails/enquire-card.component';
import TaskCardComponent from './component/genericComponents/taskDetails/task-card.component';
import { useDashboard } from './dashboard.hook';
import { useEffect, useState } from 'react';
import { EnquiryTableList } from './component/genericComponents/enquiry-table-list.component';
import { useMain } from '../main.hook';
import GlobalSearchComponent from '../common/search.component';
import { AttendanceComponent } from './component/attendance.component';
import DetailPageHeader from '../common/detail-page-header.component';
import { TargetComponent } from './component/target.component';
import { PhoneOutlined } from '@ant-design/icons';
import { CallClientComponent } from './component/call-client.component';

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

export const DashboardPageBase = () => {

    const [callClient, setCallClient] = useState(false)

    const { fetchPriorityThing, priorityThingRes, leadReportResList,
        enquiryReportResList,
        taskReportList,
        fetchSelfReport,
        filterData } = useDashboard()

    const { userConfig } = useMain()

    useEffect(() => {
        fetchPriorityThing()
        fetchSelfReport()
    }, [])

    return <>
        <DetailPageHeader headerTitle="Dashboard" isDashboard={true} />
        <section className='das-section' style={{ margin: 0 }}>
            {isMobile &&
                <Col md={15} xs={24} style={{ display: 'grid', justifyContent: 'end' }} className='ms-auto attendance-item-d'>
                    <Row style={{ width: '100%', marginBottom: 8, display: 'flex', alignItems: 'center', height: '100%' }}>
                        <GlobalSearchComponent />
                        <AttendanceComponent />
                    </Row>
                </Col>
            }
            <Row>
                <Col lg={12} xs={24} style={{
                    margin: '0 0 20px'
                }}>
                    <ProfileComponent employeeRes={userConfig.employeeRes} leadCount={priorityThingRes.leadList ? priorityThingRes.leadList.length : 0} />
                </Col>
                <Col lg={12} xs={24} className='d-padding' style={{
                    padding: '0 10px',
                    margin: '0 0 20px'
                }}>
                    <AttendanceHistoryComponent />
                </Col>
            </Row>

            <Row>
                <TargetComponent />
            </Row>

            {(priorityThingRes.taskList && priorityThingRes.taskList.length > 0) &&
                <UpcomingTaskTable taskList={priorityThingRes.taskList} />
            }
            {(priorityThingRes.enquiryList && priorityThingRes.enquiryList.length > 0) &&
                <EnquiryTableList enquiryList={priorityThingRes.enquiryList} />
            }
            {(priorityThingRes.leadSiteVisitList && priorityThingRes.leadSiteVisitList.length > 0) &&
                <LeadTableList leadList={priorityThingRes.leadSiteVisitList} title={"Lead Site visit & F2F Meeting List"}/>
            }
            {(priorityThingRes.leadList && priorityThingRes.leadList.length > 0) &&
                <LeadTableList leadList={priorityThingRes.leadList} title={"Lead Follow Up List"}/>
            }

            <Row>
                {(leadReportResList && leadReportResList.length > 0) &&
                    <Col lg={12} xs={24} style={{
                        margin: '0 0 20px'
                    }}>
                        <LeadCardComponent leadReportResList={leadReportResList} filterData={filterData} />

                    </Col>
                }
                {(enquiryReportResList && enquiryReportResList.length > 0) &&
                    <Col lg={12} xs={24} className='d-padding' style={{
                        padding: '0 10px',
                        margin: '0 0 20px'
                    }}>
                        <EnquireCardComponent enquiryReportResList={enquiryReportResList} filterData={filterData} />
                    </Col>
                }
            </Row>
            {(userConfig?.employeeRes?.permissionList && userConfig.employeeRes.permissionList.includes("lead-read")) &&
                <FloatButton
                    onClick={() => {
                        setCallClient(preValue => !preValue)
                    }}
                    icon={<PhoneOutlined />}
                    type="primary" style={{ right: 24 }} />
            }
            {(taskReportList && taskReportList.length > 0) &&
                <TaskCardComponent taskReportList={taskReportList} filterData={filterData} />
            }

            {callClient &&
                <CallClientComponent open={true} onCancel={() => { setCallClient(false) }} onSuccess={() => { setCallClient(false) }} />
            }
        </section>
    </>
}

export const DashboardPage = () => {
    return <DashBoardStateProvider>
        <DashboardPageBase />
    </DashBoardStateProvider>
}