import { PlusCircleOutlined } from "@ant-design/icons"
import { Button, Card, Col, Form, Row } from "antd"
import React, { useEffect, useState } from "react"
import { LeadRes } from "../../../../response/lead.response"
import { AddFollowUpComponent } from "./follow-up/add-follow-up.component"
import { LeadNoteSearchReq } from "../../../../request/lead-note.request"
import { LeadNoteService } from "../../../../service/lead-note.service"
import { FollowUpListComponent } from "./follow-up/follow-up-list.component"
import { useLead } from "../../lead.hook"
import { LeadRequest } from "../../../../request/lead.request"
import { LeadService } from "../../../../service/lead.service"
import { LargeStatus } from "../../../../model/large-status.model"

export interface FollowUpComponentProp {
    lead: LeadRes
}

export const FollowUpComponent = (props: FollowUpComponentProp) => {

    const [leadNoteList, setLeadNoteList] = useState([])

    const { fetchLead } = useLead()

    const [form] = Form.useForm()

    const { lead } = props

    const [isAddNewFollowUp, setIsAddNewFollowUp] = useState(false)

    const addNewFollowUpCard = () => {
        setIsAddNewFollowUp(true)
    }

    const fetchLeadNote = async (leadId: string) => {
        const [leadNoteList, error] = await LeadNoteService.getLeadNoteList({
            leadIdList: [leadId],
            pageNumber: 1,
            itemsPerPage: 50
        } as LeadNoteSearchReq)
        if (error) {
        } else {
            setLeadNoteList(leadNoteList)
        }
    }

    useEffect(() => {
        fetchLeadNote(lead.id)
    }, [lead])

    const handleOk = () => {
        form.validateFields().then(async values => {
            const leadRequest = {
                status: {
                    value: values["status"],
                    note: values["note"],
                    dateTime: new Date()
                } as LargeStatus
            } as LeadRequest

            const [data, error] = await LeadService.updateLead(lead.id, leadRequest)

            if (error) {
                onFailure()
            } else {
                onSuccess()
            }
        });
    };

    const onSuccess = async () => {
        form.resetFields()
        await fetchLead(lead.id, () => { }, () => { })
    }

    const onFailure = () => {
        fetchLead(lead.id, () => { }, () => { })
    }

    return (
        <React.Fragment>
            {leadNoteList && leadNoteList.length > 0 &&
                <Card bordered={false}
                    style={{
                        padding: '0 20px',
                        marginBottom: '20px'
                    }}
                    headStyle={{
                        padding: 0
                    }}
                    bodyStyle={{
                        padding: '15px 0'
                    }}
                    className="follow-up-details wrapper-class common-space"
                >
                    <FollowUpListComponent lead={lead} leadNoteList={leadNoteList} fetchLeadNote={fetchLeadNote} />
                </Card>
            }

            {(lead.status.value == 'Pending' || lead.status.value == 'In Progress') &&
                <Card bordered={false}
                    style={{
                        padding: '0 20px',
                        marginBottom: '20px'
                    }}
                    headStyle={{
                        padding: 0
                    }}
                    bodyStyle={{
                        padding: isAddNewFollowUp ? '15px 0' : '8px 0'
                    }}
                    className="follow-up-details add-new-details wrapper-class"
                >
                    {<React.Fragment>
                        {isAddNewFollowUp &&
                            <AddFollowUpComponent setIsAddNewFollowUp={setIsAddNewFollowUp} lead={lead} fetchLeadNote={fetchLeadNote} />
                        }
                        {!isAddNewFollowUp &&
                            <Row>
                                <Col span={24}>
                                    <Button type="link" block style={{
                                        padding: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '100%'
                                    }}
                                        onClick={addNewFollowUpCard}
                                    >
                                        <PlusCircleOutlined /> Add Follow up
                                    </Button>
                                </Col>
                            </Row>
                        }
                    </React.Fragment>}
                </Card>
            }
        </React.Fragment>
    )
}