import { createContext, useReducer } from "react";
import { LocalityState } from "./locality.state";
import { LocalityReducer } from "./locality.reducer";
import { LocalityAction } from "./locality.action";
import { LocalityService } from "../../service/locality.service";
import { LocalityRequest, LocalitySearchReq } from "../../request/locality.request";
import { UserService } from "../../service/user.service";
import { CitySearchReq } from "../../request/city.request";
import { CityService } from "../../service/city.service";
import { CountrySearchReq } from "../../request/country.request";
import { CountryService } from "../../service/country.service";

const initialState = {
    loading: false,
    localitySearchReq: {
        pageNumber: 1,
        itemsPerPage: 100
    } as LocalitySearchReq,
    localityList: [],
    countryList: [],
    cityList: []
} as LocalityState

export const LocalityContext = createContext<any>(initialState);

export const LocalityStateProvider = ({ children }: any) => {

    const [state, dispatch] = useReducer(LocalityReducer, initialState)

    const updateLoading = (loading: Boolean) => {
        dispatch({
            type: LocalityAction.UPDATE_LOADING,
            payload: {
                loading: loading
            }
        });
    };

    const fetchLocalityList = async (localitySearchReq: LocalitySearchReq, onSuccess: () => void, onFailure: () => void) => {
        updateLoading(true)
        const [localityList, error] = await LocalityService.getLocalityList(localitySearchReq)

        if (error) {
            onFailure()
        } else {
            dispatch({
                type: localitySearchReq.pageNumber == 1 ? LocalityAction.UPDATE_LOCALITY_LIST : LocalityAction.ADD_LOCALITY_LIST,
                payload: {
                    localityList: localityList
                }
            })
            if (localityList.length > 0) {
                dispatch({
                    type: LocalityAction.UPDATE_LOCALITY_SEARCH_REQ,
                    payload: {
                        localitySearchReq: {
                            ...localitySearchReq,
                            pageNumber: localitySearchReq.pageNumber + 1
                        }
                    }
                })
            }
            onSuccess()
        }
    };

    const saveLocality = async (localityRequest: LocalityRequest, onSuccess: () => void, onFailure: () => void) => {
        const [data, error] = await LocalityService.saveLocality(localityRequest)

        if (error) {
            onFailure()
        } else {
            onSuccess()
        }
    }

    const updateLocality = async (id: string, localityRequest: LocalityRequest, onSuccess: () => void, onFailure: () => void) => {
        const [data, error] = await LocalityService.updateLocality(id, localityRequest)

        if (error) {
            onFailure()
        } else {
            onSuccess()
        }
    }

    const fetchCountryList = async () => {
        const [countryList, error] = await CountryService.getCountryList({
            pageNumber: 1,
            itemsPerPage: 100
        } as CountrySearchReq)

        if (error) {

        } else {
            dispatch({
                type: LocalityAction.UPDATE_COUNTRY_LIST,
                payload: {
                    countryList: countryList
                }
            });
        }
    };

    const fetchCityList = async () => {
        const [cityList, error] = await CityService.getCityList({
            pageNumber: 1,
            itemsPerPage: 100
        } as CitySearchReq)

        if (error) {

        } else {
            dispatch({
                type: LocalityAction.UPDATE_CITY_LIST,
                payload: {
                    cityList: cityList
                }
            });
        }
    };

    const value = {
        localityList: state.localityList,
        loading: state.loading,
        localitySearchReq: state.localitySearchReq,
        countryList: state.countryList,
        cityList: state.cityList,
        updateLoading,
        fetchLocalityList,
        saveLocality,
        updateLocality,
        fetchCountryList,
        fetchCityList
    };

    return <LocalityContext.Provider value={value}>{children}</LocalityContext.Provider>
};