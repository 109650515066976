import { Button, Space, Spin, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect } from "react";
import { useTemplate } from "../template.hook";
import { TemplateFilterComponent } from "./filter.component";
import { TemplateRes } from "../../../../response/template.response";
import { useNavigate } from "react-router-dom";

export const TemplateListComponent = () => {

  const navigate = useNavigate();

  const {
    loading,
    templateList,
    fetchTemplateList,
    templateSearchReq,
    updateLoading,
  } = useTemplate();

  useEffect(() => {
    fetchTemplateList(
      { ...templateSearchReq, pageNumber: 1 },
      () => {
        updateLoading(false);
      },
      () => {
        updateLoading(false);
      }
    );
  }, []);

  const columnList: ColumnsType<TemplateRes> = [
    {
      title: "Template id",
      dataIndex: "id",
      key: "id",
      render: (id) => (
        <span>{`${String(id.substring(0, 4) + "...").padStart(3, "0")}`}</span>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (type) => <span>{type}</span>,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (title) => <span>{title}</span>,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (description) => <span>{description}</span>,
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      onCell: (template, rowIndex) => {
        return {
          onClick: (ev) => {
            navigate(`${template.id}/edit`);
          },
        };
      },
      render: (id) => <a>Edit</a>,
    },
  ];

  return (
    <div style={{ marginTop: -42 }}>
      <Space
        direction="horizontal"
        style={{ width: "100%", justifyContent: "end", marginBottom: 8 }}
      >
        <TemplateFilterComponent />
        <Button type="primary" onClick={() => { navigate("add") }}>
          Add New
        </Button>
      </Space>
      <Table
        className="task-main-table task-table"
        columns={columnList}
        dataSource={templateList}
        pagination={{
          defaultPageSize: 30,
          showSizeChanger: true,
          pageSizeOptions: ["20", "50", "100", "200", "300", "400", "500"],
        }}
        loading={{
          indicator: (
            <div>
              <Spin />
            </div>
          ),
          spinning: loading,
        }}
      />
    </div>
  );
};
