import { Button, Space, Spin, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { TeamRes } from '../../../response/team.response';
import { useTeam } from '../team.hook';
import { AddTeamComponent } from './add-team.component';
import { UpdateTeamComponent } from './update-team.component';
import { TeamFilterComponent } from './filter.component';

export const MainTeamTableComponent = () => {
    const { loading, teamList, fetchTeamList, teamSearchReq, updateLoading } = useTeam()
    const [addNewTeam, setOpenNewTeam] = useState(false)
    const [updateTeam, setUpdateTeam] = useState<TeamRes | undefined>(undefined)

    const cancelTeamEdit = () => {
        setUpdateTeam(prevVal => undefined);
    }

    const updateOpenNewTeam = () => {
        if (addNewTeam) {
            fetchTeamList({ ...teamSearchReq, pageNumber: 1 }, () => { updateLoading(false) }, () => { updateLoading(false) })
        }
        setOpenNewTeam(prevVal => !prevVal);
    }

    const columnList: ColumnsType<TeamRes> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Tags',
            key: 'tagList',
            dataIndex: 'tagList',
            render: (_, { tagList }) => (
                <>
                    {tagList.map((tag) => {
                        const color = Math.random() > 0.5 ? 'geekblue' : 'green';

                        return (
                            <Tag color={color} key={tag}>
                                {tag.toUpperCase()}
                            </Tag>
                        );
                    })}
                </>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            onCell: (team, rowIndex) => {
                return {
                    onClick: (ev) => {
                        setUpdateTeam(team)
                    },
                };
            },
            render: (id) => <a>Edit</a>,
        },
    ];

    useEffect(() => {
        fetchTeamList({ ...teamSearchReq, pageNumber: 1 }, () => { updateLoading(false) }, () => { updateLoading(false) })
    }, [])

    return <div style={{ marginTop: -42 }}>
        <Space direction="horizontal" style={{ width: '100%', justifyContent: 'end', marginBottom: 8 }}>
            <TeamFilterComponent />
            <Button type="primary" onClick={updateOpenNewTeam}>Add New</Button>
        </Space>
        <Table columns={columnList} dataSource={teamList}
            className='task-main-table task-table'
            pagination={{ defaultPageSize: 30, showSizeChanger: true, pageSizeOptions: ['20', '50', '100', '200', '300', '400', '500'] }}
            loading={{ indicator: <div><Spin /></div>, spinning: loading }} />
        {addNewTeam &&
            <AddTeamComponent open={addNewTeam} onCancel={updateOpenNewTeam} />
        }

        {updateTeam &&
            <UpdateTeamComponent team={updateTeam} open={true} onCancel={cancelTeamEdit} />
        }
    </div>
}