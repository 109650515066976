import { Button, Space, Spin, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { UserRes } from "../../../response/user.response";
import { useUser } from "../user.hook";
import { AddUserComponent } from "./add-user.component";
import { UpdateUserComponent } from "./update-user.component";
import { UserFilterComponent } from "./filters/filter.component";
import PageHeader from "../../common/page-header.component";
import { UserDetailComponent } from "./user-details.component";
import { formatName } from "../../../util/name-helper";

export const MainUserTableComponent = () => {
  const { loading, userList, fetchUserList, userSearchReq, updateLoading } =
    useUser();
  const [addNewUser, setOpenNewUser] = useState(false);
  const [userDetail, setUserDetail] = useState<UserRes | undefined>(undefined);
  const [updateUser, setUpdateUser] = useState<UserRes | undefined>(undefined);

  const closeUserDetail = () => {
    setUserDetail((prevVal) => undefined);
  };
  const cancelUserEdit = () => {
    setUpdateUser((prevVal) => undefined);
  };

  const columnList: ColumnsType<UserRes> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      onCell: (user, rowIndex) => {
        return {
          onClick: (ev) => {
            setUserDetail(user);
          },
        };
      },
      render: (id) => (
        <a>{`${String(id.substring(0, 4) + "...").padStart(3, "0")}`}</a>
      ),
    },
    {
      title: "Name",
      dataIndex: "userInfo",
      key: "name",
      render: (userInfo) => <span>{formatName(userInfo.name)}</span>,
    },
    {
      title: "Phone",
      dataIndex: "userInfo",
      key: "phone",
      render: (userInfo) => <span>+91 {userInfo.phone}</span>,
    },
    {
      title: "Email",
      dataIndex: "userInfo",
      key: "email",
      render: (userInfo) => <span>{userInfo.email}</span>,
    },
    {
      title: "Score",
      dataIndex: "score",
      key: "score",
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      onCell: (user, rowIndex) => {
        return {
          onClick: (ev) => {
            setUpdateUser(user);
          },
        };
      },
      render: (id) => <a>Edit</a>,
    },
  ];

  const updateOpenNewUser = () => {
    if (addNewUser) {
      fetchUserList(
        { ...userSearchReq, pageNumber: 1 },
        () => {
          updateLoading(false);
        },
        () => {
          updateLoading(false);
        }
      );
    }
    setOpenNewUser((prevVal) => !prevVal);
  };

  useEffect(() => {
    fetchUserList(
      { ...userSearchReq, pageNumber: 1 },
      () => {
        updateLoading(false);
      },
      () => {
        updateLoading(false);
      }
    );
  }, []);

  return (
    <>
      {console.log(userList)}
      <PageHeader
        heading={"User Directory"}
        search={userSearchReq}
        addNewMethod={updateOpenNewUser}
      />

      <Table
        columns={columnList}
        dataSource={userList}
        className="task-main-table task-table"
        pagination={{
          defaultPageSize: 30,
          showSizeChanger: true,
          pageSizeOptions: ["20", "50", "100", "200", "300", "400", "500"],
        }}
        loading={{
          indicator: (
            <div>
              <Spin />
            </div>
          ),
          spinning: loading,
        }}
      />

      {addNewUser && (
        <AddUserComponent open={addNewUser} onCancel={updateOpenNewUser} />
      )}
      {userDetail && (
        <UserDetailComponent
          userDetail={userDetail}
          open={true}
          onCancel={closeUserDetail}
        />
      )}

      {updateUser && (
        <UpdateUserComponent
          user={updateUser}
          open={true}
          onCancel={cancelUserEdit}
        />
      )}
    </>
  );
};
