import React, { useEffect } from 'react';
import { Modal, Form, Input, Select, DatePicker, Switch, theme, Row, Button, Slider, Space } from 'antd';
import { ItemProp } from '../../../model/item-prop.model';
import { useProject } from '../project.hook';
import { ProjectRequest } from '../../../request/project.request';
import { EmployeeRes } from '../../../response/employee.response';
import { BuilderRes } from '../../../response/builder.response';
import { OutSideKey } from '../../../model/outside-key';
import { handleFilter } from '../../../view/input.helper';
import { formatName } from '../../../util/name-helper';
import { isMobile } from '../../../util/device-helper';
import FileUploadView from '../../../view/file-upload.view';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { LocalityRes } from '../../../response/locality.response';
import { SliderTooltipProps } from 'antd/es/slider';
import { Address } from '../../../model/address.model';
import { MiniLocality, PriceRange, AreaRange, Rera, BasicDetail, UnitConfiguration, PropertyArea } from '../../../model/property.model';
import { AmenitiesRes } from '../../../response/amenities.response';
import MultiFileUploadView from '../../../view/multi-file-upload.view';
import { Seo } from '../../../model/seo.model';

export const AddProjectComponent: React.FC<{ open: boolean, onCancel: () => void }> = ({ open, onCancel }) => {
    const [form] = Form.useForm();

    const category = Form.useWatch('category', form);

    const { useToken } = theme;
    const { token } = useToken();
    const { loading, updateLoading, saveProject, fetchEmployeeList, employeeList, fetchBuilderList, builderList,
        projectStatusList, localityList,
        fetchLocalityList, amenitiesList, fetchAmenitiesList,
        unitTypeList,
        categoryTypeList } = useProject()

    const handleOk = () => {
        form.validateFields().then((values) => {
            updateLoading(true);

            const locality = localityList.filter((locality: LocalityRes) => {
                return locality.id == values["localityId"]
            })[0]
            const projectRequest = {
                name: values["name"],
                builderId: values["builderId"],
                totalTower: Number(values["totalTower"]),
                totalUnit: Number(values["totalUnit"]),
                status: values["status"],
                type: values["type"],
                category: values["category"],
                area: Number(values["area"]),
                employeeIdList: values["employeeIdList"],
                contact: Number(values["contact"]),
                paymentPlan: values["paymentPlan"],
                tagList: values["tagList"],
                overview: values["overview"],
                outSideKey: {
                    acre: values["acreKey"],
                    housing: values["housingKey"],
                } as OutSideKey,
                basicDetail: {
                    address: {
                        lineOne: values["lineOne"],
                        lineTwo: values["lineTwo"],
                        street: values["street"],
                        city: values["city"],
                        state: values["state"],
                        pinCode: values["pinCode"]
                    } as Address,
                    locality: {
                        id: locality?.id,
                        name: locality?.name,
                        country: locality?.country,
                        city: locality?.city,
                        location: locality?.location
                    } as MiniLocality,
                    priceRange: {
                        start: Number(values["priceRange"][0].toFixed(0)),
                        end: Number(values["priceRange"][1].toFixed(0))
                    } as PriceRange,
                    areaRange: {
                        start: Number(values["areaRangeStart"]),
                        end: Number(values["areaRangeEnd"])
                    } as AreaRange,
                    launchDate: values["launchDate"],
                    possessionDate: values["possessionDate"],
                    rera: {
                        approved: (values["reraId"] && values["reraId"].length > 0) ? true : false,
                        number: values["reraId"],
                    } as Rera
                } as BasicDetail,
                amenitiesList: values["amenitiesIdList"]?.map((id: string) => {
                    return amenitiesList.filter((amenities: AmenitiesRes) => {
                        return amenities.id == id
                    })[0]
                }),
                faqList: values["faqList"] ? values["faqList"].map((faq: any) => {
                    return {
                        ...faq,
                        order: Number(faq.order)
                    }
                }) : [],
                unitConfigurationList: values["unitConfigurationList"] ? values["unitConfigurationList"].map((unitConfiguration: any) => {
                    return {
                        ...unitConfiguration,
                        mediaList: unitConfiguration.media ? [unitConfiguration.media] : [],
                        ...(category == "Residential" && {
                            bedRoom: Number(unitConfiguration.bedRoom),
                            kitchen: Number(unitConfiguration.kitchen),
                            bathRoom: Number(unitConfiguration.bathRoom),
                            balcony: Number(unitConfiguration.balcony),
                            living: Number(unitConfiguration.living),
                            bhk: Number(unitConfiguration.bhk),
                            availability: Boolean(unitConfiguration.availability),
                            area: {
                                super: Number(unitConfiguration.super),
                                carpet: Number(unitConfiguration.carpet),
                            } as PropertyArea,
                            priceRange: {
                                start: Number(unitConfiguration.priceRange[0].toFixed(0)),
                                end: Number(unitConfiguration.priceRange[1].toFixed(0))
                            } as PriceRange
                        }),
                        ...(category == "Commercial" && {
                            commercialDetail: {
                                type: unitConfiguration?.type,
                                price: Number(Number(unitConfiguration.price ?? 0)?.toFixed(0)),
                                area: Number(unitConfiguration.commercialArea ?? 0)
                            }
                        })
                    } as UnitConfiguration
                }) : [],
                brochureList: values["brochureList"] ? values["brochureList"] : [],
                mediaList: values["mediaList"] ? values["mediaList"] : [],
                seo: {
                    title: values["seoTitle"] ?? "",
                    description: values["seoDescription"] ?? "",
                    OGDescription: values["seoOGDescription"] ?? "",
                    OGTitle: values["seoOGTitle"] ?? "",
                    OGMedia: values["seoOGMedia"] ?? "",
                    keywordList: values["seoKeywordList"] ?? [],
                } as Seo,
                hideFromWebsite: values["hideFromWebsite"],
            } as ProjectRequest;


            saveProject(projectRequest, onSuccess, onFailure)
        });
    };

    useEffect(() => {
        fetchEmployeeList();
        fetchBuilderList();
        fetchLocalityList();
        fetchAmenitiesList();
    }, [])

    const onSuccess = () => {
        updateLoading(false);
        form.resetFields()
        onCancel();
    }

    const onFailure = () => {
        updateLoading(false);
    }

    const sliderTooltipProps = {
        formatter: (value: any) => {
            if (value < 1000) {
                return `${value}`
            }
            if (value < 100000) {
                return `${(value / 1000).toFixed(2)}K`
            }
            if (value < 10000000) {
                return `${(value / 100000).toFixed(2)}L`
            }
            return `${(value / 10000000).toFixed(2)}Cr`
        },
        open: true
    } as SliderTooltipProps

    const areaTooltipProps = {
        formatter: (value: any) => {
            return `${value}`
        },
        open: true
    } as SliderTooltipProps

    return (
        <Modal
            open={open}
            title="Add New Project"
            onCancel={onCancel}
            confirmLoading={loading}
            onOk={handleOk}
            okText={"Submit"}
            cancelText={"Cancel"}
            style={{ top: 8, maxWidth: 768 }}
            width={'90vw'}
        >
            <Form form={form} layout="vertical">
                <Form.Item
                    name="name"
                    label="Name"
                    rules={[{ required: true, message: "Please enter a project name" }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="acreKey"
                    label="99 Acre Key"
                    rules={[{ required: false, message: "Please enter a project name" }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="housingKey"
                    label="Housing Key"
                    rules={[{ required: false, message: "Please enter a project name" }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="brochureList"
                    label="Add brochure"
                    rules={[{ required: false, message: 'Please add brochure' }]}
                >
                    <MultiFileUploadView onChange={() => { }} />
                </Form.Item>

                <Form.Item
                    name="mediaList"
                    label="Add media"
                    rules={[{ required: false, message: 'Please add media' }]}
                >
                    <MultiFileUploadView onChange={() => { }} />
                </Form.Item>

                <Form.Item
                    name="builderId"
                    label="Builder"
                    rules={[{ required: true, message: "Please enter a builder name" }]}
                >
                    <Select
                        showSearch={true}
                        options={builderList.map((builder: BuilderRes) => {
                            return {
                                label: builder.name,
                                value: builder.id,
                            } as ItemProp;
                        })}
                        filterOption={handleFilter}
                    />
                </Form.Item>

                <Form.Item
                    name="employeeIdList"
                    label="Employee"
                    rules={[{ required: false, message: "Please enter a builder name" }]}
                >
                    <Select
                        mode="multiple"
                        value="employeeIdList"
                        style={{
                            border: "1px solid #92B5D7",
                            borderRadius: token.borderRadiusSM,
                            width: "250px",
                        }}
                        options={employeeList?.map((employee: EmployeeRes) => {
                            return {
                                label: `${formatName(employee.userInfo.name)}`,
                                value: employee.id,
                            } as ItemProp;
                        })}
                        filterOption={handleFilter}
                    />
                </Form.Item>

                <Form.Item
                    name="localityId"
                    label="Locality"
                    rules={[{ required: false, message: "Please select locality" }]}
                >
                    <Select
                        showSearch={true}
                        options={localityList.map((locality: LocalityRes) => {
                            return {
                                label: locality.name + ", " + locality.city?.name,
                                value: locality.id,
                            } as ItemProp;
                        })}
                        filterOption={handleFilter}
                    />
                </Form.Item>

                <Form.Item
                    name="status"
                    label="Status"
                    rules={[{ required: false }]}
                >
                    <Select
                        showSearch={true}
                        options={projectStatusList}
                        filterOption={handleFilter}
                    />
                </Form.Item>

                <Form.Item
                    name="type"
                    label="Type"
                    rules={[{ required: false }]}
                >
                    <Select
                        showSearch={true}
                        options={unitTypeList}
                        filterOption={handleFilter}
                    />
                </Form.Item>

                <Form.Item
                    name="category"
                    label="Category"
                    rules={[{ required: false }]}
                >
                    <Select
                        showSearch={true}
                        options={categoryTypeList}
                        filterOption={handleFilter}
                    />
                </Form.Item>

                <Form.Item
                    name="totalTower"
                    label="Total Tower"
                    rules={[{ required: false, message: "Please enter a builder name" }]}
                >
                    <Input type="number" />
                </Form.Item>
                <Form.Item
                    name="totalUnit"
                    label="Total Unit"
                    rules={[{ required: false, message: "Please enter a builder name" }]}
                >
                    <Input type="number" />
                </Form.Item>

                <Form.Item
                    name="area"
                    label="Project Area (in acre)"
                    rules={[{ required: false, message: "Please enter project area" }]}
                >
                    <Input type="number" />
                </Form.Item>

                <Form.Item
                    name="priceRange"
                    label="Price Range"
                    rules={[{ required: false, message: 'Please enter price range' }]}
                >

                    <Slider step={0.1} range min={1000000} max={500000000} tooltip={sliderTooltipProps}
                        style={{
                            width: '87%',
                            margin: '0 10px'
                        }}
                    />
                </Form.Item>

                <Form.Item
                    name="areaRangeStart"
                    label="Area range start"
                    rules={[{ required: false, message: "Please area range start" }]}
                >
                    <Input type="number" />
                </Form.Item>

                <Form.Item
                    name="areaRangeEnd"
                    label="Area range end"
                    rules={[{ required: false, message: "Please area range end" }]}
                >
                    <Input type="number" />
                </Form.Item>

                <Form.Item
                    name="launchDate"
                    label="Launch Date"
                    style={{
                        margin: 0
                    }}
                    rules={[{ required: false, message: 'Please input launch Date' }]}
                >
                    <DatePicker
                        inputReadOnly={isMobile()}
                        style={{
                            border: '1px solid #92B5D7',
                            width: '98%',
                            borderRadius: token.borderRadius,
                            boxShadow: 'none',

                        }}
                        placeholder="Select launch date" />
                </Form.Item>

                <Form.Item
                    name="possessionDate"
                    label="Possession Date"
                    style={{
                        margin: 0
                    }}
                    rules={[{ required: false, message: 'Please input possession date' }]}
                >
                    <DatePicker
                        inputReadOnly={isMobile()}
                        style={{
                            border: '1px solid #92B5D7',
                            width: '98%',
                            borderRadius: token.borderRadius,
                            boxShadow: 'none',

                        }}
                        placeholder="Select possession date" />
                </Form.Item>

                <Form.Item
                    name="reraId"
                    label="Rera Id"
                    rules={[{ required: false, message: "Please enter rera id" }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="paymentPlan"
                    label="Payment Plan"
                    rules={[{ required: false, message: "Please enter a builder name" }]}
                >
                    <Input type="string" />
                </Form.Item>

                <Form.Item
                    name="overview"
                    label="Overview"
                    rules={[{}]}
                >
                    <Input.TextArea rows={4} placeholder="Enter overview" />
                </Form.Item>

                <Form.Item
                    name="lineOne"
                    label="Address Line One"
                    rules={[{ required: false }]}
                >
                    <Input.TextArea rows={2} placeholder="Enter your address" />
                </Form.Item>
                <Form.Item
                    name="lineTwo"
                    label="Address Line Two"
                    rules={[{ required: false }]}
                >
                    <Input.TextArea rows={2} placeholder="Enter your address" />
                </Form.Item>
                <Form.Item
                    name="street"
                    label="Street"
                    rules={[{ required: false }]}
                >
                    <Input.TextArea rows={2} placeholder="Enter your street" />
                </Form.Item>
                <Form.Item
                    name="city"
                    label="City"
                    rules={[{ required: false }]}
                >
                    <Input placeholder="Enter your city" />
                </Form.Item>
                <Form.Item
                    name="state"
                    label="State"
                    rules={[{ required: false }]}
                >
                    <Input placeholder="Enter your state" />
                </Form.Item>
                <Form.Item
                    name="pinCode"
                    label="Pin Code"
                    rules={[{ required: false }]}
                >
                    <Input type="number" placeholder="Enter your pin code" />
                </Form.Item>

                <Form.Item
                    name="hideFromWebsite"
                    label="Hide From Website"
                    rules={[{ required: false }]}
                >
                    <Switch defaultChecked={false} />
                </Form.Item>


                <Form.Item
                    name="tagList"
                    label="Tag List"
                    rules={[{ required: false, message: "Please enter a title" }]}
                >
                    <Select
                        mode="tags"
                        value="tagList"
                        maxTagCount="responsive"
                        filterOption={handleFilter}
                    />
                </Form.Item>

                <Form.Item
                    name="seoTitle"
                    label="Seo Title"
                    rules={[{ required: false }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="seoDescription"
                    label="Seo Description"
                    rules={[{ required: false }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="seoOGTitle"
                    label="Seo OG Title"
                    rules={[{ required: false }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="seoOGDescription"
                    label="Seo OG Description"
                    rules={[{ required: false }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="seoOGMedia"
                    label="SEO OG Thumbnail"
                    rules={[{ required: false, message: 'Please add media' }]}
                >
                    <FileUploadView onChange={() => { }} />
                </Form.Item>

                <Form.Item
                    name="seoKeywordList"
                    label="Seo Key words"
                    rules={[{ required: false, message: 'Enter SEO Keywords' }]}
                >
                    <Select
                        mode="tags"
                        value='seoKeywordList'
                        maxTagCount='responsive'
                        style={{
                            width: '250px',
                            marginRight: '20px',
                        }}
                        filterOption={handleFilter}
                    />
                </Form.Item>

                <Form.Item
                    name="amenitiesIdList"
                    label="Amenities"
                    rules={[{ required: false, message: "Please select amenities" }]}
                >
                    <Select
                        mode="multiple"
                        value="amenitiesIdList"
                        style={{
                            border: "1px solid #92B5D7",
                            borderRadius: token.borderRadiusSM,
                            width: "250px",
                        }}
                        options={amenitiesList?.map((amenities: AmenitiesRes) => {
                            return {
                                label: `${formatName(amenities.name)}`,
                                value: amenities.id,
                            } as ItemProp;
                        })}
                        filterOption={handleFilter}
                    />
                </Form.Item>

                <Form.List name="unitConfigurationList">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map((field) => (
                                <Space key={field.key} align="baseline">

                                    <MinusCircleOutlined onClick={() => remove(field.name)} />

                                    <Row>
                                        <Form.Item
                                            {...field}
                                            name={[field.name, 'media']}
                                            label="Add Image"
                                            rules={[{ required: true, message: 'Please Image' }]}
                                        >
                                            <FileUploadView onChange={() => { }} />
                                        </Form.Item>
                                    </Row>
                                    {category == 'Residential' && <>
                                        <Row>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'bhk']}
                                                label="BHK Type"
                                                rules={[{ required: true, message: 'Enter BHK' }]}
                                            >
                                                <Input type='number' />
                                            </Form.Item>

                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'bedRoom']}
                                                label="Bed Room Number"
                                                rules={[{ required: true, message: 'Enter Bed Room Number' }]}
                                            >
                                                <Input type='number' />
                                            </Form.Item>

                                        </Row>

                                        <Row>

                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'kitchen']}
                                                label="Kitchen Number"
                                                rules={[{ required: true, message: 'Enter Kitchen Number' }]}
                                            >
                                                <Input type='number' />
                                            </Form.Item>

                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'bathRoom']}
                                                label="Bath Room Number"
                                                rules={[{ required: true, message: 'Enter Bath Room Number' }]}
                                            >
                                                <Input type='number' />
                                            </Form.Item>


                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'balcony']}
                                                label="Balcony Number"
                                                rules={[{ required: true, message: 'Enter Balcony Number' }]}
                                            >
                                                <Input type='number' />
                                            </Form.Item>

                                        </Row>

                                        <Row>


                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'living']}
                                                label="Living Room Number"
                                                rules={[{ required: true, message: 'Enter Living Room Number' }]}
                                            >
                                                <Input type='number' />
                                            </Form.Item>

                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'super']}
                                                label="Super Area"
                                                rules={[{ required: true, message: 'Enter Super Area' }]}
                                            >
                                                <Input type='number' />
                                            </Form.Item>

                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'carpet']}
                                                label="Carpet Area"
                                                rules={[{ required: true, message: 'Enter Carpet Area' }]}
                                            >
                                                <Input type='number' />
                                            </Form.Item>

                                        </Row>

                                        <Row>

                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'priceRange']}
                                                label="Price Range"
                                                rules={[{ required: true, message: 'Please enter price range' }]}
                                            >

                                                <Slider step={0.1} range min={1000000} max={500000000} tooltip={sliderTooltipProps}
                                                    style={{
                                                        width: '87%',
                                                        margin: '0 10px'
                                                    }}
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'description']}
                                                label="Description"
                                                rules={[{ required: true, message: 'Enter description' }]}
                                            >
                                                <Input type='string' />
                                            </Form.Item>

                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'availability']}
                                                label="Availability"
                                                rules={[{ required: false, message: 'Please enter availability' }]}
                                            >
                                                <Switch />
                                            </Form.Item>

                                        </Row>
                                    </>}
                                    {category == 'Commercial' && <>
                                        <Row>

                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'type']}
                                                label="Unit Type"
                                                rules={[{ required: true, message: 'Enter Unit type' }]}
                                            >
                                                <Input type='string' />
                                            </Form.Item>

                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'commercialArea']}
                                                label="Area"
                                                rules={[{ required: true, message: 'Enter Area(sq.ft.)' }]}
                                            >
                                                <Input type='number' />
                                            </Form.Item>

                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'price']}
                                                label="Price"
                                                rules={[{ required: true, message: 'Enter price' }]}
                                            >
                                                <Input type='number' />
                                            </Form.Item>

                                        </Row>
                                    </>}
                                </Space>
                            ))}

                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    Add Unit Configuration
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>

                <Form.List name="faqList">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map((field) => (
                                <Space key={field.key} align="baseline">

                                    <Form.Item
                                        {...field}
                                        name={[field.name, 'question']}
                                        label="Question"
                                        rules={[{ required: true, message: 'Please enter question' }]}
                                    >
                                        <Input type='string' />
                                    </Form.Item>
                                    <Form.Item
                                        {...field}
                                        name={[field.name, 'answer']}
                                        label="Answer"
                                        rules={[{ required: true, message: 'Please enter answer' }]}
                                    >
                                        <Input type='string' />
                                    </Form.Item>

                                    <Form.Item
                                        {...field}
                                        name={[field.name, 'order']}
                                        label="Order"
                                        rules={[{ required: true, message: 'Please enter order' }]}
                                    >
                                        <Input type='number' />
                                    </Form.Item>

                                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                                </Space>
                            ))}

                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    Add FAQ
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Form>
        </Modal>
    );
}
