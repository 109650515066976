import React from 'react';
import { Form, Input, Button } from 'antd';
import { CitySearchReq } from '../../../request/city.request';
import { useCity } from '../city.hook';

export const CityFilterComponent: React.FC = () => {
    const [form] = Form.useForm();
    const { fetchCityList, updateLoading, citySearchReq  } = useCity()

    const handleOk = () => {
        form.validateFields().then(values => {
            updateLoading(true);
            const _citySearchReq = {
                ...citySearchReq,
                name: values["name"],
                pageNumber: 1,
                itemsPerPage: 10
            } as CitySearchReq
            fetchCityList(_citySearchReq, onSuccess, onFailure)
        });
    };

    const onSuccess = () => {
        updateLoading(false);
    }

    const onFailure = () => {
        updateLoading(false);
    }

    return (
        <Form form={form} layout="inline">
            <Form.Item
                name="name"
                style={{ minWidth: 124 }}
                initialValue={citySearchReq.name}
            >
                <Input placeholder='Name' />
            </Form.Item>

            <Form.Item style={{ minWidth: 124 }}>
                <Button type="primary" htmlType="submit"
                    onClick={handleOk}>
                    Search
                </Button>
            </Form.Item>
        </Form>
    );
}
