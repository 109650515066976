import { DatePicker, Col, Row, Select } from "antd";
import { useEffect, useState } from "react";
import { useReport } from "../report.hook";
import { EmployeeRes } from "../../../response/employee.response";
import { TeamRes } from "../../../response/team.response";
import { handleFilter } from "../../../view/input.helper";
import PhoneStatsComponent from "./phone-call/phone-stats.component";
import ReportLeadConversionComponent from "./report-lead/report-lead-conversion.component";
import ReportLeadComponent from "./report-lead/report-lead.component";
import ReportTaskEnquiryComponent from "./task-enquiry/report-task-enquiry.component";
import { EnquiryReportReq } from "../../../request/enquiry.request";
import { LeadReportReq } from "../../../request/lead.request";
import { TaskReportReq } from "../../../request/task.request";
import { EnquiryReportRes } from "../../../response/enquiry.response";
import { LeadReportRes } from "../../../response/lead.response";
import { TaskReportRes } from "../../../response/task.response";
import { EmployeeService } from "../../../service/employee.service";
import { EnquiryService } from "../../../service/enquiry.service";
import { LeadService } from "../../../service/lead.service";
import { TaskService } from "../../../service/task.service";
import { TeamService } from "../../../service/team.service";
import ReportEmployeeComponent from "./profile/report-employee.component";
import dayjs, { Dayjs } from "dayjs";
import TimelineComponent from "../../employee/component/empDetails/detailsComponents/timeline.component";
import { useMain } from "../../main.hook";
import { CallService } from "../../../service/call.service";
import { CallReportReq } from "../../../request/call.request";
import { CallReportRes } from "../../../response/call.response";
import { isMobile } from "../../../util/device-helper";
import { OfficeRes } from "../../../response/office.response";

export enum ReportTypeEnum {
    EMPLOYEE = "Employee",
    TEAMS = "Teams",
    OVERALL = "Overall",
    SOURCE = "Source"
}

const { RangePicker } = DatePicker;

const ReportComponent = () => {

    const { userConfig } = useMain()

    const [employeeRes, setEmployeeRes] = useState<EmployeeRes>()
    const [teamRes, setTeamRes] = useState<EmployeeRes>()

    const [filterData, setFilterData] = useState<any>({})

    const [leadReportResList, setLeadReportResList] = useState<LeadReportRes[]>([])
    const [enquiryReportResList, setEnquiryReportResList] = useState<EnquiryReportRes[]>([])
    const [taskReportList, setTaskReportList] = useState<TaskReportRes[]>([])
    const [dateList, setDateList] = useState<any[]>([])

    const [callReportResList, setCallReportResList] = useState<CallReportRes[]>([])

    const rangePresets: {
        label: string;
        value: [Dayjs, Dayjs];
    }[] = [
            { label: 'Today', value: [dayjs(), dayjs()] },
            { label: 'Yesterday', value: [dayjs().add(-1, 'd'), dayjs()] },
            { label: 'Last 7 Days', value: [dayjs().add(-7, 'd'), dayjs()] },
            { label: 'Last 14 Days', value: [dayjs().add(-14, 'd'), dayjs()] },
            { label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()] },
            { label: 'Last 90 Days', value: [dayjs().add(-90, 'd'), dayjs()] },
        ];

    const reportTypeList = [
        { label: 'Overall', value: 'Overall' },
        { label: 'Employee', value: 'Employee' },
        { label: 'Team', value: 'Team' },
        { label: 'Source', value: 'Source' },
    ]

    const sourceList = [
        { label: 'Portal', value: 'Portal' },
        { label: 'Housing', value: 'Housing' },
        { label: '99Acre', value: 'Acre' },
        { label: 'Facebook', value: 'Facebook' },
        { label: 'Google', value: 'Google' },
        { label: 'SMS', value: 'SMS' },
        { label: 'EMail', value: 'EMail' },
        { label: 'IVR', value: 'IVR' },
        { label: 'Website', value: 'Website' },
    ]

    const { employeeList, teamList, officeList } = useReport()

    const [employeeId, setEmployeeId] = useState<string>();
    const [teamId, setTeamId] = useState<string>();
    const [officeId, setOfficeId] = useState<string>();
    const [source, setSource] = useState<string>();

    const fetchReport = async () => {
        const _assigneeIdList: string[] = []
        const _teamIdList: string[] = []
        const _officeIdList: string[] = []
        const _sourceList: string[] = []

        // if (reportType == "Employee" && employeeId.length > 0) {
        if (employeeId && employeeId.length > 0) {
            _assigneeIdList.push(employeeId)
        }

        // if (reportType == "Team" && teamId.length > 0) {
        if (teamId && teamId.length > 0) {
            _teamIdList.push(teamId)
        }

        if (officeId && officeId.length > 0) {
            _officeIdList.push(officeId)
        }

        // if (reportType == "Source" && source.length > 0) {
        if (source && source.length > 0) {
            _sourceList.push(source)
        }

        const baseReq: any = {}
        if (dateList.length > 0) {
            baseReq.startDate = (() => {
                const date = new Date(dateList[0])
                date.setHours(0, 0, 0)
                return date
            })()
            baseReq.endDate = (() => {
                const date = new Date(dateList[1])
                date.setHours(23, 59, 59)
                return date
            })()
        }

        try {
            if (employeeId && employeeId.length > 0) {
                const [employeeRes, error] = await EmployeeService.getById(employeeId)
                if (!error) {
                    setEmployeeRes({ ...employeeRes })
                }
            }
        } catch (err: any) {

        }

        try {
            if (teamId && teamId.length > 0) {
                const [teamRes, error] = await TeamService.getById(teamId)
                if (!error) {
                    setTeamRes({ ...teamRes })
                }
            }
        } catch (err: any) {

        }

        try {
            const [leadReportResList, error] = await LeadService.getReport({
                ...baseReq,
                assigneeIdList: [..._assigneeIdList],
                teamIdList: [..._teamIdList],
                 officeIdList: [..._officeIdList],
                sourceList: [..._sourceList]
            } as LeadReportReq)
            if (!error) {
                setLeadReportResList([...(leadReportResList ? leadReportResList : [])])
            }
        } catch (err: any) {

        }

        try {
            const [enquiryReportResList, error2] = await EnquiryService.getReport({
                ...baseReq,
                assigneeIdList: [..._assigneeIdList],
                teamIdList: [..._teamIdList],
                officeIdList: [..._officeIdList],
                sourceList: [..._sourceList]
            } as EnquiryReportReq)
            if (!error2) {
                setEnquiryReportResList([...(enquiryReportResList ? enquiryReportResList : [])])
            }
        } catch (err: any) {

        }
        try {
            const [taskReportList, error3] = await TaskService.getReport({
                ...baseReq,
                assigneeIdList: [..._assigneeIdList],
                teamIdList: [..._teamIdList],
                officeIdList: [..._officeIdList]
            } as TaskReportReq)
            if (!error3) {
                setTaskReportList([...(taskReportList ? taskReportList : [])])
            }
        } catch (err: any) {

        }

        try {
            const [callReportResList, error2] = await CallService.getReport({
                ...baseReq,
                callerIdList: [..._assigneeIdList],
                teamIdList: [..._teamIdList],
                officeIdList: [..._officeIdList],
            } as CallReportReq)
            if (!error2) {
                setCallReportResList([...(callReportResList ? callReportResList : [])])
            }
        } catch (err: any) {

        }

        setFilterData({
            ...baseReq,
            assigneeIdList: [..._assigneeIdList],
            teamIdList: [..._teamIdList],
            sourceList: [..._sourceList],
            officeIdList: [..._officeIdList],
        })
    }

    useEffect(() => {
        fetchReport()
    }, [employeeId, source, teamId, dateList, officeId])

    const onDateUpdate = (values: any[]) => {
        setDateList(values)
    }

    return (
        <>
            <Row className="report-button-wrapper">
                <Col md={15} xs={24} className="report-dropdown-wrapper">
                    {/* <Select
                        value={reportType}
                        style={{ minWidth: 132, marginRight: 16 }}
                        options={reportTypeList}
                        onSelect={(value: string) => {
                            setReportType(value)
                        }}
                        filterOption={handleFilter} /> */}

                    <Select
                        showSearch={true}
                        allowClear={true}
                        placeholder={"Select Employee"}
                        value={employeeId}
                        style={{ minWidth: 154, marginRight: 16 }}
                        options={employeeList.map((employeeRes: EmployeeRes) => {
                            return {
                                value: employeeRes.id,
                                label: employeeRes.userInfo.name
                            }
                        })}
                        onSelect={(value: string) => {
                            setEmployeeId(value)
                        }}
                        onClear={() => {
                            setEmployeeId(undefined)
                        }}
                        filterOption={handleFilter} />

                    <Select
                        showSearch={true}
                        allowClear={true}
                        placeholder={"Select Team"}
                        value={teamId}
                        style={{ minWidth: 154, marginRight: 16 }}
                        options={teamList.map((teamRes: TeamRes) => {
                            return {
                                value: teamRes.id,
                                label: teamRes.name
                            }
                        })}
                        onSelect={(value: string) => {
                            setTeamId(value)
                        }}
                        onClear={() => {
                            setTeamId(undefined)
                        }}
                        filterOption={handleFilter} />

                    <Select
                        showSearch={true}
                        allowClear={true}
                        placeholder={"Select Source"}
                        value={source}
                        style={{ minWidth: 154, marginRight: 16 }}
                        options={sourceList}
                        onSelect={(value: string) => {
                            setSource(value)
                        }}
                        onClear={() => {
                            setSource(undefined)
                        }}
                        filterOption={handleFilter} />

                    <Select
                        showSearch={true}
                        allowClear={true}
                        placeholder={"Select Office"}
                        value={officeId}
                        style={{ minWidth: 154, marginRight: 16 }}
                        options={officeList.map((officeRes: OfficeRes) => {
                            return {
                                value: officeRes.id,
                                label: officeRes.name
                            }
                        })}
                        onSelect={(value: string) => {
                            setOfficeId(value)
                        }}
                        onClear={() => {
                            setOfficeId(undefined)
                        }}
                        filterOption={handleFilter} />
                </Col>


                <Col md={9} xs={24} className="report-right-wrapper">
                    <RangePicker
                        inputReadOnly={isMobile()}
                        presets={rangePresets}
                        allowClear={true}
                        onChange={(value: any) => {
                            onDateUpdate(value)
                        }} />
                </Col>


            </Row>

            {(employeeId && employeeId.length > 0) && <Row style={{ marginBottom: 16 }}>
                {(userConfig.employeeRes.employeeRole == "Admin" ||
                    userConfig.employeeRes.employeeRole == "SuperAdmin") ? <>
                    <Col md={5} xs={24}>
                        <ReportEmployeeComponent employeeRes={employeeRes} />
                    </Col>
                    <Col md={18} xs={24} style={{ marginLeft: 8, marginRight: 8 }}>
                        <TimelineComponent employeeId={employeeId} />
                    </Col>
                </> : <Col md={24} xs={24}>
                    <ReportEmployeeComponent employeeRes={employeeRes} />
                </Col>
                })
            </Row>}


            {/* {(employeeId && employeeId.length > 0) && <ReportEmployeeComponent employeeRes={employeeRes} />}

            {(employeeId && employeeId.length > 0) && <TimelineComponent employeeId={employeeId} />} */}

            {/* {(teamId && teamId.length > 0) && <ReportTeamComponent teamRes={teamRes} />} */}

            {(leadReportResList.length > 0 || enquiryReportResList.length > 0) &&
                <ReportTaskEnquiryComponent leadReportResList={leadReportResList} enquiryReportList={enquiryReportResList}
                    filterData={filterData} />
            }

            <ReportLeadComponent taskReportList={taskReportList} filterData={filterData} />

            {/*
            <ReportLeadConversionComponent />
            */}

            {(callReportResList.length > 0 || callReportResList.length > 0) &&
                <PhoneStatsComponent callReportResList={callReportResList} />
            }
        </>
    )
}

export default ReportComponent