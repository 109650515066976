import React from "react";
import { Col, Modal, Row, Space } from "antd";
import { ProjectRes } from "../../../response/project.response";

export const ProjectDetailComponent: React.FC<{
  projectDetail: ProjectRes;
  open: boolean;
  onCancel: () => void;
}> = ({ projectDetail, open, onCancel }) => {
  return (
    <>
      {console.log(projectDetail)}
      <Modal
        open={open}
        title={<h2>{projectDetail.id}</h2>}
        onCancel={onCancel}
        width={"90vw"}
        footer={false}
        style={{ top: 8, maxWidth: 768 }}
      >
        <Row gutter={16}>
          <Col span={8} style={{ paddingLeft: 16 }}>
            <Row gutter={16} style={{ display: "grid", marginBottom: 16 }}>
              <Space style={{ alignItems: "baseline" }}>
                <h3>Name </h3>
              </Space>
            </Row>
            <Row gutter={16} style={{ display: "grid", marginBottom: 16 }}>
              <h3>Builder </h3>
              <Space style={{ alignItems: "baseline", marginLeft: 8 }}></Space>
            </Row>
            <Row gutter={16} style={{ display: "grid", marginBottom: 16 }}>
              <h3>Source</h3>
              <Space style={{ alignItems: "baseline", marginLeft: 8 }}></Space>
            </Row>
            <Row gutter={16} style={{ display: "grid", marginBottom: 16 }}>
              <Space style={{ alignItems: "baseline" }}>
                <h3>Total Tower </h3>
              </Space>
            </Row>
            <Row gutter={16} style={{ display: "grid", marginBottom: 16 }}>
              <Space style={{ alignItems: "baseline" }}>
                <h3>Total Unit</h3>
              </Space>
            </Row>

            <Row gutter={16} style={{ display: "grid", marginBottom: 16 }}>
              <Space style={{ alignItems: "baseline" }}>
                <h3>Status </h3>
              </Space>
            </Row>
            <Row gutter={16} style={{ display: "grid", marginBottom: 16 }}>
              <Space style={{ alignItems: "baseline" }}>
                <h3>Address </h3>
              </Space>
            </Row>
          </Col>

          <Col span={2} style={{ paddingLeft: 16 }}>
            <Row gutter={16} style={{ display: "grid", marginBottom: 16 }}>
              <Space style={{ alignItems: "baseline" }}>
                <h3>: </h3>
              </Space>
            </Row>
            <Row gutter={16} style={{ display: "grid", marginBottom: 16 }}>
              <h3>: </h3>
              <Space style={{ alignItems: "baseline", marginLeft: 8 }}></Space>
            </Row>
            <Row gutter={16} style={{ display: "grid", marginBottom: 16 }}>
              <h3>: </h3>
              <Space style={{ alignItems: "baseline", marginLeft: 8 }}></Space>
            </Row>
            <Row gutter={16} style={{ display: "grid", marginBottom: 16 }}>
              <Space style={{ alignItems: "baseline" }}>
                <h3>:</h3>
              </Space>
            </Row>
            <Row gutter={16} style={{ display: "grid", marginBottom: 16 }}>
              <Space style={{ alignItems: "baseline" }}>
                <h3>:</h3>
              </Space>
            </Row>

            <Row gutter={16} style={{ display: "grid", marginBottom: 16 }}>
              <Space style={{ alignItems: "baseline" }}>
                <h3>: </h3>
              </Space>
            </Row>
            <Row gutter={16} style={{ display: "grid", marginBottom: 16 }}>
              <Space style={{ alignItems: "baseline" }}>
                <h3>: </h3>
              </Space>
            </Row>
          </Col>

          <Col span={14} style={{ paddingLeft: 16 }}>
            <Row gutter={16} style={{ display: "grid", marginBottom: 16 }}>
              <Space style={{ alignItems: "baseline" }}>
                <h3>{projectDetail.name} </h3>
              </Space>
            </Row>
            <Row gutter={16} style={{ display: "grid", marginBottom: 16 }}>
              <h3>{projectDetail.builder.name} </h3>
              <Space style={{ alignItems: "baseline", marginLeft: 8 }}></Space>
            </Row>
            <Row gutter={16} style={{ display: "grid", marginBottom: 16 }}>
              <h3>
                {projectDetail.outSideKey.housing != "" &&
                  `Housing : ${projectDetail.outSideKey.housing} ||`}
                {projectDetail.outSideKey.acre != "" &&
                  ` Acre : ${projectDetail.outSideKey.acre}`}
              </h3>
              <Space style={{ alignItems: "baseline", marginLeft: 8 }}></Space>
            </Row>
            <Row gutter={16} style={{ display: "grid", marginBottom: 16 }}>
              <Space style={{ alignItems: "baseline" }}>
                <h3>{projectDetail.totalTower} </h3>
              </Space>
            </Row>
            <Row gutter={16} style={{ display: "grid", marginBottom: 16 }}>
              <Space style={{ alignItems: "baseline" }}>
                <h3>{projectDetail.totalUnit}</h3>
              </Space>
            </Row>

            <Row gutter={16} style={{ display: "grid", marginBottom: 16 }}>
              <Space style={{ alignItems: "baseline" }}>
                <h3>{projectDetail.status} </h3>
              </Space>
            </Row>
            <Row gutter={16} style={{ display: "grid", marginBottom: 16 }}>
              <Space style={{ alignItems: "baseline" }}>
                {/* <h3>
                  {`${
                    projectDetail.address?.lineOne
                      ? `${projectDetail.address?.lineOne} ,`
                      : ""
                  } ${
                    projectDetail.address?.lineTwo
                      ? `${projectDetail.address?.lineTwo} ,`
                      : ""
                  } ${
                    projectDetail.address?.street
                      ? `${projectDetail.address?.street} ,`
                      : ""
                  } ${
                    projectDetail.address?.city
                      ? `${projectDetail.address?.city} ,`
                      : ""
                  } ${
                    projectDetail.address?.state
                      ? `${projectDetail.address?.state}`
                      : ""
                  }`}{" "}
                </h3> */}
              </Space>
            </Row>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
