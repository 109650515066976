import { useEffect } from "react"
import { useMapLocation } from "./location.hook"

export const LocationDeniedComponent = () => {
    const { permissionDenied } = useMapLocation()

    useEffect(() => {
        if (!permissionDenied) {
            window.location.reload()
        }
    }, [permissionDenied])

    return <h1>
        Please Provide Location access
    </h1>
}