import { Button, Card, Popconfirm, Space, Spin, Typography, theme } from 'antd';
import { useDashboard } from '../dashboard.hook';
import { useEffect, useState } from 'react';
import { StartDayReq } from '../../../request/attendance.request';
import { SecurityManager } from '../../../security/security-manager';
import dayjs from 'dayjs';
import { useMapLocation } from '../../../location/location.hook';

export const AttendanceComponent = () => {
    const { useToken } = theme;
    const { token } = useToken();

    const { fetchTodayAttendance, startDay, loading, endDay, updateLoading, attendanceConfigRes } = useDashboard()
    const { location } = useMapLocation()

    const [open, setOpen] = useState(false);

    const showPopconfirm = () => {
        setOpen(true);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    useEffect(() => {
        fetchTodayAttendance()
    }, [])

    const _startDay = async () => {
        updateLoading(true)
        const startDayReq = {
            employeeId: SecurityManager.getEid(),
            logInLocation: location
        } as StartDayReq

        await startDay(startDayReq)
    }

    const _endDay = async () => {
        const endDayReq = {
            employeeId: SecurityManager.getEid(),
            logInLocation: location
        } as StartDayReq

        await endDay(endDayReq)
    }

    return <Card className='attendance-elem' title={false} style={{ width: 'auto', margin: '0 10px 5px 10px' }} bordered={false}
        bodyStyle={{ padding: '10px 15px', display: 'flex' }}>
        {loading ? <Spin style={{ margin: 'auto' }} /> :
            <>
                {attendanceConfigRes.startAllowed &&
                    <Space className='d-space-wrapper' style={{ gap: 24, width: '100%' }}>
                        <Typography.Text>Mark your attendance</Typography.Text>
                        <Button type="primary" style={{ background: token.colorInfo, boxShadow: token.boxShadowSecondary, borderRadius: token.borderRadiusSM, padding: '2px 8px' }} onClick={_startDay}>
                            Start Day
                        </Button>
                    </Space>
                }
                {attendanceConfigRes.endAllowed &&
                    <Space className='d-space-wrapper' style={{ gap: 24, width: '100%' }}>
                        <Typography.Text>{`Logged in at : ${dayjs(attendanceConfigRes.attendanceRes.logInTime).format('hh:mm A')}`}</Typography.Text>

                        <Popconfirm
                            title="Attendance"
                            description="Are you sure you want to logout?"
                            open={open}
                            onConfirm={_endDay}
                            okButtonProps={{ loading: loading }}
                            onCancel={handleCancel}
                        >
                            <Button type="primary" style={{ background: token.colorSuccess, boxShadow: token.boxShadowSecondary, borderRadius: token.borderRadiusSM, padding: '2px 8px' }} onClick={showPopconfirm}>
                                End Day
                            </Button>
                        </Popconfirm>
                    </Space>
                }
                {(!attendanceConfigRes.startAllowed && !attendanceConfigRes.endAllowed) &&
                    <Space className='d-space-wrapper' style={{ gap: 24,  width: '100%' }}>
                        <Typography.Text>{`Today time is : ${dayjs(attendanceConfigRes.attendanceRes?.logOutTime).diff(dayjs(attendanceConfigRes.attendanceRes?.logInTime), 'hours', false)} hrs ${dayjs(attendanceConfigRes.attendanceRes?.logOutTime).diff(dayjs(attendanceConfigRes.attendanceRes?.logInTime), 'minute', false)} mins`}</Typography.Text>
                    </Space>
                }
            </>
        }
    </Card>
}