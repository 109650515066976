import React from 'react';
import { Form, Input, Button } from 'antd';
import { useMenu } from '../menu.hook';
import { MenuSearchReq } from '../../../request/menu.request';

export const MenuFilterComponent: React.FC = () => {
    const [form] = Form.useForm();
    const { fetchMenuList, updateLoading, menuSearchReq  } = useMenu()

    const handleOk = () => {
        form.validateFields().then(values => {
            updateLoading(true);
            const _menuSearchReq = {
                ...menuSearchReq,
                name: values["name"],
                pageNumber: 1,
                itemsPerPage: 10
            } as MenuSearchReq
            fetchMenuList(_menuSearchReq, onSuccess, onFailure)
        });
    };

    const onSuccess = () => {
        updateLoading(false);
    }

    const onFailure = () => {
        updateLoading(false);
    }

    return (
        <Form form={form} layout="inline">
            <Form.Item
                name="name"
                style={{ minWidth: 124 }}
                initialValue={menuSearchReq.name}
            >
                <Input placeholder='Name' />
            </Form.Item>

            <Form.Item style={{ minWidth: 124 }}>
                <Button type="primary" htmlType="submit"
                    onClick={handleOk}>
                    Search
                </Button>
            </Form.Item>
        </Form>
    );
}
