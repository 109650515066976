import { Action } from "../../model/action.model";
import { OfficeAction } from "./office.action";
import { OfficeState } from "./office.state";


export const OfficeReducer = (state: OfficeState, action: Action): OfficeState => {
    const { type, payload } = action

    switch (type) {
        case OfficeAction.UPDATE_LOADING:
            return {
                ...state,
                loading: payload.loading
            };
        case OfficeAction.UPDATE_OFFICE_LIST:
            return {
                ...state,
                officeList: [...payload.officeList]
            };

        case OfficeAction.ADD_OFFICE_LIST:
            return {
                ...state,
                officeList: [...state.officeList, ...payload.officeList]
            };

        case OfficeAction.UPDATE_OFFICE_SEARCH_REQ:
            return {
                ...state,
                officeSearchReq: { ...payload.officeSearchReq }
            };

        case OfficeAction.SET_UPDATE_OFFICE:
            return {
                ...state,
                updateOfficeData: { ...payload.updateOfficeData }
            };
        default:
            throw new Error(`No case for type ${type} found in OfficeReducer.`);
    }
}