import { EmployeeRequest, EmployeeSearchReq, ProfilePicReq } from "../request/employee.request"
import { EmployeeRes } from "../response/employee.response"
import httpClient from "../util/http-client"

export const EmployeeService = {
    getById: async (id: string): Promise<(EmployeeRes | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.get(`/employee/${id}`)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    saveEmployee: async (employeeRequest: EmployeeRequest): Promise<(EmployeeRes|any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/employee", employeeRequest)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    getEmployeeList: async (employeeSearchReq: EmployeeSearchReq): Promise<(EmployeeRes[]|any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/employee-search", employeeSearchReq)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    getReporteeList: async (reportingManager = true): Promise<(EmployeeRes[]|any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.get(`/reportee?reportingManager=${reportingManager}`)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    updateEmployee: async (id: string, employeeRequest: EmployeeRequest): Promise<(EmployeeRes | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.put(`/employee/${id}`, employeeRequest)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    updateEmployeeProfiePic: async (id: string, profilePicReq: ProfilePicReq): Promise<(EmployeeRes | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post(`/employee/${id}/update-profile`, profilePicReq)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    getMyTeam: async (): Promise<(EmployeeRes[]|any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.get("/my-team")
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    getEmployeeTree: async (): Promise<(EmployeeRes[]|any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.get("/employee-tree")
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    pause: async (id: string): Promise<(number | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.get(`/employee/${id}/update-status`)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    remove: async (id: string): Promise<(number | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.get(`/employee/${id}/remove`)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
}