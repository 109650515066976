
import { TaskDiscussionRequest, TaskDiscussionSearchReq } from "../request/task-discussion.request";
import { TaskHistorySearchReq } from "../request/task-history.request";
import { TaskReportReq, TaskRequest, TaskSearchReq } from "../request/task.request";
import { TaskDiscussionRes } from "../response/task-discussion.response";
import { TaskHistoryRes } from "../response/task-history.response";
import { TaskReportRes, TaskRes } from "../response/task.response";
import httpClient from "../util/http-client"

export const TaskService = {
    saveTask: async (taskRequest: TaskRequest): Promise<(TaskRes | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/task", taskRequest)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    getTaskById: async (id: string): Promise<(TaskRes | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.get(`/task/${id}`)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    getTaskList: async (taskSearchReq: TaskSearchReq): Promise<(TaskRes[] | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/task-search", taskSearchReq)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    updateTask: async (id: string, taskRequest: TaskRequest): Promise<(TaskRes | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.put(`/task/${id}`, taskRequest)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    getTaskHistoryList: async (taskHistorySearchReq: TaskHistorySearchReq): Promise<(TaskHistoryRes[] | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/task-history-search", taskHistorySearchReq)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    saveDiscussion: async (taskDiscussionRequest: TaskDiscussionRequest): Promise<(TaskDiscussionRes | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/task-discussion", taskDiscussionRequest)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    getTaskDiscussionList: async (taskDiscussionSearchReq: TaskDiscussionSearchReq): Promise<(TaskDiscussionRes[] | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/task-discussion-search", taskDiscussionSearchReq)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    getReport: async (taskReportReq: TaskReportReq): Promise<(TaskReportRes[])[]> => {
        let data
        let err
        try {
            const res = await httpClient.post(`/task-report`, taskReportReq)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
}
