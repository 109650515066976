import { Button, Card, Col, Form, Input, Row, theme, Typography } from "antd"
import Meta from "antd/es/card/Meta"
import TextArea from "antd/es/input/TextArea"
import React, { useState } from "react"
import ImgIcon from "../../../../../view/img-icon"
import { ProfessionalDetail, UserRes } from "../../../../../response/user.response"
import { UserRequest } from "../../../../../request/user.request"
import { UserService } from "../../../../../service/user.service"
import { useEnquiry } from "../../../enquiry.hook"

interface UserProfressionalDetailProp {
    client: UserRes
}

export const UserProfressionalDetail = (props: UserProfressionalDetailProp) => {

    const [form] = Form.useForm();

    const { client } = props
    const { useToken } = theme;
    const { token } = useToken();

    const { fetchClient } = useEnquiry()

    const gridStyleFull: React.CSSProperties = {
        width: '100%',
        boxShadow: 'none',
        padding: '10px 4px',
        marginBottom: '5px'
    };


    const [isProfressionalDetailsEdit, setIsProfressionalDetailsEdit] = useState(false);

    const handleProfressionalDetailEdit = () => {
        setIsProfressionalDetailsEdit(true)
    }

    const saveProfessinalDetail = () => {
        form.validateFields().then(async values => {
            const userRequest = {
                professionalDetail: {
                    company: values["company"],
                    profession: values["profession"],
                    designation: values["designation"],
                    website: values["website"],
                    salary: values["salary"],
                    remark: values["remark"]
                } as ProfessionalDetail
            } as UserRequest
            const [data, err] = await UserService.updateUser(client.id, userRequest)
            if (err) {

            } else {
                fetchClient(client.id, () => { setIsProfressionalDetailsEdit(false) }, () => { setIsProfressionalDetailsEdit(false) })
            }
        });
    }

    return (
        <React.Fragment>
            <Col lg={12} xs={24}>
                <Card title="Professional Details" bordered={false}
                    extra={!isProfressionalDetailsEdit &&
                        <Button style={{
                            display: 'flex',
                            alignItems: 'center',
                            border: '0',
                            background: '#E0EEF7',
                            color: '#000000'
                        }}
                            onClick={handleProfressionalDetailEdit}
                        >
                            <ImgIcon iconName="edit.svg" />
                            Edit
                        </Button>
                    }
                    style={{
                        padding: '0 20px',
                        height: '100%'
                    }}
                    headStyle={{
                        padding: 0
                    }}
                    bodyStyle={{
                        padding: '10px 0'
                    }}
                    className="enquiry-professional-details wrapper-class common-space"
                >

                    {isProfressionalDetailsEdit &&
                        <Form form={form}>
                            <Row style={{
                                marginBottom: '20px'
                            }}>
                                <Col md={12} xs={24} >
                                    <Typography.Title level={5} style={{
                                        fontSize: '14px'
                                    }}>Company Name</Typography.Title>

                                    <Form.Item
                                        name="company"
                                        style={{
                                            margin: 0
                                        }}
                                        rules={[{ required: false }]}
                                        initialValue={client.professionalDetail?.company}
                                    >

                                        <Input
                                            type="text"
                                            value=""
                                            placeholder="Type here"
                                            style={{
                                                width: '94%',
                                                border: '1px solid #92B5D7',
                                                borderRadius: token.borderRadius,
                                                boxShadow: 'none'
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col md={12} xs={24} >
                                    <Typography.Title level={5} style={{
                                        fontSize: '14px'
                                    }}>Profession</Typography.Title>
                                    <Form.Item
                                        name="profession"
                                        style={{
                                            margin: 0
                                        }}
                                        rules={[{ required: false }]}
                                        initialValue={client.professionalDetail?.profession}
                                    >
                                        <Input
                                            type="text"
                                            placeholder="Type here"
                                            style={{
                                                width: '98%',
                                                border: '1px solid #92B5D7',
                                                borderRadius: token.borderRadius,
                                                boxShadow: 'none'
                                            }}
                                        />

                                    </Form.Item>

                                </Col>
                            </Row>
                            <Row style={{
                                marginBottom: '20px'
                            }}>
                                <Col md={12} xs={24} >
                                    <Typography.Title level={5} style={{
                                        fontSize: '14px'
                                    }}>Designation</Typography.Title>
                                    <Form.Item
                                        name="designation"
                                        style={{
                                            margin: 0
                                        }}
                                        rules={[{ required: false }]}
                                        initialValue={client.professionalDetail?.designation}
                                    >
                                        <Input
                                            type="text"
                                            placeholder="Type here"
                                            style={{
                                                width: '94%',
                                                border: '1px solid #92B5D7',
                                                borderRadius: token.borderRadius,
                                                boxShadow: 'none'
                                            }}
                                        />
                                    </Form.Item>

                                </Col>
                                <Col md={12} xs={24} >
                                    <Typography.Title level={5} style={{
                                        fontSize: '14px'
                                    }}>Website</Typography.Title>
                                    <Form.Item
                                        name="website"
                                        style={{
                                            margin: 0
                                        }}
                                        rules={[{ required: false }]}
                                        initialValue={client.professionalDetail?.website}
                                    >
                                        <Input
                                            type="text"
                                            placeholder="www.example.com"
                                            style={{
                                                width: '98%',
                                                border: '1px solid #92B5D7',
                                                borderRadius: token.borderRadius,
                                                boxShadow: 'none'
                                            }}
                                        />
                                    </Form.Item>

                                </Col>
                            </Row>
                            <Row style={{
                                marginBottom: '20px'
                            }}>
                                <Col md={12} xs={24} >
                                    <Typography.Title level={5} style={{
                                        fontSize: '14px'
                                    }}>Salary</Typography.Title>
                                    <Form.Item
                                        name="salary"
                                        style={{
                                            margin: 0
                                        }}
                                        rules={[{ required: false }]}
                                        initialValue={client.professionalDetail?.salary}
                                    >
                                        <Input
                                            type="number"
                                            placeholder="Type here"
                                            style={{
                                                width: '94%',
                                                border: '1px solid #92B5D7',
                                                borderRadius: token.borderRadius,
                                                boxShadow: 'none'
                                            }}
                                        />

                                    </Form.Item>

                                </Col>
                            </Row>
                            <Row style={{
                                marginBottom: '20px'
                            }}>
                                <Col span={24}>
                                    <Typography.Title level={5} style={{
                                        fontSize: '14px'
                                    }}>Remarks</Typography.Title>
                                    <Form.Item
                                        name="remark"
                                        style={{
                                            margin: 0
                                        }}
                                        rules={[{ required: false }]}
                                        initialValue={client.professionalDetail?.remark}
                                    >
                                        <TextArea style={{
                                            width: '98%',
                                            border: '1px solid #92B5D7',
                                            borderRadius: token.borderRadius,
                                            boxShadow: 'none'
                                        }} rows={4} placeholder="maxLength is 6" />

                                    </Form.Item>


                                </Col>
                            </Row>
                            <Row className="action-row" style={{
                                marginBottom: '20px',
                                justifyContent: 'end'
                            }}>
                                <Col style={{ marginRight: 16 }}>
                                    <Button style={{
                                        border: '0',
                                        background: '#E0EEF7',
                                        color: '#000000'
                                    }}
                                        onClick={() => setIsProfressionalDetailsEdit(false)}
                                    >
                                        Cancel
                                    </Button>
                                </Col>
                                <Col>
                                    <Button type="primary" block style={{
                                        background: token.colorInfo,
                                        boxShadow: 'none'
                                    }}
                                        onClick={saveProfessinalDetail}
                                    >
                                        Save Changes
                                    </Button>
                                </Col>

                            </Row>
                        </Form>
                    }


                    {!isProfressionalDetailsEdit &&
                        <React.Fragment>
                            <Card.Grid style={gridStyleFull}>
                                <Meta
                                    title={"Company Name"}
                                    description={client.professionalDetail?.company}
                                />
                            </Card.Grid>
                            <Card.Grid style={gridStyleFull}>
                                <Meta
                                    title={"Profession"}
                                    description={client.professionalDetail?.profession}
                                />
                            </Card.Grid>
                            <Card.Grid style={gridStyleFull}>
                                <Meta
                                    title={"Website"}
                                    description={client.professionalDetail?.website}
                                />
                            </Card.Grid>
                            <Card.Grid style={gridStyleFull}>
                                <Meta
                                    title={"Salary"}
                                    description={client.professionalDetail?.salary}
                                />
                            </Card.Grid>
                            <Card.Grid style={gridStyleFull}>
                                <Meta
                                    title={"Designation"}
                                    description={client.professionalDetail?.designation}
                                />
                            </Card.Grid>
                            <Card.Grid style={gridStyleFull}>
                                <Meta
                                    title={"Remarks"}
                                    description={client.professionalDetail?.remark}
                                />
                            </Card.Grid>
                        </React.Fragment>
                    }
                </Card>
            </Col>


        </React.Fragment>
    )
}