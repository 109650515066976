import React, { useEffect } from 'react';
import { Modal, Form, Input, Select } from 'antd';
import { useLocality } from '../locality.hook';
import { LocalityRequest } from '../../../request/locality.request';
import { validateLevelName } from '../../../validator/level.validator';
import { LocalityRes } from '../../../response/locality.response';
import { CityRes } from '../../../response/city.response';
import { ItemProp } from '../../../model/item-prop.model';
import { handleFilter } from '../../../view/input.helper';
import { CountryRes } from '../../../response/country.response';
import { Media } from '../../../model/media.model';
import FileUploadView from '../../../view/file-upload.view';
import LocationPickerView from '../../../view/location-picker.view';
import { Location } from '../../../model/location.model';

export const UpdateLocalityComponent: React.FC<{ locality: LocalityRes, open: boolean, onCancel: () => void }> = ({ locality, open, onCancel }) => {
    const [form] = Form.useForm();
    const { loading, cityList, countryList, updateLoading, updateLocality, fetchCountryList,
        fetchCityList } = useLocality()

    const handleOk = () => {
        form.validateFields().then(values => {
            updateLoading(true);
            const localityRequest = {
                name: values["name"],
                pinCodeList: values["pinCodeList"],
                location: values["location"],
                thumbnail: values["thumbnail"],
                cityId: values["cityId"],
                countryId: values["countryId"]
            } as LocalityRequest
            updateLocality(locality.id, localityRequest, onSuccess, onFailure)
        });
    };

    const onSuccess = () => {
        updateLoading(false);
        form.resetFields()
        onCancel();
    }

    const onFailure = () => {
        updateLoading(false);
    }

    useEffect(() => {
        fetchCountryList()
        fetchCityList()
    }, [])

    return (
        <Modal
            open={open}
            title="Add New Locality"
            onCancel={onCancel}
            confirmLoading={loading}
            onOk={handleOk}
            okText={"Submit"}
            cancelText={"Cancel"}
            style={{ top: 8, maxWidth: 768 }}
            width={'90vw'}
        >
            <Form form={form} layout="vertical">
                <Form.Item
                    name="name"
                    label="Name"
                    rules={[{ required: true, validator: validateLevelName }]}
                    initialValue={locality.name}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="pinCodeList"
                    label="Pin Code List"
                    rules={[{ required: false }]}
                    initialValue={locality.pinCodeList}
                >
                    <Select
                        mode="tags"
                        value='pinCodeList'
                        maxTagCount='responsive'
                    />
                </Form.Item>

                <Form.Item
                    name="countryId"
                    label="Country"
                    initialValue={locality.country?.id}
                >
                    <Select
                        showSearch={true}
                        value='countryId'
                        options={countryList.map((countryRes: CountryRes) => {
                            return {
                                label: `${countryRes.name}`,
                                value: countryRes.id
                            } as ItemProp
                        })}
                        filterOption={handleFilter} />
                </Form.Item>

                <Form.Item
                    name="cityId"
                    label="City"
                    initialValue={locality.city?.id}
                >
                    <Select
                        showSearch={true}
                        value='cityId'
                        options={cityList.map((cityRes: CityRes) => {
                            return {
                                label: `${cityRes.name}`,
                                value: cityRes.id
                            } as ItemProp
                        })}
                        filterOption={handleFilter} />
                </Form.Item>

                <Form.Item
                    name="thumbnail"
                    label="Thumbnail"
                    initialValue={locality.thumbnail}
                >
                    <FileUploadView onChange={(media: Media) => { }} />
                </Form.Item>

                <Form.Item
                    name="location"
                    label="Location"
                >
                    <LocationPickerView onChange={(location: Location) => { }} />
                </Form.Item>

            </Form>
        </Modal>
    );
}