import { ProjectRequest, ProjectSearchReq } from "../request/project.request"
import { ProjectRes } from "../response/project.response"
import httpClient from "../util/http-client"

export const ProjectService = {
    saveProject: async (projectRequest: ProjectRequest): Promise<(ProjectRes | any)[]> => {
        console.log("project save request: ", {projectRequest})
        let data
        let err
        try {   
            console.log("projectRequest: ", {projectRequest})
            const res = await httpClient.post("/project", projectRequest)
            data = res.data
            
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },

    getProjectList: async (projectSearchReq: ProjectSearchReq): Promise<(ProjectRes[]|any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/project-search", projectSearchReq)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },

    updateProject: async (id: string, projectRequest: ProjectRequest): Promise<(ProjectRequest | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.put(`/project/${id}`, projectRequest)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },

    delete: async (id: string): Promise<(ProjectRequest | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.delete(`/project/${id}`)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
}