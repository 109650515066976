import { CallUpdateReq, CallSearchReq, CallReportReq, CallReq } from "../request/call.request"
import { CallReportRes, CallRes } from "../response/call.response"
import httpClient from "../util/http-client"

export const CallService = {
    makeCall: async (callReq: CallReq): Promise<(CallRes | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post(`/call`, callReq)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    getById: async (id: string): Promise<(CallRes | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.get(`/call/${id}`)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    getCallList: async (callSearchReq: CallSearchReq): Promise<(CallRes[] | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/call-search", callSearchReq)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    updateCall: async (id: string, callUpdateReq: CallUpdateReq): Promise<(CallRes | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.put(`/call/${id}`, callUpdateReq)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    getReport: async (callReportReq: CallReportReq): Promise<(CallReportRes[])[]> => {
        let data
        let err
        try {
            const res = await httpClient.post(`/call-report`, callReportReq)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
}