import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Select, DatePicker, theme } from 'antd';
import { TaskRequest } from '../../../request/task.request';
import { useTask } from '../task.hook';
import { EmployeeRes } from '../../../response/employee.response';
import { ItemProp } from '../../../model/item-prop.model';
import { TextEditorView } from '../../../view/text-editor.view';
import dayjs from 'dayjs';
import './add-task.css';
import { handleFilter } from '../../../view/input.helper';
import { formatName } from '../../../util/name-helper';
import { isMobile } from '../../../util/device-helper';

export const AddTaskComponent: React.FC<{ open: boolean, onCancel: () => void }> = ({ open, onCancel }) => {
    const [form] = Form.useForm();
    const { useToken } = theme;
    const { token } = useToken();
    const { loading, updateLoading, saveTask, employeeList, priorityList, statusList, fetchEmployeeList } = useTask()
    const [status, setStatus] = useState('')

    const handleOk = () => {
        form.validateFields().then(values => {
            updateLoading(true);
            const taskRequest = {
                title: values["title"],
                description: values["description"],
                priority: values["priority"],
                tagList: values["tagList"],
                status: values["status"],
                assigneeId: values["assigneeId"],
                dueDate: values["dueDate"],
            } as TaskRequest
            saveTask(taskRequest, onSuccess, onFailure)
        });
    };

    useEffect(() => {
        fetchEmployeeList()
    }, [])

    const onSuccess = () => {
        updateLoading(false);
        form.resetFields()
        onCancel();
    }

    const onFailure = () => {
        updateLoading(false);
    }

    const handleStatusOnChange = (value: string) => {
        setStatus(value)
    }



    return (
        <Modal
            open={open}
            title="Add New Task"
            onCancel={onCancel}
            confirmLoading={loading}
            onOk={handleOk}
            okText={"Submit"}
            cancelText={"Cancel"}
            className='add-task-modal m-web-modal'
            style={{ top: 8, maxWidth: 768, color: '#000000' }}
            width={'90vw'}
            centered
            bodyStyle={{
                height: '580px',
                overflow: 'auto',
                padding: '15px 24px'
            }}
            okButtonProps={{
                style: {
                    background: token.colorInfo,
                    boxShadow: 'none',
                    borderRadius: token.borderRadiusSM,
                    padding: '2px 8px',
                    margin: '5px'
                }
            }}
            cancelButtonProps={{
                style: {
                    background: '#C6C6C6',
                    border: 'none'
                }
            }}
        >
            <Form form={form} layout="vertical" className='add-task-form form-styles'>


                <Form.Item
                    name="status"
                    label="Status"
                    rules={[{ required: true, message: 'Please enter your status' }]}
                >
                    <Select
                        showSearch={true}
                        value={status}
                        style={{
                            borderRadius: token.borderRadiusSM,
                            width: '250px',
                            marginRight: '20px',
                        }}
                        className={status ? 'active-selector' : ''}
                        onChange={handleStatusOnChange}
                        options={statusList}
                        filterOption={handleFilter} />

                </Form.Item>


                <Form.Item
                    name="assigneeId"
                    label="Assigned to"
                    rules={[{ required: true, message: 'Assign to' }]}
                >
                    <Select
                        showSearch={true}
                        value='assigneeId'
                        style={{
                            borderRadius: token.borderRadiusSM,
                            width: '250px',
                        }}
                        options={employeeList.map((employee: EmployeeRes) => {
                            return {
                                label: `${formatName(employee.userInfo.name)}`,
                                value: employee.id
                            } as ItemProp
                        })}
                        filterOption={handleFilter} />
                </Form.Item>

                <Form.Item
                    name="title"
                    label="Title"
                    style={{
                        color: '#000000'
                    }}
                    rules={[{ required: true, message: 'Please enter a title' }]}
                >
                    <Input style={{
                        border: '1px solid #92B5D7',
                        borderRadius: token.borderRadiusSM
                    }} />
                </Form.Item>
                <Form.Item
                    name="description"
                    label="Description"
                    rules={[{ required: true, message: 'Please enter description' }]}
                >
                    <TextEditorView placeholder={'Please enter description'}
                        classes="editor-custom-view"
                        onChange={(data: string) => { }}
                        readOnly={false} />
                </Form.Item>
                <div style={{
                    display: 'flex'
                }}
                    className='add-task-item'
                >

                    <Form.Item
                        name="priority"
                        label="Priority"
                        rules={[{ required: true, message: 'Please enter Priority' }]}
                    >
                        <Select
                            showSearch={true}
                            value='priority'
                            style={{
                                borderRadius: token.borderRadiusSM,
                                width: '250px',
                                marginRight: '20px',
                            }}
                            options={priorityList}
                            filterOption={handleFilter} />
                    </Form.Item>
                    <Form.Item
                        name="tagList"
                        label="Tags"
                        rules={[{ required: true, message: 'Enter Tags' }]}
                    >
                        <Select
                            mode="tags"
                            value='tagList'
                            maxTagCount='responsive'
                            style={{
                                borderRadius: token.borderRadiusSM,
                                width: '250px',
                                marginRight: '20px',
                            }}
                            filterOption={handleFilter}
                        />
                    </Form.Item>
                </div>

                <Form.Item
                    name="dueDate"
                    label="Due Date"
                    rules={[{ required: true, message: 'Please select a date!' }]}
                >
                    <DatePicker
                     inputReadOnly={isMobile()}
                      style={{
                        border: '1px solid #92B5D7',
                        borderRadius: token.borderRadiusSM,
                        minWidth: '250px'
                    }} disabledDate={(current) => {
                        return current <= dayjs();
                    }} />
                </Form.Item>
            </Form>
        </Modal>
    );
}
