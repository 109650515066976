import { Card, Col, Progress, Row, Typography } from "antd"
import dayjs from "dayjs";
import { useDashboard } from "../../dashboard.hook";
import { useEffect, useState } from "react";
import { AttendanceService } from "../../../../service/attendance.service";

const AttendanceHistoryComponent = () => {

    const { attendanceConfigRes } = useDashboard()

    const { Title, Text } = Typography;

    const [avgWorkingHour, setAvgWorkingHour] = useState<any>(10)

    const wokingHour = !attendanceConfigRes.endAllowed ? dayjs(attendanceConfigRes.attendanceRes?.logOutTime).diff(dayjs(attendanceConfigRes.attendanceRes?.logInTime), 'hours', false) : dayjs().diff(dayjs(attendanceConfigRes.attendanceRes?.logInTime), 'hours', false)

    const fetchReport = async () => {
        const [report, err] = await AttendanceService.getReport()
        if (err != null) {

        } else {
            setAvgWorkingHour((report.workingTime/(60 * 60)).toFixed(1))
        }
    }

    useEffect(() => {
        fetchReport()
    }, [])

    return (
        <Card className="p-mweb-card" style={{
            height: '100%',
            padding: '0 20px'
        }}
            headStyle={{
                padding: '10px 0 0',
                minHeight: 44
            }}
            bodyStyle={{
                padding: '10px 0',
                color: '#000'
            }}
            title="Attendance History"
            bordered={false}
            extra={<div></div>}
        >
            <Row style={{ alignItems: 'center' }}>
                {/* <Col md={10} xs={24} style={{ marginRight: 20 }}>
                    <Title style={{
                        fontSize: 50,
                        color: '#000',
                        marginBottom: 0
                    }}>24</Title>
                    <Text style={{ color: '#6D6C68', marginBottom: 5 }}>July, 2023</Text>
                    <Title style={{ fontSize: 14, marginTop: 5 }}>Upcoming events:</Title>
                    <ul style={{
                        listStyle: 'none',
                        padding: '3px 0 0',
                        height: '100px',
                        overflowY: 'auto'
                    }}>
                        <li style={{ marginBottom: 2 }}>🟠 Close lead 23</li>
                        <li style={{ marginBottom: 2 }}>🔵 Call Owner Shiv Shankar</li>
                        <li style={{ marginBottom: 2 }}>🔴 Complete #Task024</li>
                        <li style={{ marginBottom: 2 }}>🔵 Call Owner Shiv Shankar</li>
                        <li style={{ marginBottom: 2 }}>🔵 Call Owner Shiv Shankar</li>
                        <li style={{ marginBottom: 2 }}>🔴 Complete #Task024</li>
                    </ul>
                </Col> */}
                <Col md={24} xs={24}>
                    <Title style={{ fontSize: 14, marginTop: 5 }}>Today’s Overview</Title>
                    <div>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                        >
                            <Text style={{ color: '#6D6C68', fontSize: 12 }}>Work hours</Text>
                            <Text style={{ color: '#6D6C68', fontSize: 12, marginLeft: 'auto' }}>
                                <span style={{ color: '#000' }}>{wokingHour}/10</span> hrs
                            </Text>
                        </div>
                        <Progress percent={wokingHour * 10} strokeColor={'#EC9007'} showInfo={false} />
                    </div>
                    <div>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                        >
                            <Text style={{ color: '#6D6C68', fontSize: 12 }}>Avg work hours this month</Text>
                            <Text style={{ color: '#6D6C68', fontSize: 12, marginLeft: 'auto' }}>
                                <span style={{ color: '#000' }}>{avgWorkingHour}/10</span> hrs
                            </Text>
                        </div>
                        <Progress percent={avgWorkingHour * 10} strokeColor={'#1677FF'} showInfo={false} />
                    </div>
                </Col>
            </Row>

        </Card>
    )
}

export default AttendanceHistoryComponent