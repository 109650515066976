import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import CustomUploadAdapterPlugin from './upload-adapter';

interface OnChangeHandler {
    (data: string): void;
}
export interface TextEditorViewProp {
    initData?: string
    placeholder?: string
    onChange?: OnChangeHandler;
    readOnly: boolean,
    classes?: any
}

export const TextEditorView = (props: TextEditorViewProp) => {
    const { placeholder, readOnly, initData, onChange, classes } = props

    return <>
        {readOnly ? <>
            <div className={classes}>
                <CKEditor
                    editor={ClassicEditor}
                    disabled={readOnly}
                    config={{
                        toolbar: [],
                        placeholder: placeholder,
                        extraPlugins: [CustomUploadAdapterPlugin],
                    }}
                    data={initData}
                    onReady={editor => {
                        // You can store the "editor" and use when it is needed.
                        // console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        if (onChange) {
                            onChange(data)
                        }
                    }}
                    onBlur={(event, editor) => {
                        // console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                        // console.log('Focus.', editor);
                    }}
                />
            </div>
        </> :
            <>
                <div className={classes}>
                    <CKEditor
                        editor={ClassicEditor}
                        config={{
                            placeholder: placeholder,
                            extraPlugins: [CustomUploadAdapterPlugin]
                        }}
                        data={initData}
                        onReady={editor => {
                            // You can store the "editor" and use when it is needed.
                            // console.log('Editor is ready to use!', editor);
                        }}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            if (onChange) {
                                onChange(data)
                            }
                        }}
                        onBlur={(event, editor) => {
                            // console.log('Blur.', editor);
                        }}
                        onFocus={(event, editor) => {
                            // console.log('Focus.', editor);
                        }}
                    />
                </div>
            </>}
    </>
}