import { Button, Space, Spin, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { AmenitiesRes } from '../../../response/amenities.response';
import { useAmenities } from '../amenities.hook';
import { AddAmenitiesComponent } from './add-amenities.component';
import { UpdateAmenitiesComponent } from './update-amenities.component';
import { AmenitiesFilterComponent } from './filter.component';
import { TextEditorView } from '../../../view/text-editor.view';

export const MainAmenitiesTableComponent = () => {
    const { loading, amenitiesList, fetchAmenitiesList, amenitiesSearchReq, updateLoading } = useAmenities()
    const [addNewAmenities, setOpenNewAmenities] = useState(false)
    const [updateAmenities, setUpdateAmenities] = useState<AmenitiesRes | undefined>(undefined)

    const cancelAmenitiesEdit = () => {
        setUpdateAmenities(prevVal => undefined);
    }
    const updateOpenNewAmenities = () => {
        if (addNewAmenities) {
            fetchAmenitiesList({ ...amenitiesSearchReq, pageNumber: 1 }, () => { updateLoading(false) }, () => { updateLoading(false) })
        }
        setOpenNewAmenities(prevVal => !prevVal);
    }

    const columnList: ColumnsType<AmenitiesRes> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Group',
            dataIndex: 'group',
            key: 'group',
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            onCell: (amenities, rowIndex) => {
                return {
                    onClick: (ev) => {
                        setUpdateAmenities(amenities)
                    },
                };
            },
            render: (id) => <a>Edit</a>,
        },
    ];

    useEffect(() => {
        fetchAmenitiesList({ ...amenitiesSearchReq, pageNumber: 1 }, () => { updateLoading(false) }, () => { updateLoading(false) })
    }, [])

    return <div style={{ marginTop: -42 }}>
        <Space direction="horizontal" style={{ width: '100%', justifyContent: 'end', marginBottom: 8 }}>
            <AmenitiesFilterComponent />
            <Button type="primary" onClick={updateOpenNewAmenities}>Add New</Button>
        </Space>
        <Table
            className='task-main-table task-table'
            columns={columnList} dataSource={amenitiesList}
            pagination={{ defaultPageSize: 30, showSizeChanger: true, pageSizeOptions: ['20', '50', '100', '200', '300', '400', '500'] }}
            loading={{ indicator: <div><Spin /></div>, spinning: loading }} />
        {addNewAmenities &&
            <AddAmenitiesComponent open={addNewAmenities} onCancel={updateOpenNewAmenities} />
        }

        {updateAmenities &&
            <UpdateAmenitiesComponent amenities={updateAmenities} open={true} onCancel={cancelAmenitiesEdit} />
        }
    </div>
}