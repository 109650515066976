import React, { useEffect } from 'react';
import { Modal, Form, Button, theme, Space } from 'antd';
import { LeadRequest } from '../../../../request/lead.request';
import { useLead } from '../../lead.hook';
import { LeadRes } from '../../../../response/lead.response';
import ApplicantComponent from './component/applicant.component';
import ImgIcon from '../../../../view/img-icon';
import PropertyDetailComponent from './component/property-detail.component';
import PriceDetailComponent from './component/price-detail.component';
import { ProjectRes } from '../../../../response/project.response';
import { MiniProject } from '../../../../model/mini-project';
import OtherDetailComponent from './component/other-detail.component';
import { LargeStatus } from '../../../../model/large-status.model';

export const CloseLeadComponent: React.FC<{ lead: LeadRes, open: boolean, onCancel: () => void, onLeadSuccess: () => void }> = ({ lead, open, onCancel, onLeadSuccess }) => {
    const { useToken } = theme;
    const { token } = useToken();

    const [form] = Form.useForm();
    const { loading, updateLoading, projectList, updateLead, fetchEmployeeList, fetchProjectList } = useLead()

    const handleOk = () => {
        form.validateFields().then((values) => {
            updateLoading(true);
            const leadRequest = {
                leadBookingDetail: {
                    bookingDate: values["bookingDate"],
                    project: (() => {
                        const project = projectList.find((p: ProjectRes) => p.id == values["projectId"])
                        return {
                            id: project.id,
                            name: project.name
                        } as MiniProject
                    })(),
                    paymentPlan: values["paymentPlan"],
                    remark: values["remark"],
                    projectDetail: {
                        tower: values["tower"],
                        floor: values["floor"],
                        unitNumber: values["unitNumber"],
                        unitType: values["unitType"],
                        area: values["area"],
                        uom: values["uom"],
                    },
                    priceDetail: {
                        perSqRate: Number(values["perSqRate"]),
                        baseSelling: Number(values["baseSelling"]),
                        plc: Number(values["plc"]),
                        floorPlc: Number(values["floorPlc"]),
                        idc: Number(values["idc"]),
                        edc: Number(values["edc"]),
                        cpc: Number(values["cpc"]),
                        cmrc: Number(values["cmrc"]),
                        oc: Number(values["oc"]),
                        total: Number(values["total"]),
                        bookingAmount: Number(values["bookingAmount"]),
                        apvPerSqft: Number(values["apvPerSqft"]),
                        apv: Number(values["apv"]),
                        netPropertyValue: Number(values["netPropertyValue"]),
                    },
                    applicantList: values["applicantList"] ? values["applicantList"].map((applicant: any, index: number) => {
                        return {
                            ...applicant,
                            primary: index == 0,
                            address: {
                                city: applicant.city,
                                lineOne:applicant.lineOne,
                                lineTwo:applicant.lineTwo,
                                pinCode:applicant.pinCode,
                                state:applicant.state,
                                street:applicant.street
                            }
                        }
                    }) : [],
                    createdOn: new Date(),
                    modifiedOn: new Date(),
                },
                status: {
                    value: "Closed",
                    note: "Lead Booked",
                    dateTime: new Date()
                } as LargeStatus,
            } as LeadRequest
            setTimeout(() => {
                onSuccess()
            }, 2000)
            console.log(leadRequest)
            updateLead(lead.id, leadRequest, onSuccess, onFailure)
        });
    }

    const onSuccess = async () => {
        onLeadSuccess()
        form.resetFields()
        onCancel();
    }

    const onFailure = () => {
        updateLoading(false);
    }

    useEffect(() => {
        fetchEmployeeList()
        fetchProjectList()
    }, [])


    return (
        <Modal
            open={open}
            title="Close Lead"
            onCancel={onCancel}
            confirmLoading={loading}
            onOk={handleOk}
            okText={"Submit"}
            cancelText={"Cancel"}
            width={"100%"}
            style={{ top: 10 }}
            className='h-100 bg-white new-lead-form-modal'
        >
            <Form form={form} layout="vertical">
                {/* Other Details start */}
                <>

                </>
                {/* Other Details End */}
                <OtherDetailComponent projectList={projectList} />
                {/* Applicant Details Start */}
                <Form.List name="applicantList"
                    initialValue={[{}]}>
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map((field) => (
                                <Space key={field.key} align="baseline">
                                    <ApplicantComponent field={field} remove={remove} />
                                </Space>
                            ))}

                            <Form.Item>
                                <Button
                                    onClick={() => add()} type="link" style={{
                                        marginLeft: '10px',
                                        marginBottom: '20px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: 0
                                    }}>
                                    <ImgIcon iconName="add-plus.svg" />
                                    Add Co-applicant
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
                {/* Applicant Details End */}

                {/* Property Details Start */}
                <PropertyDetailComponent />
                {/* Property Details End */}

                <PriceDetailComponent />

            </Form>
        </Modal>
    );
}