import { useContext } from "react"
import { MainContext } from "./main.context";


export const useMain = () => {
    const  context = useContext(MainContext)
    if (context === undefined){
        throw new Error(`Component is not inside UserContext scope`);
    }
    return context
}