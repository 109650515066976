import { Button, Card, Col, Form, Input, Row, theme, Typography } from "antd";
import Meta from "antd/es/card/Meta";
import React from "react";
import { useState } from "react";
import ImgIcon from "../../../../../view/img-icon";
import { EnquiryRes } from "../../../../../response/enquiry.response";
import { EnquiryRequest } from "../../../../../request/enquiry.request";
import { EnquiryService } from "../../../../../service/enquiry.service";
import { useEnquiry } from "../../../enquiry.hook";

export interface LocationPreferenceProp {
    enquiry: EnquiryRes
}

export const LocationPreference = (props: LocationPreferenceProp) => {

    const { enquiry } = props

    const [form] = Form.useForm();

    const { useToken } = theme;
    const { token } = useToken();

    const [isLocationEdit, setIsLocationEdit] = useState(false);

    const handleLocationEdit = () => {
        setIsLocationEdit(true)
    }

    const { fetchEnquiry } = useEnquiry()

    const saveLocaltionPreference = () => {
        form.validateFields().then(async values => {
            const enquiryRequest = {
                state: values["state"],
                city: values["city"],
                locality: values["locality"]
            } as EnquiryRequest
            const [data, err] = await EnquiryService.updateEnquiry(enquiry.id, enquiryRequest)
            if (err) {

            } else {
                fetchEnquiry(enquiry.id, () => { setIsLocationEdit(false) }, () => { setIsLocationEdit(false) })
            }
        });
    }

    return (
        <React.Fragment>
            <Col lg={12} xs={24}>
                <Card title="Location Prefrences" bordered={false}
                    extra={(!isLocationEdit && enquiry.status.value == 'Pending' || enquiry.status.value == 'In Progress') &&
                        <Button style={{
                            display: 'flex',
                            alignItems: 'center',
                            border: '0',
                            background: '#E0EEF7',
                            color: '#000000'
                        }}
                            onClick={handleLocationEdit}
                        >
                            <ImgIcon iconName="edit.svg" />
                            Edit
                        </Button>
                    }
                    style={{
                        padding: '0 20px',
                    }}
                    headStyle={{
                        padding: 0
                    }}
                    bodyStyle={{
                        padding: '15px 0'
                    }}
                    className="enquiry-professional-details wrapper-class"
                >

                    {isLocationEdit &&
                        <Form form={form}>
                            <Row style={{
                                marginBottom: '20px'
                            }}>
                                <Col md={12} xs={24}>
                                    <Typography.Title level={5} style={{
                                        fontSize: '14px'
                                    }}>State</Typography.Title>
                                    <Form.Item
                                        name="state"
                                        style={{
                                            margin: 0
                                        }}
                                        rules={[{ required: true, message: 'Please input your username!' }]}
                                        initialValue={enquiry.state}
                                    >
                                        <Input
                                            type="text"
                                            placeholder="Type a place"
                                            style={{
                                                width: '94%',
                                                border: '1px solid #92B5D7',
                                                borderRadius: token.borderRadius,
                                                boxShadow: 'none'
                                            }}
                                        />
                                    </Form.Item>

                                </Col>
                                <Col md={12} xs={24}>
                                    <Typography.Title level={5} style={{
                                        fontSize: '14px'
                                    }}>City</Typography.Title>
                                    <Form.Item
                                        name="city"
                                        style={{
                                            margin: 0
                                        }}
                                        rules={[{ required: true, message: 'Please input your username!' }]}
                                        initialValue={enquiry.city}
                                    >
                                        <Input
                                            type="text"
                                            placeholder="Type a City"
                                            style={{
                                                width: '98%',
                                                border: '1px solid #92B5D7',
                                                borderRadius: token.borderRadius,
                                                boxShadow: 'none'
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row style={{
                                marginBottom: '20px'
                            }}>
                                <Col md={12} xs={24}>
                                    <Typography.Title level={5} style={{
                                        fontSize: '14px'
                                    }}>Location</Typography.Title>

                                    <Form.Item
                                        name="location"
                                        style={{
                                            margin: 0
                                        }}
                                        rules={[{ required: false, message: 'Please input your username!' }]}
                                    >

                                        <Button type="link" block style={{
                                            padding: 0,
                                            display: 'flex'
                                        }}>
                                            <ImgIcon iconName="map.svg" />
                                            Search on Maps
                                        </Button>
                                    </Form.Item>
                                </Col>
                                <Col md={12} xs={24}>
                                    <Typography.Title level={5} style={{
                                        fontSize: '14px'
                                    }}>Locality</Typography.Title>
                                    <Form.Item
                                        name="locality"
                                        style={{
                                            margin: 0
                                        }}
                                        rules={[{ required: true, message: 'Please input your username!' }]}
                                        initialValue={enquiry.locality}
                                    >

                                        <Input
                                            type="text"
                                            placeholder="Type a Locality"
                                            style={{
                                                width: '98%',
                                                border: '1px solid #92B5D7',
                                                borderRadius: token.borderRadius,
                                                boxShadow: 'none'
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>


                            <Row className="action-row" style={{
                                marginBottom: '20px',
                                justifyContent: 'end'
                            }}>
                                <Col style={{ marginRight: 16 }}>
                                    <Button style={{
                                        border: '0',
                                        background: '#E0EEF7',
                                        color: '#000000'
                                    }}
                                        onClick={() => setIsLocationEdit(false)}
                                    >
                                        Cancel
                                    </Button>
                                </Col>
                                <Col>
                                    <Button type="primary" block style={{
                                        background: token.colorInfo,
                                        boxShadow: 'none'
                                    }}
                                        onClick={saveLocaltionPreference}
                                    >
                                        Save Changes
                                    </Button>
                                </Col>

                            </Row>

                        </Form>
                    }

                    {!isLocationEdit &&
                        <React.Fragment>

                            <Row style={{
                                marginBottom: '20px'
                            }}>
                                <Col md={12} xs={24}>
                                    <Meta
                                        title={"State"}
                                        description={enquiry.state}
                                    />
                                </Col>
                                <Col md={12} xs={24}>
                                    <Meta
                                        title={"City"}
                                        description={enquiry.city}
                                        className="ml-responsive-8 mt-responsive-4"
                                    />
                                </Col>
                            </Row>

                            <Row style={{
                                marginBottom: '20px'
                            }}>
                                <Col md={12} xs={24}>
                                    <Meta
                                        title={"Location"}
                                        description={"Location"}
                                    />
                                </Col>
                                <Col md={12} xs={24}>
                                    <Meta
                                        title={"Locality"}
                                        description={enquiry.locality}
                                        className="ml-responsive-8 mt-responsive-4"
                                    />
                                </Col>
                            </Row>
                        </React.Fragment>
                    }
                </Card>
            </Col>
        </React.Fragment>
    )
}