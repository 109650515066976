import { Col, Form, Row, Select, Tag, theme } from "antd";
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import React from "react";
import ImgIcon from "../../../../view/img-icon";
import { handleFilter } from "../../../../view/input.helper";


const priorityTagsRender = (props: CustomTagProps) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const getColor = () => {
        let color = '#001529'
        if(value.toLowerCase() === 'low') {
            color = '#1AA30E'
        } 
        if(value.toLowerCase() === 'medium') {
            color = '#EC9007'
        } 
        if(value.toLowerCase() === 'high') {
            color = '#EC0707'
        } 

        return color
    }


    return (
        <Tag
            color={getColor()}
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            style={{ margin: 3, textTransform: 'capitalize' }}
        >
            {label}
        </Tag>
    );
};


const PriorityFilterComponents = (props: any) => {
    const { useToken } = theme;
    const { token } = useToken();

    return (
        <Row style={{ flexDirection: 'column', width: '100%' }}>
            <Col style={{ display: 'flex'}}>
                <ImgIcon iconName="priority.svg" iconWidth="18px" />
                Priority
            </Col>
            <Col style={{ marginTop: '10px' }}>
                <Form.Item
                    name="priorityList"
                    initialValue={props.taskSearchReq.priorityList}
                >

                    <Select
                        mode="multiple"
                        showArrow
                        tagRender={priorityTagsRender}
                        className="custom-select"
                        style={{ width: '100%', border: '1px solid #92b5d7', borderRadius: token.borderRadiusSM }}
                        placeholder="Priority"
                        options={props.priorityList}
                        filterOption={handleFilter}
                    />

                </Form.Item>
            </Col>
        </Row>


    )
}

export default PriorityFilterComponents