import { createContext, useReducer } from "react";
import { CountryState } from "./country.state";
import { CountryReducer } from "./country.reducer";
import { CountryAction } from "./country.action";
import { CountryService } from "../../service/country.service";
import { CountryRequest, CountrySearchReq } from "../../request/country.request";
import { UserService } from "../../service/user.service";

const initialState = {
    loading: false,
    countrySearchReq: {
        pageNumber: 1,
        itemsPerPage: 100
    } as CountrySearchReq,
    countryList: []
} as CountryState

export const CountryContext = createContext<any>(initialState);

export const CountryStateProvider = ({ children }: any) => {

    const [state, dispatch] = useReducer(CountryReducer, initialState)

    const updateLoading = (loading: Boolean) => {
        dispatch({
            type: CountryAction.UPDATE_LOADING,
            payload: {
                loading: loading
            }
        });
    };

    const fetchCountryList = async (countrySearchReq: CountrySearchReq, onSuccess: () => void, onFailure: () => void) => {
        updateLoading(true)
        const [countryList, error] = await CountryService.getCountryList(countrySearchReq)

        if (error) {
            onFailure()
        } else {
            dispatch({
                type: countrySearchReq.pageNumber == 1 ? CountryAction.UPDATE_COUNTRY_LIST : CountryAction.ADD_COUNTRY_LIST,
                payload: {
                    countryList: countryList
                }
            })
            if (countryList.length > 0) {
                dispatch({
                    type: CountryAction.UPDATE_COUNTRY_SEARCH_REQ,
                    payload: {
                        countrySearchReq: {
                            ...countrySearchReq,
                            pageNumber: countrySearchReq.pageNumber + 1
                        }
                    }
                })
            }
            onSuccess()
        }
    };

    const saveCountry = async (countryRequest: CountryRequest, onSuccess: () => void, onFailure: () => void) => {
        const [data, error] = await CountryService.saveCountry(countryRequest)

        if (error) {
            onFailure()
        } else {
            onSuccess()
        }
    }

    const updateCountry = async (id: string, countryRequest: CountryRequest, onSuccess: () => void, onFailure: () => void) => {
        const [data, error] = await CountryService.updateCountry(id, countryRequest)

        if (error) {
            onFailure()
        } else {
            onSuccess()
        }
    }

    const value = {
        countryList: state.countryList,
        loading: state.loading,
        countrySearchReq: state.countrySearchReq,
        updateLoading,
        fetchCountryList,
        saveCountry,
        updateCountry
    };

    return <CountryContext.Provider value={value}>{children}</CountryContext.Provider>
};