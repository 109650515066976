import React, { useEffect } from 'react';
import { Modal, Form, Input, Select, Slider, Tooltip, theme } from 'antd';
import { BudgetRange, EnquiryRequest } from '../../../request/enquiry.request';
import { useEnquiry } from '../enquiry.hook';
import { validateEmail, validateName, validatePhone } from '../../../validator/user-info.validator';
import { EmployeeRes } from '../../../response/employee.response';
import { ItemProp } from '../../../model/item-prop.model';
import { LargeStatus } from '../../../model/large-status.model';
import TextArea from 'antd/es/input/TextArea';
import { ProjectRes } from '../../../response/project.response';
import { SliderTooltipProps } from 'antd/es/slider';
import { EnquirySource } from '../../../enum/source.enum';
import { handleFilter } from '../../../view/input.helper';
import { formatName } from '../../../util/name-helper';

export const AddEnquiryComponent: React.FC<{ open: boolean, onCancel: () => void }> = ({ open, onCancel }) => {
    const { useToken } = theme;
    const { token } = useToken();
    const [form] = Form.useForm();
    const { loading, updateLoading, saveEnquiry, fetchEmployeeList, employeeList, projectList, statusList, fetchProjectList,
        unitTypeList, fetchEnquiryList, enquirySearchReq, sourceList } = useEnquiry()

    const handleOk = () => {
        form.validateFields().then(async values => {
            updateLoading(true);
            const enquiryRequest = {
                phone: values["phone"],
                name: values["name"],
                email: values["email"],
                status: {
                    value: values["status"],
                    note: "Enquiry created"
                } as LargeStatus,
                source: values["source"],
                budgetRange: {
                    start: values["budgetRange"][0],
                    end: values["budgetRange"][1]
                } as BudgetRange,
                locality: values["locality"],
                unitType: values["unitType"],
                bhkType: Number(values["bhkType"]),
                city: values["city"],
                state: values["state"],
                ownerId: values["ownerId"],

                requirement: values["requirement"],
                projectId: values["projectId"]
            } as EnquiryRequest
            saveEnquiry(enquiryRequest, onSuccess, onFailure)
            const today = new Date()
            const lastDay = new Date()
            lastDay.setDate(today.getDate() - 7)
            await fetchEnquiryList({
                ...enquirySearchReq,
                startDate: lastDay,
                endDate: today,
                pageNumber: 1
            }, () => { updateLoading(false) }, () => { updateLoading(false) })
        });
    };

    const onSuccess = () => {
        updateLoading(true)
        form.resetFields()
        onCancel();
    }

    const onFailure = () => {
        updateLoading(false);
    }

    useEffect(() => {
        fetchEmployeeList()
        fetchProjectList()
    }, [])

    const sliderTooltipProps = {
        formatter: (value: any) => {
            if (value < 1000) {
                return `${value}`
            }
            if (value < 100000) {
                return `${(value / 1000).toFixed(0)}K`
            }
            if (value < 10000000) {
                return `${(value / 100000).toFixed(0)}L`
            }
            return `${(value / 10000000).toFixed(0)}Cr`
        },
        open: true
    } as SliderTooltipProps

    return (
        <Modal
            open={open}
            title="Add New Enquiry"
            onCancel={onCancel}
            confirmLoading={loading}
            onOk={handleOk}
            okText={"Submit"}
            cancelText={"Cancel"}
            className='m-web-modal'
            style={{ top: 8, maxWidth: 768, color: '#000000' }}
            width={'90vw'}
            centered
            bodyStyle={{
                height: '580px',
                overflow: 'auto',
                padding: '15px 24px'
            }}
            okButtonProps={{
                style: {
                    background: token.colorInfo,
                    boxShadow: 'none',
                    borderRadius: token.borderRadiusSM,
                    padding: '2px 8px',
                    margin: '5px'
                }
            }}
            cancelButtonProps={{
                style: {
                    background: '#C6C6C6',
                    border: 'none'
                }
            }}
        >

            <Form form={form} layout="vertical" className='add-enquiry-modal form-styles'>

                <Form.Item
                    name="name"
                    label="Name"
                    rules={[{ required: true, validator: validateName }]}
                    style={{
                        color: '#000000',
                        width: 270
                    }}

                >
                    <Input style={{
                        border: '1px solid #92B5D7',
                        borderRadius: token.borderRadiusSM
                    }} />
                </Form.Item>
                <Form.Item
                    name="email"
                    label="Email"
                    rules={[{ required: true, validator: validateEmail }]}
                    style={{
                        color: '#000000',
                        width: 270
                    }}
                >
                    <Input style={{
                        border: '1px solid #92B5D7',
                        borderRadius: token.borderRadiusSM
                    }} />
                </Form.Item>

                <Form.Item
                    name="phone"
                    label="Phone"
                    rules={[{ required: true, validator: validatePhone }]}
                    style={{
                        color: '#000000',
                        width: 270
                    }}
                >
                    <div style={{
                        display: 'flex'
                    }}>
                        <Select
                            showSearch={true}
                            value={"+ 91"}
                            style={{
                                borderRadius: token.borderRadiusSM,
                                width: 80,
                                marginRight: 10
                            }}
                            className={true ? 'active-selector' : ''}>
                            <option>+ 11</option>
                            <option>+ 91</option>
                            <option>+ 05</option>
                            <option>+ 14</option>
                        </Select>



                        <Input style={{
                            border: '1px solid #92B5D7',
                            borderRadius: token.borderRadiusSM
                        }} />
                    </div>

                </Form.Item>

                <Form.Item
                    name="requirement"
                    label="Requirement"
                    rules={[{ required: true, message: 'Please enter your requirement' }]}
                >
                    <TextArea placeholder='Write something' style={{
                        border: '1px solid #92B5D7',
                        borderRadius: token.borderRadiusSM
                    }} />
                </Form.Item>

                <Form.Item
                    name="budgetRange"
                    label="Budget Range"
                    rules={[{ required: false, message: 'Please enter your budget range' }]}
                    initialValue={[20000000, 400000000]}
                >

                    <Slider range min={1000000} max={1000000000} tooltip={sliderTooltipProps}
                        style={{
                            width: '87%',
                            margin: '0 10px'
                        }}
                    />
                </Form.Item>

                <Form.Item
                    name="locality"
                    label="Locality"
                    rules={[{ required: false }]}
                >
                    <Input style={{
                        border: '1px solid #92B5D7',
                        borderRadius: token.borderRadiusSM
                    }} />
                </Form.Item>

                <Form.Item
                    name="unitType"
                    label="Unit Type"
                    rules={[{ required: false }]}
                >
                    <Select
                        value="unitType"
                        options={unitTypeList}
                        filterOption={handleFilter} />
                </Form.Item>

                <Form.Item
                    name="bhk"
                    label="BHK"
                    rules={[{ required: false }]}
                >
                    <Input style={{
                        border: '1px solid #92B5D7',
                        borderRadius: token.borderRadiusSM
                    }} type='number' />
                </Form.Item>

                <Form.Item
                    name="city"
                    label="City"
                    rules={[{ required: false }]}
                >
                    <Input style={{
                        border: '1px solid #92B5D7',
                        borderRadius: token.borderRadiusSM
                    }} />
                </Form.Item>

                <Form.Item
                    name="state"
                    label="State"
                    rules={[{ required: false }]}
                >
                    <Input style={{
                        border: '1px solid #92B5D7',
                        borderRadius: token.borderRadiusSM
                    }} />
                </Form.Item>

                <Form.Item
                    name="status"
                    label="Status"
                    rules={[{ required: true, message: 'status' }]}
                    initialValue={"Pending"}
                >
                    <Select
                        showSearch={true}
                        value='status'
                        options={statusList}
                        filterOption={handleFilter} />
                </Form.Item>

                <Form.Item
                    name="source"
                    label="Source"
                    rules={[{ required: true, message: 'source' }]}
                    initialValue={EnquirySource.Portal}
                >
                    <Select
                        showSearch={true}
                        value='source'
                        options={sourceList}
                        filterOption={handleFilter} />
                </Form.Item>

                <Form.Item
                    name="projectId"
                    label="Project"
                    rules={[{ required: true, message: 'Project Id' }]}
                >
                    <Select
                        showSearch={true}
                        value='projectId'
                        options={projectList.map((projectRes: ProjectRes) => {
                            return {
                                label: `${projectRes.name}`,
                                value: projectRes.id
                            } as ItemProp
                        })}
                        filterOption={handleFilter} />
                </Form.Item>

                <Form.Item
                    name="ownerId"
                    label="Owner"
                    rules={[{ required: true, message: 'Owner' }]}
                >
                    <Select
                        showSearch={true}
                        value='ownerId'
                        options={employeeList.map((employee: EmployeeRes) => {
                            return {
                                label: `${formatName(employee.userInfo.name)}`,
                                value: employee.id
                            } as ItemProp
                        })}
                        filterOption={handleFilter} />
                </Form.Item>
            </Form>
        </Modal>
    );
}