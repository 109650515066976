import { Space, Tabs, TabsProps } from "antd";
import { useState } from "react";
import AttendanceHistoryComponent from "../../../dashboard/component/genericComponents/attendance-history.component";
import EnquireCardComponent from "../../../dashboard/component/genericComponents/enquireDetails/enquire-card.component";
import LeadCardComponent from "../../../dashboard/component/genericComponents/leadDetails/lead-card.component";
import ProfileComponent from "../../../dashboard/component/genericComponents/profile.component";
import TaskCardComponent from "../../../dashboard/component/genericComponents/taskDetails/task-card.component";
import LeadCoversionComponent from "./detailsComponents/charts/leadCoversion/lead-coversion.component";
import PermissionComponent from "./detailsComponents/permission/permission.component";
import TimelineComponent, { WordCloudData } from "./detailsComponents/timeline.component";
import { LeadReportRes } from "../../../../response/lead.response";
import { EnquiryReportRes } from "../../../../response/enquiry.response";
import { TaskReportRes } from "../../../../response/task.response";
import { EmployeeRes } from "../../../../response/employee.response";
import { useMain } from "../../../main.hook";

export interface EmpDetailComponentProps {
    employeeRes?: EmployeeRes
    leadReportResList: LeadReportRes[],
    enquiryReportResList: EnquiryReportRes[],
    taskReportList: TaskReportRes[],
    employeeId: string,
    filterData: any
}

const EmpDetailMWebComponent = (props: EmpDetailComponentProps) => {

    const { leadReportResList, enquiryReportResList, taskReportList, employeeRes, employeeId, filterData } = props

    const { userConfig } = useMain()

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: `Profile`,
            children: (
                <>
                    <ProfileComponent employeeRes={employeeRes} leadCount={leadReportResList ? leadReportResList.length : 0} />
                </>
            )
        },
        {
            key: '4',
            label: `Attendance`,
            children: (
                <>
                    <AttendanceHistoryComponent />
                </>
            )
        },
        {
            key: '5',
            label: `Charts`,
            children: (
                <>
                    {/* <div style={{ marginBottom: 14 }}>
                        <LeadCoversionComponent />
                    </div> */}
                    <div style={{ marginBottom: 14 }}>
                        <LeadCardComponent leadReportResList={leadReportResList} filterData={filterData}/>
                    </div>
                    <div style={{ marginBottom: 14 }}>
                        <EnquireCardComponent enquiryReportResList={enquiryReportResList} filterData={filterData} />
                    </div>
                    <div style={{ marginBottom: 14 }}>
                        <TaskCardComponent taskReportList={taskReportList} filterData={filterData}/>
                    </div>

                </>
            )
        },
    ];

    if (userConfig.employeeRes.employeeRole == "Admin" ||
        userConfig.employeeRes.employeeRole == "SuperAdmin") {
        items.push({
            key: '2',
            label: `Timeline`,
            children: (
                <>
                    <TimelineComponent employeeId={employeeId}/>
                </>
            )
        })
    }

    if (userConfig.employeeRes.employeeRole == "Manager" ||
        userConfig.employeeRes.employeeRole == "Admin" ||
        userConfig.employeeRes.employeeRole == "SuperAdmin") {
        items.push({
            key: '3',
            label: `Permissions`,
            children: (
                <>
                    <PermissionComponent />
                </>
            )
        })
    }

    const [activeKey, setActiveKey] = useState(items[0].key);

    const onChange = (key: string) => {
        setActiveKey(key);
    };


    return (
        <Tabs
            onChange={onChange}
            activeKey={activeKey}
            type="card"
            items={items}
            className="emp-tab-view"
        />
    )
}

export default EmpDetailMWebComponent