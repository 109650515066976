import { createContext, useReducer } from "react";
import { LocationState } from "./location.state";
import { Location, LocationError } from "../model/location.model";
import { LocationReducer } from "./location.reducer";
import { LocationAction } from "./location.action";
import { LocationType } from "../enum/location-type.enum";

const initialState = {
    loading: true,
    permissionDenied: false,
    location: {} as Location,
    locationError: {} as LocationError
} as LocationState

export const LocationContext = createContext<any>(initialState);

export const LocationStateProvider = ({ children }: any) => {

    const [state, dispatch] = useReducer(LocationReducer, initialState)

    const initLocation = () => {
        const positionOptions = {
            enableHighAccuracy: false,
            maximumAge: 30 * 60 * 1000,
            timeout: 1 * 60 * 1000
        } as PositionOptions
        if (navigator.geolocation) {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => {
                    updateLocation({
                        type: LocationType.Point,
                        coordinates: [position.coords.longitude, position.coords.latitude]
                    } as Location)
                    updateLoading(false)
                }, (positionError: GeolocationPositionError) => {
                    console.log(positionError.message)
                    if (positionError.code != 1) {
                        window.location.reload()
                    }
                    updateLoading(false)
                    updatePermissionDenied(true, {
                        code: positionError.code,
                        messgae: positionError.message
                    })
                    updateLoading(false)
                }, positionOptions)
            }
        } else {
            alert("Browser Not supported")
        }
    }

    const updateLoading = (loading: Boolean) => {
        dispatch({
            type: LocationAction.UPDATE_LOADING,
            payload: {
                loading: loading
            }
        });
    };

    const updatePermissionDenied = (permissionDenied: Boolean, locationError: LocationError) => {
        dispatch({
            type: LocationAction.UPDATE_PERMISSION_DENIED,
            payload: {
                permissionDenied: permissionDenied,
                locationError: locationError
            }
        });
    };

    const updateLocation = (location: Location) => {
        dispatch({
            type: LocationAction.UPDATE_LOCATION,
            payload: {
                location: location
            }
        });
    };

    const value = {
        permissionDenied: state.permissionDenied,
        loading: state.loading,
        location: state.location,
        initLocation,
        updateLoading,
        updatePermissionDenied,
        updateLocation
    };

    return <LocationContext.Provider value={value}>{children}</LocationContext.Provider>
};