import { BuilderStateProvider } from './builder.context';
import { MainBuilderTableComponent } from './component/table.component';

const BuilderPageBase = () => {

  return <>
    <MainBuilderTableComponent />
  </>
}

export const BuilderPage = () => {
  return <BuilderStateProvider>
    <BuilderPageBase />
  </BuilderStateProvider>
}

