import { Permission } from "../model/permission.model"
import { UserRequest, UserSearchReq } from "../request/user.request"
import { UserConfig, UserRes } from "../response/user.response"
import httpClient from "../util/http-client"

export const UserService = {
    saveUser: async (userRequest: UserRequest): Promise<(UserRes | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/user", userRequest)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    getUserById: async (id: string): Promise<(UserRes | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.get(`/user/${id}`)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    getUserList: async (userSearchReq: UserSearchReq): Promise<(UserRes[] | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/user-search", userSearchReq)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    getPermissionList: async (): Promise<(Permission[] | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.get("/user-permission")
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    updateUser: async (id: string, userRequest: UserRequest): Promise<(UserRes | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.put(`/user/${id}`, userRequest)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    getUserConfig: async (): Promise<(UserConfig[] | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.get("/user-config")
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
}