import { useContext } from "react"
import { AmenitiesContext } from "./amenities.context";


export const useAmenities = () => {
    const  context = useContext(AmenitiesContext)
    if (context === undefined){
        throw new Error(`Component is not inside AmenitiesContext scope`);
    }
    return context
}