import { Avatar, Button, Popconfirm, Space, Spin, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { EmployeeRes } from '../../../response/employee.response';
import { useEmployee } from '../employee.hook';
import { AddEmployeeComponent } from './add-employee.component';
import { UpdateEmployeeComponent } from './update-employee.component';
import { EmployeeFilterComponent } from './filter.component';
import { formatName } from '../../../util/name-helper';
import { EmployeeService } from '../../../service/employee.service';
import { DownloadAttendanceReportComponent } from './download-attendance-report';
import { useMain } from '../../main.hook';
import { getColor } from '../../../enum/static-data.enum';


export const MainEmployeeTableComponent = () => {
    const { userConfig } = useMain()

    const { loading, employeeList, fetchEmployeeList, employeeSearchReq, updateLoading } = useEmployee()
    const [addNewEmployee, setOpenNewEmployee] = useState(false)
    const [updateEmployee, setUpdateEmployee] = useState<EmployeeRes | undefined>(undefined)

    const [downloadAttendanceReport, setDownloadAttendanceReport] = useState<boolean>(false)

    const updateOpenNewEmployee = () => {
        if (addNewEmployee) {
            if (userConfig.employeeRes.employeeRole == "Admin" ||
                userConfig.employeeRes.employeeRole == "SuperAdmin" ||
                userConfig.employeeRes.employeeRole == "Manager") {
                fetchEmployeeList({ ...employeeSearchReq, pageNumber: 1 }, () => { updateLoading(false) }, () => { updateLoading(false) })
            } else {
                fetchEmployeeList({ ...employeeSearchReq, officeIdList: [userConfig.employeeRes.officeId], pageNumber: 1 }, () => { updateLoading(false) }, () => { updateLoading(false) })
            }
        }
        setOpenNewEmployee(prevVal => !prevVal);
    }

    const cancelEmployeeEdit = () => {
        setUpdateEmployee(prevVal => undefined);
    }

    const cancelDownloadReport = () => {
        setDownloadAttendanceReport(false);
    }

    useEffect(() => {
        if (userConfig.employeeRes.employeeRole == "Admin" ||
            userConfig.employeeRes.employeeRole == "SuperAdmin" ||
            userConfig.employeeRes.employeeRole == "Manager") {
            fetchEmployeeList({ ...employeeSearchReq, pageNumber: 1 }, () => { updateLoading(false) }, () => { updateLoading(false) })
        } else {
            fetchEmployeeList({ ...employeeSearchReq, officeIdList: [userConfig.employeeRes.officeId], pageNumber: 1 }, () => { updateLoading(false) }, () => { updateLoading(false) })
        }
    }, [])

    const [deleteEmployeeId, setDeleteEmployeeId] = useState("");

    const showPopconfirm = (employeeRes: EmployeeRes) => {
        setDeleteEmployeeId(employeeRes.id);
    };

    const popconfirmCancel = () => {
        setDeleteEmployeeId("");
    };

    const removeEmployee = async (deleteEmployeeId: string) => {
        updateLoading(true)
        await EmployeeService.remove(deleteEmployeeId)
        setDeleteEmployeeId("");
        updateLoading(false)
        window.location.reload()
    }

    const columnList: ColumnsType<EmployeeRes> = [
        {
            title: 'Employee Id',
            dataIndex: 'employeeId',
            key: 'employeeId',
            render: (employeeId) => <span>{`RB${String(employeeId).padStart(3, '0')}`}</span>,
        },
        {
            title: 'Name',
            dataIndex: 'userInfo',
            key: 'name',
            render: (userInfo) => <>
                <>
                    {(userInfo.profilePic?.url?.main) ? <Avatar size={24} src={userInfo.profilePic?.url?.main} style={{ backgroundColor: getColor(userInfo.name) }} />
                        : <Avatar shape="circle" style={{ backgroundColor: getColor(userInfo.name[0]) }} size={24}>{userInfo.name[0]?.toUpperCase()}</Avatar>}

                </>
                <span>{formatName(userInfo.name)}</span>
            </>,
        },
        {
            title: 'Phone',
            dataIndex: 'userInfo',
            key: 'phone',
            render: (userInfo) => <span>+91 {userInfo.phone}</span>,
        },
        {
            title: 'Online',
            dataIndex: 'online',
            key: 'online',
            render: (online) => <>
                {online == 1 ?
                    <span style={{ color: 'green' }}>Online</span> :
                    <span style={{ color: 'red' }}>Offline</span>
                }
            </>,
        },
        {
            title: 'Email',
            dataIndex: 'userInfo',
            key: 'email',
            render: (userInfo) => <span>{userInfo.email}</span>,
        },
        {
            title: 'Permissions',
            key: 'permissionList',
            dataIndex: 'permissionList',
            render: (_, { permissionList }) => (
                <div style={{ maxWidth: 84, maxHeight: 84 }}>
                    <span>{permissionList.length} Permissions</span>
                    {/* {permissionList.map((permission: string, index: number) => {
                        const color = getColorBynumber(index)
                        return (
                            <Tag color={color} key={permission}>
                                {permission.toUpperCase()}
                            </Tag>
                        );
                    })} */}
                </div>
            ),
        },
    ];

    columnList.push({
        title: 'Action',
        dataIndex: 'paused',
        key: 'paused',
        onCell: (employee, rowIndex) => {
            return {
                onClick: (ev) => {
                    pauseEmployee(employee)
                },
            };
        },
        render: (paused) => <a>{paused == true ? "Resume" : "Pause"}</a>,
    })

    if (userConfig.employeeRes.permissionList.includes("employee-update")) {
        columnList.push({
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            onCell: (employee, rowIndex) => {
                return {
                    onClick: (ev) => {
                        setUpdateEmployee(employee)
                    },
                };
            },
            render: (id) => <a>Edit</a>,
        })
    }
    if (userConfig.employeeRes.permissionList.includes("employee-delete")) {
        columnList.push({
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            render: (id) => <Popconfirm
                title="Delete"
                description="Are you sure that want to delete?"
                open={deleteEmployeeId == id}
                onConfirm={() => {
                    removeEmployee(deleteEmployeeId)
                }}
                okButtonProps={{ loading: loading }}
                onCancel={() => {
                    popconfirmCancel()
                }}
            ><a style={{ color: 'red' }} onClick={() => { setDeleteEmployeeId(id) }}>Remove</a></Popconfirm>,
        })
    }

    const pauseEmployee = async (employeeRes: EmployeeRes) => {
        await EmployeeService.pause(employeeRes.id)
        window.location.reload()
    }

    return <div style={{ marginTop: -42 }}>
        <Space direction="horizontal" style={{ width: '100%', justifyContent: 'end', marginBottom: 8 }}>
            <EmployeeFilterComponent setDownloadAttendanceReport={setDownloadAttendanceReport} />
            <Button type="primary" onClick={updateOpenNewEmployee}>Add New</Button>
        </Space>

        <Table

            className='task-main-table task-table'
            columns={columnList} dataSource={employeeList}
            pagination={{ defaultPageSize: 30, showSizeChanger: true, pageSizeOptions: ['20', '50', '100', '200', '300', '400', '500'] }}
            loading={{ indicator: <div><Spin /></div>, spinning: loading }} />

        {addNewEmployee && <>
            <AddEmployeeComponent open={addNewEmployee} onCancel={updateOpenNewEmployee} />
        </>}

        {updateEmployee &&
            <UpdateEmployeeComponent employee={updateEmployee} open={true} onCancel={cancelEmployeeEdit} />
        }

        {downloadAttendanceReport &&
            <DownloadAttendanceReportComponent open={true} onCancel={cancelDownloadReport} />
        }

    </div>
}