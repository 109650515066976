import { MenuRequest, MenuSearchReq } from "../request/menu.request"
import { MenuRes } from "../response/menu.response"
import httpClient from "../util/http-client"

export const MenuService = {
    saveMenu: async (menuRequest: MenuRequest): Promise<(MenuRes|any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/menu", menuRequest)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    getMenuList: async (menuSearchReq: MenuSearchReq): Promise<(MenuRes[]|any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/menu-search", menuSearchReq)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    updateMenu: async (id: string, menuRequest: MenuRequest): Promise<(MenuRes|any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.put(`/menu/${id}`, menuRequest)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
}