import { useContext } from "react"
import { MenuContext } from "./menu.context";


export const useMenu = () => {
    const  context = useContext(MenuContext)
    if (context === undefined){
        throw new Error(`Component is not inside MenuContext scope`);
    }
    return context
}