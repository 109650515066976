import { useEffect, useState } from "react";
import { Avatar, Col, Row, Select, theme } from "antd"
import ImgIcon from "../../../../view/img-icon"
import { getColor } from "../../../../enum/static-data.enum";
import { TaskService } from "../../../../service/task.service";
import { TaskRequest } from "../../../../request/task.request";
import { useTask } from "../../task.hook";
import { ItemProp } from "../../../../model/item-prop.model";
import { EmployeeRes } from "../../../../response/employee.response";
import { handleFilter } from "../../../../view/input.helper";
import { formatName } from "../../../../util/name-helper";

const AssigneeComponent = (props: any) => {

    const { task } = props;
    const { useToken } = theme;
    const { token } = useToken();

    const { employeeList, fetchEmployeeList } = useTask()

    const [selectedValue, setSelectedValue] = useState(task.assignedTo.name)

    const handleChange = (value: string) => {
        setSelectedValue(value)
        updateAssignee(value)
    }

    const updateAssignee = async (assigneeId: string) => {
        const [data, err] = await TaskService.updateTask(task.id, {
            assigneeId: assigneeId
        } as TaskRequest)
    }

    useEffect(() => {
        fetchEmployeeList()
    }, [])

    return (
        <Row style={{ marginBottom: '20px' }}>
            <Col style={{ display: 'flex', alignItems: "center" }}>
                <ImgIcon iconName="assigned.svg" iconWidth="18px" />
                Assigned To
            </Col>
            <Col style={{ marginLeft: 'auto', }}>
                <div style={{ display: 'flex', width: '175px' }} >
                    {/* <span style={{ textTransform: 'capitalize', display: 'flex', marginRight: '10px' }}>
                        <Avatar style={{ backgroundColor: getColor(task.assignedTo.name[0]) }}>{task.assignedTo.name[0].toUpperCase()}</Avatar>
                    </span> */}
                    <div style={{ border: '1px solid #92B5D7', borderRadius: token.borderRadiusLG, padding: '2px 0', width: '100%' }}>
                        <Select
                            showSearch={true}
                            value={selectedValue}
                            style={{ textTransform: 'capitalize', width: '100%', textOverflow: 'ellipsis' }}
                            className="custom-select task-details-select"
                            onChange={handleChange}
                            options={employeeList.map((employee: EmployeeRes) => {
                                return {
                                    label: `${formatName(employee.userInfo.name)}`,
                                    value: employee.id
                                } as ItemProp
                            })}
                            filterOption={handleFilter}
                        />
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default AssigneeComponent