import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Card, Col, Row, Space, Spin, Tag, theme, Typography } from 'antd';
import { useTask } from '../../task.hook';
import { TextEditorView } from '../../../../view/text-editor.view';
import { DiscussionComponent } from '../discussion.component';
import { HistoryComponent } from '../history.component';
import { useNavigate, useParams } from 'react-router-dom';
import StatusListComponent from './status-list.component';
import AssigneeComponent from './assignee.component';
import PriorityComponent from './priority.component';
import CreatedonComponent from './createdon.component';
import DueCreatedDateComponent from './due-created-date.component';
import dayjs from 'dayjs';
import { TaskService } from '../../../../service/task.service';
import { TaskRequest } from '../../../../request/task.request';
import { DoubleRightOutlined, SaveOutlined } from '@ant-design/icons';
import { pushEvent } from '../../../../event/analytics.helper';
import { EventName } from '../../../../event/event-name.enum';
import { SecurityManager } from '../../../../security/security-manager';
import { useMapLocation } from '../../../../location/location.hook';

const tabList = [
    {
        key: 'comment',
        tab: 'Comment',
    },
    {
        key: 'history',
        tab: 'History',
    }
];

export const TaskDetailComponent: React.FC<{}> = () => {
    let { id } = useParams()
    const { useToken } = theme;
    const { token } = useToken();
    const navigate = useNavigate()
    const { location } = useMapLocation()

    const [isCustomDrawerActive, setIsCustomDrawerActive] = useState<boolean>(false)

    const { loading, task, fetchTask, statusList, priorityList, updateLoading } = useTask()
    const [activeTabKey, setActiveTabKey] = useState<string>('comment');

    const [newTitle, setNewTitle] = useState<string>(task.title)
    const [newDescription, setNewDescription] = useState<string>(task.description)

    const editableTitleStyle = {
        // Add your CSS styles here
        boxShadow: 'none'
    };

    const onTabChange = (key: string) => {
        setActiveTabKey(key);
    };

    const getView = (activeTabKey: string): React.ReactNode => {
        switch (activeTabKey) {
            case "comment":
                return <DiscussionComponent taskId={task.id} />
            case "history":
                return <HistoryComponent taskId={task.id} />
            default:
                return <></>
        }
    }

    useEffect(() => {
        updateLoading(true);
        fetchTask(id, onSuccess, onFailure)
    }, [])

    const onSuccess = () => {
        updateLoading(false)
    }

    const onFailure = () => {
        updateLoading(false)
    }

    const handleTitleOnChange = (e: any) => {
        setNewTitle(e.target.value)
    }

    const updateTitle = async () => {
        if (newTitle.length < 10) {
            return
        }
        updateLoading(true)
        const [data, err] = await TaskService.updateTask(task.id, {
            title: newTitle
        } as TaskRequest)
        if (err) {
            updateLoading(false)
        } else {
            window.location.reload()
            updateLoading(false)
        }
    }

    const updateDescription = async () => {
        if (newDescription.length < 10) {
            return
        }
        updateLoading(true)
        const [data, err] = await TaskService.updateTask(task.id, {
            description: newDescription
        } as TaskRequest)
        if (err) {
            updateLoading(false)
        } else {
            window.location.reload()
            updateLoading(false)
        }
    }

    const handleCustomDrawer = () => {
        setIsCustomDrawerActive(!isCustomDrawerActive)
    }

    useEffect(() => {
        pushEvent(EventName.TASK_DETAIL_PAGE_VIEW, {
            employeeId: SecurityManager.getEid(),
            taskId: id,
            location: location,
            createdOn: new Date()
        })
    }, [])

    return (
        <>
            {(loading || !task.id) ? <div style={{ margin: 'auto', display: 'table', marginTop: 48 }}>
                <Spin />
            </div>
                :
                <div>
                    <Row style={{ alignItems: 'baseline', marginTop: -64 }}>


                        <Breadcrumb
                            items={[
                                {
                                    onClick: () => {
                                        navigate(`/task`)
                                    },
                                    title: (
                                        <a>Tasks</a>
                                    ),
                                },
                                {
                                    title: (
                                        <span>{`Task${String(task.taskId).padStart(3, '0')}`}</span>
                                    ),
                                },
                            ]}
                            style={{
                                marginBottom: '30px'
                            }}
                            className="breadcrum--custom-css"
                        />

                        <Button style={{ marginLeft: 'auto', borderRadius: '5px 0 0 5px', border: 'none', color: token.colorPrimary }} className="custom-drawer-btn" onClick={handleCustomDrawer}><DoubleRightOutlined /></Button>
                    </Row>
                    <Row gutter={16} className='task-detail-wrapper'>


                        <Row hidden style={{ width: '100%', margin: 8, marginTop: 8, display: 'flex', alignItems: 'center' }}>
                            <Col span={12}>
                                <Space className='gap-0' align='start' style={{ marginLeft: 25 }}>
                                    <img src="/images/calendar-full.svg" alt='' />
                                    <div className='ml-2'>
                                        <Typography.Title level={5} className='m-0'>Task Details</Typography.Title>
                                        <Typography.Text>Created On {`${dayjs(task.createdOn).format('DD MMMM YYYY, dddd  hh:mm A')}`}</Typography.Text>
                                    </div>
                                </Space>
                            </Col>
                        </Row>

                        <Col lg={17} md={24}>
                            <div className='t-detail-wrapper'>
                                <div style={{
                                    marginBottom: '5px'
                                }}>
                                    <Typography.Title
                                        editable={{ onEnd: updateTitle }}
                                        level={4}
                                        onChange={handleTitleOnChange}
                                        style={{
                                            width: 'fit-content',
                                            marginBottom: 5
                                        }}
                                        className='editable-wrapper'
                                    >
                                        {task.title}
                                    </Typography.Title>
                                </div>
                                <div style={{
                                    margin: '0 0 30px',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <Typography.Title
                                        level={5}
                                        style={{
                                            color: '#6D6C68',
                                            fontSize: token.fontSizeSM,
                                            margin: '0 10px 0 0'
                                        }}
                                    >{`Assigned on: ${dayjs(task.createdOn).format("DD-MM-YYYY")}`}</Typography.Title>

                                    {task.tagList && task.tagList.map((tag: string) => {
                                        let color = Math.random() > 0.5 ? 'geekblue' : 'green';
                                        if (tag.toLocaleLowerCase() === 'hot') {
                                            color = 'green';
                                        }
                                        if (tag.toLocaleLowerCase() === 'cold') {
                                            color = 'blue';
                                        }
                                        return (
                                            <Tag color={color} key={tag}>
                                                {tag.toUpperCase()}
                                            </Tag>
                                        );
                                    })}

                                </div>
                                <div style={{
                                    display: 'flex',
                                    marginBottom: '10px',
                                    alignItems: 'center'
                                }}>
                                    <Typography.Title
                                        level={5}
                                        style={{
                                            margin: '0',
                                            fontSize: token.fontSizeLG,
                                            color: '#000000'
                                        }}
                                    >Description:</Typography.Title>
                                    {(task.description !== newDescription) && <Button type='default' style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        border: '0',
                                        margin: '0 10px',
                                        padding: '0 10px'
                                    }}
                                        onClick={() => updateDescription()}
                                    >

                                        <SaveOutlined /> Save
                                    </Button>
                                    }
                                </div>


                                <div className="description-wrapper"
                                    style={{
                                        marginBottom: '30px'
                                    }}
                                >
                                    <TextEditorView initData={task.description}
                                        onChange={(data: string) => { setNewDescription(data) }}
                                        readOnly={false}
                                        classes={'editor-custom-view'}
                                    />
                                </div>

                                <div style={{
                                    marginBottom: '20px'
                                }}
                                >
                                    <Typography.Title
                                        level={5}
                                        style={{
                                            margin: '0',
                                            fontSize: token.fontSizeLG,
                                            color: '#000000'
                                        }}
                                    >Activity:</Typography.Title>

                                    <Card
                                        style={{
                                            width: '100%',
                                            padding: 0,
                                            background: 'transparent',
                                            boxShadow: 'none'
                                        }}
                                        className='task-detail-card custom-tab-style'
                                        tabList={tabList}
                                        tabProps={{
                                            animated: false,
                                            tabBarStyle: {

                                            },
                                        }}
                                        activeTabKey={activeTabKey}
                                        onTabChange={onTabChange}
                                        bordered={false}
                                        headStyle={{
                                            padding: 0
                                        }}
                                        bodyStyle={{
                                            padding: 0,
                                            background: 'transparent'
                                        }}

                                    >
                                        {getView(activeTabKey)}
                                    </Card>
                                </div>
                            </div>
                        </Col>
                        <Col span={7} style={{ paddingLeft: 16 }}>
                            <Card bordered={false} className={`custom-drawer-card ${isCustomDrawerActive ? 'open' : ''} `}>
                                <Typography.Title level={5}>Details: </Typography.Title>
                                <StatusListComponent task={task} statusList={statusList} />

                                <AssigneeComponent task={task} />
                                <PriorityComponent task={task} priorityList={priorityList} />
                                <DueCreatedDateComponent task={task} />
                                <CreatedonComponent task={task} />
                            </Card>

                        </Col>




                        <div className={`custom-drawer--mask ${isCustomDrawerActive ? 'active' : ''}`}></div>
                    </Row>
                </div>
            }
        </>
    );
}
