import { Breadcrumb, Spin, Tabs, Button, FloatButton, Typography } from "antd"
import { useNavigate, useParams } from "react-router-dom"
import { useEnquiry } from "../../enquiry.hook"
import { useEffect, useState } from "react"
import { RequirementDetailComponent } from "./requirement-detail.component"
import { FollowUpComponent } from "./follow-up.component"
import { EnquirySearchReq } from "../../../../request/enquiry.request"
import { EnquiryService } from "../../../../service/enquiry.service"
import { UserDetailComponent } from "./user-detail.ccomponent"
import "../enquiry.css"
import DetailPageHeader from "../../../common/detail-page-header.component"
import ImgIcon from "../../../../view/img-icon"
import { AddEnquiryLeadComponent } from "../add-lead.component"
import { pushEvent } from "../../../../event/analytics.helper"
import { EventName } from "../../../../event/event-name.enum"
import { SecurityManager } from "../../../../security/security-manager"
import { CustomerServiceOutlined, PhoneOutlined, WhatsAppOutlined } from "@ant-design/icons"
import { EnquiryNoteService } from "../../../../service/enquiry-note.service"
import { EnquiryNoteCallReq } from "../../../../request/enquiry-note.request"
import { useNotification } from "../../../../util/notification.hook"
import { useMapLocation } from "../../../../location/location.hook"
import { WhatsAppChatPage } from "../../../whatsapp-chat/whatsapp-chat.page"


export const EnquiryDetailV2Component = () => {
    const navigate = useNavigate()
    const { location } = useMapLocation()

    const { openNotification } = useNotification()

    let { id } = useParams()
    const [clientLoading, setClientLoading] = useState(false)
    const [oldEnquiryList, setOldEnquiryList] = useState([])
    const { loading, enquiry, fetchEnquiry, updateLoading, client, fetchClient } = useEnquiry()

    const [createLead, setCreateLead] = useState(false)

    const [chatWindow, setChatWindow] = useState(false)

    useEffect(() => {
        updateLoading(true);
        fetchEnquiry(id, onSuccess, onFailure)
    }, [])

    useEffect(() => {
        if (enquiry.client && enquiry.client.id) {
            setClientLoading(true)
            fetchClient(enquiry.client.id, () => { setClientLoading(false) }, () => { setClientLoading(false) })
            fetchOldEnquiry(enquiry.client.id)
        }
    }, [enquiry])

    const onSuccess = () => {
        updateLoading(false)
    }
    const onFailure = () => {
        updateLoading(false)
    }
    const fetchOldEnquiry = async (clientId: string) => {
        const [enquiryList, error] = await EnquiryService.getEnquiryList({
            clientIdList: [clientId],
            pageNumber: 1,
            itemsPerPage: 50
        } as EnquirySearchReq)
        if (error) {
        } else {
            setOldEnquiryList(enquiryList)
        }
    }

    const onLeadSuccess = async () => {
        await fetchEnquiry(enquiry.id, () => { updateLoading(false) }, () => { updateLoading(false) })
    }

    useEffect(() => {
        pushEvent(EventName.ENQUIRY_DETAIL_PAGE_VIEW, {
            employeeId: SecurityManager.getEid(),
            enquiryId: id,
            location: location,
            createdOn: new Date()
        })
    }, [])

    const callClient = async () => {
        const [data, err] = await EnquiryNoteService.connectCall({
            id: enquiry.currentNote.id,
            enquiryId: enquiry.id
        } as EnquiryNoteCallReq)

        if (err) {
            openNotification('error', err.response.data.message, "")
        } else {
            openNotification('success', "Please pick call in your phone", "")
        }
    }

    return <>
        {loading ? <Spin style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '32vh', display: 'block' }} /> :
            <>

                <DetailPageHeader headerTitle="Enquiry Details" dateObj={enquiry} isCreatedOn={true} />

                <Breadcrumb
                    items={[
                        {
                            onClick: () => {
                                navigate(`/enquiry`)
                            },
                            title: (
                                <a>Enquirys</a>
                            ),
                        },
                        {
                            title: (
                                <span>{enquiry.id}</span>
                            ),
                        },
                    ]}
                    style={{
                        marginBottom: 20
                    }}
                    className="breadcrum--custom-css"
                />
                <>
                    <Typography.Title level={5} className='mt-4' style={{
                        color: '#000000'
                    }}>Customer details</Typography.Title>
                    {!loading && <UserDetailComponent client={client} />}
                    <Typography.Title level={5} className='mt-4' style={{
                        color: '#000000'
                    }}>Follow Up</Typography.Title>
                    {enquiry.status && !clientLoading && <FollowUpComponent enquiry={enquiry} />}
                    <Typography.Title level={5} className='mt-4' style={{
                        color: '#000000'
                    }}>Requirement Details</Typography.Title>
                    {enquiry.status && !clientLoading && <RequirementDetailComponent oldEnquiryList={oldEnquiryList} enquiry={enquiry} />}
                </>

                <FloatButton
                    onClick={() => { setChatWindow(true) }}
                    icon={<WhatsAppOutlined />}
                    type="primary" style={{ right: 74 }} />

                <FloatButton
                    onClick={callClient}
                    icon={<PhoneOutlined />}
                    type="primary" style={{ right: 24 }} />

                {createLead &&
                    <AddEnquiryLeadComponent enquiry={enquiry} open={true} onCancel={() => { setCreateLead(false) }} onLeadSuccess={onLeadSuccess} />
                }

                {chatWindow && <div style={{
                    position: 'fixed',
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    zIndex: 99999,
                    width: '100%',
                    height: '100vh',
                    background: "rgb(221 208 208 / 73%)"
                }}>
                    <WhatsAppChatPage userId="" closeChatWindow={() => { setChatWindow(false) }} />
                </div>}
            </>
        }
    </>
}