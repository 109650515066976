import React from 'react';
import { Form, Input, Button } from 'antd';
import { LocalitySearchReq } from '../../../request/locality.request';
import { useLocality } from '../locality.hook';

export const LocalityFilterComponent: React.FC = () => {
    const [form] = Form.useForm();
    const { fetchLocalityList, updateLoading, localitySearchReq  } = useLocality()

    const handleOk = () => {
        form.validateFields().then(values => {
            updateLoading(true);
            const _localitySearchReq = {
                ...localitySearchReq,
                name: values["name"],
                pageNumber: 1,
                itemsPerPage: 10
            } as LocalitySearchReq
            fetchLocalityList(_localitySearchReq, onSuccess, onFailure)
        });
    };

    const onSuccess = () => {
        updateLoading(false);
    }

    const onFailure = () => {
        updateLoading(false);
    }

    return (
        <Form form={form} layout="inline">
            <Form.Item
                name="name"
                style={{ minWidth: 124 }}
                initialValue={localitySearchReq.name}
            >
                <Input placeholder='Name' />
            </Form.Item>

            <Form.Item style={{ minWidth: 124 }}>
                <Button type="primary" htmlType="submit"
                    onClick={handleOk}>
                    Search
                </Button>
            </Form.Item>
        </Form>
    );
}
