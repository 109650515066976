import { LocalityRequest, LocalitySearchReq } from "../request/locality.request"
import { LocalityRes } from "../response/locality.response"
import httpClient from "../util/http-client"

export const LocalityService = {
    saveLocality: async (localityRequest: LocalityRequest): Promise<(LocalityRes|any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/locality", localityRequest)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    getLocalityList: async (localitySearchReq: LocalitySearchReq): Promise<(LocalityRes[]|any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/locality-search", localitySearchReq)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    updateLocality: async (id: string, localityRequest: LocalityRequest): Promise<(LocalityRes|any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.put(`/locality/${id}`, localityRequest)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
}