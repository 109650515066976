import { useContext } from "react"
import { CityContext } from "./city.context";


export const useCity = () => {
    const  context = useContext(CityContext)
    if (context === undefined){
        throw new Error(`Component is not inside CityContext scope`);
    }
    return context
}