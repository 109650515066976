import { AmenitiesRequest, AmenitiesSearchReq } from "../request/amenities.request"
import { AmenitiesRes } from "../response/amenities.response"
import httpClient from "../util/http-client"

export const AmenitiesService = {
    saveAmenities: async (amenitiesRequest: AmenitiesRequest): Promise<(AmenitiesRes|any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/amenities", amenitiesRequest)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    getAmenitiesList: async (amenitiesSearchReq: AmenitiesSearchReq): Promise<(AmenitiesRes[]|any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/amenities-search", amenitiesSearchReq)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    updateAmenities: async (id: string, amenitiesRequest: AmenitiesRequest): Promise<(AmenitiesRes|any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.put(`/amenities/${id}`, amenitiesRequest)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
}