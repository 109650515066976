import { Col, DatePicker, Form, Input, Radio, Row, Select, theme, Typography } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { isMobile } from '../../../../../util/device-helper';
import { MinusCircleOutlined } from '@ant-design/icons';

const ApplicantComponent = (props: any) => {

    const { field, remove } = props

    const { useToken } = theme;
    const { token } = useToken();

    return (
        <>
            <Row style={{ borderBottom: "2px solid #D9D9D9", paddingBottom: '8px', marginBottom: '12px', alignItems: "center" }}>
                <h3 >Applicant</h3>
                <MinusCircleOutlined style={{ padding: 8 }} onClick={() => remove(field.name)} />
            </Row>
            <Row style={{ marginBottom: '10px' }}>
                <Col lg={6} md={12} xs={24} style={{ paddingRight: '20px', marginBottom: '20px' }}>
                    <Typography.Title level={5} style={{
                        fontSize: '14px',
                        fontWeight: 500
                    }}>Customer Name</Typography.Title>
                    <Form.Item
                        style={{
                            margin: 0
                        }}
                        {...field}
                        name={[field.name, 'name']}
                        label=""
                        rules={[{ required: true, message: 'Please Enter Customer Name' }]}
                    >
                        <Input
                            type="text"
                            placeholder="Type name"
                            style={{
                                width: '100%',
                                border: '1px solid #92B5D7',
                                borderRadius: token.borderRadius,
                                boxShadow: 'none',
                                height: '40px'
                            }}
                        />
                    </Form.Item>
                </Col>

                <Col lg={6} md={12} xs={24} style={{ paddingRight: '20px', marginBottom: '20px' }}>
                    <Typography.Title level={5} style={{
                        fontSize: '14px'
                    }}>Mobile Number</Typography.Title>
                    <Form.Item
                        {...field}
                        name={[field.name, 'phone']}
                        style={{
                            margin: 0
                        }}
                        rules={[{ required: false }]}
                    >
                        <Input
                            type="text"
                            placeholder="9987654321"
                            style={{
                                width: '99%',
                                border: '1px solid #92B5D7',
                                borderRadius: token.borderRadius,
                                boxShadow: 'none',
                                height: '40px'
                            }}
                            className='prefix-select-input'

                            addonBefore={<Select
                                showSearch={true}
                                value="+91"
                                className="lead-custom-select"
                                style={{
                                    width: 70,
                                    borderRadius: token.borderRadius,
                                    boxShadow: 'none',
                                    height: '100%'
                                }}
                                options={[
                                    { value: '+91', label: '+91' }
                                ]}

                            />}
                        />
                    </Form.Item>
                </Col>
                <Col lg={6} md={12} xs={24} style={{ paddingRight: '20px', marginBottom: '20px' }}>
                    <Typography.Title level={5} style={{
                        fontSize: '14px',
                        fontWeight: 500
                    }}>Email address</Typography.Title>
                    <Form.Item
                        style={{
                            margin: 0
                        }}
                        {...field}
                        name={[field.name, 'email']}
                        label=""
                        rules={[{ required: true, message: 'Please Enter Customer Name' }]}
                    >
                        <Input
                            type="text"
                            placeholder="janedoe890@ymail.com"
                            style={{
                                width: '100%',
                                border: '1px solid #92B5D7',
                                borderRadius: token.borderRadius,
                                boxShadow: 'none',
                                height: '40px'
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col lg={6} md={12} xs={24} style={{ paddingRight: '20px', marginBottom: '20px' }}>
                    <Typography.Title level={5} style={{
                        fontSize: '14px'
                    }}>Date of Birth</Typography.Title>
                    <Form.Item
                        {...field}
                        name={[field.name, 'birthDate']}
                        style={{
                            margin: 0
                        }}
                        rules={[{ required: false }]}
                        initialValue={dayjs(dayjs())}
                    >
                        <DatePicker
                            inputReadOnly={isMobile()}
                            style={{
                                border: '1px solid #92B5D7',
                                width: '70%',
                                borderRadius: token.borderRadius,
                                boxShadow: 'none',
                                height: '40px'
                            }}
                            placeholder="Select date" />

                    </Form.Item>
                </Col>
                <Col lg={6} md={12} xs={24} style={{ paddingRight: '20px', marginBottom: '20px' }}>
                    <Typography.Title level={5} style={{
                        fontSize: '14px',
                        fontWeight: 500
                    }}>PAN No.</Typography.Title>
                    <Form.Item
                        {...field}
                        name={[field.name, 'panNo']}
                        style={{
                            margin: 0
                        }}
                        label=""
                    >
                        <Input
                            type="text"
                            placeholder="ex. BZPQ22025K"
                            style={{
                                width: '100%',
                                border: '1px solid #92B5D7',
                                borderRadius: token.borderRadius,
                                boxShadow: 'none',
                                height: '40px'
                            }}
                        />
                    </Form.Item>
                </Col>

                <Col lg={6} md={12} xs={24} style={{ paddingRight: '20px', marginBottom: '20px' }}>
                    <Typography.Title level={5} style={{
                        fontSize: '14px',
                        fontWeight: 500
                    }}>Aadhaar No.</Typography.Title>
                    <Form.Item
                        {...field}
                        name={[field.name, 'adharNo']}
                        style={{
                            margin: 0
                        }}
                        label=""
                    >
                        <Input
                            type="text"
                            placeholder="321456321548"
                            style={{
                                width: '100%',
                                border: '1px solid #92B5D7',
                                borderRadius: token.borderRadius,
                                boxShadow: 'none',
                                height: '40px'
                            }}
                        />
                    </Form.Item>
                </Col>

                <Col lg={6} md={12} xs={24} style={{ paddingRight: '20px', marginBottom: '20px' }}>
                    <Typography.Title level={5} style={{
                        fontSize: '14px',
                        fontWeight: 500
                    }}>Occupation</Typography.Title>
                    <Form.Item
                        {...field}
                        name={[field.name, 'occupation']}
                        style={{
                            margin: 0
                        }}
                        label=""
                    >
                        <Input
                            type="text"
                            placeholder="Type occupation"
                            style={{
                                width: '100%',
                                border: '1px solid #92B5D7',
                                borderRadius: token.borderRadius,
                                boxShadow: 'none',
                                height: '40px'
                            }}
                        />
                    </Form.Item>
                </Col>

                <Col lg={6} md={12} xs={24} style={{ paddingRight: '20px', marginBottom: '20px' }}>
                    <Typography.Title level={5} style={{
                        fontSize: '14px',
                        fontWeight: 500
                    }}>Company Name</Typography.Title>
                    <Form.Item
                        {...field}
                        name={[field.name, 'companyName']}
                        style={{
                            margin: 0
                        }}
                        label=""
                    >
                        <Input
                            type="text"
                            placeholder="Type company name"
                            style={{
                                width: '100%',
                                border: '1px solid #92B5D7',
                                borderRadius: token.borderRadius,
                                boxShadow: 'none',
                                height: '40px'
                            }}
                        />
                    </Form.Item>
                </Col>

                <Col md={12} xs={24} style={{ paddingRight: '20px', marginBottom: '20px' }}>
                    <Typography.Title level={5} style={{
                        fontSize: '14px',
                        fontWeight: 500
                    }}>Address Line 1</Typography.Title>
                    <Form.Item
                        {...field}
                        name={[field.name, 'lineOne']}
                        style={{
                            margin: 0
                        }}

                        label=""
                    >
                        <Input
                            type="text"
                            placeholder="Type building no, floor no"
                            style={{
                                width: '100%',
                                border: '1px solid #92B5D7',
                                borderRadius: token.borderRadius,
                                boxShadow: 'none',
                                height: '40px'
                            }}
                        />
                    </Form.Item>
                </Col>

                <Col md={12} xs={24} style={{ paddingRight: '20px', marginBottom: '20px' }}>
                    <Typography.Title level={5} style={{
                        fontSize: '14px',
                        fontWeight: 500
                    }}>Address Line 2</Typography.Title>
                    <Form.Item
                        {...field}
                        name={[field.name, 'lineTwo']}
                        style={{
                            margin: 0
                        }}
                        label=""
                    >
                        <Input
                            type="text"
                            placeholder="Landmark, post office"
                            style={{
                                width: '100%',
                                border: '1px solid #92B5D7',
                                borderRadius: token.borderRadius,
                                boxShadow: 'none',
                                height: '40px'
                            }}
                        />
                    </Form.Item>
                </Col>


                <Col lg={6} md={12} xs={24} style={{ paddingRight: '20px', marginBottom: '20px' }}>
                    <Typography.Title level={5} style={{
                        fontSize: '14px',
                        fontWeight: 500
                    }}>Street</Typography.Title>
                    <Form.Item
                        {...field}
                        name={[field.name, 'street']}
                        style={{
                            margin: 0
                        }}
                        label=""
                    >
                        <Input
                            type="text"
                            placeholder="Type street no."
                            style={{
                                width: '100%',
                                border: '1px solid #92B5D7',
                                borderRadius: token.borderRadius,
                                boxShadow: 'none',
                                height: '40px'
                            }}
                        />
                    </Form.Item>
                </Col>

                <Col lg={6} md={12} xs={24} style={{ paddingRight: '20px', marginBottom: '20px' }}>
                    <Typography.Title level={5} style={{
                        fontSize: '14px',
                        fontWeight: 500
                    }}>Pin Code</Typography.Title>
                    <Form.Item
                        {...field}
                        name={[field.name, 'pinCode']}
                        style={{
                            margin: 0
                        }}
                        label=""
                    >
                        <Input
                            type="text"
                            placeholder="ex. 700010"
                            style={{
                                width: '100%',
                                border: '1px solid #92B5D7',
                                borderRadius: token.borderRadius,
                                boxShadow: 'none',
                                height: '40px'
                            }}
                        />
                    </Form.Item>
                </Col>

                <Col lg={6} md={12} xs={24} style={{ paddingRight: '20px', marginBottom: '20px' }}>
                    <Typography.Title level={5} style={{
                        fontSize: '14px',
                        fontWeight: 500
                    }}>State</Typography.Title>
                    <Form.Item
                        {...field}
                        name={[field.name, 'state']}
                        style={{
                            margin: 0
                        }}
                        label=""
                    >
                        <Input
                            type="text"
                            placeholder="Type state name"
                            style={{
                                width: '100%',
                                border: '1px solid #92B5D7',
                                borderRadius: token.borderRadius,
                                boxShadow: 'none',
                                height: '40px'
                            }}
                        />
                    </Form.Item>
                </Col>

                <Col lg={6} md={12} xs={24} style={{ paddingRight: '20px', marginBottom: '20px' }}>
                    <Typography.Title level={5} style={{
                        fontSize: '14px',
                        fontWeight: 500
                    }}>City</Typography.Title>
                    <Form.Item
                        {...field}
                        name={[field.name, 'city']}
                        style={{
                            margin: 0
                        }}
                        label=""
                    >
                        <Input
                            type="text"
                            placeholder="Type city name"
                            style={{
                                width: '100%',
                                border: '1px solid #92B5D7',
                                borderRadius: token.borderRadius,
                                boxShadow: 'none',
                                height: '40px'
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col lg={12} md={12} xs={24} style={{ paddingRight: '20px', marginBottom: '20px' }}>
                    <Typography.Title level={5} style={{
                        fontSize: '14px',
                        fontWeight: 500
                    }}>Nationality</Typography.Title>
                    <Form.Item
                        {...field}
                        name={[field.name, 'nationality']}
                        style={{
                            margin: 0
                        }}
                        label=""
                        initialValue={"indian"}

                    >
                        <Radio.Group className='lead-nationality-radio'>
                            <Radio.Button value="indian"
                                style={{ marginRight: '6px', border: '1px solid #92B5D7', borderRadius: token.borderRadius, background: '#E0EEF7', color: '#6D6C68' }}
                            >
                                Indian
                            </Radio.Button>
                            <Radio.Button value="NRI"
                                style={{ marginRight: '6px', border: '1px solid #92B5D7', borderRadius: token.borderRadius, background: '#E0EEF7', color: '#6D6C68' }}
                            >
                                NRI
                            </Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>
        </>
    )
}

export default ApplicantComponent