import React, { useState } from "react"
import { Row, Col, Tag, Button, Dropdown, Space, MenuProps, Tooltip } from "antd"
import ImgIcon from "../../../../view/img-icon"
import { EnquiryRes } from "../../../../response/enquiry.response"
import dayjs from "dayjs"
import { AddEnquiryLeadComponent } from "../add-lead.component"
import { Link } from "react-router-dom"
import { UpdateEnquiryStatusComponent } from "../update-status.component"
import { ItemProp } from "../../../../model/item-prop.model"
import { EnquiryNoteService } from "../../../../service/enquiry-note.service"
import { EnquiryNoteCallReq } from "../../../../request/enquiry-note.request"
import { useNotification } from "../../../../util/notification.hook"
import { formatName } from "../../../../util/name-helper"

export interface EnquireRowDetailItemProp {
    enquiry: EnquiryRes
    lastItem: boolean
    onLeadSuccess: () => void
    statusList: ItemProp[]
}

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

export const EnquireRowDetailItemBase: React.FC<EnquireRowDetailItemProp> = ({ enquiry, lastItem, onLeadSuccess, statusList }) => {

    const [createLead, setCreateLead] = useState(false)

    const { openNotification } = useNotification()

    const [updateStatus, setUpdateStatus] = useState(false)
    const [selectedStatus, setSelectedStatus] = useState(enquiry.status.value)

    const onClick: MenuProps['onClick'] = ({ key }) => {
        setIsDropwDownOpen(false)
        setSelectedStatus(key)
        setUpdateStatus(true)
    };

    const [isDropwDownOpen, setIsDropwDownOpen] = useState(false)

    const handleOpenState = (isOpen: boolean) => {
        setIsDropwDownOpen(isOpen)
    }

    const callClient = async () => {
        const [data, err] = await EnquiryNoteService.connectCall({
            id: enquiry.currentNote.id,
            enquiryId: enquiry.id
        } as EnquiryNoteCallReq)

        if (err) {
            openNotification('error', err.response.data.message, "")
        } else {
            openNotification('success', "Please pick call in your phone", "")
        }
    }

    const getIcon = (status: string) => {
        switch (status) {
            case "Pending":
                return "open.svg"
            case "Closed":
                return "close.svg"
            case "In Progress":
                return "inprogress.svg"
            case "Not Interested":
                return "rejected.svg"
            case "Dump / Spam":
                return "rejected.svg"
        }

        return "inprogress.svg"
    }

    return (
        <React.Fragment>
            <div style={{
                borderBottom: lastItem ? 'none' : '1px solid #C6C6C6',
                marginBottom: lastItem ? 0 : 5,
                marginTop: 10
            }}>
                <Row style={{ marginBottom: 8 }}>
                    <Col style={{
                        marginRight: 20,
                        color: '#6D6C68'
                    }}
                        flex="85px"
                    >
                        <Link to={`/enquiry/${enquiry.id}`} target={isMobile ? "" : "_blank"} rel={isMobile ? "" : "noopener noreferrer"}>#{enquiry.id}
                        </Link>
                    </Col>
                </Row>
                <Row style={{ marginBottom: 8 }}>
                    <Col style={{
                        marginRight: 20,
                        color: '#6D6C68'
                    }}
                        flex="85px"
                    >Name</Col>
                    <Col style={{
                        color: '#000000'
                    }}>{formatName(enquiry.client.name)}</Col>
                </Row>
                <Row style={{ marginBottom: 8 }}>
                    <Col style={{
                        marginRight: 20,
                        color: '#6D6C68'
                    }}
                        flex="85px"
                    >Assigned to</Col>
                    <Col style={{
                        color: '#000000'
                    }}>{formatName(enquiry.owner.assignedTo.name)}</Col>
                </Row>
                <Row style={{ marginBottom: 8 }}>
                    <Col style={{
                        marginRight: 20,
                        color: '#6D6C68'
                    }}
                        flex="85px"
                    >Project</Col>
                    <Col style={{
                        color: '#000000'
                    }}>{enquiry.project.name}</Col>
                </Row>

                <Row style={{ marginBottom: 8 }}>
                    <Col style={{
                        marginRight: 20,
                        color: '#6D6C68'
                    }}
                        flex="85px"
                    >Source</Col>
                    <Col style={{
                        color: '#000000'
                    }}>{enquiry.source}</Col>
                </Row>

                <Row style={{ marginBottom: 8 }}>
                    {/* <Col style={{
                        marginRight: 20,
                        color: '#6D6C68'
                    }}
                        flex="85px"
                    >Action</Col> */}
                    <Col style={{
                        color: '#000000',
                        flexGrow: 1

                    }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'baseline'
                        }}>
                            <Tag color="magenta">{enquiry.currentNote.type}</Tag>
                            <span style={{ color: dayjs(enquiry.currentNote.dueDate).diff(dayjs()) < 60 * 60 * 1000 ? "red" : "" }}>on {`${dayjs(enquiry.currentNote.dueDate).format('DD MMMM YYYY, hh:mm A')}`}</span>
                            <Button type="default" style={{
                                marginLeft: 'auto',
                                border: '1px solid #6D6C68',
                                borderRadius: '4px',
                                padding: '4px 10px'
                            }}
                                onClick={callClient}>
                                <ImgIcon iconName="phone.svg" iconClass="m-0" />
                            </Button>
                        </div>
                    </Col>
                </Row>

                <Row style={{ marginBottom: 8 }}>
                    <Col style={{
                        marginRight: 20,
                        color: '#6D6C68'
                    }}
                    >Requirements</Col>
                    <Col style={{
                        color: '#000000',
                        fontSize: '12px'
                    }}>{enquiry.requirement}</Col>
                </Row>
                <Row style={{ marginBottom: 8 }}>
                    <Col style={{
                        marginRight: 20,
                        color: '#6D6C68'
                    }}
                        flex="85px"
                    >Assigned On</Col>
                    <Col style={{
                        color: '#000000'
                    }}>{`${dayjs(enquiry.owner.assignedAt).format('DD MMMM YYYY, hh:mm A')}`}</Col>
                </Row>

                {(enquiry.status.value == 'Pending' || enquiry.status.value == 'In Progress') ? <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '10px 0',
                    cursor: 'pointer'
                }}>
                    <Tooltip title={'You can create enquiry to lead when you are sure it is valid enquiry'}>
                        <ImgIcon iconName="info-icon.svg" iconWidth="16px" />
                    </Tooltip>
                    <Button type="link" style={{
                        padding: 0,
                        marginRight: 'auto',
                        fontSize: 12
                    }}
                        onClick={() => { setCreateLead(true) }}
                    >
                        Convert to lead
                    </Button>

                    <Dropdown onOpenChange={handleOpenState} menu={{
                        items: statusList.map((status: ItemProp) => {
                            return {
                                key: status.value,
                                label: status.label
                            }
                        }),
                        selectable: true,
                        defaultSelectedKeys: [enquiry.status.value],
                        onClick: onClick
                    }} trigger={['click']}>
                        <a onClick={(e) => e.preventDefault()}>
                            <Space style={{ fontSize: 12, color: '#6D6C68' }}>
                                Change Status
                                <ImgIcon iconName={isDropwDownOpen ? "up-arrow.svg" : "down-arrow.svg"} />
                            </Space>
                        </a>
                    </Dropdown>

                </div> : <>
                    <Row style={{ marginBottom: 8 }}>
                        <Col style={{
                            marginRight: 20,
                            color: '#6D6C68'
                        }}
                        >Status</Col>
                        <Col style={{
                            color: '#000000',
                            fontSize: '12px'
                        }}>
                            <span style={{ display: "flex" }}><ImgIcon iconName={getIcon(enquiry.status.value)} iconWidth="16px" />
                                {enquiry.status.value} | {enquiry.status.note} | {enquiry.status.description}</span></Col>
                    </Row>
                </>}
            </div>

            {createLead && <AddEnquiryLeadComponent enquiry={enquiry} open={createLead} onCancel={() => { setCreateLead(false) }} onLeadSuccess={onLeadSuccess} />}
            {updateStatus && <UpdateEnquiryStatusComponent enquiry={enquiry} selectedStatus={selectedStatus} open={updateStatus} onCancel={() => { setUpdateStatus(false) }} onStatusSuccess={onLeadSuccess} />}
        </React.Fragment>
    )
}

export const EnquireRowDetailItem = React.memo(EnquireRowDetailItemBase);