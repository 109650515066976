import { EmployeeStateProvider } from './employee.context';
import { Outlet } from 'react-router-dom';

const EmployeePageBase = () => {

  return <>
    <Outlet />
  </>
}

export const EmployeePage = () => {
  return <EmployeeStateProvider>
    <EmployeePageBase />
  </EmployeeStateProvider>
}

