import { createContext, useReducer } from "react";
import { ArticleState } from "./article.state";
import { ArticleReducer } from "./article.reducer";
import { ArticleAction } from "./article.action";
import { ArticleService } from "../../service/article.service";
import { ArticleRequest, ArticleSearchReq } from "../../request/article.request";
import { UserService } from "../../service/user.service";

const initialState = {
    loading: false,
    articleSearchReq: {
        pageNumber: 1,
        itemsPerPage: 100
    } as ArticleSearchReq,
    articleList: [],
    permissionList: []
} as ArticleState

export const ArticleContext = createContext<any>(initialState);

export const ArticleStateProvider = ({ children }: any) => {

    const [state, dispatch] = useReducer(ArticleReducer, initialState)

    const updateLoading = (loading: Boolean) => {
        dispatch({
            type: ArticleAction.UPDATE_LOADING,
            payload: {
                loading: loading
            }
        });
    };

    const fetchArticleList = async (articleSearchReq: ArticleSearchReq, onSuccess: () => void, onFailure: () => void) => {
        updateLoading(true)
        const [articleList, error] = await ArticleService.getArticleList(articleSearchReq)

        if (error) {
            onFailure()
        } else {
            dispatch({
                type: articleSearchReq.pageNumber == 1 ? ArticleAction.UPDATE_ARTICLE_LIST : ArticleAction.ADD_ARTICLE_LIST,
                payload: {
                    articleList: articleList
                }
            })
            if (articleList.length > 0) {
                dispatch({
                    type: ArticleAction.UPDATE_ARTICLE_SEARCH_REQ,
                    payload: {
                        articleSearchReq: {
                            ...articleSearchReq,
                            pageNumber: articleSearchReq.pageNumber + 1
                        }
                    }
                })
            }
            onSuccess()
        }
    };

    const saveArticle = async (articleRequest: ArticleRequest, onSuccess: () => void, onFailure: () => void) => {
        const [data, error] = await ArticleService.saveArticle(articleRequest)

        if (error) {
            onFailure()
        } else {
            onSuccess()
        }
    }

    const updateArticle = async (id: string, articleRequest: ArticleRequest, onSuccess: () => void, onFailure: () => void) => {
        const [data, error] = await ArticleService.updateArticle(id, articleRequest)

        if (error) {
            onFailure()
        } else {
            onSuccess()
        }
    }

    const value = {
        articleList: state.articleList,
        loading: state.loading,
        articleSearchReq: state.articleSearchReq,
        updateLoading,
        fetchArticleList,
        saveArticle,
        updateArticle,
    };

    return <ArticleContext.Provider value={value}>{children}</ArticleContext.Provider>
};