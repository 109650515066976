import { OfficeRequest, OfficeSearchReq } from "../request/office.request"
import { OfficeRes } from "../response/office.response"
import httpClient from "../util/http-client"

export const OfficeService = {
    saveOffice: async (officeRequest: OfficeRequest): Promise<(OfficeRes|any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/office", officeRequest)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    getOfficeList: async (officeSearchReq: OfficeSearchReq): Promise<(OfficeRes[]|any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/office-search", officeSearchReq)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    updateOffice: async (id: string, officeRequest: OfficeRequest): Promise<(OfficeRes|any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.put(`/office/${id}`, officeRequest)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
}