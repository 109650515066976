import { EmployeeAction } from "../model/employee-action.model";
import { UserConfig } from "../response/user.response";
import { SecurityManager } from "../security/security-manager";
import { EmployeeActivityService } from "../service/employee-activity.service";
import { EventName } from "./event-name.enum";
import { getAnalytics, logEvent, setUserProperties } from "firebase/analytics";

const addInDatabase = async (employeeAction: EmployeeAction) => {
    await EmployeeActivityService.addAction({
        actionList: [employeeAction]
    })
}

export const pushEvent = (name: EventName, params: any = {}) => {
    const analytics = getAnalytics();
    logEvent(analytics, name, params);

    addInDatabase({
        name: name,
        createdOn: new Date(),
        metaData: params
    } as EmployeeAction)
}

export const addUserProperties = (userConfig: UserConfig) => {
    const analytics = getAnalytics();
    setUserProperties(analytics, {
        userId: SecurityManager.getUid(),
        employeeId: SecurityManager.getEid(),
        employeeName: userConfig.employeeRes.userInfo.name,
        employeeMobile: userConfig.employeeRes.userInfo.phone
    });
}