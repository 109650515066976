import { Avatar, Button, Card, Form, Input, List, Skeleton } from 'antd';
import { WhatsAppChatStateProvider } from './whatsapp-chat.context';
import { useState, useEffect } from 'react';
import { CloseOutlined } from '@ant-design/icons';
const { TextArea } = Input;

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
export interface WhatsAppChatPageProps {
  userId: string
  closeChatWindow: () => void
}

interface CommentItem {
  author: string;
  avatar: string;
  content: React.ReactNode;
  datetime: string;
}

interface EditorProps {
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onSubmit: () => void;
  submitting: boolean;
  value: string;
}

interface DataType {
  gender?: string;
  name: {
    title?: string;
    first?: string;
    last?: string;
  };
  email?: string;
  picture: {
    large?: string;
    medium?: string;
    thumbnail?: string;
  };
  nat?: string;
  loading: boolean;
  description: string;
}

const count = 3;

const Editor = ({ onChange, onSubmit, submitting, value }: EditorProps) => (
  <div style={{}}>
    <Form.Item>
      <TextArea rows={4} onChange={onChange} value={value} />
    </Form.Item>
    <Form.Item>
      <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
        Add Comment
      </Button>
    </Form.Item>
  </div>
);

const WhatsAppChatPageBase: React.FC<WhatsAppChatPageProps> = ({ userId, closeChatWindow }) => {
  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<DataType[]>([]);
  const [list, setList] = useState<DataType[]>([]);

  const [submitting, setSubmitting] = useState(false);
  const [value, setValue] = useState('');

  const handleSubmit = () => {
    if (!value) return;

    setSubmitting(true);

    setTimeout(() => {
      setSubmitting(false);
      setValue('');
      setList([
        ...list,
        list[0],
      ]);
    }, 1000);
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  };

  useEffect(() => {

        // setInitLoading(false);
        // setData(res.results);
        // setList(res.results);
  }, []);

  const onLoadMore = () => {
    setLoading(true);
    setList([]);
        // const newData = data.concat(res.results);
        setData([]);
        setList([]);
        setLoading(false);
      
        window.dispatchEvent(new Event('resize'));
  };

  const loadMore =
    !initLoading && !loading ? (
      <div
        style={{
          textAlign: 'center',
          marginTop: 12,
          height: 32,
          lineHeight: '32px',
        }}
      >
        <Button onClick={onLoadMore}>loading more</Button>
      </div>
    ) : null;

  return (
    <Card style={{
      position: 'fixed',
      bottom: isMobile ? 0: 16,
      right: isMobile ? 0: 16,
      zIndex: 999,
      width: '100%',
      height: '100vh',
      maxHeight: isMobile ? '100vh': 600,
      maxWidth: isMobile ? '100%': 374,
      overflow: 'auto'
    }}>
      <Button style={{
        position: 'absolute',
        right: 16,
        top: 16,
        zIndex: 9999,
      }} onClick={closeChatWindow} shape="circle" icon={<CloseOutlined />} />
      <List
        className="demo-loadmore-list"
        loading={initLoading}
        itemLayout="horizontal"
        loadMore={loadMore}
        dataSource={list}
        renderItem={(item) => (
          <List.Item
            actions={[<a key="list-loadmore-edit">edit</a>]}
          >
            <Skeleton avatar title={false} loading={item.loading} active>
              <List.Item.Meta
                avatar={<Avatar src={item.picture.large} />}
                title={<a href="https://ant.design">{item.name?.last}</a>}
                description={item.description}
              />
              {/* <div>content</div> */}
            </Skeleton>
          </List.Item>
        )}
      />
      <Editor
        onChange={handleChange}
        onSubmit={handleSubmit}
        submitting={submitting}
        value={value}
      />
    </Card>
  );
}

export const WhatsAppChatPage: React.FC<WhatsAppChatPageProps> = ({ userId, closeChatWindow }) => {
  return <WhatsAppChatStateProvider>
    <WhatsAppChatPageBase userId={userId} closeChatWindow={closeChatWindow} />
  </WhatsAppChatStateProvider>
}

