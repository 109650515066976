import { Action } from "../../model/action.model";
import { ArticleAction } from "./article.action";
import { ArticleState } from "./article.state";


export const ArticleReducer = (state: ArticleState, action: Action): ArticleState => {
    const { type, payload } = action

    switch (type) {
        case ArticleAction.UPDATE_LOADING:
            return {
                ...state,
                loading: payload.loading
            };
        case ArticleAction.ADD_ARTICLE_LIST:
            return {
                ...state,
                articleList: [...state.articleList, ...payload.articleList]
            };
        case ArticleAction.UPDATE_ARTICLE_LIST:
            return {
                ...state,
                articleList: [...payload.articleList]
            };
        case ArticleAction.UPDATE_ARTICLE_SEARCH_REQ:
            return {
                ...state,
                articleSearchReq: { ...payload.articleSearchReq }
            };
        default:
            throw new Error(`No case for type ${type} found in ArticleReducer.`);
    }
}