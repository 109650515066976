import { Action } from "../../model/action.model";
import { TaskAction } from "./task.action";
import { TaskState } from "./task.state";

export const TaskReducer = (state: TaskState, action: Action): TaskState => {
    const { type, payload } = action

    switch (type) {
        case TaskAction.UPDATE_TASK_LIST:
            return {
                ...state,
                taskList: [...payload.taskList]
            };
        case TaskAction.ADD_TASK_LIST:
            return {
                ...state,
                taskList: [...state.taskList, ...payload.taskList]
            };
        case TaskAction.UPDATE_TASK_SEARCH_REQ:
            return {
                ...state,
                taskSearchReq: { ...payload.taskSearchReq }
            };
        case TaskAction.UPDATE_TASK:
            return {
                ...state,
                task: { ...payload.task }
            };
        case TaskAction.UPDATE_LOADING:
            return {
                ...state,
                loading: payload.loading
            };
        case TaskAction.UPDATE_EMPLOYEE_LIST:
            return {
                ...state,
                employeeList: [...state.employeeList, ...payload.employeeList]
            };
        case TaskAction.UPDATE_DISCUSSION_LIST:
            return {
                ...state,
                discussionList: [...payload.discussionList]
            };
        case TaskAction.UPDATE_HISTORY_LIST:
            return {
                ...state,
                historyList: [...payload.historyList]
            };
        default:
            throw new Error(`No case for type ${type} found in TaskReducer.`);
    }
}