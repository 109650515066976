import React from "react";
import { Col, Modal, Row, Space } from "antd";
import { ProjectRes } from "../../../response/project.response";
import { UserRes } from "../../../response/user.response";
import { formatName } from "../../../util/name-helper";

export const UserDetailComponent: React.FC<{
  userDetail: UserRes;
  open: boolean;
  onCancel: () => void;
}> = ({ userDetail, open, onCancel }) => {
  return (
    <>
      {console.log(userDetail)}
      <Modal
        open={open}
        title={<h2>{userDetail.id}</h2>}
        onCancel={onCancel}
        width={"90vw"}
        footer={false}
        style={{ top: 8, maxWidth: 768 }}
      >
        <Row gutter={16}>
          <Col span={8} style={{ paddingLeft: 16 }}>
            <Row gutter={16} style={{ display: "grid", marginBottom: 16 }}>
              <Space style={{ alignItems: "baseline" }}>
                <h3>Name</h3>
              </Space>
            </Row>
            <Row gutter={16} style={{ display: "grid", marginBottom: 16 }}>
              <h3>Phone </h3>
              <Space style={{ alignItems: "baseline", marginLeft: 8 }}></Space>
            </Row>
            <Row gutter={16} style={{ display: "grid", marginBottom: 16 }}>
              <h3>Email </h3>
              <Space style={{ alignItems: "baseline", marginLeft: 8 }}></Space>
            </Row>
            <Row gutter={16} style={{ display: "grid", marginBottom: 16 }}>
              <Space style={{ alignItems: "baseline" }}>
                <h3>Score </h3>
              </Space>
            </Row>
            <Row gutter={16} style={{ display: "grid", marginBottom: 16 }}>
              <Space style={{ alignItems: "baseline" }}>
                <h3>Address </h3>
              </Space>
            </Row>
          </Col>
          <Col span={2} style={{ paddingLeft: 16 }}>
            <Row gutter={16} style={{ display: "grid", marginBottom: 16 }}>
              <Space style={{ alignItems: "baseline" }}>
                <h3>:</h3>
              </Space>
            </Row>
            <Row gutter={16} style={{ display: "grid", marginBottom: 16 }}>
              <h3>: </h3>
              <Space style={{ alignItems: "baseline", marginLeft: 8 }}></Space>
            </Row>
            <Row gutter={16} style={{ display: "grid", marginBottom: 16 }}>
              <h3>: </h3>
              <Space style={{ alignItems: "baseline", marginLeft: 8 }}></Space>
            </Row>
            <Row gutter={16} style={{ display: "grid", marginBottom: 16 }}>
              <Space style={{ alignItems: "baseline" }}>
                <h3>: </h3>
              </Space>
            </Row>
            <Row gutter={16} style={{ display: "grid", marginBottom: 16 }}>
              <Space style={{ alignItems: "baseline" }}>
                <h3>:</h3>
              </Space>
            </Row>
          </Col>
          <Col span={14} style={{ paddingLeft: 16 }}>
            <Row gutter={16} style={{ display: "grid", marginBottom: 16 }}>
              <Space style={{ alignItems: "baseline" }}>
                <h3>{formatName(userDetail.userInfo.name)}</h3>
              </Space>
            </Row>
            <Row gutter={16} style={{ display: "grid", marginBottom: 16 }}>
              <h3>{userDetail.userInfo.phone} </h3>
              <Space style={{ alignItems: "baseline", marginLeft: 8 }}></Space>
            </Row>
            <Row gutter={16} style={{ display: "grid", marginBottom: 16 }}>
              <h3>{userDetail.userInfo.email}</h3>
              <Space style={{ alignItems: "baseline", marginLeft: 8 }}></Space>
            </Row>
            <Row gutter={16} style={{ display: "grid", marginBottom: 16 }}>
              <Space style={{ alignItems: "baseline" }}>
                <h3>{userDetail.score} </h3>
              </Space>
            </Row>
            <Row gutter={16} style={{ display: "grid", marginBottom: 16 }}>
              <Space style={{ alignItems: "baseline" }}>
                <h3>
                  {`${
                    userDetail.address?.lineOne
                      ? `${userDetail.address?.lineOne} ,`
                      : ""
                  } ${
                    userDetail.address?.lineTwo
                      ? `${userDetail.address?.lineTwo} ,`
                      : ""
                  } ${
                    userDetail.address?.street
                      ? `${userDetail.address?.street} ,`
                      : ""
                  } ${
                    userDetail.address?.city
                      ? `${userDetail.address?.city} ,`
                      : ""
                  } ${
                    userDetail.address?.state
                      ? `${userDetail.address?.state}`
                      : ""
                  }`}
                </h3>
              </Space>
            </Row>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
