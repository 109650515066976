import { Row, Col, theme, Typography, Select, DatePicker, Button, Form, Radio } from "antd"
import TextArea from "antd/es/input/TextArea";
import { useState } from "react";
import { EnquiryNoteService } from "../../../../../service/enquiry-note.service";
import { EnquiryNoteRequest } from "../../../../../request/enquiry-note.request";
import { useEnquiry } from "../../../enquiry.hook";
import { EnquiryRes } from "../../../../../response/enquiry.response";
import { handleFilter } from "../../../../../view/input.helper";
import { isMobile } from "../../../../../util/device-helper";

export interface AddFollowUpComponentProp {
    enquiry: EnquiryRes
    setIsAddNewFollowUp: (addNewFollowUp: boolean) => void
    fetchEnquiryNote: (enquiryId: string) => void
}

export const AddFollowUpComponent = (props: AddFollowUpComponentProp) => {

    const { enquiry, setIsAddNewFollowUp, fetchEnquiryNote } = props

    const { useToken } = theme;
    const { token } = useToken();

    const { noteStatusList, noteTypeList } = useEnquiry()

    const [form] = Form.useForm()

    const type = Form.useWatch('type', form);

    const handleOk = () => {
        form.validateFields().then(async values => {
            const enquiryNoteRequest = {
                enquiryId: enquiry.id,
                type: values["type"],
                status: values["status"],
                description: values["description"],
                dueDate: values["dueDate"]
            } as EnquiryNoteRequest

            const [data, error] = await EnquiryNoteService.saveEnquiryNote(enquiryNoteRequest)
            if (error) {
                onFailure()
            } else {
                onSuccess()
            }
        });
    };

    const onSuccess = async () => {
        form.resetFields()
        await fetchEnquiryNote(enquiry.id)
        setIsAddNewFollowUp(false);
    }

    const onFailure = () => {
        setIsAddNewFollowUp(false);
    }

    return (
        <Form form={form}>
            <Row>
                <Col md={12} xs={24} style={{
                    paddingRight: '50px'
                }}>
                    <Row style={{
                        marginBottom: '20px'
                    }}>
                        <Col md={12} xs={24}>
                            <Typography.Title level={5} style={{
                                fontSize: '14px'
                            }}>Type</Typography.Title>
                            <Form.Item
                                name="type"
                                style={{
                                    margin: 0
                                }}
                                rules={[{ required: true, message: 'Please input your type!' }]}
                            >

                                <Select
                                    showSearch={true}
                                    className="form-custom-select"
                                    placeholder="Select Type"
                                    style={{
                                        width: '94%',
                                        border: '1px solid #92B5D7',
                                        borderRadius: token.borderRadius,
                                        boxShadow: 'none'
                                    }}
                                    options={noteTypeList}
                                    filterOption={handleFilter}
                                />
                            </Form.Item>

                        </Col>
                        <Col md={12} xs={24}>
                            <Typography.Title level={5} style={{
                                fontSize: '14px'
                            }}>Expected Due Date</Typography.Title>
                            <Form.Item
                                name="dueDate"
                                style={{
                                    margin: 0
                                }}
                                rules={[{ required: true, message: 'Please input your username!' }]}
                            >
                                <DatePicker
                                    inputReadOnly={isMobile()}
                                    showTime={true}
                                    showSecond={false}
                                    style={{
                                        border: '1px solid #92B5D7',
                                        width: '98%',
                                        borderRadius: token.borderRadius,
                                        boxShadow: 'none',

                                    }}
                                    placeholder="Select date" />
                            </Form.Item>

                        </Col>
                    </Row>

                    {(type && type.length > 0) && (
                        <Row style={{
                            marginBottom: '20px'
                        }}>
                            <Col md={12} xs={24}>
                                <Typography.Title level={5} style={{
                                    fontSize: '14px'
                                }}>Status</Typography.Title>
                                <Form.Item
                                    name="status"
                                    style={{
                                        margin: 0
                                    }}
                                    rules={[{ required: true, message: 'Please input your username!' }]}
                                    initialValue={"Scheduled"}
                                >
                                    <Select
                                        showSearch={true}
                                        className="form-custom-select"
                                        placeholder="Select Status"
                                        style={{
                                            width: '94%',
                                            border: '1px solid #92B5D7',
                                            borderRadius: token.borderRadius,
                                            boxShadow: 'none'
                                        }}
                                        options={noteStatusList[type]}
                                        filterOption={handleFilter}
                                    />
                                </Form.Item>

                            </Col>
                        </Row>
                    )}

                </Col>
                <Col md={12} xs={24} className="reason-wrapper">

                    <Typography.Title level={5} style={{
                        fontSize: '14px'
                    }}>Reason</Typography.Title>
                    <Form.Item
                        name="description"
                        style={{
                            margin: 0
                        }}
                        rules={[{ required: true, message: 'Please input your username!' }]}
                    >
                        <TextArea style={{
                            width: '99%',
                            border: '1px solid #92B5D7',
                            borderRadius: token.borderRadius,
                            boxShadow: 'none',
                        }} rows={5} placeholder="Write something" />
                    </Form.Item>


                </Col>
            </Row>
            <Row style={{
                marginTop: '10px',
                marginBottom: '10px',
                justifyContent: 'end'
            }}>
                <Col style={{
                    display: 'flex'
                }}>
                    <Button type="default" style={{
                        boxShadow: 'none',
                        marginRight: '20px'
                    }}
                        onClick={() => setIsAddNewFollowUp(false)}
                    >
                        Cancel
                    </Button>
                    <Button type="primary" block style={{
                        background: token.colorInfo,
                        boxShadow: 'none'
                    }}
                        onClick={handleOk}
                    >
                        Save Changes
                    </Button>
                </Col>

            </Row>
        </Form>
    )
}