import { createContext, useReducer } from "react";
import { TargetAction } from "./target.action";
import { TargetReducer } from "./target.reducer";
import { TargetService } from "../../service/target.service";
import { TargetState } from "./target.state";
import { TargetRequest, TargetSearchReq } from "../../request/target.request";
import { EmployeeService } from "../../service/employee.service";
import { EmployeeSearchReq } from "../../request/employee.request";

const _typeList = [
    { label: "Site Visit", value: "Site Visit" },
    { label: "Call", value: "Call" },
    { label: "Revenue", value: "Revenue" },
    { label: "Lead Closed", value: "Lead Closed" },
]

const initialState = {
    loading: false,
    targetSearchReq: {
        pageNumber: 1,
        itemsPerPage: 100
    } as TargetSearchReq,
    targetList: [],
    employeeList: [],
    employeeSearchReq: {
        pageNumber: 1,
        itemsPerPage: 200
    } as EmployeeSearchReq,
    typeList: _typeList,
} as TargetState



export const TargetContext = createContext<any>(initialState);

export const TargetStateProvider = ({ children }: any) => {

    const [state, dispatch] = useReducer(TargetReducer, initialState)

    const fetchTargetList = async (targetSearchReq: TargetSearchReq, onSuccess: () => void, onFailure: () => void) => {
        const [targetList, error] = await TargetService.getTargetList(targetSearchReq);
        if (error) {
            onFailure()
        } else {
            dispatch({
                type: targetSearchReq.pageNumber == 1 ? TargetAction.UPDATE_TARGET_LIST : TargetAction.ADD_TARGET_LIST,
                payload: {
                    targetList: targetList
                }
            })
            if (targetList.length > 0) {
                dispatch({
                    type: TargetAction.UPDATE_TARGET_SEARCH_REQ,
                    payload: {
                        targetSearchReq: {
                            ...targetSearchReq,
                            pageNumber: targetSearchReq.pageNumber + 1
                        }
                    }
                })
            }
            onSuccess()
        }
    }

    const updateLoading = (loading: Boolean) => {
        dispatch({
            type: TargetAction.UPDATE_LOADING,
            payload: {
                loading: loading
            }
        })
    };

    const fetchEmployeeList = async () => {
       if (false) {
        const [data, error] = await EmployeeService.getEmployeeList(state.employeeSearchReq)
        if (error) {

        } else {
            dispatch({
                type: TargetAction.UPDATE_EMPLOYEE_LIST,
                payload: {
                    employeeList: data
                }
            });
        }
       } else {
        const [data, error] = await EmployeeService.getMyTeam()
        if (error) {

        } else {
            dispatch({
                type: TargetAction.UPDATE_EMPLOYEE_LIST,
                payload: {
                    employeeList: data
                }
            });
        }
       }
    };

    const saveTarget = async (targetRequest: TargetRequest, onSuccess: () => void, onFailure: () => void) => {
        const [data, error] = await TargetService.saveTarget(targetRequest)

        if (error) {
            onFailure()
        } else {
            onSuccess()
        }
    }

    const value = {
        targetList: state.targetList,
        loading: state.loading,
        employeeList: state.employeeList,
        typeList: state.typeList,
        targetSearchReq: state.targetSearchReq,
        updateLoading,
        fetchTargetList,
        fetchEmployeeList,
        saveTarget
    };

    return <TargetContext.Provider value={value}>{children}</TargetContext.Provider>
};



