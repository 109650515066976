import React from 'react';
import { Form, Input, Button } from 'antd';
import { CountrySearchReq } from '../../../request/country.request';
import { useCountry } from '../country.hook';

export const CountryFilterComponent: React.FC = () => {
    const [form] = Form.useForm();
    const { fetchCountryList, updateLoading, countrySearchReq } = useCountry()

    const handleOk = () => {
        form.validateFields().then(values => {
            updateLoading(true);
            const _countrySearchReq = {
                ...countrySearchReq,
                name: values["name"],
                pageNumber: 1,
                itemsPerPage: 10
            } as CountrySearchReq
            fetchCountryList(_countrySearchReq, onSuccess, onFailure)
        });
    };

    const onSuccess = () => {
        updateLoading(false);
    }

    const onFailure = () => {
        updateLoading(false);
    }

    return (
        <Form form={form} layout="inline">
            <Form.Item
                name="name"
                style={{ minWidth: 124 }}
                initialValue={countrySearchReq.name}
            >
                <Input placeholder='Name' />
            </Form.Item>

            <Form.Item style={{ minWidth: 124 }}>
                <Button type="primary" htmlType="submit"
                    onClick={handleOk}>
                    Search
                </Button>
            </Form.Item>
        </Form>
    );
}
