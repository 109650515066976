import { ArticleStateProvider } from './article.context';
import { MainArticleTableComponent } from './component/table.component';

const ArticlePageBase = () => {

  return <>
    <MainArticleTableComponent />
  </>
}

export const ArticlePage = () => {
  return <ArticleStateProvider>
    <ArticlePageBase />
  </ArticleStateProvider>
}

