import { Card, Row, Spin } from "antd"
import { LeadRes } from "../../../../response/lead.response"
import theme from "antd/lib/theme"
import Table, { ColumnsType } from "antd/es/table"
import { TextEditorView } from "../../../../view/text-editor.view"
import { Link, useNavigate } from "react-router-dom"
import React from "react"
import { PropertyPrefrence } from "./requirement/property-prefrences.component"
import { LocationPreference } from "./requirement/location-pref.component"
import { LeadRowReadItem } from "./lead-row-read-item.component"
import { LeadStatus } from "./requirement/lead-status.component"
import { formatName } from "../../../../util/name-helper"

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

export interface RequirementDetailComponentProps {
    lead: LeadRes
    oldLeadList: LeadRes[]
}


export const RequirementDetailComponent = (props: RequirementDetailComponentProps) => {
    const { useToken } = theme
    const { token } = useToken()
    const navigate = useNavigate()

    const { lead, oldLeadList } = props

    const columnList: ColumnsType<LeadRes> = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            render: (id) => <Link to={`/lead/${id}`} target="_blank" rel="noopener noreferrer">{id.substring(0, 6)}...</Link>,
        },
        {
            title: 'Source',
            dataIndex: 'source',
            key: 'source',
        },
        {
            title: 'Requirement',
            dataIndex: 'requirement',
            render: (requirement) => <div style={{ minWidth: 374, maxWidth: 374 }}><TextEditorView initData={requirement} onChange={() => { }} readOnly={true} /></div>,
        },
        {
            title: 'Owner',
            dataIndex: 'owner',
            key: 'owner',
            render: (owner) => <span>{formatName(owner.assignedTo.name)}</span>,
        },
        {
            title: 'Project Name',
            dataIndex: 'project',
            key: 'project',
            render: (project) => <span>{project?.name}</span>,
        },
        {
            title: 'Next Action',
            dataIndex: 'currentNote',
            key: 'currentNote',
            render: (currentNote) => <span>{currentNote.type}</span>,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => <span>{status.value}</span>,
        },
    ];

    return <>
        <React.Fragment>
            <Row className="card-custom-row">
                <PropertyPrefrence lead={lead} />
                <LeadStatus lead={lead} />

            </Row>
            <Row className="card-custom-row" style={{
                marginTop: '25px',
            }}>

                <LocationPreference lead={lead} />

            </Row>

        </React.Fragment>


        <Card title="Old Lead" bordered={false} style={{ width: '100%', marginTop: token.marginSM }}>
            {isMobile ? <>
                {oldLeadList.map((lead: LeadRes, index: number) => {
                    return <LeadRowReadItem addLink={false} lead={lead} lastItem={index == oldLeadList.length - 1} />
                })}
            </> : <Table
                columns={columnList} dataSource={oldLeadList}
                pagination={false}
                loading={{ indicator: <div><Spin /></div>, spinning: false }} />
            }
        </Card>
    </>
}