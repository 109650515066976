import React, { useEffect } from "react";
import { Modal, Form, Input, Select, theme, Slider, DatePicker, Button, Space, Switch, Row } from "antd";
import { ItemProp } from "../../../model/item-prop.model";
import { ProjectRes } from "../../../response/project.response";
import { useProject } from "../project.hook";
import { ProjectRequest } from "../../../request/project.request";
import { EmployeeRes } from "../../../response/employee.response";
import { BuilderRes } from "../../../response/builder.response";
import { MiniUser } from "../../../model/mini-user";
import { OutSideKey } from "../../../model/outside-key";
import { handleFilter } from "../../../view/input.helper";
import { formatName } from "../../../util/name-helper";
import { LocalityRes } from "../../../response/locality.response";
import { Amenities, AreaRange, BasicDetail, MiniLocality, PriceRange, PropertyArea, Rera, UnitConfiguration } from "../../../model/property.model";
import { Address } from "../../../model/address.model";
import { SliderTooltipProps } from "antd/es/slider";
import { isMobile } from "../../../util/device-helper";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import FileUploadView from "../../../view/file-upload.view";
import dayjs from "dayjs";
import { AmenitiesRes } from "../../../response/amenities.response";
import MultiFileUploadView from "../../../view/multi-file-upload.view";
import { Seo } from "../../../model/seo.model";

export const UpdateProjectComponent: React.FC<{
  projectRes: ProjectRes;
  open: boolean;
  onCancel: () => void;
}> = ({ projectRes, open, onCancel }) => {
  const [form] = Form.useForm();

  const category = Form.useWatch('category', form);

  const { useToken } = theme;
  const { token } = useToken();
  const {
    loading,
    updateLoading,
    updateProject,
    fetchEmployeeList,
    employeeList,
    fetchBuilderList,
    builderList,
    projectStatusList,
    localityList,
    fetchLocalityList, amenitiesList, fetchAmenitiesList,
    unitTypeList,
    categoryTypeList
  } = useProject();

  const handleOk = () => {
    form.validateFields().then((values) => {
      updateLoading(true);

      const locality = localityList.filter((locality: LocalityRes) => {
        return locality.id == values["localityId"]
      })[0]
      console.log(values["priceRangeEnd"])
      const projectRequest = {
        name: values["name"],
        builderId: values["builderId"],
        totalTower: Number(values["totalTower"]),
        totalUnit: Number(values["totalUnit"]),
        status: values["status"],
        type: values["type"],
        category: values["category"],
        area: Number(values["area"]),
        employeeIdList: values["employeeIdList"],
        contact: Number(values["contact"]),
        paymentPlan: values["paymentPlan"],
        tagList: values["tagList"],
        overview: values["overview"],
        outSideKey: {
          acre: values["acreKey"],
          housing: values["housingKey"],
        } as OutSideKey,
        basicDetail: {
          address: {
            lineOne: values["lineOne"],
            lineTwo: values["lineTwo"],
            street: values["street"],
            city: values["city"],
            state: values["state"],
            pinCode: values["pinCode"]
          } as Address,
          locality: {
            id: locality?.id,
            name: locality?.name,
            country: locality?.country,
            city: locality?.city,
            location: locality?.location
          } as MiniLocality,
          priceRange: {
            start: Number(Number(values["priceRangeStart"])?.toFixed(0)),
            end: Number(Number(values["priceRangeEnd"])?.toFixed(0))
          } as PriceRange,
          areaRange: {
            start: Number(values["areaRangeStart"]),
            end: Number(values["areaRangeEnd"])
          } as AreaRange,
          launchDate: values["launchDate"],
          possessionDate: values["possessionDate"],
          rera: {
            approved: (values["reraId"] && values["reraId"].length > 0) ? true : false,
            number: values["reraId"],
          } as Rera
        } as BasicDetail,
        amenitiesList: values["amenitiesIdList"]?.map((id: string) => {
          return amenitiesList.filter((amenities: AmenitiesRes) => {
            return amenities.id == id
          })[0]
        }),
        faqList: values["faqList"] ? values["faqList"].map((faq: any) => {
          return {
            ...faq,
            order: Number(faq.order)
          }
        }) : [],
        unitConfigurationList: values["unitConfigurationList"] ? values["unitConfigurationList"].map((unitConfiguration: any) => {
          return {
            ...unitConfiguration,
            mediaList: unitConfiguration.media ? [unitConfiguration.media] : [],
            ...(values["category"] == "Residential" && {
              bedRoom: Number(unitConfiguration.bedRoom),
              kitchen: Number(unitConfiguration.kitchen),
              bathRoom: Number(unitConfiguration.bathRoom),
              balcony: Number(unitConfiguration.balcony),
              living: Number(unitConfiguration.living),
              bhk: Number(unitConfiguration.bhk),
              availability: Boolean(unitConfiguration.availability),
              area: {
                super: Number(unitConfiguration.super),
                carpet: Number(unitConfiguration.carpet),
              } as PropertyArea,
              priceRange: {
                start: Number(Number(unitConfiguration.priceRangeStart).toFixed(0)),
                end: Number(Number(unitConfiguration.priceRangeEnd).toFixed(0))
              } as PriceRange
            }),
            ...(values["category"] == "Commercial" && {
              commercialDetail: {
                type: unitConfiguration.type,
                price: Number(Number(unitConfiguration.price ?? 0)?.toFixed(0)),
                area: Number(unitConfiguration.commercialArea ?? 0)
              }
            })
          } as UnitConfiguration
        }) : [],
        brochureList: values["brochureList"] ?? values["brochureList"],
        mediaList: values["mediaList"] ?? [],
        seo: {
          title: values["seoTitle"] ?? "",
          description: values["seoDescription"] ?? "",
          OGDescription: values["seoOGDescription"] ?? "",
          OGTitle: values["seoOGTitle"] ?? "",
          OGMedia: values["seoOGMedia"] ?? "",
          keywordList: values["seoKeywordList"] ?? [],
        } as Seo,
        hideFromWebsite: values["hideFromWebsite"],
      } as ProjectRequest;

      updateProject(projectRes.id, projectRequest, onSuccess, onFailure);
    });
  };

  useEffect(() => {
    fetchEmployeeList();
    fetchBuilderList();
    fetchLocalityList();
    fetchAmenitiesList();
  }, []);

  const onSuccess = () => {
    updateLoading(false);
    form.resetFields();
    onCancel();
    window.location.reload();
  };

  const onFailure = () => {
    updateLoading(false);
  };

  const sliderTooltipProps = {
    formatter: (value: any) => {
      if (value < 1000) {
        return `${value}`
      }
      if (value < 100000) {
        return `${(value / 1000).toFixed(2)}K`
      }
      if (value < 10000000) {
        return `${(value / 100000).toFixed(2)}L`
      }
      return `${(value / 10000000).toFixed(2)}Cr`
    },
    open: true
  } as SliderTooltipProps

  const areaTooltipProps = {
    formatter: (value: any) => {
      return `${value}`
    },
    open: true
  } as SliderTooltipProps

  return (
    <Modal
      open={open}
      title="Update Project"
      onCancel={onCancel}
      confirmLoading={loading}
      onOk={handleOk}
      okText={"Submit"}
      cancelText={"Cancel"}
      style={{ top: 8, maxWidth: 768 }}
      width={"90vw"}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Please enter a project name" }]}
          initialValue={projectRes.name}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="acreKey"
          label="99 Acre Key"
          rules={[{ required: false, message: "Please enter a project name" }]}
          initialValue={projectRes.outSideKey.acre}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="housingKey"
          label="Housing Key"
          rules={[{ required: false, message: "Please enter a project name" }]}
          initialValue={projectRes.outSideKey.housing}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="brochureList"
          label="Add brochure"
          rules={[{ required: false, message: 'Please add brochure' }]}
          initialValue={projectRes?.brochureList}
        >
          <MultiFileUploadView onChange={() => { }} initmediaList={projectRes?.brochureList ?? []} />
        </Form.Item>

        <Form.Item
          name="mediaList"
          label="Add media"
          rules={[{ required: false, message: 'Please add media' }]}
          initialValue={projectRes?.mediaList}
        >
          <MultiFileUploadView onChange={() => { }} initmediaList={projectRes?.mediaList ?? []} />
        </Form.Item>

        <Form.Item
          name="builderId"
          label="Builder"
          rules={[{ required: true, message: "Please enter a builder name" }]}
          initialValue={projectRes.builder.id}
        >
          <Select
            showSearch={true}
            options={builderList.map((builder: BuilderRes) => {
              return {
                label: builder.name,
                value: builder.id,
              } as ItemProp;
            })}
            filterOption={handleFilter}
          />
        </Form.Item>

        <Form.Item
          name="employeeIdList"
          label="Employee"
          rules={[{ required: false, message: "Please enter a builder name" }]}
          initialValue={projectRes.employeeList?.map((employee: MiniUser) => {
            return employee.id;
          })}
        >
          <Select
            mode="multiple"
            value="employeeIdList"
            style={{
              border: "1px solid #92B5D7",
              borderRadius: token.borderRadiusSM,
              width: "250px",
            }}
            options={employeeList?.map((employee: EmployeeRes) => {
              return {
                label: `${formatName(employee.userInfo.name)}`,
                value: employee.id,
              } as ItemProp;
            })}
            filterOption={handleFilter}
          />
        </Form.Item>

        <Form.Item
          name="localityId"
          label="Locality"
          rules={[{ required: false, message: "Please select locality" }]}
          initialValue={projectRes?.basicDetail?.locality?.id}
        >
          <Select
            showSearch={true}
            options={localityList.map((locality: LocalityRes) => {
              return {
                label: locality.name + ", " + locality.city?.name,
                value: locality.id,
              } as ItemProp;
            })}
            filterOption={handleFilter}
          />
        </Form.Item>

        <Form.Item
          name="status"
          label="Status"
          rules={[{ required: false }]}
          initialValue={projectRes.status}
        >
          <Select
            showSearch={true}
            options={projectStatusList}
            filterOption={handleFilter}
          />
        </Form.Item>

        <Form.Item
          name="type"
          label="Type"
          rules={[{ required: false }]}
          initialValue={projectRes.type}
        >
          <Select
            showSearch={true}
            options={unitTypeList}
            filterOption={handleFilter}
          />
        </Form.Item>

        <Form.Item
          name="category"
          label="Category"
          rules={[{ required: false }]}
          initialValue={projectRes.category}
        >
          <Select
            showSearch={true}
            options={categoryTypeList}
            filterOption={handleFilter}
          />
        </Form.Item>

        <Form.Item
          name="totalTower"
          label="Total Tower"
          rules={[{ required: false, message: "Please enter a builder name" }]}
          initialValue={projectRes.totalTower}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          name="totalUnit"
          label="Total Unit"
          rules={[{ required: false, message: "Please enter a builder name" }]}
          initialValue={projectRes.totalUnit}
        >
          <Input type="number" />
        </Form.Item>

        <Form.Item
          name="area"
          label="Project Area (in acre)"
          rules={[{ required: false, message: "Please enter project area" }]}
          initialValue={projectRes.area}
        >
          <Input type="number" />
        </Form.Item>

        <Form.Item
          name="priceRangeStart"
          label="Price range start"
          rules={[{ required: false, message: "Please price range start" }]}
          initialValue={projectRes?.basicDetail?.priceRange?.start}
        >
          <Input type="number" />
        </Form.Item>

        <Form.Item
          name="priceRangeEnd"
          label="Price range end"
          rules={[{ required: false, message: "Please price range end" }]}
          initialValue={projectRes?.basicDetail?.priceRange?.end}
        >
          <Input type="number" />
        </Form.Item>

        <Form.Item
          name="areaRangeStart"
          label="Area range start"
          rules={[{ required: false, message: "Please area range start" }]}
          initialValue={projectRes?.basicDetail?.areaRange?.start}
        >
          <Input type="number" />
        </Form.Item>

        <Form.Item
          name="areaRangeEnd"
          label="Area range end"
          rules={[{ required: false, message: "Please area range end" }]}
          initialValue={projectRes?.basicDetail?.areaRange?.end}
        >
          <Input type="number" />
        </Form.Item>

        <Form.Item
          name="launchDate"
          label="Launch Date"
          style={{
            margin: 0
          }}
          rules={[{ required: false }]}
          initialValue={dayjs(projectRes.basicDetail.launchDate, 'YYYY-MM-DD')}
        >
          <DatePicker
            inputReadOnly={isMobile()}
            style={{
              border: '1px solid #92B5D7',
              width: '98%',
              borderRadius: token.borderRadius,
              boxShadow: 'none',

            }}
            placeholder="Select launch date" />
        </Form.Item>

        <Form.Item
          name="possessionDate"
          label="Possession Date"
          style={{
            margin: 0
          }}
          rules={[{ required: false, message: 'Please input possession date' }]}
          initialValue={dayjs(projectRes.basicDetail.possessionDate, 'YYYY-MM-DD')}
        >
          <DatePicker
            inputReadOnly={isMobile()}
            style={{
              border: '1px solid #92B5D7',
              width: '98%',
              borderRadius: token.borderRadius,
              boxShadow: 'none',

            }}
            placeholder="Select possession date" />
        </Form.Item>

        <Form.Item
          name="reraId"
          label="Rera Id"
          rules={[{ required: false, message: "Please enter rera id" }]}
          initialValue={projectRes.basicDetail?.rera?.number}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="paymentPlan"
          label="Payment Plan"
          rules={[{ required: false, message: "Please enter a builder name" }]}
          initialValue={projectRes.paymentPlan}
        >
          <Input type="string" />
        </Form.Item>

        <Form.Item
          name="overview"
          label="Overview"
          rules={[{}]}
          initialValue={projectRes?.overview}
        >
          <Input.TextArea rows={4} placeholder="Enter overview" />
        </Form.Item>

        <Form.Item
          name="lineOne"
          label="Address Line One"
          rules={[{ required: false }]}
          initialValue={projectRes.basicDetail?.address?.lineOne}
        >
          <Input.TextArea rows={2} placeholder="Enter your address" />
        </Form.Item>
        <Form.Item
          name="lineTwo"
          label="Address Line Two"
          rules={[{ required: false }]}
          initialValue={projectRes.basicDetail?.address?.lineTwo}
        >
          <Input.TextArea rows={2} placeholder="Enter your address" />
        </Form.Item>
        <Form.Item
          name="street"
          label="Street"
          rules={[{ required: false }]}
          initialValue={projectRes.basicDetail?.address?.state}
        >
          <Input.TextArea rows={2} placeholder="Enter your street" />
        </Form.Item>
        <Form.Item
          name="city"
          label="City"
          rules={[{ required: false }]}
          initialValue={projectRes.basicDetail?.address?.city}
        >
          <Input placeholder="Enter your city" />
        </Form.Item>
        <Form.Item
          name="state"
          label="State"
          rules={[{ required: false }]}
          initialValue={projectRes.basicDetail?.address?.state}
        >
          <Input placeholder="Enter your state" />
        </Form.Item>
        <Form.Item
          name="pinCode"
          label="Pin Code"
          rules={[{ required: false }]}
          initialValue={projectRes.basicDetail?.address?.pinCode}
        >
          <Input type="number" placeholder="Enter your pin code" />
        </Form.Item>

        <Form.Item
          name="hideFromWebsite"
          label="Hide From Website"
          rules={[{ required: false }]}
          initialValue={projectRes.hideFromWebsite}
        >
          <Switch defaultChecked={projectRes.hideFromWebsite} />
        </Form.Item>


        <Form.Item
          name="tagList"
          label="Tag List"
          rules={[{ required: false, message: "Please enter a title" }]}
          initialValue={projectRes.tagList}
        >
          <Select
            mode="tags"
            value="tagList"
            maxTagCount="responsive"
            filterOption={handleFilter}
          />
        </Form.Item>

        <Form.Item
          name="seoTitle"
          label="Seo Title"
          rules={[{ required: false }]}
          initialValue={projectRes.seo?.title}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="seoDescription"
          label="Seo Description"
          rules={[{ required: false }]}
          initialValue={projectRes.seo?.description}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="seoOGTitle"
          label="Seo OG Title"
          rules={[{ required: false }]}
          initialValue={projectRes.seo?.OGTitle}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="seoOGDescription"
          label="Seo OG Description"
          rules={[{ required: false }]}
          initialValue={projectRes.seo?.OGDescription}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="seoOGMedia"
          label="SEO OG Thumbnail"
          rules={[{ required: false, message: 'Please add media' }]}
          initialValue={projectRes.seo?.OGMedia}
        >
          <FileUploadView onChange={() => { }} />
        </Form.Item>

        <Form.Item
          name="seoKeywordList"
          label="Seo Key words"
          rules={[{ required: false, message: 'Enter SEO Keywords' }]}
          initialValue={projectRes.seo?.keywordList}
        >
          <Select
            mode="tags"
            value='seoKeywordList'
            maxTagCount='responsive'
            style={{
              width: '250px',
              marginRight: '20px',
            }}
            filterOption={handleFilter}
          />
        </Form.Item>

        <Form.Item
          name="amenitiesIdList"
          label="Amenities"
          rules={[{ required: false, message: "Please select amenities" }]}
          initialValue={projectRes.amenitiesList?.map((amenities: Amenities) => {
            return amenities.id;
          })}
        >
          <Select
            mode="multiple"
            value="amenitiesIdList"
            style={{
              border: "1px solid #92B5D7",
              borderRadius: token.borderRadiusSM,
              width: "250px",
            }}
            options={amenitiesList?.map((amenities: AmenitiesRes) => {
              return {
                label: `${formatName(amenities.name)}`,
                value: amenities.id,
              } as ItemProp;
            })}
            filterOption={handleFilter}
          />
        </Form.Item>

        <Form.List name="unitConfigurationList"
          initialValue={projectRes?.unitConfigurationList?.map((unitConfiguration: UnitConfiguration) => {
            return {
              ...unitConfiguration,
              super: unitConfiguration.area.super,
              carpet: unitConfiguration.area.carpet,
              media: unitConfiguration.mediaList[0],
              priceRangeStart: unitConfiguration?.priceRange?.start,
              priceRangeEnd: unitConfiguration?.priceRange?.end,
              type: unitConfiguration?.commercialDetail?.type,
              commercialArea: unitConfiguration?.commercialDetail?.area,
              price: unitConfiguration?.commercialDetail?.price,
            }
          })}>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <Space key={field.key} align="baseline">

                  <MinusCircleOutlined onClick={() => remove(field.name)} />

                  <Row>
                    <Form.Item
                      {...field}
                      name={[field.name, 'media']}
                      label="Add Image"
                      rules={[{ required: true, message: 'Please Image' }]}
                    >
                      <FileUploadView onChange={() => { }} />
                    </Form.Item>

                  </Row>
                  {category == 'Residential' && <>
                    <Row>
                      <Form.Item
                        {...field}
                        name={[field.name, 'bhk']}
                        label="BHK Type"
                        rules={[{ required: true, message: 'Enter BHK' }]}
                      >
                        <Input type='number' />
                      </Form.Item>

                      <Form.Item
                        {...field}
                        name={[field.name, 'bedRoom']}
                        label="Bed Room Number"
                        rules={[{ required: true, message: 'Enter Bed Room Number' }]}
                      >
                        <Input type='number' />
                      </Form.Item>

                    </Row>

                    <Row>

                      <Form.Item
                        {...field}
                        name={[field.name, 'kitchen']}
                        label="Kitchen Number"
                        rules={[{ required: true, message: 'Enter Kitchen Number' }]}
                      >
                        <Input type='number' />
                      </Form.Item>

                      <Form.Item
                        {...field}
                        name={[field.name, 'bathRoom']}
                        label="Bath Room Number"
                        rules={[{ required: true, message: 'Enter Bath Room Number' }]}
                      >
                        <Input type='number' />
                      </Form.Item>


                      <Form.Item
                        {...field}
                        name={[field.name, 'balcony']}
                        label="Balcony Number"
                        rules={[{ required: true, message: 'Enter Balcony Number' }]}
                      >
                        <Input type='number' />
                      </Form.Item>

                    </Row>

                    <Row>


                      <Form.Item
                        {...field}
                        name={[field.name, 'living']}
                        label="Living Room Number"
                        rules={[{ required: true, message: 'Enter Living Room Number' }]}
                      >
                        <Input type='number' />
                      </Form.Item>

                      <Form.Item
                        {...field}
                        name={[field.name, 'super']}
                        label="Super Area"
                        rules={[{ required: true, message: 'Enter Super Area' }]}
                      >
                        <Input type='number' />
                      </Form.Item>

                      <Form.Item
                        {...field}
                        name={[field.name, 'carpet']}
                        label="Carpet Area"
                        rules={[{ required: true, message: 'Enter Carpet Area' }]}
                      >
                        <Input type='number' />
                      </Form.Item>

                    </Row>

                    <Row>

                      <Form.Item
                        {...field}
                        name={[field.name, 'priceRangeStart']}
                        label="Price range start"
                        rules={[{ required: true, message: 'Please enter price range start' }]}
                      >
                        <Input type="number" />
                      </Form.Item>

                      <Form.Item
                        {...field}
                        name={[field.name, 'priceRangeEnd']}
                        label="Price Range end"
                        rules={[{ required: true, message: 'Please enter price range end' }]}
                      >
                        <Input type="number" />
                      </Form.Item>

                      <Form.Item
                        {...field}
                        name={[field.name, 'description']}
                        label="Description"
                        rules={[{ required: true, message: 'Enter description' }]}
                      >
                        <Input type='string' />
                      </Form.Item>

                      <Form.Item
                        {...field}
                        name={[field.name, 'availability']}
                        label="Availability"
                        rules={[{ required: false, message: 'Please enter availability' }]}
                      >
                        <Switch />
                      </Form.Item>

                    </Row>
                  </>}
                  {category == 'Commercial' && <>
                    <Row>

                      <Form.Item
                        {...field}
                        name={[field.name, 'type']}
                        label="Unit Type"
                        rules={[{ required: true, message: 'Enter Unit type' }]}
                      >
                        <Input type='string' />
                      </Form.Item>

                      <Form.Item
                        {...field}
                        name={[field.name, 'commercialArea']}
                        label="Area"
                        rules={[{ required: true, message: 'Enter Area(sq.ft.)' }]}
                      >
                        <Input type='number' />
                      </Form.Item>

                      <Form.Item
                        {...field}
                        name={[field.name, 'price']}
                        label="Price"
                        rules={[{ required: true, message: 'Enter price' }]}
                      >
                        <Input type='number' />
                      </Form.Item>

                    </Row>
                  </>}
                </Space>
              ))}

              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Add Unit Configuration
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Form.List name="faqList"
          initialValue={projectRes?.faqList}>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <Space key={field.key} align="baseline">

                  <Form.Item
                    {...field}
                    name={[field.name, 'question']}
                    label="Question"
                    rules={[{ required: true, message: 'Please enter question' }]}
                  >
                    <Input type='string' />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    name={[field.name, 'answer']}
                    label="Answer"
                    rules={[{ required: true, message: 'Please enter answer' }]}
                  >
                    <Input type='string' />
                  </Form.Item>

                  <Form.Item
                    {...field}
                    name={[field.name, 'order']}
                    label="Order"
                    rules={[{ required: true, message: 'Please enter order' }]}
                  >
                    <Input type='number' />
                  </Form.Item>

                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                </Space>
              ))}

              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Add FAQ
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
};
