import { Button, Space, Spin, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { CityRes } from '../../../response/city.response';
import { useCity } from '../city.hook';
import { AddCityComponent } from './add-city.component';
import { UpdateCityComponent } from './update-city.component';
import { CityFilterComponent } from './filter.component';
import { getColorBynumber } from '../../../enum/static-data.enum';

export const MainCityTableComponent = () => {
    const { loading, cityList, fetchCityList, citySearchReq, updateLoading } = useCity()
    const [addNewCity, setOpenNewCity] = useState(false)
    const [updateCity, setUpdateCity] = useState<CityRes | undefined>(undefined)

    const cancelCityEdit = () => {
        setUpdateCity(prevVal => undefined);
    }
    const updateOpenNewCity = () => {
        if (addNewCity) {
            fetchCityList({ ...citySearchReq, pageNumber: 1 }, () => { updateLoading(false) }, () => { updateLoading(false) })
        }
        setOpenNewCity(prevVal => !prevVal);
    }

    const columnList: ColumnsType<CityRes> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            onCell: (city, rowIndex) => {
                return {
                    onClick: (ev) => {
                        setUpdateCity(city)
                    },
                };
            },
            render: (id) => <a>Edit</a>,
        },
    ];

    useEffect(() => {
        fetchCityList({ ...citySearchReq, pageNumber: 1 }, () => { updateLoading(false) }, () => { updateLoading(false) })
    }, [])

    return <div style={{ marginTop: -42 }}>
        <Space direction="horizontal" style={{ width: '100%', justifyContent: 'end', marginBottom: 8 }}>
            <CityFilterComponent />
            <Button type="primary" onClick={updateOpenNewCity}>Add New</Button>
        </Space>
        <Table
            className='task-main-table task-table'
            columns={columnList} dataSource={cityList}
            pagination={{ defaultPageSize: 30, showSizeChanger: true, pageSizeOptions: ['20', '50', '100', '200', '300', '400', '500'] }}
            loading={{ indicator: <div><Spin /></div>, spinning: loading }} />
        {addNewCity &&
            <AddCityComponent open={addNewCity} onCancel={updateOpenNewCity} />
        }

        {updateCity &&
            <UpdateCityComponent city={updateCity} open={true} onCancel={cancelCityEdit} />
        }
    </div>
}