import { createContext, useReducer } from "react";
import { TemplateState } from "./template.state";
import { TemplateReducer } from "./template.reducer";
import { TemplateAction } from "./template.action";
import { TemplateSearchReq, TemplateRequest } from "../../../request/template.request";
import { TemplateRes } from "../../../response/template.response";
import { TemplateService } from "../../../service/template.service";

const initialState = {
    loading: false,
    templateSearchReq: {
        pageNumber: 1,
        itemsPerPage: 100
    } as TemplateSearchReq,
    templateList: [],
    typeList: []
} as TemplateState

export const TemplateContext = createContext<any>(initialState);

export const TemplateStateProvider = ({ children }: any) => {

    const [state, dispatch] = useReducer(TemplateReducer, initialState)


    const updateLoading = (loading: Boolean) => {
        dispatch({
            type: TemplateAction.UPDATE_LOADING,
            payload: {
                loading: loading
            }
        });
    };

    const fetchTemplateList = async (templateSearchReq: TemplateSearchReq, onSuccess: () => void, onFailure: () => void) => {
        updateLoading(true)
        const [templateList, error] = await TemplateService.getTemplateList(templateSearchReq)

        if (error) {
            onFailure()
        } else {
            dispatch({
                type: templateSearchReq.pageNumber == 1 ? TemplateAction.UPDATE_TEMPLATE_LIST : TemplateAction.ADD_TEMPLATE_LIST,
                payload: {
                    templateList: templateList
                }
            })
            if (templateList.length > 0) {
                dispatch({
                    type: TemplateAction.UPDATE_TEMPLATE_SEARCH_REQ,
                    payload: {
                        templateSearchReq: {
                            ...templateSearchReq,
                            pageNumber: templateSearchReq.pageNumber + 1
                        }
                    }
                })
            }
            onSuccess()
        }
    };

    const saveTemplate = async (templateRequest: TemplateRequest, onSuccess: () => void, onFailure: () => void) => {
        const [data, error] = await TemplateService.saveTemplate(templateRequest)

        if (error) {
            onFailure()
        } else {
            onSuccess()
        }
    }

    const updateTemplate = async (id: string, templateRequest: TemplateRequest, onSuccess: () => void, onFailure: () => void) => {
        const [data, error] = await TemplateService.updateTemplate(id, templateRequest)

        if (error) {
            onFailure()
        } else {
            onSuccess()
        }
    }

    const value = {
        templateList: state.templateList,
        loading: state.loading,
        templateSearchReq: state.templateSearchReq,
        typeList: state.typeList,
        updateLoading,
        fetchTemplateList,
        saveTemplate,
        updateTemplate
    };

    return <TemplateContext.Provider value={value}>{children}</TemplateContext.Provider>
};