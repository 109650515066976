import { Button, Card, Col, DatePicker, Modal, Row, Timeline, Typography } from "antd";
import { useEffect, useState } from "react";
import { EmployeeActivityService } from "../../../../../service/employee-activity.service";
import { EmployeeActivitySearchReq } from "../../../../../request/employee-activity.request";
import { EmployeeActivityRes } from "../../../../../response/employee-activity.response";
import { EmployeeAction } from "../../../../../model/employee-action.model";
import { groupList } from "../../../../../util/group-convertor";
import dayjs, { Dayjs } from "dayjs";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { isMobile } from "../../../../../util/device-helper";
import WordColudChart from "./charts/word-colud-chart.component";
import { getColor } from "../../../../../enum/static-data.enum";

const { RangePicker } = DatePicker;

export interface WordCloudData {
    name: String
    weight: Number
}

export interface MarkerData {
    title: string
    point: any
}

export interface TimeLineAction {
    createdOn: Date
    actionList: EmployeeAction[]
}

export interface TimelineComponentProp {
    employeeId: string
}

const TimelineComponent = (props: TimelineComponentProp) => {

    const { employeeId } = props

    const [wordCloudDataList, setWordCloudDataList] = useState<WordCloudData[]>([])

    const [markerList, setMarkerList] = useState<any[]>([])
    const [timeLineActionList, setTimeLineActionList] = useState<TimeLineAction[]>([])

    const { Title, Text, Paragraph } = Typography;
    const [openTimelineModal, setOpenTimelineModal] = useState(false);

    const fetchActivity = async () => {
        try {
            const [data, err] = await EmployeeActivityService.getEmployeeActivityList({
                employeeIdList: [employeeId],
                itemsPerPage: 200,
                pageNumber: 1
            } as EmployeeActivitySearchReq)

            if (err) {

            } else {
                const actionList: EmployeeAction[] = []
                const _markerList: any[] = []
                data.map((employeeActivityRes: EmployeeActivityRes) => {
                    employeeActivityRes.actionList.map((employeeAction: EmployeeAction) => {
                        employeeAction.metaData.map((data: any) => {
                            try {
                                // _markerList.push({ lat: Number(data.Value[1].Value[1]), lng: Number(data.Value[1].Value[0]) })
                                const newMarker: MarkerData = {
                                    title:  employeeAction.name,
                                    point: {
                                        lat: Number(data.Value[1].Value[1]),
                                        lng: Number(data.Value[1].Value[0])
                                    }
                                }
                                _markerList.push(newMarker)
                            } catch {

                            }
                        })
                    })
                    actionList.push(...employeeActivityRes.actionList)
                })

                setMarkerList(_markerList)

                const graphMap = groupList(actionList, (item) => item.name)

                const _wordCloudDataList = graphMap.map((itemList: EmployeeAction[]) => {
                    return {
                        name: itemList[0].name,
                        weight: itemList.length
                    }
                })

                const timelineMap = groupList(actionList, (item) => dayjs(item.createdOn).format('DD MMMM YYYY, dddd hh A'))

                const _timeLineActionList = timelineMap.map((itemList: EmployeeAction[]) => {
                    return {
                        createdOn: itemList[0].createdOn,
                        actionList: itemList
                    } as TimeLineAction
                })

                setTimeLineActionList(_timeLineActionList)
                setMarkerList(_markerList)
                setWordCloudDataList(_wordCloudDataList)
            }
        } catch (err: any) {
            console.log("err")
        }
    }
    useEffect(() => {
        fetchActivity()
    }, [employeeId])

    const rangePresets: {
        label: string;
        value: [Dayjs, Dayjs];
    }[] = [
            { label: 'Today', value: [dayjs(), dayjs()] },
            { label: 'Yesterday', value: [dayjs().add(-1, 'd'), dayjs()] },
            { label: 'Last 7 Days', value: [dayjs().add(-7, 'd'), dayjs()] },
            { label: 'Last 14 Days', value: [dayjs().add(-14, 'd'), dayjs()] },
            { label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()] },
            { label: 'Last 90 Days', value: [dayjs().add(-90, 'd'), dayjs()] },
        ];

    const onDateUpdate = async (values: any[]) => {
        try {
            const [data, err] = await EmployeeActivityService.getEmployeeActivityList({
                employeeIdList: [employeeId],
                startDate: (() => {
                    const date = new Date(values[0])
                    date.setHours(0, 0, 0)
                    return date
                })(),
                endDate: (() => {
                    const date = new Date(values[1])
                    date.setHours(23, 59, 59)
                    return date
                })(),
                itemsPerPage: 200,
                pageNumber: 1
            } as EmployeeActivitySearchReq)

            if (err) {

            } else {
                const actionList: EmployeeAction[] = []
                const _markerList: MarkerData[] = []
                data.map((employeeActivityRes: EmployeeActivityRes) => {
                    employeeActivityRes.actionList.map((employeeAction: EmployeeAction) => {
                        employeeAction.metaData.map((data: any) => {
                            if (data.Key == "location" && data.Value.length >= 2 && data.Value[1].Value && data.Value[1].Value.length >= 2) {
                                try {
                                    const newMarker: MarkerData = {
                                        title: employeeAction.name,
                                        point: {
                                            lat: Number(data.Value[1].Value[1]),
                                            lng: Number(data.Value[1].Value[0])
                                        }
                                    }
                                    _markerList.push(newMarker)
                                    // _markerList.push({ lat: Number(data.Value[1].Value[1]), lng: Number(data.Value[1].Value[0]) })
                                } catch {

                                }
                            }
                        })
                    })
                    actionList.push(...employeeActivityRes.actionList)
                })
                const graphMap = groupList(actionList, (item) => item.name)

                const _wordCloudDataList = graphMap.map((itemList: EmployeeAction[]) => {
                    return {
                        name: itemList[0].name,
                        weight: itemList.length
                    }
                })

                const timelineMap = groupList(actionList, (item) => dayjs(item.createdOn).format('DD MMMM YYYY, dddd hh A'))

                const _timeLineActionList = timelineMap.map((itemList: EmployeeAction[]) => {
                    return {
                        createdOn: itemList[0].createdOn,
                        actionList: itemList
                    } as TimeLineAction
                })

                setTimeLineActionList(_timeLineActionList)
                setMarkerList(_markerList)
                setWordCloudDataList(_wordCloudDataList)
            }
        } catch (err: any) {
            console.log("markerList", err)
        }
    }

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY!,
    });

    const onLoad = (map: any) => {
        const bounds = new google.maps.LatLngBounds();
        markerList?.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
        map.fitBounds(bounds);
    };

    return (
        <>

            <Card className="p-mweb-card" style={{
                height: '100%',
                padding: '0 20px'
            }}
                headStyle={{
                    padding: '10px 0 0',
                    minHeight: 44
                }}
                bodyStyle={{
                    padding: '10px 0',
                    color: '#000'
                }}
                title="Timeline"
                bordered={false}
                extra={<Button type="link" style={{ color: '#6D6C68' }} onClick={() => setOpenTimelineModal(true)}>View timeline</Button>}
            >
                <Row style={{ alignItems: 'center' }}>
                    <Col md={5} xs={24} style={{ marginRight: 10 }}>
                        {/* <DatePicker /> */}
                        <RangePicker
                            inputReadOnly={isMobile()}
                            presets={rangePresets}
                            allowClear={true}
                            onChange={(value: any) => {
                                onDateUpdate(value)
                            }} />
                    </Col>
                    <Col md={18} xs={24}>
                        <WordColudChart data={wordCloudDataList ? wordCloudDataList : []} />
                    </Col>
                </Row>

                <Row style={{ alignItems: 'center' }}>
                    {!isLoaded ? (
                        <h1>Loading...</h1>
                    ) : (
                        <GoogleMap
                            zoom={4}
                            mapContainerClassName="map-container" onLoad={onLoad}
                            center={markerList[0]}>
                            {markerList.map(({ title, point }) => (
                                <Marker position={{ lat: point.lat, lng: point.lng }}
                                    label={title.match(/\b\w/g).join('')} />
                            ))}
                        </GoogleMap>
                    )}
                </Row>
            </Card>

            <Modal
                title="Timeline"
                open={openTimelineModal}
                onCancel={() => setOpenTimelineModal(false)}
                footer={false}
                width={1000}
                style={{ top: 20 }}
                bodyStyle={{ height: '85vh' }}
                className="timeline-modal"
            >
                <div className="timeline-wrapper" style={{ height: '98%', overflowY: 'auto', paddingTop: 10 }}>
                    <Timeline
                        items={
                            timeLineActionList.map((timeLineAction: TimeLineAction) => {
                                return {
                                    color: getColor(timeLineAction.actionList[0].name),
                                    children: (
                                        <>
                                            <div style={{ alignItems: 'left' }}>
                                                <Title style={{ color: '#343433', fontSize: 14, marginRight: 5 }}>
                                                    {`${timeLineAction.actionList.length} Action`}
                                                </Title>
                                                <Text style={{ color: '#343433', fontSize: 12, marginRight: 5 }}>
                                                    {timeLineAction.actionList.map((action: EmployeeAction) => {
                                                        return action.name
                                                    }).toString()}
                                                </Text>
                                                <br />
                                                <Text style={{ color: '#343433', fontSize: 10, marginRight: 5 }}> {`${dayjs(timeLineAction.createdOn).format('DD MMMM YYYY, dddd hh A')}`}</Text>
                                            </div>
                                        </>
                                    ),
                                }
                            })
                        }
                    />
                </div>
            </Modal>
        </>
    )
}

export default TimelineComponent