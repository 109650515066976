import { Action } from "../../model/action.model";
import { CallAction } from "./call.action";
import { CallState } from "./call.state";

export const CallReducer = (state: CallState, action: Action): CallState => {
    const { type, payload } = action

    switch (type) {
        case CallAction.UPDATE_CALL_LIST:
            return {
                ...state,
                callList: [...payload.callList]
            };
        case CallAction.ADD_CALL_LIST:
            return {
                ...state,
                callList: [...state.callList, ...payload.callList]
            };
        case CallAction.UPDATE_CALL_SEARCH_REQ:
            return {
                ...state,
                callSearchReq: { ...payload.callSearchReq }
            };
        case CallAction.UPDATE_LOADING:
            return {
                ...state,
                loading: payload.loading
            };
        case CallAction.UPDATE_EMPLOYEE_LIST:
            return {
                ...state,
                employeeList: [...payload.employeeList]
            };
        default:
            throw new Error(`No case for type ${type} found in CallReducer.`);
    }
}