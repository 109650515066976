import { createContext, useReducer } from "react";
import { TeamState } from "./team.state";
import { TeamReducer } from "./team.reducer";
import { TeamAction } from "./team.action";
import { TeamService } from "../../service/team.service";
import { TeamRes } from "../../response/team.response";
import { TeamRequest, TeamSearchReq } from "../../request/team.request";
import { EmployeeService } from "../../service/employee.service";
import { OfficeService } from "../../service/office.service";
import { EmployeeSearchReq } from "../../request/employee.request";
import { OfficeSearchReq } from "../../request/office.request";


const initialState = {
    loading: false,
    teamSearchReq: {
        pageNumber: 1,
        itemsPerPage: 40
    } as TeamSearchReq,
    teamList: [],
    employeeList: [],
    employeeSearchReq: {
        pageNumber: 1,
        itemsPerPage: 200
    } as EmployeeSearchReq,
    officeList: [],
    officeSearchReq: {
        pageNumber: 1,
        itemsPerPage: 100
    } as OfficeSearchReq,
    teamTypeList: [],
    updateTeamData: {} as TeamRes
} as TeamState

export const TeamContext = createContext<any>(initialState);

export const TeamStateProvider = ({ children }: any) => {

    const [state, dispatch] = useReducer(TeamReducer, initialState)


    const updateLoading = (loading: Boolean) => {
        dispatch({
            type: TeamAction.UPDATE_LOADING,
            payload: {
                loading: loading
            }
        });
    };

    const fetchTeamList = async (teamSearchReq: TeamSearchReq, onSuccess: () => void, onFailure: () => void) => {
        updateLoading(true)
        const [teamList, error] = await TeamService.getTeamList(teamSearchReq)

        if (error) {
            onFailure()
        } else {
            dispatch({
                type: teamSearchReq.pageNumber == 1 ? TeamAction.UPDATE_TEAM_LIST : TeamAction.ADD_TEAM_LIST,
                payload: {
                    teamList: teamList
                }
            })
            if (teamList.length > 0) {
                dispatch({
                    type: TeamAction.UPDATE_TEAM_SEARCH_REQ,
                    payload: {
                        teamSearchReq: {
                            ...teamSearchReq,
                            pageNumber: teamSearchReq.pageNumber + 1
                        }
                    }
                })
            }
            onSuccess()
        }
    };

    const saveTeam = async (teamRequest: TeamRequest, onSuccess: () => void, onFailure: () => void) => {
        const [data, error] = await TeamService.saveTeam(teamRequest)

        if (error) {
            onFailure()
        } else {
            onSuccess()
        }
    }

    const updateTeam = async (id: string, teamRequest: TeamRequest, onSuccess: () => void, onFailure: () => void) => {
        const [data, error] = await TeamService.updateTeam(id, teamRequest)

        if (error) {
            onFailure()
        } else {
            onSuccess()
        }
    }

    const fetchEmployeeList = async () => {
        const [data, error] = await EmployeeService.getEmployeeList(state.employeeSearchReq)

        if (error) {

        } else {
            dispatch({
                type: TeamAction.UPDATE_EMPLOYEE_LIST,
                payload: {
                    employeeList: data
                }
            });
        }
    };

    const fetchOfficeList = async () => {
        const [data, error] = await OfficeService.getOfficeList(state.officeSearchReq)

        if (error) {

        } else {
            dispatch({
                type: TeamAction.UPDATE_OFFICE_LIST,
                payload: {
                    officeList: data
                }
            });
        }
    };

    const fetchTeamTypeList = async () => {
        const [data, error] = await TeamService.getTeamTypeList()

        if (error) {

        } else {
            dispatch({
                type: TeamAction.UPDATE_TEAM_TYPE_LIST,
                payload: {
                    teamTypeList: data
                }
            });
        }
    };


    const value = {
        teamList: state.teamList,
        loading: state.loading,
        teamSearchReq: state.teamSearchReq,
        employeeList: state.employeeList,
        teamTypeList: state.teamTypeList,
        officeList: state.officeList,
        updateLoading,
        fetchTeamList,
        saveTeam,
        fetchEmployeeList,
        fetchOfficeList,
        fetchTeamTypeList,
        updateTeam
    };

    return <TeamContext.Provider value={value}>{children}</TeamContext.Provider>
};