import { Button, Spin, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { EnquiryRes } from '../../../response/enquiry.response';
import { useEnquiry } from '../enquiry.hook';
import { TextEditorView } from '../../../view/text-editor.view';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { UpdateEnquiryOwnerComponent } from './update-owner.component';
import { formatName } from '../../../util/name-helper';
import ImgIcon from '../../../view/img-icon';
import React from 'react';

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

export const MainEnquiryTableComponent = () => {

    const [updateOwner, setUpdateOwner] = useState(false)
    const { enquiryList, enquirySearchReq, loading, fetchEnquiryList, updateLoading } = useEnquiry()

    const columnList: ColumnsType<EnquiryRes> = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            render: (id) => <Link to={`/enquiry/${id}`} target={isMobile ? "" : "_blank"} rel={isMobile ? "" : "noopener noreferrer"}>{id.substring(0, 6)}...</Link>,
        },
        {
            title: 'Client Name',
            dataIndex: 'client',
            key: 'client',
            render: (client) => <span>{formatName(client.name)}</span>,
        },
        {
            title: 'Source',
            dataIndex: 'source',
            key: 'source',
        },
        {
            title: 'Requirement',
            dataIndex: 'requirement',
            render: (requirement) => <div style={{ maxWidth: 216 }}><TextEditorView initData={requirement} onChange={() => { }} readOnly={true} /></div>,
        },
        {
            title: 'Owner',
            dataIndex: 'owner',
            key: 'owner',
            render: (owner) => <span>{formatName(owner.assignedTo.name)}</span>,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => <React.Fragment>
                <span style={{ textTransform: 'capitalize', display: 'flex' }}>
                    {status.value === 'Pending' &&
                        <ImgIcon iconName="open.svg" />
                    }
                    {status.value === 'Closed' &&
                        <ImgIcon iconName="close.svg" />
                    }
                    {status.value === 'In Progress' &&
                        <ImgIcon iconName="inprogress.svg" />
                    }
                    {status.value === 'Not Interested' &&
                        <ImgIcon iconName="rejected.svg" />
                    }
                    {status.value === 'Dump / Spam' &&
                        <ImgIcon iconName="rejected.svg" />
                    }
                    {status.value}
                </span>
            </React.Fragment>,
        },
        {
            title: 'Project Name',
            dataIndex: 'project',
            key: 'project',
            render: (project) => <span>{project?.name}</span>,
        }
    ];

    const [selectedEnquiryIdList, setSelectedEnquiryIdList] = useState<string[]>([])

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: EnquiryRes[]) => {
            setSelectedEnquiryIdList(selectedRows.map((selectedRow: EnquiryRes) => { return selectedRow.id }))
        },
        getCheckboxProps: (enquiryRes: EnquiryRes) => ({
            disabled: false,
            name: enquiryRes.id,
        }),
    };

    const clearFilter = async () => {
        updateLoading(true)
        await fetchEnquiryList({
            ...enquirySearchReq,
            statusList: [] as string[],
            assigneeIdList: [],
            pageNumber: 1
        }, () => { updateLoading(false) }, () => { updateLoading(false) })
    }

    return <>
        {selectedEnquiryIdList.length > 0 && <Button style={{ marginTop: 20, marginLeft: 20 }} onClick={() => setUpdateOwner(true)}>Assign To</Button>}

        {(enquiryList.length > 0 && !loading) ? <Table
            style={{ marginTop: 16 }}
            rowSelection={{
                type: 'checkbox',
                ...rowSelection,
            }}
            columns={columnList} dataSource={enquiryList.map((enquiry: EnquiryRes) => { return { ...enquiry, key: enquiry.id } })}
            className='task-main-table task-table'
            pagination={{
                onChange(page, pageSize) {
                    console.log(page * pageSize > (enquiryList.length - pageSize))
                    if (page * pageSize > (enquiryList.length - pageSize) && enquiryList.length > pageSize) {
                        fetchEnquiryList({ ...enquirySearchReq }, () => { updateLoading(false) }, () => { updateLoading(false) })
                    }
                },
                showSizeChanger: true,
                onShowSizeChange(current, size) {
                },
                pageSize: 50,
                pageSizeOptions: ['20', '30', '50']
            }}
            loading={{ indicator: <div><Spin /></div>, spinning: loading }} /> : loading ? <Spin style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '24vh' }} /> :
            <div style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '24vh', width: 'fit-content' }} >
                <p>No Data Found</p>
                <Button onClick={clearFilter} type='primary'>Clear Filter</Button>
            </div>}


        {updateOwner && <UpdateEnquiryOwnerComponent enquiryIdList={selectedEnquiryIdList} open={updateOwner} onCancel={() => { setUpdateOwner(false) }} onOwnerSuccess={() => { setUpdateOwner(false) }} />}
    </>
}