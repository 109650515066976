import { useEffect } from 'react';
import { TaskHistoryRes } from '../../../response/task-history.response';
import { TextEditorView } from '../../../view/text-editor.view';
import { useTask } from '../task.hook';
import { Avatar, Tooltip } from 'antd';
import { Comment } from '@ant-design/compatible';
import { getColor } from '../../../enum/static-data.enum';

export const HistoryComponent: React.FC<{ taskId: string }> = ({ taskId }) => {

    const { historyList, fetchTaskHistoryList } = useTask()

    useEffect(() => {
        fetchTaskHistoryList(taskId)
    }, [])

    return <>
        {historyList.map((history: TaskHistoryRes) => {
            return (
                <Comment
                    className='read-only'
                    style={{
                        background: 'transparent'
                    }}
                    author={<span>{history.createdBy.name}</span>}
                    avatar={<Avatar style={{backgroundColor: getColor(history.createdBy.name[0])}}>{history.createdBy.name[0].toUpperCase()}</Avatar>}
                    content={
                        <TextEditorView initData={history.description} onChange={(data: string) => {}} readOnly={true} />
                    }
                    datetime={
                        <Tooltip title="2016-11-22 11:22:33">
                            <span>{new Date(history.createdOn).toLocaleDateString('en', { year: 'numeric', month: 'long', day: 'numeric' })}</span>
                        </Tooltip>
                    }
                />
            );
        })}
    </>
}