import { Action } from "../../model/action.model";
import { WhatsAppChatAction } from "./whatsapp-chat.action";
import { WhatsAppChatState } from "./whatsapp-chat.state";


export const WhatsAppChatReducer = (state: WhatsAppChatState, action: Action): WhatsAppChatState => {
    const { type, payload } = action

    switch (type) {
        case WhatsAppChatAction.UPDATE_LOADING:
            return {
                ...state,
                loading: payload.loading
            };
        case WhatsAppChatAction.UPDATE_WHATS_APP_CHAT_LIST:
            return {
                ...state,
                whatsAppChatList: [...payload.whatsAppChatList]
            };
        case WhatsAppChatAction.ADD_WHATS_APP_CHAT_LIST:
            return {
                ...state,
                whatsAppChatList: [...state.whatsAppChatList, ...payload.whatsAppChatList]
            };
        case WhatsAppChatAction.UPDATE_WHATS_APP_CHAT_SEARCH_REQ:
            return {
                ...state,
                whatsAppChatSearchReq: { ...payload.whatsAppChatSearchReq }
            };
        case WhatsAppChatAction.SET_UPDATE_WHATS_APP_CHAT:
            return {
                ...state,
                updateWhatsAppChatData: { ...payload.updateWhatsAppChatData }
            };
        default:
            throw new Error(`No case for type ${type} found in WhatsAppChatReducer.`);
    }
}