import { ProjectStateProvider } from './project.context';
import { MainProjectTableComponent } from './component/table.component';

const ProjectPageBase = () => {

    return <>
        <MainProjectTableComponent />
    </>
}


export const ProjectPage = () => {
    return <ProjectStateProvider>
        <ProjectPageBase />
    </ProjectStateProvider>
}

